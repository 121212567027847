import { referenceRegExpTester } from '@/bundles/Shared/entities/formula';
import {
  REFERENCE_FIELD_NOTE,
  VARIABLE_VALUE_TYPE_OPTIONS,
} from '@/bundles/Shared/entities/formula/config';
import { LabelFieldController } from '@/shared/ui/LabelFieldController';
import {
  useDeleteApiReconcileUnderwritingBudgetsByBudgetIdMetricsAndBudgetMetricIdMutation,
  useGetApiReconcileUnderwritingBudgetsByBudgetIdMetricsQuery,
  usePostApiReconcileBudgetMetricsMutation,
  usePostApiReconcileUnderwritingBudgetsByBudgetIdMetricsMutation,
  usePutApiReconcileBudgetMetricsByIdMutation,
} from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetEnhancedApi';
import {
  LegalEntityUnderwritingBudget,
  UnderwritingBudgetMetric,
} from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetGeneratedApi';
import { useLoadedUnderwritingBudget } from 'bundles/REconcile/underwritting/lib';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import { SettingsModal } from 'bundles/Shared/components/SettingsModal/SettingsModal';
import { useDebouncedQuery } from '@/shared/lib/hooks/useDebouncedQuery';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { asserts } from 'lib/typeHelpers/assertsType';
import React, { ComponentProps } from 'react';
import { Modal, SidePanel } from 'stories/Modals/Modal/Modal';
import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';
import {
  Button,
  Checkbox,
  Field,
  IconButton,
  Input,
  ModalHeaderWithSubtitle,
  SearchInput,
  Textarea,
} from 'stories/index';
import {
  PERIOD_AGGREGATION_STRATEGIES,
  PERIOD_AGGREGATION_STRATEGIES_OPTIONS,
} from 'bundles/Shared/entities/periodAggregationStrategy';
import * as yup from 'yup';
import { getOptionsValues } from '@/shared/lib/listHelpers';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldsContainer } from 'stories/Field/Field';
import { RadioGroupController } from 'stories/RadioButton/RadioGroup';
import { SharedSelectController } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';

const METRIC_SCHEMA = yup.object().shape({
  reference: yup.string().required().test(referenceRegExpTester),
  name: yup.string().required(),
  valueType: yup
    .string()
    .oneOf(getOptionsValues(VARIABLE_VALUE_TYPE_OPTIONS))
    .required()
    .nullable(),
  periodAggregationStrategy: yup
    .string()
    .oneOf(Object.values(PERIOD_AGGREGATION_STRATEGIES))
    .required()
    .nullable(),
  description: yup.string().optional(),
});

type MetricForm = yup.InferType<typeof METRIC_SCHEMA>;

const DEFAULT_METRIC_FORM: MetricForm = {
  name: '',
  reference: '',
  valueType: null,
  periodAggregationStrategy: null,
  description: '',
};

const MetricsModal = ({
  onClose,
  onSubmit,
  defaultValues,
}: DialogProps<MetricForm> & {
  defaultValues?: MetricForm;
}) => {
  const methods = useForm<MetricForm>({
    resolver: yupResolver(METRIC_SCHEMA),
    defaultValues,
  });

  const {
    control,
    formState: { isDirty, isValid, isSubmitting },
  } = methods;
  const handleSubmit = methods.handleSubmit((values) => onSubmit?.(values));
  return (
    <Modal
      toggle={onClose}
      header={
        <ModalHeaderWithSubtitle
          title={defaultValues?.reference ? 'Edit' : 'Create'}
          subtitle="KPIs & Metrics"
        />
      }
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!isDirty || !isValid || isSubmitting}
            variant="success"
            onClick={handleSubmit}
          >
            {defaultValues?.reference ? 'Save updates' : 'Create'}
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <FieldsContainer>
          <Field labelText="Variable Name" required>
            <LabelFieldController
              control={control}
              name="name"
              referenceFieldName="reference"
            />
          </Field>
          <Field labelText="Reference" required note={REFERENCE_FIELD_NOTE}>
            <Input {...methods.register('reference')} />
          </Field>
          <Field labelText="Value Type" required>
            <RadioGroupController
              control={control}
              name="valueType"
              options={VARIABLE_VALUE_TYPE_OPTIONS}
            />
          </Field>
          <Field labelText="Period Aggregation Strategy" required>
            <SharedSelectController
              control={control}
              name="periodAggregationStrategy"
              options={PERIOD_AGGREGATION_STRATEGIES_OPTIONS}
            />
          </Field>
          <Field labelText="Description" required>
            <Textarea {...methods.register('description')} />
          </Field>
        </FieldsContainer>
      </FormProvider>
    </Modal>
  );
};

const useOpenMetricSettingsModal = () => {
  const { openModal } = useModal();

  return async (
    args: {
      initialForm: MetricForm;
    } = {
      initialForm: DEFAULT_METRIC_FORM,
    },
  ) => {
    const res = await openModal(MetricsModal, {
      defaultValues: args.initialForm,
    });

    return res;
  };
};

export function MetricsSettingsPanel({
  onClose,
  budgetId,
}: DialogProps & {
  budgetId: NonNullish<LegalEntityUnderwritingBudget['budget']>['id'];
}) {
  const { data: currentBudget } = useLoadedUnderwritingBudget({ budgetId });
  const { confirm } = useModal();

  const { query, debouncedQuery, handleChange, resetQuery } =
    useDebouncedQuery();
  const openMetricSettingsModal = useOpenMetricSettingsModal();
  const [createMetric] = usePostApiReconcileBudgetMetricsMutation();
  const [updateMetricForUWB] = usePutApiReconcileBudgetMetricsByIdMutation();
  const [addMetricToBudget, { isLoading: isAddingMetricToBudget }] =
    usePostApiReconcileUnderwritingBudgetsByBudgetIdMetricsMutation();
  const [deleteMetricFromBudget, { isLoading: isDeletingMetricFromBudget }] =
    useDeleteApiReconcileUnderwritingBudgetsByBudgetIdMetricsAndBudgetMetricIdMutation();

  const { data, isFetching, isLoading } =
    useGetApiReconcileUnderwritingBudgetsByBudgetIdMetricsQuery({
      budgetId,
      query: debouncedQuery,
    });

  const checkboxesDisabled =
    isAddingMetricToBudget ||
    isDeletingMetricFromBudget ||
    isLoading ||
    isFetching;

  const editMetricDisabled = isLoading || isFetching;

  const searchInputSearching = isLoading || isFetching;

  const handleCreate = async () => {
    const res = await openMetricSettingsModal();

    if (res == null) return;

    await createMetric({
      body: {
        label: res.name,
        name: res.reference,
        value_type: res.valueType,
        period_aggregation_strategy: res.periodAggregationStrategy,
        description: res.description,
      },
    });
  };

  const handleEditMetric = async (m: UnderwritingBudgetMetric) => {
    const res = await openMetricSettingsModal({
      initialForm: {
        name: m.label,
        reference: m.name,
        valueType: m.valueType,
        periodAggregationStrategy: m.periodAggregationStrategy,
        description: m.description ?? '',
      },
      submitButtonText: 'Save updates',
      modalHeaderProps: {
        title: 'Edit',
        subtitle: 'KPIs & Metrics',
      },
      // for next iterations
      // children: (
      //   <div className="-mx-tw-6 -mb-tw-6 mt-tw-4 px-tw-6 border-t border-solid border-neutral-200 py-tw-4 flex items-center justify-between">
      //     <p className="inline-semibold text-neutral-550">
      //       This Item has not yet been used in any place
      //     </p>
      //     <Button className="px-tw-2" iconName="trash" iconPosition="right" variant="danger">Remove</Button>
      //   </div>
      // )
    });

    if (res == null) return;

    await updateMetricForUWB({
      id: m.id,
      body: {
        label: res.name,
        name: res.reference,
        description: res.description,
        period_aggregation_strategy: res.periodAggregationStrategy,
        value_type: res.valueType,
      },
    });
  };

  const currentMetricIds = mapListToIds(currentBudget.budgetMetricRows);

  const resolveBudgetMetricChecked = (m: UnderwritingBudgetMetric) => {
    if (m.budgetMetric == null) return false;
    return currentMetricIds.includes(m.budgetMetric.id);
  };

  const handleCheckMetric = async (m: UnderwritingBudgetMetric) => {
    if (resolveBudgetMetricChecked(m)) {
      asserts(m.budgetMetric);

      const res = await confirm({
        title: 'Remove KPI / Metric',
        subtitle: 'Are you sure? Any values set to this KPI will be deleted.',
      });

      if (!res) return;

      await deleteMetricFromBudget({
        budgetId,
        budgetMetricId: m.budgetMetric.id,
      });
    } else {
      await addMetricToBudget({
        budgetId,
        body: {
          metric_id: m.id,
        },
      });
    }
  };
  return (
    <SidePanel
      maxHeight
      header="Add KPIs & Metrics"
      classes={{
        body: 'bg-neutral-050 flex flex-col gap-tw-4',
      }}
      toggle={onClose}
    >
      <div className="flex gap-tw-2">
        <SearchInput
          className="grow"
          placeholder="Search By Formula Name"
          value={query}
          onChange={handleChange}
          onReset={resetQuery}
          isSearching={searchInputSearching}
          suggestions={['Metric name', 'Metric reference']}
        />
        <Button
          className="gap-tw-1"
          size="s"
          iconName="addSmall"
          variant="success"
          onClick={handleCreate}
        >
          New
        </Button>
      </div>

      <div className="flex flex-col gap-1 overflow-x-hidden rounded-xl">
        {isLoading &&
          Array.from({ length: 3 }).map((_, i) => (
            <SkeletonBlock className="flex h-[80px] w-full" key={i} />
          ))}

        {!isLoading &&
          data?.items.map((m) => (
            <div
              className="flex flex-col gap-tw-2 bg-neutral-000 p-tw-4"
              key={m.id}
            >
              <div className="flex items-center gap-tw-2">
                <p className="body-semibold text-neutral-800">{m.label}</p>
                <IconButton
                  disabled={editMetricDisabled}
                  onClick={() => handleEditMetric(m)}
                  iconName="edit"
                />
                <Checkbox
                  disabled={checkboxesDisabled}
                  onChange={() => handleCheckMetric(m)}
                  checked={resolveBudgetMetricChecked(m)}
                  labelClassName="flex ml-auto"
                />
              </div>

              {m.description && (
                <p className="secondary-regular text-neutral-600">
                  {m.description}
                </p>
              )}
            </div>
          ))}

        {!isFetching && data?.items.length === 0 && (
          <NoDataOverlay title="Nothing found" />
        )}
      </div>
    </SidePanel>
  );
}
