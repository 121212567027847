import { navigate, useParams } from '@reach/router';
import RequestApprovalForm from 'bundles/Construction/components/ChangeOrderEvent/AvailableActions/modals/RequestApprovalForm';
import VerificationForm from 'bundles/Construction/components/ChangeOrderEvent/AvailableActions/modals/VerificationForm';
import CostBreakdown from 'bundles/Construction/components/ChangeOrderEvent/CostBreakdown';
import Documents from 'bundles/Construction/components/ChangeOrderEvent/Documents';
import { WORKFLOW } from 'bundles/Construction/components/eSignature/consts';
import ESignPlacementModal from 'bundles/Construction/components/eSignature/ESignPlacementModal';
import {
  defineEsignChangeEvent,
  fetchChangeEvent,
  loadingChangeEvent,
  promoteChangeEvent,
  reviseChangeEvent,
  selectCETimingImpact,
  selectChangeEvent,
} from 'bundles/Construction/reducers/ReconcileChangeEventSlice';
import {
  fetchCompanies,
  fetchContacts,
  fetchReasons,
} from 'bundles/Construction/reducers/ReconcileSlice';
import withAccess from 'bundles/Shared/components/withAccess';
import { toDate } from '@/shared/lib/formatting/dates';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useModal } from '@/shared/lib/hooks/useModal';
import { TRouteParams, useNavigation } from '@/shared/lib/hooks/useNavigation';
import { usePreventBodyScrollingEffect } from '@/shared/lib/hooks/usePreventBodyScollingEffect';
import {
  currentUserAllowedTo,
  currentUserIsSreAdmin,
  currentUserIsSuperAdmin,
  TProductNames,
} from 'lib/permissions';
import {
  createRef,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AnimationLoader,
  Button,
  HugeModalHeaderForCe,
  Tooltip,
} from 'stories';
import { destroyChangeOrderEvent } from '../../actions';
import AvailableActions from './AvailableActions/AvailableActions';
import ApprovalForm from './AvailableActions/modals/ApprovalForm';
import PromoteModal from './AvailableActions/modals/PromoteModal';
import RejectedForm from './AvailableActions/modals/RejectedForm';
import ReviseForm from './AvailableActions/modals/ReviseForm';
import ChangeEventDetails from './ChangeEventDetails/ChangeEventDetails';
import History from './History';
import InfoBadge from './InfoBadge';
import ChangeEventStatusMessage from './MessageBox/ChangeEventStatusMessage';
import PromoteReviseCEModal from './PromoteModal/PromoteReviseCEModal';
import SnapshotModal from './SnapshotModal';
import { convertToSeconds } from '@/shared/lib/converters';

export type TStepVerifyCE =
  | 'addDocs'
  | 'completeFields'
  | 'confirmValue'
  | 'checkTimingImpact';

export interface IVerifySteps {
  checkTimingImpact: boolean;
  completeFields: number;
  confirmValue: boolean;
  addDocs: boolean;
  selectFinal: boolean;
  eSign: boolean;
}

export interface IActionsHints {
  checkTimingImpact: RefObject<HTMLDivElement>[];
  completeFields: RefObject<HTMLDivElement>[];
  confirmValue: RefObject<HTMLDivElement>[];
  addDocs: RefObject<HTMLDivElement>[];
  selectFinal: RefObject<HTMLDivElement>[];
}

type Props = TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_EVENT'] & {
  containerClassName?: string;
};

function ChangeOrderEvent({ containerClassName = '' }: Props) {
  const changeEvent = useAppSelector(selectChangeEvent);
  const chooseTimingImpact = useAppSelector(selectCETimingImpact);
  const loading = useAppSelector(loadingChangeEvent);
  const dispatch = useAppDispatch();
  const { id, legalEntityCode } =
    useParams<
      TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_EVENT']
    >();
  const navigation = useNavigation();
  const { openModal } = useModal();
  const isCOR = changeEvent?.category === 'COR';
  const [showSnapshotModal, setShowSnapshotModal] = useState(false);
  const [showCECreateModal, setShowCECreateModal] = useState(false);
  const [commentRevise, setCommentRevise] = useState('');
  const [actionsHints, setActionsHints] = useState({
    checkTimingImpact: [],
    completeFields: [],
    confirmValue: [],
    addDocs: [],
    selectFinal: [],
    eSign: [],
  });

  const documentRef = useRef(null);
  const costBreakDownRef = useRef(null);
  const dateParentRef = createRef();

  usePreventBodyScrollingEffect();

  useEffect(() => {
    dispatch(
      fetchChangeEvent({
        legalEntityCode,
        id,
      }),
    );
    dispatch(fetchReasons());
    dispatch(fetchCompanies());
    dispatch(fetchContacts());
  }, [id]);

  const handleDestroyAction = () =>
    dispatch(
      destroyChangeOrderEvent({
        legalEntityCode,
        id,
      }),
    );

  const handleReviseSubmit = async (data) => {
    await dispatch(
      reviseChangeEvent({
        legalEntityCode,
        id,
        data: { ...data, event_comment: commentRevise },
      }),
    );
    setCommentRevise('');
  };

  const handleShowDocumentPlacementModal = async () => {
    const data = await openModal(ESignPlacementModal, {
      contractFile: changeEvent.contractFile,
    });
    if (data) {
      await dispatch(defineEsignChangeEvent({ id, legalEntityCode, data }));
    }
  };

  const onCloseModal = () => {
    navigate(
      navigation.getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_EVENTS', {
        legalEntityCode,
      }),
    );
  };

  const verifyStep = useMemo(
    () => ({
      checkTimingImpact:
        chooseTimingImpact === true || chooseTimingImpact === false,
      completeFields: changeEvent?.workCosts?.length,
      confirmValue: changeEvent?.costBreakdownConfirmed,
      addDocs: changeEvent?.documents?.length > 0,
      selectFinal: changeEvent?.contractFileId,
      eSign:
        changeEvent?.contractFile?.esignPlacements?.length >= WORKFLOW.length,
    }),
    [changeEvent, chooseTimingImpact],
  );

  const scrollToFunc = (step) => {
    if (['addDocs', 'selectFinal', 'eSign'].includes(step)) {
      documentRef.current.scrollIntoView();
    } else if (step === 'completeFields' || step === 'confirmValue') {
      costBreakDownRef.current.scrollIntoView();
    } else {
      dateParentRef.current.getDateRef.scrollIntoView();
    }
  };

  const actionsData = [
    {
      title: 'Verify',
      description: '',
      iconName: 'checkDouble',
      show: changeEvent?.availableActions?.includes('verify'),
      content: (
        <VerificationForm
          verifyStep={verifyStep}
          actionsHints={actionsHints}
          setActionsHints={setActionsHints}
          scrollToFunc={scrollToFunc}
        />
      ),
    },
    {
      title: 'Request Approval',
      description: '',
      iconName: 'bell',
      show: changeEvent?.availableActions?.includes('start_approval'),
      content: <RequestApprovalForm />,
    },
    {
      title: 'Approve',
      description: '',
      iconName: 'acceptAlt',
      show: changeEvent?.availableActions?.includes('approve'),
      content: <ApprovalForm />,
    },
    {
      title: 'Reject',
      description: '',
      iconName: 'nodeOpen',
      show: changeEvent?.availableActions?.includes('approve'),
      content: <RejectedForm />,
    },
    {
      title: 'Promote',
      description: '',
      iconName: 'upgrade',
      show: changeEvent?.availableActions?.includes('promote'),
      content: <PromoteModal setShowCECreateModal={setShowCECreateModal} />,
    },
    {
      title: 'Revise',
      description: '',
      iconName: 'sync',
      show: changeEvent?.availableActions?.includes('revise'),
      content: (
        <ReviseForm
          setShowCECreateModal={setShowCECreateModal}
          commentRevise={commentRevise}
          setCommentRevise={setCommentRevise}
        />
      ),
    },
  ];
  const destroyCE = changeEvent?.availableActions?.includes('destroy');

  if (!changeEvent && loading !== 'success') return <AnimationLoader />;

  async function promoteFunc(data, revise) {
    const {
      changeOrderReasons,
      amount,
      category,
      contractorDate,
      receivedDate,
      sentToDesignerOfRecordDate,
      reconcileCompany,
      reconcileContact,
      probability,
      probability_comment,
      reallocationJccId,
    } = data;
    const sendData = {
      ...data,
      category: showCECreateModal,
      ...(category !== 'IRC' || showCECreateModal === 'PCO'
        ? {
            company_name: reconcileCompany.label,
          }
        : {}),
      contact_name: reconcileContact.label,
      contractor_date: toDate(convertToSeconds(contractorDate)),
      received_date: toDate(convertToSeconds(receivedDate)),
      sent_to_designer_of_record_date: toDate(
        convertToSeconds(sentToDesignerOfRecordDate),
      ),
      value: amount,
      probability_comment: probability ? probability_comment : null,
    };
    if (revise) {
      await handleReviseSubmit({
        ...sendData,
        category,
        change_order_reason_ids: changeOrderReasons.map((r) => r.id),
        ...(category !== 'IRC'
          ? { reallocation_jcc_id: reallocationJccId }
          : {}),
      });
    } else {
      dispatch(
        promoteChangeEvent({
          legalEntityCode,
          id,
          data: {
            ...sendData,
            change_order_reason_ids: changeOrderReasons.map((r) => r.id),
            reallocation_jcc_id: reallocationJccId,
          },
        }),
      );
    }
    setShowCECreateModal(false);
  }

  const esignPlacementAvailable =
    changeEvent?.esignEnabled &&
    verifyStep.selectFinal &&
    changeEvent?.availableActions?.includes('verify');

  return (
    <div className={containerClassName}>
      <div className="bg-white px-l py-m">
        <HugeModalHeaderForCe
          iconName="documentAlt"
          title={changeEvent?.title}
          subtitle={`${changeEvent?.category} ${changeEvent?.displayNumber}`}
          onClose={onCloseModal}
        />
      </div>
      <div className="ce-modal-wrapper px-l pt-l">
        <div className="row bg-light-10">
          <div className="col-3">
            <InfoBadge
              setShowSnapshotModal={() => setShowSnapshotModal(true)}
            />
            <ChangeEventStatusMessage />
            <AvailableActions
              actionsData={actionsData.filter((action) => action.show)}
              destroyCE={destroyCE}
              handleDestroyAction={handleDestroyAction}
              onCloseModal={onCloseModal}
            />
          </div>
          <div className="col-6 change-event-scroll">
            <div>
              <ChangeEventDetails
                actionsHints={actionsHints}
                dateParentRef={dateParentRef}
              />
            </div>
            {isCOR && (
              <div ref={costBreakDownRef}>
                <Tooltip
                  trigger="click"
                  triggerTarget={actionsHints.confirmValue}
                  className="!block"
                  placement="right"
                  arrowPosition="left"
                  titleText="Complete fields and Confirm value"
                  classes={{ spanContainer: 'mb-m w-full' }}
                >
                  <CostBreakdown
                    verifyStep={verifyStep}
                    actionsHints={actionsHints}
                  />
                </Tooltip>
              </div>
            )}
            <Tooltip
              trigger="click"
              triggerTarget={actionsHints.selectFinal}
              titleText="Add docs and Select Final"
              className="!block"
              placement="right"
              arrowPosition="left"
              classes={{ spanContainer: 'w-full' }}
            >
              <div className="mt-tw-4 w-full" ref={documentRef}>
                <Tooltip
                  trigger="click"
                  triggerTarget={actionsHints.addDocs}
                  titleText="Add docs"
                  className="!block"
                  placement="right"
                  classes={{ spanContainer: 'w-full' }}
                >
                  <Documents
                    definePlacementButton={
                      esignPlacementAvailable && (
                        <Tooltip
                          trigger="click"
                          triggerTarget={actionsHints.eSign}
                          titleText="Please define & locate objects"
                          className="!block"
                          placement="right"
                          classes={{ spanContainer: 'ml-auto' }}
                        >
                          <Button
                            variant={verifyStep.eSign ? 'success' : 'warning'}
                            onClick={handleShowDocumentPlacementModal}
                          >
                            Define E-sign Placement
                          </Button>
                        </Tooltip>
                      )
                    }
                  />
                </Tooltip>
              </div>
            </Tooltip>
          </div>
          <div className="col-3">
            <History />
          </div>
        </div>
      </div>
      {showSnapshotModal && (
        <SnapshotModal
          legalEntityCode={legalEntityCode}
          onClose={() => setShowSnapshotModal(false)}
        />
      )}
      {showCECreateModal && (
        <PromoteReviseCEModal
          setShowModal={setShowCECreateModal}
          promoteFunc={promoteFunc}
          categoryChange={showCECreateModal}
        />
      )}
    </div>
  );
}

export default withAccess(ChangeOrderEvent, {
  hasAccess: () =>
    currentUserIsSreAdmin() ||
    currentUserIsSuperAdmin() ||
    currentUserAllowedTo('view', TProductNames.CHANGE_MANAGEMENT),
});
