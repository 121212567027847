import React, { ComponentProps, useCallback, useMemo, useState } from 'react';
import { AnimationLoader, SearchInput } from 'stories';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { cn } from '@/shared/lib/css/cn';
import styles from './ItemFilterList.module.scss';
import { debounce, sortBy } from 'lodash-es';
import { includesInLowerCase } from '@/shared/lib/listHelpers';

interface Props<T> {
  items: T[];
  onSelectedItemChange: (item: T) => void;
  getMainFieldTitle: (item: T) => string;
  sortBySelected?: boolean;
  getSecondaryFieldTitle?: (item: T) => string;
  maxHeight?: number | string;
  mask?: string;
  selectedItems?: T[];
  searchInputProps?: Pick<
    ComponentProps<typeof SearchInput>,
    'placeholder' | 'size'
  >;
  classes?: {
    itemTitle?: string;
    item?: string;
  };
  className?: string;
  loading?: boolean;
  query?: string;
  onQueryChange?: (text: string) => void;
}

function ItemFilterList<T = any>({
  items,
  selectedItems,
  onSelectedItemChange,
  sortBySelected,
  maxHeight,
  mask = 'items',
  getMainFieldTitle,
  getSecondaryFieldTitle,
  searchInputProps,
  classes,
  className,
  loading,
  query = '',
  onQueryChange,
}: Props<T>) {
  const [internalQuery, setInternalQuery] = useState('');
  const filter = onQueryChange ? query : internalQuery;
  const debouncedChangeHandler = useCallback(
    debounce((text) => onQueryChange?.(text), 500),
    [],
  );

  const handleFilterTextChange = (text: string) => {
    if (onQueryChange) {
      debouncedChangeHandler(text);
    } else {
      setInternalQuery(text);
    }
  };
  const isItemSelected = (item: T) =>
    selectedItems?.map(({ id }) => id).includes(item.id);

  const filteredItems = useMemo(() => {
    const filtered =
      items?.filter(
        (item) =>
          includesInLowerCase(getMainFieldTitle(item), filter) ||
          includesInLowerCase(getSecondaryFieldTitle?.(item) ?? '', filter),
      ) ?? [];

    const sorted = sortBy(filtered, (item) => {
      if (sortBySelected) {
        return isItemSelected(item) ? 0 : 1;
      }
      return 0;
    });

    return sorted;
  }, [filter, items, selectedItems, sortBySelected]);

  return (
    <div style={{ maxHeight }} className={cn('flex flex-col', className)}>
      <SearchInput
        onChange={(e) => handleFilterTextChange(e.target.value)}
        placeholder="Search..."
        className="m-tw-2"
        {...searchInputProps}
        onReset={() => handleFilterTextChange('')}
      />
      {loading ? (
        <div style={{ minHeight: maxHeight ?? '20rem', minWidth: '17.5rem' }}>
          <AnimationLoader />
        </div>
      ) : (
        <OverlayScrollbarsComponent
          options={{
            className: cn(
              'os-theme-thin-dark user-filter-list w-full os-host-flexbox',
            ),
          }}
        >
          {filteredItems.map((item) => (
            <div
              key={item.id}
              onClick={() => onSelectedItemChange(item)}
              className={cn(
                'cursor-pointer px-tw-4 py-tw-2',
                [styles.itemFilterList],
                {
                  [styles.itemFilterList_active]: isItemSelected(item),
                },
                classes?.item,
              )}
            >
              <div
                className={cn(
                  'inline-semibold',
                  styles.itemFilterListTitle,
                  classes?.itemTitle,
                )}
              >
                {getMainFieldTitle(item)}
              </div>

              {getSecondaryFieldTitle?.(item) && (
                <span className={cn('secondary-regular')}>
                  {getSecondaryFieldTitle?.(item)}
                </span>
              )}
            </div>
          ))}
          {filteredItems.length === 0 && (
            <div className="my-s text-center">No {mask} found</div>
          )}
        </OverlayScrollbarsComponent>
      )}
    </div>
  );
}

export default ItemFilterList;
