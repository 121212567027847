import { PermissionListModal } from '@/bundles/Shared/components/Permissions/PermissionListModal';
import { transformPermissionsToRequest } from '@/bundles/Shared/entities/permissions';
import { usePostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishMutation } from '@/entities/report/reportBuilder';
import { REPORT_PUBLISH_CONFIRMATION_TEXT } from '@/features/report/report/publishReport/config';
import { IS_STAGING } from '@/lib/http';
import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import { useHostedUrl } from '@/shared/lib/hooks/useHostedUrl';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';

export const usePublishReport = ({
  reportId,
  assetId,
}: {
  reportId: string;
  assetId: number;
}) => {
  const hostedUrl = useHostedUrl();
  const { navigateBack } = useNavigateBack({
    fallbackUrl: ROUTES_ROOT.assets.fullPath,
  });
  const [publishReport, options] =
    usePostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishMutation();
  const { openModal, confirm } = useModal();

  return [
    async () => {
      const res = await openModal(PermissionListModal, {
        investmentObject: {
          type: 'Asset',
          entity: {
            id: assetId,
          },
        },
        initialState: {
          directInvestmentEntities: [],
          directRoles: [],
          directTags: [],
          directUsers: [],
          indirectUsers: [],
          isPublic: false,
        },
        customHeader: false,
      });

      if (res == null) return;

      const answer = await confirm({
        title: REPORT_PUBLISH_CONFIRMATION_TEXT.title,
        subtitle: REPORT_PUBLISH_CONFIRMATION_TEXT.subtitle,
        actions: {
          primaryButton: {
            variant: 'success',
            text: 'Publish',
          },
          secondaryButton: {
            text: 'Cancel',
            variant: 'secondary',
          },
        },
      });

      if (!answer) return;

      await publishReport({
        id: reportId,
        assetId,
        body: {
          shared_file: transformPermissionsToRequest(res),
          ...(IS_STAGING && {
            render_pdf_host: hostedUrl,
          }),
        },
      });
      navigateBack();
    },
    options,
  ] as const;
};
