import {
  AdjustmentExpression,
  TotalCalculationStrategy,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import {
  EAGLE_EYE_DASHBOARD_SECTION_TYPE,
  KpiTableWidgetDto,
  PeriodShiftConfig,
  ReportEagleEyeDashboardSection,
} from 'bundles/Shared/entities/dashboard';
import { GridOptions } from 'ag-grid-community';
import { TableVizConfig } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { ValueDisplayOptions } from '@/shared/lib/formatting/displayOptions';
import { KpiTableWidgetConfigDto } from 'bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';

type AvailablePeriodShiftConfig = Pick<
  PeriodShiftConfig,
  'days' | 'weeks' | 'months'
>;
export const KPI_TABLE_GROUPING_TYPES = {
  SEGMENTS: 'segments',
  ASSETS: 'assets',
} as const;

export const KPI_TABLE_PERIOD_TYPES = {
  WEEK: 'week',
  DAY_TO_DAY: 'day_to_day',
  MONTH_TO_MONTH: 'month_to_month',
  YEAR_TO_DATE: 'year_to_date',
  MONTH: 'month',
} as const;

export type KpiTableGroupingType =
  (typeof KPI_TABLE_GROUPING_TYPES)[keyof typeof KPI_TABLE_GROUPING_TYPES];
export type KpiTablePeriodType =
  (typeof KPI_TABLE_PERIOD_TYPES)[keyof typeof KPI_TABLE_PERIOD_TYPES];
export type KpiTableWidgetConfigColumn = {
  key: number;
  label: string;
  expression: string;
  value_display_options?: ValueDisplayOptions;
  period_shift?: AvailablePeriodShiftConfig;
  adjustment_expression?: AdjustmentExpression;
  total_calculation_strategy?: TotalCalculationStrategy;
};

export interface KpiTableWidgetConfig {
  columns: KpiTableWidgetConfigColumn[];
  period_types: KpiTablePeriodType[];
  mtd_ytd_toggle: boolean;
  t3_t6_t12_buttons: boolean;
  default_options: {
    grouping_type: KpiTableGroupingType;
    period_type: KpiTablePeriodType;
    date_from: {
      period_type: 'day' | 'week' | 'month';
      period_shift?: AvailablePeriodShiftConfig;
    };
    date_to?: {
      period_type: 'day' | 'week' | 'month';
      period_shift?: AvailablePeriodShiftConfig;
    };
    mtd_ytd_toggle?: 'mtd' | 'ytd';
    t3_t6_t12_buttons?: 't3' | 't6' | 't12';
  };
  viz_type: 'table';
  viz_config: TableVizConfig;
  ag_grid_config: Partial<GridOptions>;
}

export type KpiTableWidgetSection = ReportEagleEyeDashboardSection & {
  widgetType: typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE;
  widgetConfig: KpiTableWidgetConfigDto;
  defaultOptions: {
    groupingType: KpiTableGroupingType;
    date: DateString;
    periodType: KpiTablePeriodType;
    availablePeriodTypes: KpiTablePeriodType[];
  };
};
export type KpiTableWidgetDataRow = NonNullable<
  KpiTableWidgetDto['data']
>[number];
export type KpiTableWidgetDataColumn = {
  key: number;
  label: string;
  values?: {
    key: number;
    value: number | null;
  }[];
  valueDisplayOptions: ValueDisplayOptions;
};
export type KpiTableWidgetDataTotalRow = KpiTableWidgetDataRow & {
  type: 'total';
};

export const isKpiTableWidgetSection = (
  section: ReportEagleEyeDashboardSection,
): section is KpiTableWidgetSection =>
  section.widgetType === EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE;
export const isKpiTableRowTotal = (
  row: KpiTableWidgetDataRow,
): row is KpiTableWidgetDataTotalRow => row.type === 'total';
export const isKpiTableRowSegment = (
  row: KpiTableWidgetDataRow,
): row is KpiTableWidgetDataRow & { type: 'segment' } => row.type === 'segment';
export const isKpiTableRowAsset = (
  row: KpiTableWidgetDataRow,
): row is KpiTableWidgetDataRow & { type: 'asset' } => row.type === 'asset';
export const isKpiTableRowLegalEntity = (
  row: KpiTableWidgetDataRow,
): row is KpiTableWidgetDataRow & { type: 'legal_entity' } =>
  row.type === 'legal_entity';
