import React, { ComponentProps, useCallback, useEffect } from 'react';
import {
  findFormulaOrVariableByReference,
  FORMULA_REFERENCE_PREFIX,
  isFormulaByExpression,
  openFormula,
  useReportFormulasQuery,
} from 'bundles/Shared/entities/formula';
import { useDispatch } from 'react-redux';
import styles from './ReportTableConfigEditor.module.scss';
import { debounce } from 'lodash-es';
import { editor as editorTypes, Uri } from 'monaco-editor';
import { CodeEditor, JSONEditor } from '@/shared/ui/CodeEditor/component';
import { useCodeEditorRef } from '@/shared/ui/CodeEditor/lib';
import { clipboardWriteText } from '@/shared/lib/browser/clipboardApi';

const markFormulas = (editor: editorTypes.IStandaloneCodeEditor) => {
  const model = editor.getModel()!;
  const matches = model.findMatches(
    new RegExp(`${FORMULA_REFERENCE_PREFIX}[^"\\s]+`, 'g'),
    true,
    true,
    false,
    null,
    true,
  );

  matches.forEach((match) => {
    editor.createDecorationsCollection([
      {
        range: match.range,
        options: {
          isWholeLine: false,
          blockClassName: styles.textRed,
          inlineClassName: styles.textRed,
        },
      },
    ]);
  });
};

export function ReportTableConfigEditor({
  onChange,
  ...props
}: ComponentProps<typeof JSONEditor>) {
  const codeEditorRef = useCodeEditorRef();
  const handleChange = useCallback(debounce(onChange ?? (() => null), 500), [
    onChange,
  ]);
  const dispatch = useDispatch();
  const { formulas } = useReportFormulasQuery();

  useEffect(() => {
    codeEditorRef.current?.editor.registerLinkOpener({
      open(resource: Uri): boolean | Promise<boolean> {
        if (resource.path.startsWith('/formula-copy-')) {
          const formulaReference = resource.path.replace('/formula-copy-', '');
          clipboardWriteText(formulaReference);
          return true;
        }
        const formulaId = resource.path.replace('/formula-', '');
        const formula = formulas.find((f) => f.id === formulaId);
        if (formula == null) {
          toastr.error(
            `Formula ${formulaId} not found. Please check the reference`,
          );
          return false;
        }
        dispatch(openFormula(formula));
        return true;
      },
    });
    const hoverProviderHandle =
      codeEditorRef.current?.languages.registerHoverProvider('json', {
        provideHover: (model, position) => {
          const definition = model.getWordAtPosition(position);
          const reference = definition?.word;
          if (reference && isFormulaByExpression(reference)) {
            const formula = findFormulaOrVariableByReference(
              formulas,
              reference,
            );
            if (formula == null) {
              return;
            }
            return {
              contents: [
                {
                  value: `${formula.reference} <a href="formula-copy-${formula.reference}">Copy</a>`,
                  supportHtml: true,
                  isTrusted: true,
                },
                {
                  value: formula.label,
                },
                {
                  value: formula.description,
                },
                {
                  value: `<a href="formula-${formula.id}">Open</a>`,
                  supportHtml: true,
                  isTrusted: true,
                },
              ],
            };
          }
          return { contents: [] };
        },
      });

    return () => {
      hoverProviderHandle?.dispose();
    };
  }, [formulas]);

  const handleMount = useCallback<
    NonNullable<ComponentProps<typeof CodeEditor>['onMount']>
  >((editor, monaco) => {
    markFormulas(editor);
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      enableSchemaRequest: false,
      validate: false,
    });
    editor.onDidChangeModelContent(() => {
      markFormulas(editor);
    });
  }, []);

  return (
    <JSONEditor
      beforeMount={(monaco) => {
        codeEditorRef.current = monaco;
      }}
      onMount={handleMount}
      onChange={handleChange}
      {...props}
    />
  );
}
