import { cn } from '@/shared/lib/css/cn';
import { FireIconAlternative } from 'bundles/FireStation/components/FireIcon/FireIcon';
import VerticalSeparator from 'bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import { Formula } from 'bundles/Shared/entities/formula';
import { FormulaInUse } from 'bundles/Shared/entities/formula/ui/FormulaInUseLabelPopover';
import { FormulaReferenceLabel } from 'bundles/Shared/entities/formula/ui/FormulaReferenceLabel';
import { EntityTagLabel } from 'bundles/Shared/entities/tag/ui/EntityTagLabel';
import { useHovered } from '@/shared/lib/hooks/useHovered';
import { useIsTextTruncated } from '@/shared/lib/hooks/useIsTextTruncated';
import { isEmpty } from 'lodash-es';
import React, { useRef } from 'react';
import { Icon, Tooltip } from 'stories';
import { ClassNameProps } from 'types/Props';
import { EntityTag } from 'bundles/Shared/entities/tag';

interface Props extends ClassNameProps {
  label: Formula['label'];
  description: Formula['description'];
  reference: Formula['reference'];
  selected?: boolean;
  tags?: EntityTag[];
  labelPanel?: React.ReactNode;
  labelInfo?: React.ReactNode;
  buttonProps?: React.DOMAttributes<HTMLButtonElement>;
}

const FormulaControl = ({
  referencedInEntitiesCount,
  opened,
  error,
  onOpen,
  onClose,
}: {
  referencedInEntitiesCount: number;
  error?: boolean;
  opened?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}) => {
  const hasReferences = referencedInEntitiesCount > 0;
  const hasSeparator = error && hasReferences;
  return (
    <div
      className={cn(
        'shadow-[0px_2px_2px_0px_rgba(12,25,54,0.08)]',
        'flex h-[24px] items-center gap-tw-1 overflow-hidden !rounded-lg bg-neutral-000',
        'border border-solid border-neutral-150',
        opened && 'border-0 border-neutral-200 bg-neutral-200 pr-tw-1',
        onOpen && 'cursor-pointer',
      )}
    >
      <div
        onClick={onOpen}
        className={cn(
          'flex h-full items-center gap-tw-1 !rounded-lg p-tw-1',
          opened && 'border-1 border-solid border-neutral-999 bg-neutral-900',
          hasReferences && 'pr-tw-2',
        )}
      >
        <Icon
          className={cn(opened && 'text-neutral-000')}
          iconName="inspectFunction"
        />
        {error && (
          <FireIconAlternative
            classes={
              opened
                ? {
                    fire: 'fill-danger-090',
                    marker: 'stroke-neutral-900 fill-danger-055',
                  }
                : undefined
            }
            withMarker
          />
        )}
        {hasSeparator && (
          <div className="px-tw-1">
            <VerticalSeparator
              className={cn(opened && 'bg-neutral-999')}
              size="4px"
            />
          </div>
        )}
        {hasReferences && (
          <div className="flex items-center gap-tw-1">
            <FormulaInUse.UsedSvg
              viewBox="4 4 16 16"
              className="h-[12px] w-[12px]"
              used
            />
            {referencedInEntitiesCount > 1 && (
              <span
                className={cn(
                  'secondary-semibold text-neutral-700',
                  opened && 'text-neutral-000',
                )}
              >
                {referencedInEntitiesCount}
              </span>
            )}
          </div>
        )}
      </div>
      <Icon
        onClick={onClose}
        className={cn('hover:text-neutral-600', !opened && 'hidden')}
        iconName="closeSmall"
      />
    </div>
  );
};

export function FormulaCard({
  labelPanel,
  labelInfo,
  label,
  description,
  reference,
  className,
  selected,
  tags,
  buttonProps,
}: Props) {
  const labelRef = useRef<HTMLSpanElement>(null);
  const isTruncated = useIsTextTruncated(labelRef, label.trim());
  return (
    <button
      className={cn(
        'group relative flex !cursor-default flex-col gap-tw-2 border-none bg-neutral-000 p-tw-4 pl-tw-6',
        selected &&
          'before:absolute before:bottom-tw-4 before:left-tw-3 before:top-tw-4 before:w-tw-1 before:rounded-sm before:bg-info-055 before:content-[""]',
        className,
      )}
      {...buttonProps}
    >
      {labelPanel && (
        <div className="flex w-full items-center">{labelPanel}</div>
      )}
      <div className="flex w-full items-center justify-between">
        <Tooltip
          disabled={!isTruncated}
          classes={{ spanContainer: 'min-w-0 inline-flex' }}
          mainText={label}
        >
          <span
            ref={labelRef}
            className="body-semibold text-ellipsis text-neutral-800"
          >
            {label}
          </span>
        </Tooltip>
        {labelInfo && labelInfo}
      </div>
      <FormulaReferenceLabel reference={reference} />
      {!isEmpty(description) && (
        <span className="secondary-regular text-neutral-600">
          {description}
        </span>
      )}
      {!isEmpty(tags) && (
        <EntityTagLabel.List>
          {tags!.map((tag) => (
            <EntityTagLabel key={tag.id} label={tag.name} />
          ))}
        </EntityTagLabel.List>
      )}
    </button>
  );
}

FormulaCard.FormulaControl = FormulaControl;
