import {
  LEAVE_DASHBOARD_CONFIRM_FUNC_PROPS,
  WIDGET_SETTINGS_BUTTON_TOOLTIP,
} from '@/bundles/Settings/components/REport/Dashboards/Dashboard/config';
import { SettingsReportObjectDashboardDateFilter } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/widgets/SettingsReportDashboardDateFilter';
import { DashboardSectionRemoveButton } from '@/bundles/Shared/features/dashboard/sectionActions/ui/DashboardSectionRemoveButton';
import { OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { cn } from '@/shared/lib/css/cn';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useModal } from '@/shared/lib/hooks/useModal';
import {
  generateUrl,
  ROUTES_ROOT,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import { Link, useNavigate } from '@reach/router';
import {
  orderDashboardSections,
  useNavigateToBoard,
  useNavigateToFirstBoardEffect,
  useNavigateToWidget,
  useReportSettingsDashboardScreenParams,
  useSettingsReportObjectDashboardRedirectToFirstAssetEffect,
} from 'bundles/Settings/components/REport/Dashboards/Dashboard/lib';
import {
  DashboardLayout,
  ObjectLevelDashboardLayout,
} from 'bundles/Shared/components/layouts/dashboard/DashboardLayout';
import {
  buildLayoutsId,
  DashboardHasChangesButton,
  DashboardIcon,
  DashboardSection,
  DashboardSettingsToggle,
  DashboardStatusDropdown,
  isObjectDashboardSectionEditable,
  ObjectDashboardSectionTypes,
  ReportDashboardType,
  ReportObjectDashboardSection,
  selectReportDashboardMetadataById,
  useGetApiSettingsReportObjectDashboardsByIdQuery,
} from 'bundles/Shared/entities/dashboard';
import { selectReportDashboardLayoutDirty } from 'bundles/Shared/entities/dashboard/model/slices/layouts';
import { useAddDashboardWidgetSection } from 'bundles/Shared/features/dashboard/addWidget';
import { useDeleteDashboard } from 'bundles/Shared/features/dashboard/delete';
import { MoveToBoardButton } from 'bundles/Shared/features/dashboard/moveToBoard';
import { ObjectDashboardAssetFilter } from 'bundles/Shared/features/dashboard/object/filter/byAsset/ui/ObjectDashboardAssetFilter';
import {
  useDashboardRemoveSection,
  useDashboardUpdateLayout,
} from 'bundles/Shared/features/dashboard/sectionActions';
import DashboardSectionDragButton from 'bundles/Shared/features/dashboard/sectionActions/ui/DashboardSectionDragButton';
import { ObjectDashboardUpdate } from 'bundles/Shared/features/dashboard/update/object';
import { useUpdateDashboardStatus } from 'bundles/Shared/features/dashboard/updateStatus';
import { DashboardBoards } from 'bundles/Shared/widgets/dashboard/board';
import { DashboardGridLayout } from 'bundles/Shared/widgets/dashboard/layout';
import {
  DashboardWidgetEditPanel,
  useDashboardSectionFocus,
} from 'bundles/Shared/widgets/dashboard/widgetPanel';
import {
  DashboardWidgetsBar,
  useDashboardWidgetsBarDroppableItem,
} from 'bundles/Shared/widgets/dashboard/widgetsBar';
import { ObjectDashboardWidget } from 'bundles/Shared/widgets/dashboard/widgetsHelpers';
import React from 'react';
import { Icon, IconButton } from 'stories';
import { IconsId } from 'types/sre-icons';

export const FilterBlock = ({
  iconName,
  value,
  children,
  label,
}: React.PropsWithChildren<{
  iconName: IconsId;
  label: string;
  value: string;
}>) => {
  return (
    <div className="flex items-center justify-between gap-tw-4 !rounded-lg bg-neutral-000 py-tw-1 pl-tw-2 pr-tw-4">
      <div className="flex items-center gap-tw-2">
        <Icon
          className="!rounded-lg bg-neutral-100 p-tw-1.5"
          iconName={iconName}
        />
        <div className="flex flex-col">
          <span className="secondary-semibold text-neutral-550">{label}</span>
          <span className="inline-semibold text-neutral-800">{value}</span>
        </div>
      </div>
      {children}
    </div>
  );
};

export function SettingsReportObjectLevelDashboard() {
  const navigate = useNavigate();
  const { confirm } = useModal();
  const { dashboardId } = useReportSettingsDashboardScreenParams();
  const { assetId, boardId } = useQueryParams<{
    assetId: string;
    boardId: string;
  }>();
  const [deleteDashboard] = useDeleteDashboard(ReportDashboardType.OBJECT);
  const viewDashboardUrl = generateUrl(ROUTES_ROOT.report.dashboards.fullPath, {
    pathParams: {
      dashboardId,
    },
    queryParams: {
      assetId,
      boardId,
    },
  });
  const { data, isLoading } = useGetApiSettingsReportObjectDashboardsByIdQuery({
    id: dashboardId,
  });

  const navigateToWidget = useNavigateToWidget();
  const navigateToBoard = useNavigateToBoard();
  const isLayoutDirty = useAppSelector(
    selectReportDashboardLayoutDirty(
      {
        dashboardId,
        boardId,
      },
      selectReportDashboardMetadataById,
      {
        withoutColor: true,
      },
    ),
  );
  const moveLayout = useDashboardUpdateLayout();
  const updateDashboardStatus = useUpdateDashboardStatus();
  const boardState = useAppSelector((state) =>
    selectReportDashboardMetadataById(
      state,
      buildLayoutsId({
        dashboardId,
        boardId,
      }),
    ),
  );
  const [addObjectDashboardWidget] = useAddDashboardWidgetSection();
  const removeWidget = useDashboardRemoveSection();
  const {
    handleDragStart,
    droppableItem,
    handleDrop: handleWidgetsBarDrop,
    droppableItemPlaceholderProps,
  } = useDashboardWidgetsBarDroppableItem();
  const { isSectionFocused, dashboardLayoutProps, widgetEditPanelProps } =
    useDashboardSectionFocus();

  useSettingsReportObjectDashboardRedirectToFirstAssetEffect();
  useNavigateToFirstBoardEffect(data?.boards ?? []);

  const handleDrop: React.ComponentProps<
    typeof DashboardGridLayout
  >['onDrop'] = async (layout, layoutItem, _event) => {
    const type = layoutItem.i as ObjectDashboardSectionTypes;
    const res = await addObjectDashboardWidget({
      layout,
      layoutItem,
      type,
      boardId,
    });

    handleWidgetsBarDrop();

    if ('error' in res) {
      return;
    }
  };

  const handlePreviewClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!isLayoutDirty) {
      const res = await confirm(LEAVE_DASHBOARD_CONFIRM_FUNC_PROPS);
      if (res) {
        await moveLayout({
          layout: boardState!.layouts!.lg,
          boardId,
        });
      }
    }
    navigate(viewDashboardUrl);
  };

  const generateLayout = () => {
    const board = data?.boards.find((b) => b.id === boardId);

    if (data === undefined || board === undefined) {
      return null;
    }

    if (board.sections?.length === 0 && droppableItem != null) {
      return <div {...droppableItemPlaceholderProps} />;
    }

    return orderDashboardSections(board.sections).map((s) => {
      const section = s as unknown as ReportObjectDashboardSection;
      if (OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP[section.widgetType] == null) {
        return null;
      }
      const { title, icon } =
        OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP[section.widgetType];
      const layoutItem = boardState?.layouts?.lg.find(
        (item) => item.i === section.id,
      );
      const isEditable = isObjectDashboardSectionEditable(section);
      const isFocused = isSectionFocused(section.id);
      const handleEdit = isEditable
        ? () => {
            navigateToWidget({
              widgetId: section.id,
              boardId,
            });
          }
        : undefined;
      const handleRemove = () =>
        removeWidget({
          widgetId: section.id,
          layout: boardState!.layouts!.lg,
          boardId,
        });

      return (
        <div
          className={cn(
            isFocused && 'z-[1]',
            section.position.w === 1 && 'col-span-2',
          )}
          data-grid={layoutItem}
          key={section.id}
        >
          <DashboardWidgetEditPanel
            {...widgetEditPanelProps}
            visible={isFocused}
            widget={{
              id: section.id,
              widgetType: section.widgetType,
            }}
            onEdit={handleEdit}
            onRemove={handleRemove}
          >
            <DashboardSection
              id={section.id}
              className="h-full"
              focused={isFocused}
              panel={
                <div className="flex items-center gap-tw-2">
                  <DashboardSectionDragButton />
                  <div className="flex gap-tw-1">
                    <Icon iconName={icon} />
                    <span className="secondary-semibold uppercase text-neutral-500">
                      {title}
                    </span>
                  </div>
                  <div className="grow" />
                  <div className="flex items-center gap-tw-2">
                    {isEditable && (
                      <IconButton
                        size="s"
                        iconName="edit"
                        onClick={handleEdit}
                        tooltipProps={{
                          mainText: WIDGET_SETTINGS_BUTTON_TOOLTIP,
                        }}
                      />
                    )}
                    <MoveToBoardButton
                      boardId={boardId}
                      sectionId={section.id}
                      boards={data.boards}
                    />
                    <DashboardSectionRemoveButton
                      sectionId={section.id}
                      boardId={boardId}
                      layout={boardState!.layouts!.lg}
                    />
                  </div>
                </div>
              }
            >
              <ObjectDashboardWidget
                dashboardId={dashboardId}
                widgetSection={section}
                mode="edit"
                selectedAssetId={Number(assetId)}
                boardId={boardId}
                assets={data?.assets ?? []}
              />
            </DashboardSection>
          </DashboardWidgetEditPanel>
        </div>
      );
    });
  };
  if (isLoading) {
    return <DashboardLayout.LoadingPlaceholder />;
  }

  if (data === undefined) {
    return null;
  }

  return (
    <ObjectLevelDashboardLayout>
      <ObjectLevelDashboardLayout.Sidebar>
        <DashboardLayout.Header className="h-auto">
          <DashboardLayout.Header.Title
            title={data.name}
            subtitle={
              <>
                <DashboardIcon iconName="objectLevelDashboard" />
                Object Level
              </>
            }
          />
          <div className="flex items-center gap-tw-2">
            <IconButton
              iconName="trash"
              onClick={async () => {
                const res = await deleteDashboard(dashboardId);
                if (!res) return;
                navigate(-1);
              }}
            />
            <DashboardStatusDropdown
              onChange={(status) => {
                updateDashboardStatus({
                  dashboardId,
                  status,
                });
              }}
              value={data.status}
            />
          </div>
          <div className="grow" />
          <Link onClick={handlePreviewClick} to={viewDashboardUrl}>
            <DashboardSettingsToggle isActive />
          </Link>
        </DashboardLayout.Header>
        <div className="px-tw-6 pt-tw-2">
          <ObjectDashboardUpdate model={data} />
        </div>
      </ObjectLevelDashboardLayout.Sidebar>
      <ObjectLevelDashboardLayout.Body>
        <DashboardLayout.Header className="grid-column-2">
          <ObjectDashboardAssetFilter
            assetId={Number(assetId)}
            pathParams={{
              dashboardId,
            }}
            path={ROUTES_ROOT.settings.report.dashboards.dashboard.fullPath}
          />
          <SettingsReportObjectDashboardDateFilter />
          <div className="grow" />
          <DashboardHasChangesButton
            isDirty={isLayoutDirty}
            onClick={() => {
              moveLayout({
                layout: boardState!.layouts!.lg,
                boardId,
              });
            }}
          />
        </DashboardLayout.Header>
        <DashboardLayout.Body>
          <DashboardBoards
            editable
            boards={data.boards}
            selectedBoardId={boardId}
            onBoardChange={navigateToBoard}
          >
            <DashboardLayout.Grid>
              <DashboardGridLayout
                {...dashboardLayoutProps}
                originalLayout={boardState?.layouts?.lg}
                editable
                droppingItem={droppableItem ?? undefined}
                isDroppable
                onDrop={handleDrop}
              >
                {generateLayout()}
              </DashboardGridLayout>
            </DashboardLayout.Grid>
          </DashboardBoards>
        </DashboardLayout.Body>
      </ObjectLevelDashboardLayout.Body>
      <DashboardWidgetsBar
        layout={boardState?.layouts}
        onDragStart={handleDragStart}
      />
    </ObjectLevelDashboardLayout>
  );
}
