import { TConfirmFuncProps } from '@/shared/lib/hooks/useModal';
import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';

export const DASHBOARD_SKELETON_JSX = Array.from({ length: 7 }).map(
  (_, index) => <SkeletonBlock className="h-[330px]" key={index} />,
);

export const UNSUPPORTED_DASHBOARD_INFO_TEXT =
  'Unsupported type of the dashboard';

export const LEAVE_DASHBOARD_CONFIRM_FUNC_PROPS = {
  title: 'You have unsaved changes',
  subtitle: 'Do you want to save them?',
  actions: {
    primaryButton: {
      variant: 'success',
      text: 'Save',
    },
    secondaryButton: {
      text: 'Continue without saving',
    },
  },
} as const satisfies TConfirmFuncProps;

export const WIDGET_SETTINGS_BUTTON_TOOLTIP = 'Open widget settings';
export const REMOVE_WIDGET_BUTTON_TOOLTIP = 'Delete widget';
export const DRAG_N_DROP_WIDGET_BUTTON_TOOLTIP = "Change widget's position";
export const MOVE_WIDGET_TO_BOARD_BUTTON_TOOLTIP =
  'Move widget to a different board';
