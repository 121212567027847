import { isRejectedWithValue } from '@reduxjs/toolkit';
import { Middleware, MiddlewareAPI } from 'redux';

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action) && action.payload?.data?.errors) {
      if (Array.isArray(action.payload.data.errors)) {
        toastr.error(action.payload.data.errors.join('; '));
      }
    }

    return next(action);
  };
