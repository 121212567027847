import { MutableRefObject, useCallback, useRef } from 'react';

export const useInternalRef = <T>(
  externalRef: MutableRefObject<T> | ((instance: T) => void) | null,
  value: T | null = null,
) => {
  const ref = useRef<T | null>(value);
  const internalRefCallback = useCallback(
    (instance: T) => {
      ref.current = instance;
      if (!externalRef) {
        return;
      }
      if (typeof externalRef === 'function') {
        externalRef(instance);
        return;
      }
      // eslint-disable-next-line no-param-reassign
      externalRef.current = instance;
    },
    [externalRef],
  );

  return [ref, internalRefCallback] as const;
};
