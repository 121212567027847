import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { EagleEyeDashboardXYChartWidgetConfig } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/config/component';
import { EagleEyeXYChartWidget } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/widget';
import { XY_CHART_WIDGET_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/xyChart';

export const XY_SINGLE_KPI_CHART_WIDGET_CONFIG = {
  ...XY_CHART_WIDGET_CONFIG,
  title: 'XY Chart Single KPI',
  Component: EagleEyeXYChartWidget,
  ConfigComponent: EagleEyeDashboardXYChartWidgetConfig,
} as const satisfies WidgetConfiguration;
export { transformXYChartWidgetConfigToForm } from 'bundles/Shared/widgets/dashboard/widgets/xyChart/config/component.form';
export { XY_CHART_CONFIG_SCHEMA } from 'bundles/Shared/widgets/dashboard/widgets/xyChart/config/component.form';
