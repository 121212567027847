import React, { useRef } from 'react';
import { WidgetProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { DelinquencySnapshotColumnChartWidgetDto } from 'bundles/Shared/shared/api/dashboardsGeneratedApi';
import { useAmchart } from 'lib/amcharts/useAmchart';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { XYChart } from '@amcharts/amcharts5/xy';
import { DashboardWidgetCard } from 'bundles/Shared/widgets/dashboard/widgets/common';
import AnimationLoader from 'stories/AnimationLoader/AnimationLoader';
import * as am5plugins_json from '@amcharts/amcharts5/plugins/json';
import { DELINQUENCY_SNAPSHOT_CHART_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/delinquencySnapshot/config';
import { COLOR_SET, getReturnDashboardTheme } from 'lib/amcharts/utils';
import {
  addExportToChart,
  buildDataFieldsFromSeries,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib';
import { ExportChartButton } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/ExportChartButton';

const WIDGET_TITLE = 'Delinquency Snapshot';

export function DelinquencySnapshotWidget({
  data,
  isFetching,
}: WidgetProps<DelinquencySnapshotColumnChartWidgetDto>) {
  const ref = useRef(null);

  useAmchart(
    ref,
    (root) => {
      if (!data) {
        return;
      }
      const myTheme = getReturnDashboardTheme(root);
      myTheme.rule('ColorSet').set('colors', COLOR_SET);
      root.setThemes([am5themesAnimated.new(root), myTheme]);

      const parser = am5plugins_json.JsonParser.new(root);
      parser
        .parse(
          {
            ...DELINQUENCY_SNAPSHOT_CHART_CONFIG,
            refs: [
              {
                data: data.columns,
              },
              ...DELINQUENCY_SNAPSHOT_CHART_CONFIG.refs,
            ],
          },
          {
            parent: root.container,
          },
        )
        .then(function (chart: XYChart) {
          // Chart is ready
          chart.series.getIndex(0)?.appear(1000);
          chart.appear(1000, 100);
          addExportToChart({
            chartRef: ref,
            root,
            title: WIDGET_TITLE,
            data: data.columns,
            dataFields: {
              label: 'Label',
              ...buildDataFieldsFromSeries(chart.series.values),
            },
            withoutDate: true,
          });
        });
    },
    [data],
  );

  return (
    <DashboardWidgetCard>
      <DashboardWidgetCard.Header>
        <span className="header5-bold text-neutral-850">{WIDGET_TITLE}</span>
        <div className="grow" />
        <ExportChartButton chartRef={ref} />
      </DashboardWidgetCard.Header>
      {isFetching && <AnimationLoader />}
      <div className="h-[350px]" ref={ref} />
    </DashboardWidgetCard>
  );
}
