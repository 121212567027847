import React, { FC, ReactNode, useMemo, useState } from 'react';
import { Icon, ConfirmationModal } from 'stories';
import { IconsId } from 'types/sre-icons';
import AvailableActionsItem from './AvailableActionsItem';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { selectReconcileActiveAction } from 'bundles/Construction/reducers/ReconcileSlice';
import FormInput from 'bundles/Shared/components/GroupForm/FormItems/FormInput';

interface IAction {
  title: string;
  description: string;
  iconName: IconsId;
  show: boolean;
  content: ReactNode;
}

interface IAvailableActionsProps {
  actionsData: IAction[];
  destroyCE?: boolean;
  handleDestroyAction: (data: { action_type: string }) => Promise<void>;
  onCloseModal: () => void;
}

const AvailableActions: FC<IAvailableActionsProps> = ({
  actionsData,
  destroyCE,
  handleDestroyAction,
  onCloseModal,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [comment, setComment] = useState({
    value: '',
  });
  const activeAction = useAppSelector(selectReconcileActiveAction);

  const destroyHandle = async (status: boolean) => {
    if (status) {
      await handleDestroyAction({
        action_type: 'destroy',
      });
      onCloseModal();
    }
    setShowConfirmModal(false);
  };

  return (
    <>
      {!activeAction && Boolean(actionsData.length) && (
        <div className="inline-semibold mb-s">Available Actions</div>
      )}
      <div className="change-event-scroll change-event-scroll_actions">
        {activeAction && (
          <div>
            {actionsData
              .filter((action) => action.title === activeAction)
              .map(
                (item, idx) =>
                  item.show && (
                    <AvailableActionsItem key={`action${idx}`} {...item} />
                  ),
              )}
          </div>
        )}
        {!activeAction ? (
          <div>
            {actionsData.map(
              (item, idx) =>
                item.show && (
                  <AvailableActionsItem key={`action${idx}`} {...item} />
                ),
            )}
          </div>
        ) : (
          activeAction &&
          Boolean(actionsData.length) && (
            <div>
              <div className="inline-semibold mb-s">Other Actions</div>
              {actionsData
                .filter((action) => action.title !== activeAction)
                .map(
                  (item, idx) =>
                    item.show && (
                      <AvailableActionsItem key={`action${idx}`} {...item} />
                    ),
                )}
            </div>
          )
        )}

        {destroyCE && (
          <div
            className="flex items-center mt-s cursor-pointer"
            onClick={() => setShowConfirmModal(true)}
          >
            <Icon iconName="trash" className="pr-xs" />
            <div className="inline-semibold">Remove</div>
          </div>
        )}
      </div>
      {showConfirmModal && (
        <ConfirmationModal
          title="Remove Change Event"
          subtitle={
            <div className="flex flex-col gap-tw-2">
              <span>To confirm deletion, enter "Delete" in the field</span>
              <FormInput
                fieldName="value"
                setFormData={setComment}
                value={comment.value}
                styles={{
                  nomb: true,
                  input__item: 'comment-in-modal',
                }}
              />
            </div>
          }
          actions={{
            primaryButton: {
              text: 'Yes',
              variant: 'danger',
              disabled: comment.value !== 'Delete',
            },
            secondaryButton: {
              text: 'No',
              variant: 'secondary',
            },
          }}
          handleResolve={destroyHandle}
        />
      )}
    </>
  );
};

export default AvailableActions;
