import { isEmpty } from 'lodash-es';

export function includesInLowerCase(
  value: string,
  searchQuery: string,
): boolean;
export function includesInLowerCase(
  value: string[],
  searchQuery: string,
): boolean;
export function includesInLowerCase(
  value: string | string[],
  searchQuery: string,
) {
  const preparedQuery = searchQuery.trim().toLowerCase();
  const includesInQuery = (item: string) =>
    item.toLowerCase().includes(preparedQuery);
  const empty = isEmpty(searchQuery);

  if (Array.isArray(value)) {
    return empty || value.some(includesInQuery);
  }

  return empty || includesInQuery(value);
}
