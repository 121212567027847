import { WIDGET_SETTINGS_BUTTON_TOOLTIP } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/config';
import { DashboardSectionRemoveButton } from '@/bundles/Shared/features/dashboard/sectionActions/ui/DashboardSectionRemoveButton';
import { EAGLE_EYE_DASHBOARD_WIDGETS_CONFIG_MAP } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { cn } from '@/shared/lib/css/cn';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useNavigate } from '@reach/router';
import {
  useGetApiSettingsReportDashboardsByIdWithScreenParams,
  useNavigateToBoard,
  useNavigateToFirstBoardEffect,
} from 'bundles/Settings/components/REport/Dashboards/Dashboard/lib';
import SettingsReportDashboardHeader from 'bundles/Settings/components/REport/Dashboards/Dashboard/widgets/SettingsReportDashboardHeader';
import { DashboardLayout } from 'bundles/Shared/components/layouts/dashboard/DashboardLayout';
import {
  buildLayoutsId,
  DashboardSection,
  EagleEyeDashboardSectionType,
  ReportDashboardSectionPositionWithId,
  selectReportEagleEyeDashboardSettingsById,
  useDashboardContext,
} from 'bundles/Shared/entities/dashboard';
import { useAddDashboardWidgetSection } from 'bundles/Shared/features/dashboard/addWidget';
import { MoveToBoardButton } from 'bundles/Shared/features/dashboard/moveToBoard';
import {
  DashboardSectionColorPicker,
  useDashboardRemoveSection,
} from 'bundles/Shared/features/dashboard/sectionActions';
import DashboardSectionDragButton from 'bundles/Shared/features/dashboard/sectionActions/ui/DashboardSectionDragButton';
import { DashboardBoards } from 'bundles/Shared/widgets/dashboard/board/ui/DashboardBoards';
import { DashboardGridLayout } from 'bundles/Shared/widgets/dashboard/layout';
import { useDashboardSectionFocus } from 'bundles/Shared/widgets/dashboard/widgetPanel/lib';
import { DashboardWidgetEditPanel } from 'bundles/Shared/widgets/dashboard/widgetPanel/ui/DashboardWidgetEditPanel';
import { useDashboardWidgetsBarDroppableItem } from 'bundles/Shared/widgets/dashboard/widgetsBar';
import { DashboardWidgetsBar } from 'bundles/Shared/widgets/dashboard/widgetsBar/ui/DashboardWidgetsBar';
import {
  EagleEyeDashboardWidget,
  EagleEyeDashboardWidgetContext,
} from 'bundles/Shared/widgets/dashboard/widgetsHelpers';
import { useState } from 'react';
import { ReactGridLayoutProps } from 'react-grid-layout';
import { Field, Icon, IconButton } from 'stories/index';

function SettingsReportEagleEyeDashboard() {
  const { dashboardId, boardId } = useDashboardContext();
  const [bp, setBp] = useState('');
  const [addEagleEyeDashboardWidget] = useAddDashboardWidgetSection();
  const removeSection = useDashboardRemoveSection();
  const navigate = useNavigate();
  const navigateToBoard = useNavigateToBoard();
  const {
    handleDragStart,
    droppableItem,
    handleDrop: handleWidgetsBarDrop,
    droppableItemPlaceholderProps,
  } = useDashboardWidgetsBarDroppableItem();
  const { isSectionFocused, dashboardLayoutProps, widgetEditPanelProps } =
    useDashboardSectionFocus();

  const { dashboard, data, isLoading } =
    useGetApiSettingsReportDashboardsByIdWithScreenParams();
  const boardSettings = useAppSelector((state) =>
    selectReportEagleEyeDashboardSettingsById(
      state,
      buildLayoutsId({
        boardId,
        dashboardId,
      }),
    ),
  )!;
  const { layouts } = boardSettings ?? {};

  const handleDrop: ReactGridLayoutProps['onDrop'] = async (
    layout,
    layoutItem,
    _event,
  ) => {
    await addEagleEyeDashboardWidget({
      type: [layoutItem.i as EagleEyeDashboardSectionType],
      boardId,
      layoutItem: layoutItem as ReportDashboardSectionPositionWithId,
      layout: layout as unknown as ReportDashboardSectionPositionWithId[],
    });
    handleWidgetsBarDrop();
  };
  useNavigateToFirstBoardEffect(dashboard?.boards);

  const generateLayout = () => {
    const board = dashboard?.boards.find((b) => b.id === boardId);

    if (dashboard === undefined || board === undefined) {
      return null;
    }

    if (board.sections.length === 0 && droppableItem != null) {
      return <div {...droppableItemPlaceholderProps} />;
    }

    return board.sections
      .filter(
        (section) => layouts?.lg.find((item) => item.i === section.id) != null,
      )
      .map((section) => {
        const layoutItem = layouts?.lg.find((item) => item.i === section.id);

        const { widgetType, sectionType, id: sectionId } = section;
        const { Component, title, icon } =
          EAGLE_EYE_DASHBOARD_WIDGETS_CONFIG_MAP[sectionType ?? widgetType];
        if (Component == null) {
          return null;
        }
        const isFocused = isSectionFocused(sectionId);
        const handleRemove = () =>
          removeSection({
            widgetId: sectionId,
            boardId,
            layout: layouts!.lg,
          });

        const handleViewDashboard = () => {
          navigate(
            generateUrl(
              ROUTES_ROOT.settings.report.dashboards.dashboard.widget.fullPath,
              {
                pathParams: {
                  dashboardId,
                  boardId,
                  widgetId: section.id,
                },
              },
            ),
          );
        };
        const handleEdit = () => {
          handleViewDashboard?.();
        };
        return (
          <div
            className={cn(isFocused && 'z-[1]')}
            data-grid={layoutItem}
            key={section.id}
          >
            <DashboardWidgetEditPanel
              {...widgetEditPanelProps}
              visible={isFocused}
              onRemove={handleRemove}
              widget={section}
              onEdit={handleEdit}
              settings={
                <>
                  <Field className="w-full" labelText="Border">
                    <DashboardSectionColorPicker
                      activeColor={layoutItem?.color ?? null}
                      sectionId={section.id}
                      dashboardId={dashboardId}
                    />
                  </Field>
                </>
              }
            >
              <DashboardSection
                focused={isFocused}
                color={layoutItem?.color ?? null}
                id={section.id}
                panel={
                  <div className="flex items-center gap-tw-2">
                    <DashboardSectionDragButton />
                    <div className="flex gap-tw-1">
                      <Icon iconName={icon} />
                      <span className="secondary-semibold uppercase text-neutral-500">
                        {title}
                      </span>
                    </div>
                    <div className="grow" />
                    <div className="flex items-center gap-tw-2">
                      <IconButton
                        size="s"
                        iconName="edit"
                        onClick={handleEdit}
                        tooltipProps={{
                          mainText: WIDGET_SETTINGS_BUTTON_TOOLTIP,
                        }}
                      />
                      <MoveToBoardButton
                        boardId={boardId}
                        sectionId={section.id}
                        boards={dashboard.boards}
                      />
                      <DashboardSectionRemoveButton
                        sectionId={section.id}
                        boardId={boardId}
                        layout={layouts!.lg}
                      />
                    </div>
                  </div>
                }
              >
                <EagleEyeDashboardWidget
                  dashboardId={dashboard.id}
                  mode="view"
                  widgetId={section.id}
                  widgetSection={section}
                  boardId={boardId}
                  context={
                    {
                      columnVisibilityEnabled: true,
                      segments: dashboard.segments,
                      assets: dashboard.assets,
                      ...data?.meta,
                    } satisfies EagleEyeDashboardWidgetContext
                  }
                />
              </DashboardSection>
            </DashboardWidgetEditPanel>
          </div>
        );
      });
  };

  if (isLoading) {
    return <DashboardLayout.LoadingPlaceholder />;
  }

  if (dashboard === undefined) {
    return null;
  }

  return (
    <DashboardLayout>
      <SettingsReportDashboardHeader />
      <DashboardLayout.Body>
        <DashboardBoards
          editable
          boards={dashboard.boards}
          selectedBoardId={boardId}
          onBoardChange={navigateToBoard}
        >
          <DashboardLayout.Grid>
            <DashboardGridLayout
              {...dashboardLayoutProps}
              key={`${boardId}-${bp}`}
              onBreakpointChange={setBp}
              originalLayout={layouts?.lg}
              editable
              droppingItem={droppableItem ?? undefined}
              isDroppable
              onDrop={handleDrop}
            >
              {generateLayout()}
            </DashboardGridLayout>
          </DashboardLayout.Grid>
        </DashboardBoards>
      </DashboardLayout.Body>
      <DashboardWidgetsBar layout={layouts!} onDragStart={handleDragStart} />
    </DashboardLayout>
  );
}

export default SettingsReportEagleEyeDashboard;
