import {
  ExtractParams,
  generateUrl,
  ROUTES_ROOT,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import { useNavigate, useParams } from '@reach/router';
import {
  DashboardWidgetSectionDto,
  EagleEyeBoard,
  ReportDashboardAsset,
  useDashboardContext,
  useGetApiSettingsReportDashboardsQuery,
  useReportComparisonDashboardAssetsQuery,
  useReportEagleEyeDashboardSettingsById,
  useReportObjectDashboardAssetsQuery,
} from 'bundles/Shared/entities/dashboard';
import { orderBy } from 'lodash-es';
import { useCallback, useEffect } from 'react';

export const useReportSettingsDashboardScreenParams = () =>
  useParams<
    ExtractParams<
      typeof ROUTES_ROOT.settings.report.dashboards.dashboard.fullPath
    >
  >();

export const useReportSettingsDashboardScreenQueryParams = () =>
  useQueryParams<{
    boardId?: string;
  }>();

export const useReportDashboardSettingsScreenQueryParams = () => {
  const { assetId, boardId } = useQueryParams<{
    assetId?: string;
    boardId?: string;
  }>();
  return {
    assetId: assetId && Number(assetId),
    boardId,
  };
};

const useSettingsReportDashboardRedirectToFirstAssetEffect = ({
  assets,
}: {
  assets: ReportDashboardAsset[];
}) => {
  const { dashboardId } = useReportSettingsDashboardScreenParams();
  const { assetId } = useReportDashboardSettingsScreenQueryParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (assetId != null) {
      return;
    }
    navigate(
      generateUrl(ROUTES_ROOT.settings.report.dashboards.dashboard.fullPath, {
        pathParams: {
          dashboardId,
        },
        queryParams: {
          assetId: assets?.[0]?.id,
        },
      }),
      {
        replace: true,
      },
    );
  }, [assets]);
};

export const useSettingsReportObjectDashboardRedirectToFirstAssetEffect =
  () => {
    const { assets } = useReportObjectDashboardAssetsQuery();

    useSettingsReportDashboardRedirectToFirstAssetEffect({
      assets,
    });
  };

export const useSettingsReportComparisonDashboardRedirectToFirstAssetEffect =
  () => {
    const { assets } = useReportComparisonDashboardAssetsQuery();

    useSettingsReportDashboardRedirectToFirstAssetEffect({
      assets,
    });
  };

export const NO_BOARD_ID = 'board-none';

export const useNavigateToWidget = () => {
  const navigate = useNavigate();
  const { dashboardId } = useReportSettingsDashboardScreenParams();

  const { assetId: selectedAssetId } =
    useReportDashboardSettingsScreenQueryParams();
  return ({
    widgetId,
    assetId,
    boardId,
  }: {
    widgetId: string;
    boardId: string;
    assetId?: number | string;
  }) =>
    navigate(
      generateUrl(
        ROUTES_ROOT.settings.report.dashboards.dashboard.widget.fullPath,
        {
          pathParams: {
            dashboardId,
            widgetId,
            boardId,
          },
          queryParams: {
            assetId: assetId ?? selectedAssetId,
          },
        },
      ),
    );
};

export const useNavigateToBoard = () => {
  const navigate = useNavigate();
  const { dashboardId } = useDashboardContext();
  const { assetId } = useReportDashboardSettingsScreenQueryParams();

  return useCallback(
    (newBoardId: string) => {
      navigate(
        generateUrl(ROUTES_ROOT.settings.report.dashboards.dashboard.fullPath, {
          pathParams: {
            dashboardId,
          },
          queryParams: {
            assetId,
            boardId: newBoardId,
          },
        }),
        {
          replace: true,
        },
      );
    },
    [assetId, dashboardId],
  );
};

export const useNavigateToFirstBoardEffect = (
  boards: Pick<EagleEyeBoard, 'id'>[] | undefined,
) => {
  const navigateToBoard = useNavigateToBoard();
  const { boardId } = useReportSettingsDashboardScreenQueryParams();

  useEffect(() => {
    if (boards == null || boards.length === 0 || boardId) {
      return;
    }
    navigateToBoard(boards[0].id);
  }, [boards, boardId]);
};

export const useReportSettingsDashboardById = (dashboardId: string) => {
  const { data, isLoading } = useGetApiSettingsReportDashboardsQuery();
  return {
    data: data?.find((d) => d.id === dashboardId),
    isLoading,
  };
};

export const useGetApiSettingsReportDashboardsByIdWithScreenParams = () => {
  const { dashboardId } = useReportSettingsDashboardScreenParams();
  return useReportEagleEyeDashboardSettingsById({
    dashboardId,
  });
};

export const orderDashboardSections = <
  S extends Pick<DashboardWidgetSectionDto, 'position'>,
>(
  sections: S[],
) => {
  return orderBy(
    sections,
    [(section) => section.position.y, (section) => section.position.x],
    ['asc', 'asc'],
  );
};
