const chartFilter = {
  periods: ['2022-11-01', '2023-11-01'],
  assetIds: [16, 10, 8, 9],
  segmentIds: [1],
  defaultPeriodShift: -1,
  graphedColumnKey: '1',
};
const scorecard = {
  id: '02ebfe53-0cfb-487a-9fd9-100e4f5f3dd2',
  assets: [
    {
      id: 4,
      name: 'Forest Park Place',
    },
    {
      id: 2,
      name: 'Asbury Lane',
    },
    {
      id: 6,
      name: 'Autumn Oaks',
    },
    {
      id: 1,
      name: 'Lakeside Manor',
    },
    {
      id: 5,
      name: 'Royal Lane',
    },
    {
      id: 9,
      name: 'Eagle River',
    },
    {
      id: 15,
      name: 'Royal Ranch',
    },
    {
      id: 8,
      name: 'Canterbury Park',
    },
    {
      id: 7,
      name: 'Arbor Grove',
    },
    {
      id: 3,
      name: 'Royal Court',
    },
    {
      id: 10,
      name: 'Lookout Grove',
    },
    {
      id: 18,
      name: '108 CNC (Orion)',
    },
  ],
  createdAt: 1683631784,
  defaultPeriodShift: -1,
  description: '',
  displayAverage: false,
  displayTotal: false,
  graphedColumns: [
    {
      key: '1',
      label: 'Occ. Rate',
      valueDisplayOptions: {
        type: 'percentage',
        precision: 0,
      },
    },
    {
      key: '2',
      label: 'Total Units',
      valueDisplayOptions: {
        type: 'number',
        precision: 0,
      },
    },
    {
      key: '3',
      label: 'Rental Revenue',
      valueDisplayOptions: {
        type: 'currency',
        precision: 2,
      },
    },
    {
      key: '4',
      label: 'Rev / Sq Ft',
      valueDisplayOptions: {
        type: 'currency',
        precision: 2,
      },
    },
    {
      key: '5',
      label: 'OPEX Margin',
      valueDisplayOptions: {
        type: 'percentage',
        precision: 2,
      },
    },
    {
      key: '7',
      label: 'Capex - $',
      valueDisplayOptions: {
        type: 'currency',
        precision: 2,
      },
    },
    {
      key: '9',
      label: 'Debt Yield',
      valueDisplayOptions: {
        type: 'percentage',
        precision: 2,
      },
    },
    {
      key: '10',
      label: 'DSCR',
      valueDisplayOptions: {
        type: 'metric',
        precision: 2,
      },
    },
  ],
  segments: [
    {
      id: 2,
      title: 'Multifamily - Eastern',
    },
    {
      id: 1,
      title: 'Multifamily - Midwest',
    },
    {
      id: 3,
      title: 'Senior - ABC Mgmt',
    },
  ],
  status: 'published',
  title: "Benny's Boxscore",
  withMtdYtd: false,
};

const chartWidgetSection = {
  id: '33',
  chartPresentation: {
    id: 34,
    assets: [
      {
        assetId: 16,
        id: 47,
      },
      {
        assetId: 10,
        id: 92,
      },
      {
        assetId: 8,
        id: 93,
      },
      {
        assetId: 9,
        id: 94,
      },
    ],
    kpiKey: '1',
    reportTableSegments: [
      {
        id: 34,
        tableSegmentId: 1,
      },
    ],
  },
  color: null,
  defaultPresentation: 'chart',
  defaultView: 'asset',
  kpiGoals: [
    {
      id: 10,
      kpiKey: '1',
      scorecardSectionId: 33,
      value: 95.5,
    },
  ],
  position: {
    h: 12,
    w: 1,
    x: 1,
    y: 0,
    maxW: 2,
    minH: 14,
    minW: 1,
  },
  scorecard: {
    id: '02ebfe53-0cfb-487a-9fd9-100e4f5f3dd2',
    assets: [
      {
        id: 4,
        name: 'Forest Park Place',
      },
      {
        id: 2,
        name: 'Asbury Lane',
      },
      {
        id: 6,
        name: 'Autumn Oaks',
      },
      {
        id: 1,
        name: 'Lakeside Manor',
      },
      {
        id: 5,
        name: 'Royal Lane',
      },
      {
        id: 9,
        name: 'Eagle River',
      },
      {
        id: 15,
        name: 'Royal Ranch',
      },
      {
        id: 8,
        name: 'Canterbury Park',
      },
      {
        id: 7,
        name: 'Arbor Grove',
      },
      {
        id: 3,
        name: 'Royal Court',
      },
      {
        id: 10,
        name: 'Lookout Grove',
      },
      {
        id: 18,
        name: '108 CNC (Orion)',
      },
    ],
    createdAt: 1683631784,
    defaultPeriodShift: -1,
    description: '',
    displayAverage: false,
    displayTotal: false,
    graphedColumns: [
      {
        key: '1',
        label: 'Occ. Rate',
        valueDisplayOptions: {
          type: 'percentage',
          precision: 0,
        },
      },
      {
        key: '2',
        label: 'Total Units',
        valueDisplayOptions: {
          type: 'number',
          precision: 0,
        },
      },
      {
        key: '3',
        label: 'Rental Revenue',
        valueDisplayOptions: {
          type: 'currency',
          precision: 2,
        },
      },
      {
        key: '4',
        label: 'Rev / Sq Ft',
        valueDisplayOptions: {
          type: 'currency',
          precision: 2,
        },
      },
      {
        key: '5',
        label: 'OPEX Margin',
        valueDisplayOptions: {
          type: 'percentage',
          precision: 2,
        },
      },
      {
        key: '7',
        label: 'Capex - $',
        valueDisplayOptions: {
          type: 'currency',
          precision: 2,
        },
      },
      {
        key: '9',
        label: 'Debt Yield',
        valueDisplayOptions: {
          type: 'percentage',
          precision: 2,
        },
      },
      {
        key: '10',
        label: 'DSCR',
        valueDisplayOptions: {
          type: 'metric',
          precision: 2,
        },
      },
    ],
    segments: [
      {
        id: 2,
        title: 'Multifamily - Eastern',
      },
      {
        id: 1,
        title: 'Multifamily - Midwest',
      },
      {
        id: 3,
        title: 'Senior - ABC Mgmt',
      },
    ],
    status: 'published',
    title: "Benny's Boxscore",
    withMtdYtd: false,
  },
  sectionId: '482c3807-ad98-4177-a2ff-212c83261cac',
  sectionType: 'scorecard',
  tablePresentation: {
    id: 34,
    periodType: 'mtd',
  },
  widgetType: 'scorecard',
};

const chartData = {
  graphedColumn: {
    key: '1',
    label: 'Occ. Rate',
    valueDisplayOptions: {
      type: 'percentage',
      precision: 0,
    },
  },
  values: [
    {
      objectType: 'asset',
      period: '2022-11-01',
      value: 44.11764705882353,
      objectId: 9,
    },
    {
      objectType: 'asset',
      period: '2022-12-01',
      value: 46.07843137254902,
      objectId: 9,
    },
    {
      objectType: 'asset',
      period: '2023-01-01',
      value: 46.568627450980394,
      objectId: 9,
    },
    {
      objectType: 'asset',
      period: '2023-02-01',
      value: 48.03921568627451,
      objectId: 9,
    },
    {
      objectType: 'asset',
      period: '2023-03-01',
      value: 50,
      objectId: 9,
    },
    {
      objectType: 'asset',
      period: '2023-04-01',
      value: 73.17708333333333,
      objectId: 9,
    },
    {
      objectType: 'asset',
      period: '2023-05-01',
      value: 75,
      objectId: 9,
    },
    {
      objectType: 'asset',
      period: '2023-06-01',
      value: 65.68627450980392,
      objectId: 9,
    },
    {
      objectType: 'asset',
      period: '2023-07-01',
      value: 69.6078431372549,
      objectId: 9,
    },
    {
      objectType: 'asset',
      period: '2023-08-01',
      value: 77.45098039215686,
      objectId: 9,
    },
    {
      objectType: 'asset',
      period: '2023-09-01',
      value: 80.88235294117646,
      objectId: 9,
    },
    {
      objectType: 'asset',
      period: '2023-10-01',
      value: 85.29411764705883,
      objectId: 9,
    },
    {
      objectType: 'asset',
      period: '2023-11-01',
      value: 86.27450980392157,
      objectId: 9,
    },
    {
      objectType: 'asset',
      period: '2022-11-01',
      value: 67.05882352941177,
      objectId: 8,
    },
    {
      objectType: 'asset',
      period: '2022-12-01',
      value: 68.23529411764706,
      objectId: 8,
    },
    {
      objectType: 'asset',
      period: '2023-01-01',
      value: 69.41176470588235,
      objectId: 8,
    },
    {
      objectType: 'asset',
      period: '2023-02-01',
      value: 70.58823529411765,
      objectId: 8,
    },
    {
      objectType: 'asset',
      period: '2023-03-01',
      value: 71.76470588235294,
      objectId: 8,
    },
    {
      objectType: 'asset',
      period: '2023-04-01',
      value: 89.02743142144638,
      objectId: 8,
    },
    {
      objectType: 'asset',
      period: '2023-05-01',
      value: 90.27431421446384,
      objectId: 8,
    },
    {
      objectType: 'asset',
      period: '2023-06-01',
      value: 80,
      objectId: 8,
    },
    {
      objectType: 'asset',
      period: '2023-07-01',
      value: 82.3529411764706,
      objectId: 8,
    },
    {
      objectType: 'asset',
      period: '2023-08-01',
      value: 83.52941176470588,
      objectId: 8,
    },
    {
      objectType: 'asset',
      period: '2023-09-01',
      value: 87.05882352941177,
      objectId: 8,
    },
    {
      objectType: 'asset',
      period: '2023-10-01',
      value: 89.41176470588235,
      objectId: 8,
    },
    {
      objectType: 'asset',
      period: '2023-11-01',
      value: 90.58823529411765,
      objectId: 8,
    },
    {
      objectType: 'asset',
      period: '2022-11-01',
      value: 56.54205607476636,
      objectId: 10,
    },
    {
      objectType: 'asset',
      period: '2022-12-01',
      value: 57.00934579439252,
      objectId: 10,
    },
    {
      objectType: 'asset',
      period: '2023-01-01',
      value: 59.345794392523366,
      objectId: 10,
    },
    {
      objectType: 'asset',
      period: '2023-02-01',
      value: 64.01869158878505,
      objectId: 10,
    },
    {
      objectType: 'asset',
      period: '2023-03-01',
      value: 66.35514018691589,
      objectId: 10,
    },
    {
      objectType: 'asset',
      period: '2023-04-01',
      value: 70.09345794392523,
      objectId: 10,
    },
    {
      objectType: 'asset',
      period: '2023-05-01',
      value: 73.3644859813084,
      objectId: 10,
    },
    {
      objectType: 'asset',
      period: '2023-06-01',
      value: 82.24299065420561,
      objectId: 10,
    },
    {
      objectType: 'asset',
      period: '2023-07-01',
      value: 85.98130841121495,
      objectId: 10,
    },
    {
      objectType: 'asset',
      period: '2023-08-01',
      value: 88.78504672897196,
      objectId: 10,
    },
    {
      objectType: 'asset',
      period: '2023-09-01',
      value: 92.05607476635514,
      objectId: 10,
    },
    {
      objectType: 'asset',
      period: '2023-10-01',
      value: 92.05607476635514,
      objectId: 10,
    },
    {
      objectType: 'asset',
      period: '2023-11-01',
      value: 90.65420560747664,
      objectId: 10,
    },
  ],
};

const kpiTableSingleDateState = {
  groupingType: 'assets',
  date: '2024-02-14',
};

const ktsdData = {
  columns: [
    {
      key: 0,
      label: 'Day',
      valueDisplayOptions: {
        type: 'number',
        precision: 2,
        kiloFormatting: false,
      },
      dateFrom: '2023-11-29',
      dateTo: '2023-11-29',
    },
    {
      key: 2,
      label: 'Week',
      valueDisplayOptions: {
        type: 'number',
        precision: 2,
        kiloFormatting: false,
      },
      dateFrom: '2023-06-12',
      dateTo: '2023-06-18',
    },
    {
      key: 3,
      label: 'Ytd',
      valueDisplayOptions: {
        type: 'number',
        precision: 2,
        kiloFormatting: false,
      },
      dateFrom: '2022-01-01',
      dateTo: '2022-12-20',
    },
    {
      key: 4,
      label: 'Ungrouped col',
      valueDisplayOptions: {
        type: 'number',
        precision: 2,
        kiloFormatting: false,
      },
      dateFrom: '2023-06-01',
      dateTo: '2023-06-30',
    },
    {
      key: 5,
      label: 'Year',
      valueDisplayOptions: {
        type: 'number',
        precision: 2,
        kiloFormatting: false,
      },
      dateFrom: '2023-07-01',
      dateTo: '2023-09-30',
    },
  ],
  rows: [
    {
      label: 'should be draft',
      path: 'segment-68',
      type: 'segment',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Asset for testing script 2',
      path: 'segment-68/asset-122',
      type: 'asset',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Royal Court 3, LLC',
      path: 'segment-68/asset-122/le-fd4d2344-8845-43ac-a2c0-ae5323794c4c',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Foxtrot 2, LLC',
      path: 'segment-68/asset-122/le-24ca9b3b-ebe0-4a2e-b77c-544f19d70768',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Test segment',
      path: 'segment-69',
      type: 'segment',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Reason Villas',
      path: 'segment-69/asset-115',
      type: 'asset',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'SR Testing Big Time LLC',
      path: 'segment-69/asset-115/le-b2a59f32-f674-4cc1-ba1b-74d73c03cb1f',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'hmm test',
      path: 'segment-70',
      type: 'segment',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Reason Villas',
      path: 'segment-70/asset-115',
      type: 'asset',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'SR Testing Big Time LLC',
      path: 'segment-70/asset-115/le-b2a59f32-f674-4cc1-ba1b-74d73c03cb1f',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Test Segment 4',
      path: 'segment-73',
      type: 'segment',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Reason Villas',
      path: 'segment-73/asset-115',
      type: 'asset',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'sam llc',
      path: 'segment-73/asset-115/le-63a26f50-233b-499a-b778-813b5dfbc7d6',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'SR Super Segment',
      path: 'segment-74',
      type: 'segment',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: "Sam's Castle",
      path: 'segment-74/asset-97',
      type: 'asset',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'And Another One',
      path: 'segment-74/asset-97/le-87ec504f-680d-401a-bfe6-c23b904e30be',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Reason Villas',
      path: 'segment-74/asset-115',
      type: 'asset',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'SR Testing Big Time LLC',
      path: 'segment-74/asset-115/le-b2a59f32-f674-4cc1-ba1b-74d73c03cb1f',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'sam llc',
      path: 'segment-74/asset-115/le-63a26f50-233b-499a-b778-813b5dfbc7d6',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Segment 01',
      path: 'segment-76',
      type: 'segment',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'AA Lukianov Estate',
      path: 'segment-76/asset-8',
      type: 'asset',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Lukianov Estate',
      path: 'segment-76/asset-8/le-561f5346-2232-4c4a-99cb-441ec6e345b2',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Operational Scoreboard #1',
      path: 'segment-82',
      type: 'segment',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: "Alex's Mansion",
      path: 'segment-82/asset-62',
      type: 'asset',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Legal Entity, 1430',
      path: 'segment-82/asset-62/le-0b17e8d2-b3a9-41c0-bbc0-0bbcfc0c79f3',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Kaer Morhen',
      path: 'segment-82/asset-65',
      type: 'asset',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'GSCPHI',
      path: 'segment-82/asset-65/le-6c0a3dfc-4207-4eb9-abfc-c20fd947cca6',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: '#2 Operational Scoreboard ',
      path: 'segment-83',
      type: 'segment',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Kaer Morhen',
      path: 'segment-83/asset-65',
      type: 'asset',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'GSCPHI',
      path: 'segment-83/asset-65/le-6c0a3dfc-4207-4eb9-abfc-c20fd947cca6',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Reason Villas',
      path: 'segment-83/asset-115',
      type: 'asset',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'SR Testing Big Time LLC',
      path: 'segment-83/asset-115/le-b2a59f32-f674-4cc1-ba1b-74d73c03cb1f',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'sam llc',
      path: 'segment-83/asset-115/le-63a26f50-233b-499a-b778-813b5dfbc7d6',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Test Segment for report operational',
      path: 'segment-86',
      type: 'segment',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Kaer Morhen',
      path: 'segment-86/asset-65',
      type: 'asset',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'TOA',
      path: 'segment-86/asset-65/le-680e534a-f706-4782-bad3-42dbc135d0c4',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: '50 LPO',
      path: 'segment-86/asset-65/le-76db2bb6-5a3a-4769-b862-6df57ac5cba6',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'GSCPHI',
      path: 'segment-86/asset-65/le-6c0a3dfc-4207-4eb9-abfc-c20fd947cca6',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'P52',
      path: 'segment-86/asset-65/le-87b291a2-a3da-442c-b718-8aa9986301dd',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: "Tagir's Test Segment",
      path: 'segment-88',
      type: 'segment',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'tagir test asset 2',
      path: 'segment-88/asset-124',
      type: 'asset',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'tagir_test_inc2',
      path: 'segment-88/asset-124/le-b7d869e3-82ef-4de3-ac20-9ce2668d4a68',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'tagir_test_inc6',
      path: 'segment-88/asset-124/le-8f1017a9-775e-48a6-a202-94d5529eb0f9',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'tagir test asset 3',
      path: 'segment-88/asset-125',
      type: 'asset',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'tagir_test_inc3',
      path: 'segment-88/asset-125/le-798e7731-4fd2-405c-bf05-595de828cb1a',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'tagir test asset 5',
      path: 'segment-88/asset-177',
      type: 'asset',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'tagir_test_inc5',
      path: 'segment-88/asset-177/le-cbc2d3a3-8292-47e1-9473-398196ba7078',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'New segment',
      path: 'segment-89',
      type: 'segment',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Royal Court',
      path: 'segment-89/asset-5',
      type: 'asset',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Royal Court 2, LLC',
      path: 'segment-89/asset-5/le-1de1075a-2098-4855-9d3a-66cd96e298ca',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Royal Court 1, LLC',
      path: 'segment-89/asset-5/le-b838c7a9-b4ed-4519-b60a-30cd9c465edf',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'AA Lukianov Estate',
      path: 'segment-89/asset-8',
      type: 'asset',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Lukianov Estate',
      path: 'segment-89/asset-8/le-561f5346-2232-4c4a-99cb-441ec6e345b2',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: 'Parkview Estates',
      path: 'segment-89/asset-14',
      type: 'asset',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: '22222',
      path: 'segment-89/asset-14/le-cb4915be-bcd4-43ce-84d8-b14a3f0dbdc8',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
    {
      label: '111111',
      path: 'segment-89/asset-14/le-f92f98ec-1710-4bce-a29b-59cc9b238d20',
      type: 'legal_entity',
      values: {
        '0': 12,
        '2': 12,
        '3': 4,
        '4': 12,
        '5': 12,
      },
    },
  ],
  totalRow: {
    label: 'Total',
    path: 'total',
    type: 'total',
    values: {
      '0': 12,
      '2': 12,
      '3': 4,
      '4': 12,
      '5': 12,
    },
  },
  type: 'kpi_table_single_date',
};

const ktsdWS = {
  id: '796b91cc-ab23-4447-a9b9-5bd32460af9a',
  defaultOptions: {
    date: '2023-12-20',
    groupingType: 'segments',
  },
  position: {
    h: 8,
    w: 2,
    x: 0,
    y: 0,
    maxH: 12,
    maxW: 2,
    minH: 6,
    minW: 1,
  },
  sectionId: '185f7d86-e99a-4821-bc1c-0c0cb60ff276',
  title: 'New Kpi Table Single Date',
  widgetConfig: {
    columns: [
      {
        key: 0,
        label: 'Day',
        expression: 'formulas.reference_clone',
        period_type: 'day',
        period_shift: {
          weeks: 3,
        },
        value_display_options: {
          type: 'number',
          precision: 2,
          kilo_formatting: false,
        },
      },
      {
        key: 2,
        label: 'Week',
        expression: 'formulas.reference_clone',
        period_type: 'week',
        period_shift: {
          quarters: 2,
        },
        value_display_options: {
          type: 'number',
          precision: 2,
          kilo_formatting: false,
        },
      },
      {
        key: 3,
        label: 'Ytd',
        expression: 'formulas.2_plus_2',
        period_type: 'ytd',
        period_shift: {
          years: 1,
        },
        value_display_options: {
          type: 'number',
          precision: 2,
          kilo_formatting: false,
        },
      },
      {
        key: 4,
        label: 'Ungrouped col',
        expression: 'formulas.reference_clone',
        period_type: 'month',
        period_shift: {
          quarters: 2,
        },
        value_display_options: {
          type: 'number',
          precision: 2,
          kilo_formatting: false,
        },
      },
      {
        key: 5,
        label: 'Year',
        expression: 'formulas.reference_clone',
        period_type: 'quarter',
        period_shift: {
          days: 3,
          weeks: 0,
          years: 0,
          months: 0,
          quarters: 0,
        },
        value_display_options: {
          type: 'number',
          precision: 2,
          kilo_formatting: false,
        },
      },
    ],
    ag_grid_config: {
      type: 'serial',
      columnDefs: [
        {
          groupId: 0,
          children: [
            {
              colId: '5',
              initialSort: null,
            },
            {
              colId: '3',
              initialSort: 'desc',
            },
          ],
          headerName: 'Red',
          headerGroupComponentParams: {
            style: {
              background: 'rgba(255, 81, 26, 1)',
              borderColor: 'rgba(235, 61, 6, 1)',
            },
          },
        },
        {
          groupId: 1,
          children: [
            {
              colId: '2',
              initialSort: null,
              columnGroupShow: 'open',
            },
            {
              colId: '0',
              initialSort: null,
            },
          ],
          headerName: 'Blue',
          headerGroupComponentParams: {
            style: {
              background: 'rgba(0, 146, 238, 1)',
              borderColor: 'rgba(0, 126, 218, 1)',
            },
          },
        },
        {
          groupId: 2,
          children: [],
          headerName: 'Purple',
          headerGroupComponentParams: {
            style: {
              background: 'rgba(108, 67, 182, 1)',
              borderColor: 'rgba(88, 47, 162, 1)',
            },
          },
        },
      ],
    },
    default_options: {
      date: {
        period_shift: {
          days: 56,
        },
      },
      grouping_type: 'segments',
    },
  },
  widgetType: 'kpi_table_single_date',
};

export const MOCK_TEMPLATE_BUILDER_DATA = {
  widgetHeader: {
    objectName: 'Royal Court',
    reportTitle: 'Quarterly Asset Report',
    reportSubtitle: 'Q1-2024',
    templateName: 'Quarterly Asset Summary',
  },
  scorecard: {
    chartData,
    chartWidgetSection,
    itself: scorecard,
    chartFilter,
  },

  kpiTableSingleDate: {
    state: kpiTableSingleDateState,
    data: ktsdData,
    widgetSection: ktsdWS,
  },

  textArea: {
    text: `
        <b>Overview</b>
        <p>
          AbIgaIl continued its strong performance in the second quarter, wIth
          NOI exceeding budget by sobk or 29.5%. Revenue exceeded budget by sibk
          or 3.4%, this revenue growth continues to be driven by higher than
          budged occupancy and marker rents.
          <br />
          <br />
          Expenses also continue to trend well below budget by $50k or 21.9%, as
          the on site team continues their best efforts to contro expenses by
          completing tasks in-house prior to outsourcing to vendors to Q1-21.
          During the quarter there were 14 new leases with an average increase
          of si87 or 21.9% over the prior lease, ahead of budget by 3.0%.
          Renewals totaled 10 with an impressive average increase of $269 or
          34%, this is an on-going trend in 2022 as the team continues to push
          renewal rates in order to create vacancy for renovations. The team
          continues to renovate all classic units that become vacant, to date 84
          units have been renovated with an average increase of $278 or 34.1%
          over the prior lease.
          <br />
          <br />
          Unit renovations have been completed with an average spend Of $4,333,
          resulting in an ROl Of 7b.|%. Renovations are expected to resume at a
          high volume through as to capitalize on the strong leasing season.
          <br />
          <br />
          Abigail continued its strong performance in the second quarter, with
          NOI exceeding budget by sook or 29.5%. Revenue exceeded budget by sibk
          or 3.4%, this revenue growth continues to be driven by higher than
          budged occupancy and marker rents.
          <br />
          <br />
          Expenses also continue to trend well below budget by $50k or 21.9%, as
          the on site team continues their best efforts to control expenses by
          completing tasks in-house prior to outsourcing to vendors.
          <br />
          <br />
          Distributions since inception are $450,000, with an average cash on
          cash of 9.2%. Both metrics are ahead of budget.
        </p>
    `,
  },
};
