import React from 'react';
import { Icon, IconButton } from '@/stories';
import { ReportDashboardFilterByObjectModal } from '@/bundles/Shared/features/dashboard/object/filter/byObject/ui/ReportDashboardFilterByObjectModal';
import { FilterBlock } from 'bundles/Settings/components/REport/Dashboards/Dashboard/widgets/SettingsReportObjectLevelDashboard';
import {
  ReportDashboardAsset,
  useDashboardContext,
  useGetApiSettingsReportObjectDashboardsByIdQuery,
} from 'bundles/Shared/entities/dashboard';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useNavigate } from '@reach/router';
import { ExtractParams, generateUrl } from '@/shared/lib/hooks/useNavigation';

export interface ReportDashboardAssetFilterPropsProps<T extends string> {
  path: T;
  assetId: number;
  assets: ReportDashboardAsset[]
  pathParams: ExtractParams<T>;
}

export function ReportDashboardAssetFilter<Path extends string>({
  path,
  assetId,
  pathParams,
  assets,
}: ReportDashboardAssetFilterPropsProps<Path>) {
  const { boardId } = useDashboardContext();
  const { openModal } = useModal();
  const navigate = useNavigate();

  const navigateToAsset = (newAssetId: string) => {
    navigate(
      generateUrl(path, {
        pathParams,
        queryParams: {
          assetId: newAssetId,
          boardId,
        },
      }),
      {
        replace: true,
      },
    );
  };

  return (
    <FilterBlock
      iconName="asset"
      label="Asset"
      value={
        assets.find((asset) => asset.id === assetId)?.name ?? 'Select Asset'
      }
    >
      <IconButton
        onClick={async () => {
          const res = await openModal(ReportDashboardFilterByObjectModal, {
            assets,
          });
          if (res == null) {
            return;
          }
          navigateToAsset(res.toString());
        }}
        iconName="columnExpand"
        variant='secondary'
      >
        <Icon
          style={{
            transform: 'rotate(90deg)',
          }}
          iconName="columnExpand"
        />
      </IconButton>
    </FilterBlock>
  );
}
