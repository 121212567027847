import { HistoricalTableWidgetColumnForm } from 'bundles/Shared/widgets/dashboard/widgets/historicalTable/config/column.form';
import { HistoricalReviewTableWidgetConfigModel } from 'bundles/Shared/widgets/dashboard/widgets/historicalTable';
import {
  transformPeriodShiftFormToDto,
  transformPeriodTypeFormToDto,
  upsertColumnSettings,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { produce } from 'immer';
import { HistoricalTableWidgetRowForm } from 'bundles/Shared/widgets/dashboard/widgets/historicalTable/config/row.form';
import { isEmpty } from 'lodash-es';
import { upsertRowSettings } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/updaters';
import { HistoricalReviewTableWidgetConfigDto } from 'bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { HistoricalTableWidgetConfigForm } from 'bundles/Shared/widgets/dashboard/widgets/historicalTable/config/component.form';

export const upsertColumn = ({
  column,
  widgetConfig,
  groupId,
}: {
  column: HistoricalTableWidgetColumnForm;
  widgetConfig: HistoricalReviewTableWidgetConfigModel;
  groupId?: string;
}) => {
  return produce(widgetConfig, (draft) => {
    const { columns = [] } = draft;
    let columnIndex = columns.findIndex((c) => c.key === column.key);
    if (columnIndex === -1) {
      columnIndex = columns.length;
    }
    const key = column.key ?? columnIndex;
    columns[columnIndex] = {
      key: column.key ?? columnIndex,
      label: column.label,
      period_shift: transformPeriodShiftFormToDto(
        column.period_shift ?? undefined,
      ),
      period_type: transformPeriodTypeFormToDto(column.period_type),
    };

    upsertColumnSettings(column, draft, {
      key: key.toString(),
      groupId,
    });
  });
};

export const upsertRow = (
  form: HistoricalTableWidgetRowForm,
  widgetConfig: HistoricalReviewTableWidgetConfigModel,
) => {
  return produce(widgetConfig, (draft) => {
    const { rows = [] } = draft;
    let rowIndex = rows.findIndex((r) => r.key === form.key);
    if (rowIndex === -1) {
      rowIndex = rows.length;
    }
    const key = form.key ?? rowIndex;
    rows[rowIndex] = {
      key: form.key ?? rowIndex,
      label: form.label,
      expression: form.expression,
      value_display_options: form.value_display_options,
    };
    if (!isEmpty(form.adjustment)) {
      rows[rowIndex].adjustment_expression = form.adjustment;
    }

    upsertRowSettings(form, draft, {
      key: key.toString(),
    });
  });
};

export const updateDefaultOptions = (
  values: HistoricalTableWidgetConfigForm,
  widgetConfig: HistoricalReviewTableWidgetConfigDto,
): HistoricalReviewTableWidgetConfigDto => {
  return produce(widgetConfig, (draft) => {
    draft.default_options = {
      date: {
        period_shift: transformPeriodShiftFormToDto(values.period_shift)!,
      },
    };
  });
};
