import { ListOption } from '@/stories/Checkbox/CheckList';
import {
  ADJUSTMENT_EXPRESSION_ONE_SCHEMA,
  ADJUSTMENT_EXPRESSION_VALUE_ACTUAL,
  ADJUSTMENT_TYPE_ONE_SCHEMA,
  ADJUSTMENT_TYPE_VALUE_TOTAL,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { Control } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import { FieldPath } from 'react-hook-form/dist/types/path/eager';
import {
  SectionField,
  SectionFieldsContainer,
} from 'stories/Field/FieldsWrappers';
import { RadioGroupController } from 'stories/RadioButton/RadioGroup';
import * as yup from 'yup';

type AdjustmentExpressionOptions = yup.InferType<
  typeof ADJUSTMENT_EXPRESSION_ONE_SCHEMA
>;
type AdjustmentTypeOptions = yup.InferType<typeof ADJUSTMENT_TYPE_ONE_SCHEMA>;

const ADJUSTMENT_EXPRESSION_OPTIONS = [
  {
    label: 'Actual',
    value: ADJUSTMENT_EXPRESSION_VALUE_ACTUAL,
  },
  {
    label: 'Operational Budget',
    value: 'op_budget',
  },
  {
    label: 'Operational Budget Variance',
    value: 'op_variance',
  },
  {
    label: 'Operational Budget Variance Percent',
    value: 'op_variance_percent',
  },
  {
    label: 'Underwriting Budget',
    value: 'uw_budget',
  },
  {
    label: 'Underwriting Budget Variance',
    value: 'uw_variance',
  },
  {
    label: 'Underwriting Budget Variance Percent',
    value: 'uw_variance_percent',
  },
] satisfies ListOption<NonNullable<AdjustmentExpressionOptions>>[];

const ADJUSTMENT_TYPE_OPTIONS = [
  {
    label: 'Total',
    value: ADJUSTMENT_TYPE_VALUE_TOTAL,
  },
  {
    label: 'Per SQFT',
    value: 'sqft',
  },
  {
    label: 'Per Unit (Total)',
    value: 'unit',
  },
  {
    label: 'Per Unit (Occupied)',
    value: 'unit_occupied',
  },
  {
    label: 'Per Unit (Vacant)',
    value: 'unit_vacant',
  },
  {
    label: 'Per Unit (Available)',
    value: 'unit_available',
  },
  {
    label: 'Per Unit (Pre-leased)',
    value: 'unit_preleased',
  },
] satisfies ListOption<NonNullable<AdjustmentTypeOptions>>[];

export function FinancialTableSinglePeriodColumnAdjustmentFields<
  FV extends FieldValues = FieldValues,
>({ control }: { control: Control<FV> }) {
  const getFieldName = (name: 'adjustment_expression' | 'adjustment_type') =>
    name as FieldPath<FV>;

  return (
    <SectionFieldsContainer>
      <SectionField labelText="Column Type">
        <RadioGroupController
          control={control}
          name={getFieldName('adjustment_expression')}
          options={ADJUSTMENT_EXPRESSION_OPTIONS}
        />
      </SectionField>
      <SectionField labelText="Adjustment Type">
        <RadioGroupController
          control={control}
          name={getFieldName('adjustment_type')}
          options={ADJUSTMENT_TYPE_OPTIONS}
        />
      </SectionField>
    </SectionFieldsContainer>
  );
}
