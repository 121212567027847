import { useCapitalInvestments } from '@/bundles/REturn/hooks/useCapitalInvestments';
import { useModal } from '@/shared/lib/hooks/useModal';
import { IFetchInvestmentObjectRequest } from 'bundles/REturn/actions/capitalLegalEntities';
import ManageCapitalInvestmentModal, {
  IManageCapitalInvestmentModal,
} from 'bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/ManageCapitalInvestmentModal';
import { useActions } from 'bundles/REturn/components/Ownership/ownershipTable/actions';
import {
  useColumns,
  useTotalColumns,
} from 'bundles/REturn/components/Ownership/ownershipTable/columns';
import AppBarDivider from 'bundles/Shared/components/Containers/AppBarDivider';
import ContainerWithAppBar from 'bundles/Shared/components/Containers/ContainerWithAppBar';
import AppBar from 'bundles/Shared/components/Containers/appBar/AppBar';
import Table from 'bundles/Shared/components/Table/Table';
import { useCustomer } from 'lib/customers';
import { usePageParamsTableSorting } from '@/shared/lib/hooks/usePageParamsTableSorting';
import { REPORT_PRODUCT_NAME } from 'lib/permissions';
import { useState } from 'react';
import { Button } from 'stories';

const OwnershipTable = () => {
  const { modalList } = useModal();
  const [params, setParams] = useState<IFetchInvestmentObjectRequest>({
    sort: {
      order: 'desc',
      field: 'total_contributions',
    },
  });
  const { settings, setSettings } = usePageParamsTableSorting(
    params,
    setParams,
  );

  const [manageCapitalInvestmentModal, setManageCapitalInvestmentModal] =
    useState<IManageCapitalInvestmentModal | null>(null);

  const currentCustomerHasReportProduct =
    useCustomer().products?.includes(REPORT_PRODUCT_NAME);

  const { data: capitalInvestmentsData, isFetching } = useCapitalInvestments({
    legalEntityIds: params.legal_entity_ids,
    sort: params.sort,
  });

  const capitalInvestments = capitalInvestmentsData ?? [];
  const totalColumns = useTotalColumns(capitalInvestments);

  const { handleCreateOwner, ...actions } = useActions(capitalInvestments);

  const columns = useColumns(
    {
      ...actions,
      openManageCapitalInvestmentModal: (data) =>
        setManageCapitalInvestmentModal(data),
    },
    currentCustomerHasReportProduct,
  );

  return (
    <ContainerWithAppBar
      appBar={
        <AppBar title="Ownership" classes={{ title: 'header3-bold' }}>
          <AppBarDivider />
          <Button variant="primary" onClick={handleCreateOwner}>
            Create Owner
          </Button>
        </AppBar>
      }
    >
      {modalList}
      <Table
        classes={{
          container: 'min-h-[240px]',
        }}
        scrollHorizontally
        loading={isFetching}
        columns={columns}
        settings={settings}
        setSettings={setSettings}
        onFilterModelChange={(filterModel) =>
          setParams((prevParams) => ({
            ...prevParams,
            legal_entity_ids: filterModel.legal_entity_name?.map((le) => le.id),
          }))
        }
        items={capitalInvestments}
        bottomRowColumns={capitalInvestments.length > 0 ? totalColumns : []}
      />
      {manageCapitalInvestmentModal && (
        <ManageCapitalInvestmentModal
          capitalInvestments={capitalInvestments}
          manageCapitalInvestmentModal={manageCapitalInvestmentModal}
          onClose={() => setManageCapitalInvestmentModal(null)}
          navigateToCreateEntryWorkflow={actions.navigateToCreateEntryWorkflow}
        />
      )}
    </ContainerWithAppBar>
  );
};

export default OwnershipTable;
