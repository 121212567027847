import Rentegi from 'bundles/Shared/entities/erpsystem/ui/svgs/rentegi.svg';
import appFolio from 'images/sourceTypeLogos/app-folio.png';
import cymaLogo from 'images/sourceTypeLogos/cyma.png';
import entrataLogo from 'images/sourceTypeLogos/entrata.png';
import m3Logo from 'images/sourceTypeLogos/m3.png';
import mriLogo from 'images/sourceTypeLogos/mri.png';
import netSuite from 'images/sourceTypeLogos/net-suite.png';
import quickBookLogo from 'images/sourceTypeLogos/qb.png';
import realpageLogo from 'images/sourceTypeLogos/realpage.png';
import resmanLogo from 'images/sourceTypeLogos/resman.png';
import sageLogo from 'images/sourceTypeLogos/sage.svg';
import symmetreLogo from 'images/sourceTypeLogos/symmetre.png';
import yardiLogo from 'images/sourceTypeLogos/yardi.png';
import { ExternalSourceType } from '@/entities/core/legalEntity';

export const SOURCE_TYPE_SETTINGS: Record<
  ExternalSourceType,
  { title: string; site: string; image?: string }
> = {
  [ExternalSourceType.QUICKBOOKS_DESKTOP]: {
    title: 'Quickbooks Desktop',
    image: quickBookLogo,
    site: 'quickbooks.intuit.com/desktop',
  },
  [ExternalSourceType.QUICKBOOKS_ONLINE]: {
    title: 'Quickbooks Online',
    image: quickBookLogo,
    site: 'quickbooks.intuit.com/online',
  },
  [ExternalSourceType.YARDI]: {
    title: 'Yardi',
    image: yardiLogo,
    site: 'www.yardi.com',
  },
  [ExternalSourceType.ENTRATA]: {
    title: 'Entrata',
    image: entrataLogo,
    site: 'www.entrata.com',
  },
  [ExternalSourceType.M3]: {
    title: 'M3',
    image: m3Logo,
    site: 'www.m3as.com',
  },
  [ExternalSourceType.MRI]: {
    title: 'MRI Software',
    image: mriLogo,
    site: 'www.mrisoftware.com',
  },
  [ExternalSourceType.REALPAGE]: {
    title: 'Realpage',
    image: realpageLogo,
    site: 'realpage.com',
  },
  [ExternalSourceType.RESMAN]: {
    title: 'Resman',
    image: resmanLogo,
    site: 'myresman.com',
  },
  [ExternalSourceType.CYMA]: {
    title: 'CYMA',
    image: cymaLogo,
    site: 'cyma.com',
  },
  [ExternalSourceType.SAGE]: {
    title: 'Sage',
    image: sageLogo,
    site: 'www.sage.com',
  },
  [ExternalSourceType.SYMMETRE]: {
    title: 'symmetRE',
    image: symmetreLogo,
    site: 'symmetre.com',
  },
  [ExternalSourceType.NET_SUITE]: {
    title: 'NetSuite',
    image: netSuite,
    site: 'netsuite.com',
  },
  [ExternalSourceType.FORTRESS]: {
    title: 'Fortress',
    image: undefined,
    site: 'fortress.com',
  },
  [ExternalSourceType.APP_FOLIO]: {
    title: 'AppFolio',
    image: appFolio,
    site: 'appfolio.com',
  },
  [ExternalSourceType.RENTEGI]: {
    title: 'Rentegi',
    image: Rentegi,
    site: 'rentegi.com',
  },
  [ExternalSourceType.RASI]: {
    title: 'RASI',
    image: undefined,
    site: 'rasiusa.com',
  },
  [ExternalSourceType.XERO]: {
    title: 'Xero',
    image: undefined,
    site: 'xero.com',
  },
  [ExternalSourceType.RENT_MANAGER]: {
    title: 'Rent Manager',
    image: undefined,
    site: 'rentmanager.com',
  },
  [ExternalSourceType.BUILDIUM]: {
    title: 'Buildium',
    image: undefined,
    site: 'buildium.com',
  },
  [ExternalSourceType.PROPERTYWARE]: {
    title: 'Propertyware',
    image: undefined,
    site: 'propertyware.com',
  },
  [ExternalSourceType.RENTVINE]: {
    title: 'Rentvine',
    image: undefined,
    site: 'rentvine.com',
  },
  [ExternalSourceType.ZOHO]: {
    title: 'ZOHO',
    image: undefined,
    site: 'zoho.com',
  },
  [ExternalSourceType.MICROSOFT_DYNAMICS_365]: {
    title: 'Microsoft Dynamics 365',
    image: undefined,
    site: 'microsoft.com',
  },
  [ExternalSourceType.ACUMATICA]: {
    title: 'Acumatica',
    image: undefined,
    site: 'acumatica.com',
  },
  [ExternalSourceType.INFOR]: {
    title: 'Infor',
    image: undefined,
    site: 'infor.com',
  },
  [ExternalSourceType.RADIX]: {
    title: 'Radix',
  },
};
