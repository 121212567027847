import * as Sentry from '@sentry/react';
import http from 'lib/http';
import {
  IChangeEvent,
  ICompany,
  IContact,
  IReason,
  TChangeOrderStatus,
} from 'bundles/Construction/types';
import { IParamsCE } from './../reducers/ReconcileChangeEventSlice';

export async function fetchReconcileChangeEvent({
  legalEntityCode,
  id,
}: IParamsCE['basicParams']) {
  const res = await http.get(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events/${id}`,
  );
  const json = await res.json();

  if (res.status === 404) {
    Sentry.captureMessage(
      `Change Event (${id}) with LE (${legalEntityCode}) code not found`,
    );
  }

  return json as IChangeEvent;
}

export async function createReconcileChangeEvent({
  legalEntityCode,
  data,
}: IParamsCE['create']) {
  const res = await http.post(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events`,
    data,
  );
  const json = await res.json();

  return json as IChangeEvent;
}

export async function updateReconcileChangeEvent({
  legalEntityCode,
  id,
  data,
}: IParamsCE['update']) {
  const res = await http.put(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events/${id}`,
    data,
  );
  const json = await res.json();

  return json as IChangeEvent;
}

export async function updateStatusReconcileChangeEvent(
  legalEntityCode: string,
  id: string,
  data: {
    status: TChangeOrderStatus;
    event_comment: string | null;
  },
) {
  const res = await http.put(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events/${id}/set_status`,
    data,
  );
  const json = await res.json();

  return json as IChangeEvent;
}

export async function fetchHistoryItemReconcileChangeEvent({
  legalEntityCode,
  id,
  historyEventId,
}: IParamsCE['fetchHistoryItem']) {
  const res = await http.get(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events/${id}/history_events/${historyEventId}`,
  );
  const json = await res.json();

  return json as IChangeEvent;
}

export async function fetchChangeOrderEventMobile({
  legalEntityCode,
  id,
}: IParamsCE['basicParams']) {
  const res = await http.get(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events/${id}/mobile`,
  );
  const json = await res.json();

  return json as IChangeEvent;
}

export async function confirmValueReconcileChangeEvent({
  legalEntityCode,
  id,
}: IParamsCE['confirmValue']) {
  const res = await http.put(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events/${id}/confirm_cost_breakdown`,
    {},
  );
  const json = await res.json();

  return json as IChangeEvent;
}

export async function uploadDocumentsReconcileChangeEvent({
  legalEntityCode,
  id,
  data,
}: IParamsCE['uploadDocuments']) {
  const res = await http.post(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events/${id}/documents/bulk_upload`,
    data,
  );
  const json = await res.json();

  return json as IChangeEvent;
}

export async function selectFinalDocumentsReconcileChangeEvent({
  legalEntityCode,
  id,
  data,
}: IParamsCE['selectedDocuments']) {
  const res = await http.put(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events/${id}/set_supporting_document`,
    data,
  );
  const json = await res.json();

  return json as IChangeEvent;
}

export async function deletedDocumentsReconcileChangeEvent({
  legalEntityCode,
  id,
  documentId,
}: IParamsCE['deletedDocuments']) {
  const res = await http.del(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events/${id}/documents/${documentId}`,
  );
  const json = await res.json();

  return json as IChangeEvent;
}

export async function verifyReconcileChangeEvent({
  legalEntityCode,
  id,
  data,
}: IParamsCE['verify']) {
  const res = await http.put(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events/${id}/verify`,
    data,
  );
  const json = await res.json();

  return json as IChangeEvent;
}

export async function approveReconcileChangeEvent({
  legalEntityCode,
  id,
}: IParamsCE['basicParams']) {
  const res = await http.put(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events/${id}/approve`,
    {},
  );
  const json = await res.json();

  return json as IChangeEvent;
}

export async function rejectReconcileChangeEvent({
  legalEntityCode,
  id,
  data,
}: IParamsCE['reject']) {
  const res = await http.put(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events/${id}/reject`,
    data,
  );
  const json = await res.json();

  return json as IChangeEvent;
}

export async function setSubsReconcileChangeEvent({
  legalEntityCode,
  id,
  data,
}: IParamsCE['subsDate']) {
  const res = await http.put(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events/${id}/set_substantial_completion_date`,
    data,
  );
  const json = await res.json();

  return json as IChangeEvent;
}

export async function updateCostBreakDownReconcileChangeEvent({
  legalEntityCode,
  id,
  data,
}: IParamsCE['subsDate']) {
  const res = await http.put(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events/${id}/update_cost_breakdown`,
    data,
  );
  const json = await res.json();

  return json as IChangeEvent;
}

export async function reviseReconcileChangeEvent({
  legalEntityCode,
  id,
  data,
}: IParamsCE['update']) {
  const res = await http.put(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events/${id}/revise`,
    data,
  );
  const json = await res.json();

  return json as IChangeEvent;
}

export async function promoteReconcileChangeEvent({
  legalEntityCode,
  id,
  data,
}: IParamsCE['update']) {
  const res = await http.put(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events/${id}/promote`,
    data,
  );
  const json = await res.json();

  return json as IChangeEvent;
}

export async function startApprovalReconcileChangeEvent({
  legalEntityCode,
  id,
}: IParamsCE['basicParams']) {
  const res = await http.put(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events/${id}/start_approval`,
    {},
  );
  const json = await res.json();

  return json as IChangeEvent;
}

export async function defineEsignPlacementChangeEvent({
  legalEntityCode,
  id,
  data,
}: IParamsCE['esignPlacement']) {
  const res = await http.put(
    `/reconcile/legal_entities/${legalEntityCode}/change_order_events/${id}/define_esign_placements`,
    { esign_placements: data },
  );
  const json = await res.json();

  return json as IChangeEvent;
}

export async function fetchReconcileReasons() {
  const res = await http.get('/reconcile/change_order_reasons');
  const json = await res.json();

  return json as IReason;
}

export async function fetchReconcileCompanies() {
  const res = await http.get('/reconcile/companies');
  const json = await res.json();

  return json as ICompany;
}

export async function fetchReconcileContacts() {
  const res = await http.get('/reconcile/contacts');
  const json = await res.json();

  return json as IContact;
}
