import {
  InvestmentEntitiesTab,
  MembersTab,
  RolesTab,
  TagsTab,
} from 'bundles/Shared/components/ModalWithSideTabs/Tabs';
import {
  Permitted,
  UserForPermitted,
} from 'bundles/Shared/entities/permissions';
import { sortBy } from 'lodash-es';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { MetaState, SideTabKey } from '../types';
import { mapListToIds } from '@/shared/lib/listHelpers';

export function PermissionsSideTab({
  currentSideTab,
  metaState,
  handleUpdate,
  permittedState,
  computedIndirectUsers,
}: {
  currentSideTab: SideTabKey;
  metaState: MetaState;
  permittedState: Permitted;
  computedIndirectUsers: MetaState['userOptions'];
  handleUpdate: (
    key: SideTabKey,
    val: Permitted[SideTabKey] | Permitted[SideTabKey][number],
  ) => void;
}) {
  const resolveCurrentSideTab = () => {
    switch (currentSideTab) {
      case 'directUsers': {
        const allIndirectUserIds = mapListToIds([
          ...computedIndirectUsers,
          ...(permittedState.indirectUsers ?? []),
        ]);

        return (
          <MembersTab
            records={sortBy(metaState.userOptions, (member) =>
              member.fullName.toLowerCase(),
            )}
            selectedIds={permittedState.directUsers.map(({ id }) => id)}
            indirectUserIds={allIndirectUserIds}
            onChange={(value) =>
              handleUpdate('directUsers', value as UserForPermitted)
            }
          />
        );
      }
      case 'directRoles': {
        const undeselectableRoles = metaState.roleOptions.filter(
          (r) => r.undeselectable,
        );

        return (
          <RolesTab
            records={metaState.roleOptions.filter(
              ({ category }) => category !== 'sre_admin',
            )}
            selectedIds={permittedState.directRoles.map(({ id }) => id)}
            undeselectableRoles={undeselectableRoles}
            onChange={(value) => handleUpdate('directRoles', value)}
          />
        );
      }
      case 'directTags': {
        return (
          <TagsTab
            records={metaState.tagOptions}
            selectedIds={permittedState.directTags.map(({ id }) => id)}
            onChange={(value) => handleUpdate('directTags', value)}
          />
        );
      }
      case 'directInvestmentEntities': {
        return (
          <InvestmentEntitiesTab
            records={metaState.investementEntityOptions}
            selectedIds={permittedState.directInvestmentEntities.map(
              ({ id }) => id,
            )}
            onChange={(value) =>
              handleUpdate('directInvestmentEntities', value)
            }
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className="w-[320px]">
      <OverlayScrollbarsComponent
        options={{
          className:
            'permissions-modal-body-part border-left px-tw-4 os-theme-thin-dark w-full',
          paddingAbsolute: true,
        }}
      >
        {resolveCurrentSideTab()}
      </OverlayScrollbarsComponent>
    </div>
  );
}
