import dayjs, { Dayjs } from 'dayjs';

import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import objectSupport from 'dayjs/plugin/objectSupport';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(objectSupport);

type FormatDate =
  | 'MMM DD'
  | 'MMM-DD'
  | 'MMM DD, YYYY'
  | 'MM/DD/YYYY'
  | 'MMM DD, YYYY h:mm'
  | 'MM-DD-YYYY'
  | 'MMM-YY'
  | 'YYYY'
  | 'L'
  | 'LL'
  | 'LLL'
  | 'MM.DD.YYYY'
  | 'YYYY-MM-DD'
  | 'MMMM'
  | 'MMM-YYYY'
  | 'MMMM DD, YYYY'
  | 'MMMM DD, YYYY h:mm A'
  | 'HH:mm:ss'
  | 'Q-YY'
  | 'hh:mm A';

export const formatUnixDateFromNow = (date: number) =>
  date ? dayjs.unix(date).fromNow() : <>&ndash;</>;

export const dateToUnix = (date: DateParam): number => {
  return dayjs(date).unix();
};

export const dateToYear = (date: DateParam): number => {
  return dayjs(date).year();
};

export const formatUnixDate = (
  date: DateString | UnixTime | null | undefined,
  formatOption: FormatDate,
) => (date ? dayjs.unix(date).format(formatOption) : '&ndash;');

type DateParam = DateString | Dayjs | Date | number | null | undefined | string;

export const formatDate = (
  date: DateParam,
  formatOption: FormatDate,
): string =>
  date
    ? dayjs(date).format(formatOption)
    : String.fromCharCode(parseInt('02013', 16));

/**
 * Formats a given date to a quarter with prefix.
 *
 * @param {DateParam} date - The date to be formatted.
 * @returns {string} - The formatted date in the format "Q<quarter>-YY".
 */
export const formatDateToQuarterWithPrefix = (date: DateParam) =>
  `Q${dayjs(date).format('Q-YY')}`;

export const toDateSeconds = (date: UnixTime) => (date ? new Date(date) : ''); // JS works in milliseconds, while we pass timestamp in seconds

export const toDate = (date: UnixTime): DateString =>
  (date ? new Date(date * 1000) : '') as DateString; // JS works in milliseconds, while we pass timestamp in seconds
