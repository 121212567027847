import { SETTINGS_REPORT_OPERATIONAL_DICTIONARY } from '@/bundles/Settings/components/REport/Operational/config';
import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import { BOTTOM_LEVEL_POPOVER_Z_INDEX } from '@/bundles/Shared/constants';
import { SreUnitType } from '@/bundles/Shared/entities/sreUnitType';
import { useItemsFilterByText } from '@/shared/lib/hooks/useItemsFilterByText';
import { mapItemsToListOption } from '@/shared/lib/listHelpers';
import { Button, IconButton, Popover, SearchInput } from '@/stories';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useState } from 'react';
import { RadioGroup } from 'stories/RadioButton/RadioGroup';
import fuzzysort from 'fuzzysort';

export function SreUnitTypeFormatter({
  value,
  unitTypes,
  onUnitTypeReset,
  onSubmit,
  onUnitTypeEdit,
  onUnitTypeRemove,
  initialValue = null,
  showEditButton = false,
  disabled,
  searchRowActions,
}: {
  value: string | null | undefined;
  unitTypes: SreUnitType[];
  onUnitTypeReset: () => void;
  onSubmit: (sreUnitType: SreUnitType) => void;
  onUnitTypeEdit?: (sreUnitType: SreUnitType) => void;
  onUnitTypeRemove?: (sreUnitType: SreUnitType) => void;
  initialValue?: SreUnitType | null;
  showEditButton?: boolean;
  disabled?: boolean;
  searchRowActions?: React.ReactNode;
}) {
  const [checkedUnitType, setCheckedUnitType] = useState<SreUnitType | null>(
    initialValue,
  );

  const { filteredItems: filteredUnitTypes, inputProps } = useItemsFilterByText(
    unitTypes,
    (item, searchText) =>
      fuzzysort.go(searchText, [item.label, item.name]).length > 0,
  );

  return (
    <div className="flex items-center gap-tw-1">
      {value == null || showEditButton ? (
        <Popover
          onHidden={() => {
            if (checkedUnitType == null) return;

            if (initialValue?.id === checkedUnitType.id) return;

            onSubmit(checkedUnitType);
          }}
          appendToBody
          placement="bottom-end"
          trigger="click"
          zIndex={BOTTOM_LEVEL_POPOVER_Z_INDEX}
          maxWidth={280}
          hiddenArrow
          template={
            <div className="flex flex-col gap-tw-2">
              <div className="flex items-center justify-between">
                <p className="secondary-semibold text-neutral-500">
                  sRE{' '}
                  {SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.pluralForm}
                </p>

                {initialValue && (
                  <Button
                    variant="secondary"
                    size="xs"
                    onClick={() => {
                      onUnitTypeReset();
                    }}
                  >
                    Reset
                  </Button>
                )}
              </div>
              <div className="flex items-center gap-tw-2">
                <SearchInput
                  size="s"
                  placeholder={
                    SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes
                      .singularForm
                  }
                  {...inputProps}
                />
                {searchRowActions}
              </div>
              <OverlayScrollbarsComponent
                style={{
                  maxHeight: 200,
                }}
                options={{
                  className:
                    'os-theme-thin-dark user-filter-list w-full os-host-flexbox',
                }}
              >
                <RadioGroup
                  value={checkedUnitType?.id}
                  onChange={(o) =>
                    setCheckedUnitType(
                      unitTypes.find((ut) => ut.id === o.value)!,
                    )
                  }
                  getItemProps={(o) => ({
                    actionsSlot: (onUnitTypeEdit || onUnitTypeRemove) && (
                      <div className="ml-auto flex gap-tw-1">
                        {onUnitTypeEdit && (
                          <IconButton
                            onClick={() =>
                              onUnitTypeEdit(
                                unitTypes.find((ut) => ut.id === o.value)!,
                              )
                            }
                            iconName="edit"
                          />
                        )}
                        {onUnitTypeRemove && (
                          <IconButton
                            onClick={() =>
                              onUnitTypeRemove(
                                unitTypes.find((ut) => ut.id === o.value)!,
                              )
                            }
                            iconName="trash"
                          />
                        )}
                      </div>
                    ),
                  })}
                  options={mapItemsToListOption(filteredUnitTypes, 'label')}
                />
                {filteredUnitTypes.length === 0 && (
                  <NoDataOverlay title="Not Found" />
                )}
              </OverlayScrollbarsComponent>
            </div>
          }
        >
          {showEditButton ? (
            <IconButton size="s" iconName="edit" />
          ) : (
            <Button
              altIconName="arrowBottom"
              altIconPosition="right"
              iconName="link"
              variant="secondary"
              size="xs"
              disabled={disabled}
              tooltipProps={{
                disabled: !disabled,
                mainText: 'Legal Entity classification is required',
              }}
            >
              Set{' '}
              {SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm}
            </Button>
          )}
        </Popover>
      ) : (
        value
      )}
    </div>
  );
}
