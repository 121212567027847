import {
  addSectionsToLayout,
  AllWidgetTypes,
  buildLayoutsId,
  mapLayoutToLayoutDto,
  ReportDashboardType,
  selectReportDashboardMetadataById,
  selectReportEagleEyeDashboardSettingsById,
  usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardMutation,
  usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardMutation,
  usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardMutation,
} from 'bundles/Shared/entities/dashboard';
import { useCallback, useMemo } from 'react';
import store from '@/app/stores';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { WIDGETS_CONFIG_MAP } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { selectReportComparisonDashboardMetadataById } from '@/bundles/Shared/entities/dashboard/model/slices/comparisonSlice';

export const useCopyWidgetSectionToBoard = ({
  dashboardType,
  boardId,
  dashboardId,
}: {
  dashboardType: ReportDashboardType;
  dashboardId: string;
  boardId: string;
}) => {
  const eagleEyeMutation = usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardMutation()
  const objectMutation =  usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardMutation();
  const comparisonMutation = usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardMutation()

  const [copyToBoard, options] = useMemo(() => {
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return objectMutation;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return eagleEyeMutation;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return comparisonMutation;
      }
    }
  }, [objectMutation, eagleEyeMutation, comparisonMutation])

  const stateSelector = useMemo(() => {
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return selectReportDashboardMetadataById;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return selectReportEagleEyeDashboardSettingsById
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return selectReportComparisonDashboardMetadataById;
      }
    }
  }, [
    selectReportEagleEyeDashboardSettingsById,
    selectReportEagleEyeDashboardSettingsById,
    selectReportComparisonDashboardMetadataById,
  ]);

  const handleCopyToBoard = useCallback(
    async ({
      sections,
    }: {
      sections: {
        id: string;
        widgetType: AllWidgetTypes;
      }[];
    }) => {
      const { layouts: targetBoardLayout } = stateSelector(
        store.getState(),
        buildLayoutsId({
          boardId,
          dashboardId,
        }),
      )!;
      const newLayout = addSectionsToLayout(
        targetBoardLayout!.lg!,
        sections.map((s) => ({
          i: s.id,
          type: s.widgetType,
          ...WIDGETS_CONFIG_MAP[s.widgetType].layout,
        })),
      );
      return await copyToBoard({
        objectDashboardId: dashboardId,
        eagleEyeDashboardId: dashboardId,
        comparisonDashboardId: dashboardId,
        boardId,
        body: {
          board_layout: mapLayoutToLayoutDto(newLayout),
          sections_to_copy_ids: mapListToIds(sections),
        },
      });
    },
    [copyToBoard, dashboardId, boardId, stateSelector],
  );

  return [handleCopyToBoard, options] as const;
};
