import dayjs from 'dayjs';

type BasicDateUnit = 'day' | 'month' | 'year' | 'quarter';
type DateUnit = Exclude<BasicDateUnit, 'quarter'>;

export const shiftDateBackward = (
  date: Date,
  duration: number,
  unit: BasicDateUnit,
): Date => dayjs(date).subtract(duration, unit).toDate();

export const shiftTodayBackward = (
  duration: number,
  unit: BasicDateUnit,
): Date => shiftDateBackward(new Date(), duration, unit);

export const shiftTodayForward = (
  duration: number,
  unit: BasicDateUnit,
): Date => dayjs().add(duration, unit).toDate();

export const yearAgoDate = (): Date => shiftTodayBackward(1, 'year');

export const yesterdayDate = (): Date => shiftTodayBackward(1, 'day');

export const shiftDateYearBackward = (date: Date): Date =>
  shiftDateBackward(date, 1, 'year');

export const tomorrowDate = (): Date => shiftTodayForward(1, 'day');

export const isSameDate = (
  date1: Date,
  date2: Date,
  unit?: BasicDateUnit,
): boolean => dayjs(date1).isSame(date2, unit);

export const isSameOrBeforeDate = (
  date1: Date,
  date2: Date,
  unit: DateUnit,
): boolean => dayjs(date1).isSameOrBefore(date2, unit);

export const isSameDay = (date1: Date, date2: Date): boolean =>
  isSameDate(date1, date2, 'day');

export const isSameYear = (date1: Date, date2: Date): boolean =>
  isSameDate(date1, date2, 'year');

export const isSameQuarter = (date1: Date, date2: Date): boolean =>
  isSameDate(date1, date2, 'quarter');

export const startOf = (date: Date, unit: DateUnit): Date =>
  dayjs(date).startOf(unit).toDate();

export const startOfMonth = (date: Date): Date => startOf(date, 'month');
