import React, { useEffect, useState } from 'react';
import { Button, Dropdown, DropdownItem, IconButton, Input } from 'stories';
import Select from 'react-select';
import http from 'lib/http';
import PermissionList from 'bundles/Shared/components/PermissionList';
import TextEditor from 'bundles/Shared/components/TextEditor';
import { RRule, Frequency } from 'rrule';
import { capitalize } from 'lodash-es';
import { disableStylingKeystrokes } from '@/shared/lib/ckEditor/utils';
import EmailTemplateFormInputGroup from '../../../../EmailBuilder/components/EmailTemplateFormInputGroup';
import { ICustomTemplate } from '../editor/CustomTemplateEdit';
import ReccurenceModal from '../ReccurenceModal';
import TemplateEditor from '../editor/TemplateEditor';
import AaIcon from '/images/aaIcon.svg';
import HIcon from '/images/hIcon.svg';
import { TOOLBAR_ITEMS_VARIABLE_AND_ALIGNMENTS } from '../utils/consts';

function buildEditorConfiguration() {
  return {
    placeholder: 'Enter text here',
    toolbar: {
      items: ['alignment:left', 'alignment:center', 'alignment:right'],
      shouldNotGroupWhenFull: true,
    },
    language: 'en',
    link: {
      addTargetToExternalLinks: true,
    },
  };
}
interface Props {
  template: ICustomTemplate;
  onTemplateChange: (template: ICustomTemplate) => void;
  isLoading?: boolean;
  variables?: any;
  usedVariables?: any;
  onUsedVariablesChange?: (usedVariables: any) => void;
  onVariablesChange?: (variables: any) => void;
}

const CustomTemplateForm = ({
  template,
  onTemplateChange,
  isLoading,
  variables,
  usedVariables,
  onUsedVariablesChange,
  onVariablesChange,
}: Props) => {
  const setField = <K extends keyof ICustomTemplate>(
    field: K,
    value: ICustomTemplate[K],
  ) => {
    onTemplateChange({
      ...template,
      [field]: value,
    });
  };

  const [scoreCards, setScoreCards] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      const res = await http.get('/report_scorecards');
      const data = await res.json();
      setScoreCards(data.items);
    })().catch((err) => console.log(err));
  }, []);

  const addSection = (section) => {
    onTemplateChange({
      ...template,
      sections: [...template?.sections, section],
    });
  };

  const removeSection = (section) => {
    const newSections = [...sections];
    const index = newSections.findIndex((s) => s.position === section.position);
    newSections[index] = {
      ...newSections[index],
      _destroy: true,
    };

    onTemplateChange({
      ...template,
      sections: newSections,
    });
  };

  const sections = template?.sections || [];
  const notDeletedSections = sections.filter((section) => !section._destroy);
  const getSectionTitleFromType = (type) => {
    switch (type) {
      case 'report':
        return 'Report';
      case 'text':
        return 'Text';
      case 'header':
        return 'Header';
      default:
        return 'Section';
    }
  };

  const setSections = (newSections) => {
    onTemplateChange({
      ...template,
      sections: newSections,
    });
  };

  const onMove = (section, direction) => {
    const newSections = [...sections];
    const moveNumber = direction === 'up' ? -1 : 1;
    const index = sections.findIndex((s) => s.position === section.position);
    newSections[index + moveNumber].position = section.position;
    newSections[index].position = section.position + moveNumber;
    setSections(newSections.sort((a, b) => a.position > b.position));
  };

  const updateSection = (position, data) => {
    const newSections = [...sections];
    const sectionIndex = newSections.findIndex((s) => s.position === position);
    newSections[sectionIndex] = { ...newSections[sectionIndex], ...data };
    setSections(newSections);
  };

  const [recurrenceModalOpened, setRecurrenceModalOpened] = useState(false);

  const defaultRrule = new RRule({
    dtstart: new Date(),
    until: undefined,
    byhour: [12],
    byminute: [0],
    bysecond: [0],
    interval: 1,
    tzid: undefined,
  });
  const [rrule, setRrule] = useState(
    template.schedule ? RRule.fromString(template.schedule) : defaultRrule,
  );

  useEffect(() => {
    if (template.schedule) {
      setRrule(RRule.fromString(template.schedule));
    } else {
      setRrule(new RRule(defaultRrule.options));
    }
  }, [template]);

  const defaultDailyRules = {
    ...rrule.options,
    freq: Frequency.DAILY,
  };

  const defaultWeeklyRules = {
    ...rrule.options,
    freq: Frequency.WEEKLY,
    byweekday: [],
  };

  const defaultMonthlyRules = {
    ...rrule.options,
    freq: Frequency.MONTHLY,
    bysetpos: [1],
    byweekday: [RRule.MO],
    bymonthday: [1],
  };

  const defaultYearlyRules = {
    ...rrule.options,
    freq: Frequency.YEARLY,
    bymonth: [1],
    bymonthday: [1],
    byweekday: [RRule.MO],
    bysetpos: [1],
  };

  const everyWeekdayRules = {
    ...rrule.options,
    freq: Frequency.WEEKLY,
    byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
  };

  const recurrenceDropdownItems = [
    {
      label: (
        <div>
          Every weekday <span className="light-60">(Mon - Fri)</span>
        </div>
      ),
      rule: everyWeekdayRules,
    },
    {
      label: 'Daily',
      rule: defaultDailyRules,
    },
    {
      label: 'Weekly',
      rule: defaultWeeklyRules,
    },
    {
      label: 'Monthly',
      rule: defaultMonthlyRules,
    },
    {
      label: 'Yearly',
      rule: defaultYearlyRules,
    },
  ];

  const forceEditorHeading = (editor) => {
    editor.execute('heading', { value: 'heading1' });
  };

  const handleHeaderEditorReady = (editor) => {
    forceEditorHeading(editor);
    disableStylingKeystrokes(editor);
  };

  const setVariableField = (field, value) => {
    onUsedVariablesChange({
      ...usedVariables,
      [field]: value,
    });
  };

  return (
    <form>
      <div className="flex flex-col gap-tw-4">
        <div>
          <EmailTemplateFormInputGroup title="General" isLoading={isLoading}>
            <>
              <span className="mb-[0.5rem] label dark-60 secondary-regular">
                Template Name
              </span>
              <Input
                id="name"
                onChange={(e) => setField('name', e.target.value)}
                value={template?.name}
                placeholder="Template Name"
              />
            </>
            <>
              <span
                className="mb-[0.5rem] label dark-60 secondary-regular mt-m"
                htmlFor="subject"
              >
                Email Subject
              </span>
              <Input
                id="subject"
                onChange={(e) => setField('subject', e.target.value)}
                value={template?.subject}
                placeholder="Enter Subject"
              />
            </>
            <span className="mb-[0.5rem] label dark-60 secondary-regular mt-tw-4">
              Recipients
              <span className="red">*</span>
            </span>
            <div className="d-inline-flex">
              <PermissionList
                permissions={{
                  roles: template?.userRoles || [],
                  users: template?.users || [],
                  tags: [],
                  investmentEntities: [],
                }}
                hideActions={false}
                onSubmit={(permissions) => {
                  onTemplateChange({
                    ...template,
                    ...{
                      userRoles: permissions.roles,
                      users: permissions.users,
                    },
                  });
                }}
                tabsIsHidden
                modalTitle="Custom Template Recipients"
                canEdit
                isEmail
                whiteListedTabs={['roles', 'users']}
              />
            </div>
            <div
              style={{ borderTop: '1px solid var(--light-10)' }}
              className="mt-m"
            >
              <div className="mb-[0.5rem] label dark-60 secondary-regular mt-m">
                Reccurence Rules
                <span className="red">*</span>
              </div>
              {template.schedule && (
                <>
                  <div className="font-weight-400 light-90 inline-regular">
                    {capitalize(rrule.toText())}
                  </div>
                  <div className="mt-s flex">
                    <Button
                      variant="primary"
                      className="mr-s"
                      onClick={() => setRecurrenceModalOpened(true)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="secondary"
                      iconName="reload"
                      iconPosition="right"
                      onClick={() => {
                        setRecurrenceModalOpened(false);
                        onTemplateChange({ ...template, schedule: undefined });
                      }}
                    >
                      Reset
                    </Button>
                  </div>
                </>
              )}
              {!template.schedule && (
                <div>
                  <Dropdown
                    maxWidth="17.25rem"
                    items={recurrenceDropdownItems.map((item) => (
                      <DropdownItem
                        onClick={() => {
                          setRrule(new RRule(item.rule));
                          setRecurrenceModalOpened(true);
                        }}
                      >
                        {item.label}
                      </DropdownItem>
                    ))}
                  >
                    <Button
                      iconName="bottom"
                      size="m"
                      variant="secondary"
                      iconPosition="right"
                    >
                      Add
                    </Button>
                  </Dropdown>
                </div>
              )}
            </div>
          </EmailTemplateFormInputGroup>
        </div>
        <div className="justify-between mb-m mt-m flex">
          <h5 className="light-90 header5-bold">Sections</h5>
          <Dropdown
            items={
              <>
                <DropdownItem
                  onClick={() => {
                    addSection({
                      position:
                        Number(sections[sections.length - 1]?.position) + 1 ||
                        1,
                      type: 'report',
                    });
                  }}
                >
                  <div className="items-center flex">
                    <IconButton
                      iconName="table"
                      variant="secondary"
                      className="mr-s"
                      offHoverStyles
                    />
                    Report
                  </div>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    addSection({
                      position:
                        Number(sections[sections.length - 1]?.position) + 1 ||
                        1,
                      type: 'text',
                    });
                  }}
                >
                  <div className="items-center flex">
                    <IconButton
                      variant="secondary"
                      className="mr-s"
                      offHoverStyles
                    >
                      <img
                        src={AaIcon}
                        alt="icon"
                        className="h-[1rem] w-[1rem]"
                      />
                    </IconButton>
                    Custom Text
                  </div>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    addSection({
                      position:
                        Number(sections[sections.length - 1]?.position) + 1 ||
                        1,
                      type: 'header',
                    });
                  }}
                >
                  <div className="items-center flex">
                    <IconButton
                      variant="secondary"
                      className="mr-s"
                      offHoverStyles
                    >
                      <img
                        src={HIcon}
                        alt="icon"
                        className="h-[0.75rem] w-[0.75rem]"
                      />
                    </IconButton>
                    Custom Header
                  </div>
                </DropdownItem>
              </>
            }
          >
            <Button
              iconName="bottom"
              size="m"
              variant="secondary"
              iconPosition="right"
            >
              Add
            </Button>
          </Dropdown>
        </div>

        {notDeletedSections
          .sort((a, b) => a.position > b.position)
          .map((section, i) => (
            <div key={section?.position}>
              <EmailTemplateFormInputGroup
                title={
                  <div className="items-center flex">
                    <IconButton
                      iconName="table"
                      variant="secondary"
                      className="mr-s"
                      offHoverStyles
                    />
                    {getSectionTitleFromType(section.type)}
                  </div>
                }
                isLoading={isLoading}
                onClose={() => removeSection(section)}
                onMoveUp={i !== 0 ? () => onMove(section, 'up') : undefined}
                onMoveDown={
                  notDeletedSections.length !== i + 1
                    ? () => onMove(section, 'down')
                    : undefined
                }
              >
                {section.type === 'report' && (
                  <>
                    <div
                      className="mb-[0.5rem] label dark-60 secondary-regular"
                      htmlFor="subject"
                    >
                      Select Report
                    </div>
                    <Select
                      value={{
                        value: section.reportScorecard?.id,
                        label: section.reportScorecard?.title,
                      }}
                      placeholder="Select Report"
                      options={
                        scoreCards
                          ?.filter(
                            (scoreCard) =>
                              !notDeletedSections
                                .map((s) => s?.reportScorecard?.id)
                                .includes(scoreCard.id),
                          )
                          .map((scoreCard) => ({
                            value: scoreCard.id,
                            label: scoreCard.title,
                          })) || []
                      }
                      onChange={(scoreCard) => {
                        updateSection(section.position, {
                          reportScorecard: {
                            id: scoreCard?.value,
                            title: scoreCard?.label,
                          },
                        });
                      }}
                    />
                  </>
                )}
                {section.type === 'text' && (
                  <TextEditor
                    config={buildEditorConfiguration()}
                    text={section.textSection?.text}
                    setText={(text) =>
                      updateSection(section.position, { textSection: { text } })
                    }
                  />
                )}
                {section.type === 'header' && (
                  <TemplateEditor
                    placeholder="Enter Header"
                    text={section.headerSection?.text}
                    setText={(text) =>
                      updateSection(section.position, {
                        headerSection: { text },
                      })
                    }
                    variables={variables.header}
                    usedVariables={usedVariables.header}
                    setUsedVariables={(newVariables) =>
                      setVariableField('header', newVariables)
                    }
                    toolbarItems={TOOLBAR_ITEMS_VARIABLE_AND_ALIGNMENTS}
                    onChangeStart={(editor) => forceEditorHeading(editor)}
                    onReady={handleHeaderEditorReady}
                  />
                )}
              </EmailTemplateFormInputGroup>
            </div>
          ))}
      </div>
      {recurrenceModalOpened && (
        <ReccurenceModal
          onClose={() => setRecurrenceModalOpened(false)}
          rrule={rrule}
          defaultDailyRules={defaultDailyRules}
          defaultWeeklyRules={defaultWeeklyRules}
          defaultMonthlyRules={defaultMonthlyRules}
          defaultYearlyRules={defaultYearlyRules}
          onSubmit={(newRrule) => {
            setRecurrenceModalOpened(false);
            onTemplateChange({ ...template, schedule: newRrule.toString() });
          }}
        />
      )}
    </form>
  );
};

export default CustomTemplateForm;
