import { cn } from '@/shared/lib/css/cn';
import { IHeaderGroupParams, IHeaderParams } from 'ag-grid-community';
import useHeaderSorting from 'lib/ag-grid/useHeaderSorting';
import { CSSProperties, PropsWithChildren } from 'react';
import { IconButton } from 'stories/index';
import { CELL_CLASS_NAMES } from '../classNames';
import { CellRendererClasses } from '../types';
import { TABLE_CELL_FONT_SIZE_CSS_VAR_REF } from '@/bundles/Shared/components/AgGrid/Table/utils/getTableCellStyle';

export type HeaderComponentClasses = {
  reactNode?: React.ReactNode;
} & CellRendererClasses;

export type HeaderComponentProps = IHeaderGroupParams & HeaderComponentClasses & {
  style?: CSSProperties;
};

export function BasicHeaderComponent({
  classes,
  children,
  style,
}: PropsWithChildren &
  HeaderComponentProps) {
  return (
    <div
      style={style}
      className={cn(CELL_CLASS_NAMES.Header.wrapper.default, classes?.wrapper)}
    >
      <div
        style={{ fontSize: `calc(${TABLE_CELL_FONT_SIZE_CSS_VAR_REF} - 2px)` }}
        className={cn(CELL_CLASS_NAMES.Header.inner.default, classes?.inner)}
      >
        {children}
      </div>
    </div>
  );
}

export function HeaderSortButton(params: IHeaderParams) {
  const { onSortRequested, activeSort } = useHeaderSorting(params);

  const getSortIcon = () => {
    switch (activeSort) {
      case 'asc':
        return 'sortDesc';
      case 'desc':
        return 'sortAsc';
      default:
        return 'sortOff';
    }
  };

  return (
    <IconButton
      onClick={onSortRequested}
      onTouchEnd={onSortRequested}
      variant="ghost"
      iconName={getSortIcon()}
    />
  );
}

export function HeaderComponent({
  reactNode,
  ...props
}: HeaderComponentProps) {
  return (
    <BasicHeaderComponent {...props}>
      {reactNode}
      {props.displayName}
    </BasicHeaderComponent>
  );
}
