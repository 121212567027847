import { PermissionsSideTab } from '@/bundles/Shared/components/Permissions/EditPermissionsModal/components/PermissionsSideTab';
import { SideTabKey } from '@/bundles/Shared/components/Permissions/EditPermissionsModal/types';
import { concatUndeselectableRoleOptions } from '@/bundles/Shared/components/Permissions/helpers';
import { cn } from '@/shared/lib/css/cn';
import { isEqual, uniqBy } from 'lodash-es';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import pluralize from 'pluralize';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  AnimationLoader,
  Button,
  LinkButton,
  Modal,
  ThinTabGroup,
} from 'stories';
import { IInvestmentEntity } from 'types/IInvestmentEntity';
import { IUserTag } from 'types/IUserTag';
import { IPermissions } from 'types/Permissions';
import { IUser, IUserRole } from 'types/User';
import { fetchPermissionModalSubjectables } from '../actions/permission_modal';
import {
  InvestmentEntitiesList,
  RolesList,
  TagsList,
  UsersList,
} from './ModalWithSideTabs/Lists';
import styles from './PermissionsModal.module.scss';

interface Props {
  onClose: () => void;
  onSubmit: (permissions: IPermissions) => void;
  initialState?: IPermissions;
  initialTab?: 'public' | 'restricted';
  title?: string;
  productName?: string;
  investmentObject?: {
    type: string;
    entity: {
      id: number;
    };
  };
  customHeader?: ReactNode;
  objectableId?: number;
  objectableType?: string;
  tabsIsHidden?: boolean;
  isEmail?: boolean;
  whiteListedTabs?: string[];
  itemType?: 'segment' | 'document';
}

const ProceedWithCaution = () => (
  <div className="proceed-with-caution-alert rounded-[0.5rem] bg-light text-center">
    <h5 className="header5-regular mb-m">Proceed with caution!</h5>
    <p className="proceed-with-caution-alert__body">
      Selecting <b className="font-weight-600">All Users</b> access will include
      <b className="font-weight-600">
        <u> ANY</u>
      </b>{' '}
      user with permission to manage and/or view, as detailed below:
    </p>
  </div>
);

const EMPTY_PERMITTED_STATE = {
  roles: [],
  tags: [],
  users: [],
  investmentEntities: [],
  public: false,
} satisfies Partial<IPermissions>;

type DeprecatedSideTabType = 'roles' | 'tags' | 'users' | 'investmentEntities';

const MAP_FOR_DEPRECATED_SIDE_TAB_KEY_NAME = {
  investmentEntities: 'directInvestmentEntities',
  roles: 'directRoles',
  tags: 'directTags',
  users: 'directUsers',
} satisfies Record<DeprecatedSideTabType, SideTabKey>;

const MAP_FOR_DEPRECATED_SIDE_TAB_KEY_NAME_REVERSED = {
  directInvestmentEntities: 'investmentEntities',
  directRoles: 'roles',
  directTags: 'tags',
  directUsers: 'users',
} satisfies Record<SideTabKey, DeprecatedSideTabType>;

const TAB_ITEMS = [
  {
    id: 'public',
    label: 'All Users',
  },
  {
    id: 'restricted',
    label: 'Restricted',
  },
] as const;

/**
 * @deprecated use this component instead `src/bundles/Shared/components/Permissions/PermissionListModal.tsx`
 */
const PermissionsModal = ({
  onClose,
  onSubmit,
  initialState,
  initialTab = 'public',
  title = 'Configure permissions',
  productName,
  investmentObject,
  customHeader,
  objectableId,
  objectableType,
  tabsIsHidden,
  whiteListedTabs,
  isEmail = false,
  itemType = 'document',
}: Props) => {
  const [tab, setTab] = useState<string>(initialTab);
  const [sideTab, setSideTab] = useState<DeprecatedSideTabType>('roles');
  const [state, setState] = useState<IPermissions>(
    initialState ?? (EMPTY_PERMITTED_STATE as unknown as IPermissions),
  );
  const [allUsers, setAllUsers] = useState<IUser[]>([]);
  const [allTags, setAllTags] = useState<IUserTag[]>([]);
  const [allRoles, setAllRoles] = useState<IUserRole[]>([]);
  const [allInvestmentEntities, setAllInvestmentEntities] = useState<
    IInvestmentEntity[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>();

  const mapTabsWithLists = () => {
    const filteredSideTabs: {
      roles?: 'Roles';
      tags?: 'Tags';
      users?: 'Members';
      investmentEntities?: 'Entities';
    } = {};
    if (allRoles.length > 0) filteredSideTabs.roles = 'Roles';
    if (allTags.length > 0) filteredSideTabs.tags = 'Tags';
    if (allUsers.length > 0) filteredSideTabs.users = 'Members';
    if (allInvestmentEntities.length > 0)
      filteredSideTabs.investmentEntities = 'Entities';
    return filteredSideTabs;
  };

  useEffect(() => {
    setIsLoading(true);
    fetchPermissionModalSubjectables({
      only_product: productName,
      asset_id:
        investmentObject?.type === 'Asset'
          ? investmentObject?.entity?.id
          : undefined,
      fund_id:
        investmentObject?.type === 'Fund'
          ? investmentObject?.entity?.id
          : undefined,
      objectable_id: objectableId,
      objectable_type: objectableType,
    })
      .then(
        (result: {
          userRoles: IUserRole[];
          userTags: IUserTag[];
          users: IUser[];
          investmentEntities: IInvestmentEntity[];
        }) => {
          const wlTabsPresent = whiteListedTabs && whiteListedTabs.length > 0;
          if (!wlTabsPresent || whiteListedTabs.includes('roles'))
            setAllRoles(result.userRoles);
          if (!wlTabsPresent || whiteListedTabs.includes('tags'))
            setAllTags(result.userTags);
          if (!wlTabsPresent || whiteListedTabs.includes('users'))
            setAllUsers(result.users);
          if (!wlTabsPresent || whiteListedTabs.includes('entities'))
            setAllInvestmentEntities(result.investmentEntities);

          setState((prev) => ({
            ...prev,
            roles: concatUndeselectableRoleOptions(
              result?.userRoles ?? [],
              prev.roles,
            ),
          }));
        },
      )
      .finally(() => setIsLoading(false));
  }, []);

  const isPublic = tab === 'public';

  const usersList = isPublic
    ? allUsers
    : allUsers.filter((u) => state.users.some(({ id }) => id === u.id));

  const rolesList = isPublic
    ? allRoles
    : allRoles.filter((r) => state.roles.some(({ id }) => id === r.id));

  const tagsList = isPublic
    ? allTags
    : allTags.filter((t) => state.tags.some(({ id }) => id === t.id));

  const investmentEntitiesList = isPublic
    ? allInvestmentEntities
    : allInvestmentEntities.filter((t) =>
        state.investmentEntities.some(({ id }) => id === t.id),
      );

  const getAutoSelectedUsersList = (currentState: IPermissions) =>
    allUsers.filter(
      (u) =>
        currentState.roles.some(({ id }) => id === u.role?.id) ||
        currentState.tags.some(({ id }) =>
          u.tags.map((tag) => tag.id).includes(id),
        ) ||
        currentState.investmentEntities.some(
          ({ id }) =>
            u.investmentEntities?.map((entity) => entity.id).includes(id),
        ),
    );

  const autoSelectedUsersList = getAutoSelectedUsersList(state);

  const update = (
    key: keyof typeof state,
    value:
      | IInvestmentEntity
      | IUserRole
      | IUserTag
      | IUser
      | IUserRole[]
      | IUserTag[]
      | IUser[],
  ) => {
    const values = state[key];
    let newValues;
    if (Array.isArray(value)) {
      newValues = value;
    } else {
      // @ts-ignore
      newValues = values.map(({ id }) => id).includes(value.id)
        ? // @ts-ignore
          values.filter((v: { id: string }) => v.id !== value.id)
        : // @ts-ignore
          [...values, value];
    }

    setState({ ...state, [key]: newValues });
  };

  const isEmpty =
    state?.roles?.length === 0 &&
    state.users.length === 0 &&
    state.tags.length === 0 &&
    state.investmentEntities.length === 0;

  const buttonTitle = useMemo(() => {
    if (isEmail) return 'Set Recipients';
    return isPublic ? 'Set Public Access' : 'Set Restricted Access';
  }, [isEmail, isPublic]);

  const handleSubmit = () => {
    onSubmit({
      ...state,
      type: tab as IPermissions['type'],
      autoSelectedUsers: autoSelectedUsersList,
      allUsers,
      public: isPublic,
      users: isPublic ? allUsers : state.users,
    });
    onClose();
  };

  const countSideTabItems = (key: keyof typeof state) => {
    if (key === 'users')
      return uniqBy([...state.users, ...autoSelectedUsersList], 'id').length;
    // @ts-ignore
    return state[key].length;
  };

  const isNotChanged = () => isEqual(initialState, state);

  return (
    <Modal
      toggle={onClose}
      header={customHeader ?? title}
      classes={{
        body: `p-0 bg-light relative ${styles['modal-body_permissions']}`,
        footer: 'justify-end pt-[1rem] modal-footer_shadow',
      }}
      bodyPadding="0px"
      contentClassName="modal-content-h-700"
      actions={
        !isLoading && (
          <>
            <Button onClick={onClose} className="mr-s" variant="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={!isPublic && (isEmpty || isNotChanged())}
              variant="success"
            >
              {buttonTitle}
            </Button>
          </>
        )
      }
      scrollable={false}
      size={isPublic ? '700' : 'permission-lg'}
    >
      {isLoading && <AnimationLoader />}
      {!isLoading && (
        <div
          className={cn(
            styles['permissions-list-body'],
            'modal-with-side-tabs h-full',
          )}
        >
          {!tabsIsHidden && (
            <div
              className={cn(
                'flex items-center justify-center gap-m bg-light-5 p-tw-3',
                styles['tab-row'],
              )}
            >
              <span className="light-90 inline-regular">
                What rights do you want to set for these{' '}
                {pluralize(itemType, 2)}?
              </span>
              <ThinTabGroup
                onSelectedItemChange={(selected) => {
                  setState((prev) => ({
                    ...prev,
                    users:
                      // This is needed because current endpoint doesn't send "indirectUsers". So we can't determine "direct" & "indirect" users
                      initialState?.public && selected.id === 'public'
                        ? prev.users
                        : [],
                  }));
                  setTab(selected.id as unknown as string);
                }}
                selectedItem={tab}
                items={TAB_ITEMS}
              />
            </div>
          )}
          <div className="flex grow" style={{ height: '30.75rem' }}>
            {!isPublic && (
              <section className="border-right flex">
                <aside className="flex flex-col" style={{ width: '6.125rem' }}>
                  <ul>
                    {Object.entries(mapTabsWithLists()).map(
                      ([localTab, localTitle]) => (
                        <li
                          key={localTab}
                          className={cn({ active: localTab === sideTab })}
                        >
                          <LinkButton
                            onClick={() =>
                              setSideTab(localTab as DeprecatedSideTabType)
                            }
                            className="light-60 d-block"
                          >
                            <div className="mb-tw-2">
                              {countSideTabItems(
                                localTab as keyof typeof state,
                              )}
                            </div>
                            {localTitle}
                          </LinkButton>
                        </li>
                      ),
                    )}
                  </ul>
                </aside>
                <OverlayScrollbarsComponent
                  options={{
                    className:
                      'permissions-modal-body-part border-left os-theme-thin-dark w-full',
                    paddingAbsolute: true,
                  }}
                >
                  <PermissionsSideTab
                    computedIndirectUsers={autoSelectedUsersList}
                    metaState={{
                      initialState: {
                        directInvestmentEntities: state.investmentEntities,
                        directRoles: state.roles,
                        directTags: state.tags,
                        directUsers: state.users,
                        isPublic: state.public,
                        indirectUsers: state.autoSelectedUsers ?? [],
                      },
                      investementEntityOptions: allInvestmentEntities,
                      roleOptions: allRoles,
                      tagOptions: allTags,
                      userOptions: allUsers,
                    }}
                    permittedState={{
                      directInvestmentEntities: state.investmentEntities,
                      directRoles: state.roles,
                      directTags: state.tags,
                      directUsers: state.users,
                      isPublic: state.public,
                      indirectUsers:
                        state.autoSelectedUsers ?? autoSelectedUsersList ?? [],
                    }}
                    handleUpdate={(k, v) =>
                      update(
                        MAP_FOR_DEPRECATED_SIDE_TAB_KEY_NAME_REVERSED[k],
                        v,
                      )
                    }
                    currentSideTab={
                      MAP_FOR_DEPRECATED_SIDE_TAB_KEY_NAME[sideTab]
                    }
                  />
                </OverlayScrollbarsComponent>
              </section>
            )}
            <div className="mnw-0">
              <OverlayScrollbarsComponent
                options={{
                  className: cn(
                    'permissions-modal-body-part os-theme-thin-dark p-m w-full flex flex-col gap-m os-flexbox',
                    { 'pr-0': !isPublic && isEmpty },
                  ),
                }}
              >
                {!isPublic && (
                  <>
                    <h5 className="header5-regular mb-m">
                      {isEmail ? 'Email to' : 'Share with'}
                    </h5>
                    {isEmpty ? (
                      <p className="white-space-nw">
                        You haven&apos;t chosen anyone yet
                      </p>
                    ) : (
                      <div className="flex flex-col gap-m">
                        {(state.roles.length || isPublic) && (
                          <RolesList
                            items={rolesList}
                            closable={!isPublic}
                            onClose={(id) => update('roles', id)}
                          />
                        )}
                        {(state.tags.length || isPublic) && (
                          <TagsList
                            items={tagsList}
                            closable={!isPublic}
                            onClose={(id) => update('tags', id)}
                          />
                        )}
                        {(state.investmentEntities.length || isPublic) && (
                          <InvestmentEntitiesList
                            items={investmentEntitiesList}
                            closable={!isPublic}
                            onClose={(id) => update('investmentEntities', id)}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
                {isPublic && <ProceedWithCaution />}
                {(isPublic || !isEmpty) &&
                  (usersList.length ||
                    autoSelectedUsersList.length ||
                    isPublic) && (
                    <UsersList
                      members={usersList}
                      autoSelectedMembers={autoSelectedUsersList}
                      closable={!isPublic}
                      onClose={(id) => update('users', id)}
                      displayTagsColumn={!isPublic}
                    />
                  )}
              </OverlayScrollbarsComponent>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default PermissionsModal;
