import {
  PostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishApiResponse,
  PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse,
  PutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiResponse,
  coreAssetsReportBuilderReportsGeneratedApi,
} from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';

export const coreAssetsReportBuilderReportsEnhancedApi =
  coreAssetsReportBuilderReportsGeneratedApi.enhanceEndpoints({
    addTagTypes: ['ReportList', 'Report', 'ReportWidget', 'Snapshot'],
    endpoints: {
      getApiCoreAssetsByAssetIdReportBuilderReports: {
        providesTags: ['ReportList'],
      },
      postApiCoreAssetsByAssetIdReportBuilderReports: {
        invalidatesTags: ['ReportList'],
      },
      getApiCoreAssetsByAssetIdReportBuilderReportsAndId: {
        providesTags: ['Report'],
      },
      deleteApiCoreAssetsByAssetIdReportBuilderReportsAndId: {
        invalidatesTags: ['ReportList'],
        transformResponse: (
          response: PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse,
        ) => {
          toastr.success('Report successfully deleted');
          return response;
        },
      },
      postApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshot:
        {
          providesTags: ['Snapshot'],
        },
      putApiCoreAssetsByAssetIdReportBuilderReportsAndId: {
        transformResponse: (
          response: PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse,
        ) => {
          toastr.success('Report updated successfully');
          return response;
        },
        invalidatesTags: (_, __, args) => {
          const BasicTags = ['Report', 'ReportList'] as const;

          // No need to invalidate all snapshots (costly) if there are no changes for widgets
          if (args.body.date != null || args.body.legal_entity_ids != null) {
            return [...BasicTags, 'Snapshot'];
          }

          return BasicTags;
        },
      },
      postApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublish: {
        invalidatesTags: ['ReportList', 'Report'],
        transformResponse: (
          response: PostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishApiResponse,
        ) => {
          toastr.success('Report published successfully');
          return response;
        },
      },
      putApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshot:
        {
          transformResponse: (
            response: PutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiResponse,
          ) => {
            toastr.success('Report updated successfully');
            return response;
          },
          invalidatesTags: ['Report', 'ReportWidget', 'Snapshot'],
        },
    },
  });

export const {
  useGetApiCoreAssetsByAssetIdReportBuilderReportsQuery,
  usePostApiCoreAssetsByAssetIdReportBuilderReportsMutation,
  useDeleteApiCoreAssetsByAssetIdReportBuilderReportsAndIdMutation,
  usePutApiCoreAssetsByAssetIdReportBuilderReportsAndIdMutation,
  useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdQuery,
  usePutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotMutation,
  usePostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotQuery,

  usePostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishMutation,
} = coreAssetsReportBuilderReportsEnhancedApi;
