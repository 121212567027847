import React, { useState } from 'react';
import { IconButton } from 'stories';
import { ISharedDocument } from 'types/SharedDocument';
import { downloadSharedFile } from 'lib/sharedFile';
import { cn } from '@/shared/lib/css/cn';
import { ClassNameProps } from 'types/Props';

interface Props extends ClassNameProps {
  row: ISharedDocument;
}

function DownloadButton({ row, className }: Props) {
  const [downloading, setDownloading] = useState(false);
  const handleClick = () => {
    if (row.confidential) return;
    setDownloading(true);
    downloadSharedFile(row).finally(() => setDownloading(false));
  };

  return (
    <span
      className={className}
      data-tip={row.confidential ? "You can't download this file" : 'Download'}
      style={
        row.confidential
          ? {
              pointerEvents: 'none',
              opacity: 0.5,
            }
          : {}
      }
    >
      <IconButton
        iconName={downloading ? 'sync' : 'download'}
        size="m"
        className={cn('sre-icon-button_size-mobile-l bg-white', {
          syncing: downloading,
        })}
        onClick={handleClick}
      />
    </span>
  );
}

export default DownloadButton;
