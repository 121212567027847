import { FinancialTableSinglePeriodPeriodType } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/model';
import { SelectableMultilineButton } from '@/stories';
import { PeriodTypeWidgetState } from 'bundles/Shared/widgets/dashboard/widgets/common';
import { WidgetStateProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { AVAILABLE_PERIOD_TYPES_OPTIONS } from 'bundles/Shared/widgets/dashboard/widgets/common/config';

export function WidgetStatePeriodType({
  state,
  onStateChange,
  periodTypes,
}: WidgetStateProps<PeriodTypeWidgetState> & {
  periodTypes: FinancialTableSinglePeriodPeriodType[];
}) {
  return (
    <div className="flex gap-tw-1">
      {AVAILABLE_PERIOD_TYPES_OPTIONS.filter((o) =>
        periodTypes.includes(o.value),
      ).map((o) => (
        <SelectableMultilineButton
          selected={state.periodType === o.value}
          title={o.label}
          className="h-[32px] grow-0 py-tw-1"
          key={o.value}
          onClick={() => {
            onStateChange({
              ...state,
              periodType: o.value,
            });
          }}
        />
      ))}
    </div>
  );
}
