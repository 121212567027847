import { InvestmentIndex } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { lowerCase, trim } from 'lodash-es';
import { useMemo } from 'react';

const MIN_CHARS_FOR_SEARCH = 2;

export function useComputedInvEntities({
  query,
  capitalInvestments,
}: {
  query: string;
  capitalInvestments: InvestmentIndex[];
}) {
  const computedCapitalInvestments = useMemo(() => {
    const preparedQuery = lowerCase(trim(query));

    if (preparedQuery.length <= MIN_CHARS_FOR_SEARCH) return capitalInvestments;

    return capitalInvestments.filter((invEnt) => {
      const hasInvEntName = lowerCase(invEnt.investmentEntity.name).includes(
        preparedQuery,
      );
      const hasLegalEntName = lowerCase(invEnt.legalEntity.name).includes(
        preparedQuery,
      );

      return hasInvEntName || hasLegalEntName;
    });
  }, [capitalInvestments, query]);

  return computedCapitalInvestments;
}
