import React, { useMemo } from 'react';
import {
  ReportDashboardType,
  ReportEagleEyeDashboard,
  updateReportObjectDashboardDate,
  useDashboardContext,
} from 'bundles/Shared/entities/dashboard';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import Calendar from 'stories/FlexibleFilterByPeriods/calendar/Calendar';
import dayjs from 'dayjs';
import { formatDate } from '@/shared/lib/formatting/dates';
import { FilterBlock } from 'bundles/Settings/components/REport/Dashboards/Dashboard/widgets/SettingsReportObjectLevelDashboard';
import { IconButton } from '@/stories';
import { updateReportComparisonDashboardDate } from '@/bundles/Shared/entities/dashboard/model/slices/comparisonSlice';
import { formatToDateStringForRequest } from '@/shared/lib/converters';

interface Props
  extends Omit<
    React.ComponentProps<typeof Calendar>,
    'value' | 'onChange' | 'selectionMode'
  > {
  dashboardId: ReportEagleEyeDashboard['id'];
  date: string | null;
}

export function ReportDashboardDateFilter({
  date,
  ...props
}: Props) {
  const dispatch = useAppDispatch();
  const { dashboardType, dashboardId } = useDashboardContext()
  const updateDashboard = useMemo(() => {
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return updateReportObjectDashboardDate
      }
      case ReportDashboardType.EAGLE_EYE: {
        throw new Error('This date filter doesn\'t support Eagle Eye Dashboard')
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return updateReportComparisonDashboardDate
      }
    }
  }, [])

  return (
    <Calendar
      value={[dayjs(date ?? new Date())]}
      onChange={([item]) => {
        dispatch(
          updateDashboard({
            dashboardId,
            date: formatToDateStringForRequest(item),
          }),
        );
      }}
      selectionMode="daily"
      {...props}
    />
  );
}

export const ReportDashboardDateFilterBlock = ({
  date,
  dashboardId,
}: {
  dashboardId: ReportEagleEyeDashboard['id'];
  date: string | null;
}) => {
  return (
    <ReportDashboardDateFilter
      dashboardId={dashboardId}
      date={date ?? null}
      popoverProps={{
        children: (
          <FilterBlock
            iconName="calendar"
            label="Date"
            value={
              date
                ? formatDate(date as DateString, 'MM-DD-YYYY')
                : 'Select Date'
            }
          >
            <IconButton iconName="bottom" />
          </FilterBlock>
        ),
      }}
    />
  );
};
