import http, { apiClient, requireAuthorizationHeaders } from 'lib/http';
import { DEFAULT_STRINGIFY_OPTIONS } from 'lib/stringify';
import { camelCase, mapKeys } from 'lodash-es';
import QueryString, { stringify } from 'qs';
import { Dispatch } from 'redux';
import { IAsset } from 'types/Asset';
import { IAssetStage } from 'types/AssetStage';
import { FetchAssetsWithMetaResponse } from '../reducers/AssetsSlice';

export const FETCH_ASSETS = 'symmetre-client-api/FETCH_ASSETS';
export const FETCH_ASSET = 'symmetre-client-api/FETCH_ASSET';
export const CREATE_ASSET = 'symmetre-client-api/CREATE_ASSET';
export const UPDATE_ASSET = 'symmetre-client-api/UPDATE_ASSET';

export interface IAssetsPageParams {
  page: number;
  per_page: number;
  stages?: IAssetStage['name'][];
  favorite_ids?: string[];
  statuses?: string[];
  search_by_query?: string;
  fund_id?: number;
}

export async function plainFetchAssetsWithMeta(params: IAssetsPageParams) {
  const options: QueryString.IStringifyOptions = {
    ...DEFAULT_STRINGIFY_OPTIONS,
    encode: false,
  } as const;

  const res = await http.get(`/assets${stringify(params, options)}`);
  const data = (await res.json()) as FetchAssetsWithMetaResponse;
  return data;
}

export function fetchAssets(params?: IAssetsPageParams) {
  return async (dispatch) => {
    const options: QueryString.IStringifyOptions = {
      ...DEFAULT_STRINGIFY_OPTIONS,
      encode: false,
    } as const;

    const res = await http.get(`/assets${stringify(params, options)}`); // TODO: assets load with default pagination of 50. Need to fix it
    const data = (await res.json()) as FetchAssetsWithMetaResponse;

    dispatch({ type: FETCH_ASSETS, payload: data.assets });

    return data;
  };
}

export async function plainFetchAssets(data?) {
  const res = await http.get(`/assets?${stringify(data)}`); // TODO: assets load with default pagination of 50. Need to fix it
  const responseData = await res.json();
  return responseData;
}

export async function plainFetchAsset(id: number) {
  const res = await http.get(`/assets/${id}`);
  const asset = await res.json();

  return asset as IAsset;
}

export function fetchAsset(id, shouldReset?) {
  return async (dispatch) => {
    if (shouldReset) {
      // if switching between assets
      dispatch({ type: FETCH_ASSET, payload: null });
    }

    const asset = await plainFetchAsset(id);

    dispatch({ type: FETCH_ASSET, payload: asset });
  };
}

export function nullifyAsset() {
  return (dispatch: Dispatch) => {
    dispatch({ type: FETCH_ASSET, payload: null });
  };
}

export function createAsset(formData) {
  return async (dispatch) => {
    const res = await apiClient.post('/api/assets', formData, {
      headers: requireAuthorizationHeaders({
        'Content-Type': 'multipart/form-data',
      }),
    });

    const asset = res.data;

    if (asset.errors) {
      toastr.error(`${asset.errors}\n`);
    } else {
      toastr.success('Asset created successfully');
      dispatch({ type: CREATE_ASSET, payload: asset });
    }
  };
}

export function heavyUpdateAsset(formData: FormData) {
  return async (dispatch, getState) => {
    const { currentAsset } = getState();

    const res = await apiClient.put(
      `/api/assets/${(formData.get('id') as string | null) ?? ''}`,
      formData,
      {
        headers: requireAuthorizationHeaders({
          'Content-Type': 'multipart/form-data',
        }),
      },
    );

    const responseData = res.data;

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
      return;
    }

    toastr.success('Asset has been updated');

    const updatedAsset: IAsset = {
      ...currentAsset,
      ...responseData,
    };
    dispatch({ type: UPDATE_ASSET, payload: updatedAsset });
  };
}

function updateAsset({
  data,
  isHeavyUpdate = false,
}: {
  data: IAsset;
  isHeavyUpdate: boolean;
}) {
  return async (dispatch, getState) => {
    const { currentAsset } = getState();

    const endpoint = `/assets/${data.id}${
      isHeavyUpdate ? '' : '/simple_update'
    }`;
    const res = await http.put(endpoint, data);
    const responseData = await res.json();
    const transformData = isHeavyUpdate
      ? responseData
      : mapKeys(data.asset, (_value, key) => camelCase(key));

    const newData: IAsset = {
      ...currentAsset,
      ...transformData,
    };

    if (responseData.errors) {
      toastr.error(`${responseData.errors}\n`);
      return;
    }
    toastr.success('Asset has been updated');
    if (window.location.href.includes('/assets/'))
      history.pushState({}, null, responseData.slug);

    dispatch({ type: UPDATE_ASSET, payload: newData });

    return newData;
  };
}

export function simpleUpdateAsset(data) {
  return updateAsset({ data });
}

export function heavyUpdateAssetAddress(data) {
  return updateAsset({ data, isHeavyUpdate: true });
}

export type TUpdateAssetConfigRequest = Partial<{
  hard_construction_cost: number;
  hard_cost_contingency: number;
  sitework_contingency: number;
  completed_and_stored: number;
  retainage: string;
  previous_certified_works: number;
  hard_construction_cost_code: number;
  hard_cost_contingency_code: number;
  soft_cost_contingency_code: number;
  initial_estimated_completion_date: Date;
  contractor_retainage_value: string;
}>;

export const updateAssetConfig = async (
  assetId: number,
  assetConfig: TUpdateAssetConfigRequest,
) => {
  const res = await http.put(`/assets/${assetId}/asset_config`, {
    asset_config: assetConfig,
  });
  const responseData = await res.json();
  if (responseData.errors) {
    toastr.error(`${responseData.errors} \n`);
    return false;
  }
  toastr.success('Config has been updated');
  return true;
};
