import { kebabCase } from "lodash-es";
import { KebabCase } from "type-fest";

export class CssCustomProperty<const N extends string> {
  #initialName: N;

  static EMPTY_CASE_RESULT = 'empty-string' as const

  constructor(initialName: N) {
    this.#initialName = initialName;
  }

  static getName = <const N extends string>(name: N): `--${KebabCase<N extends '' ? typeof CssCustomProperty.EMPTY_CASE_RESULT : N>}` => {
    return `--${kebabCase(name || this.EMPTY_CASE_RESULT)}` as `--${KebabCase<N extends '' ? typeof this.EMPTY_CASE_RESULT : N>}`
  }

  static getRef = <const VarName extends string>(name: VarName) => {
    return `var(${CssCustomProperty.getName(name)})` as const;
  }

  get name() {
    return CssCustomProperty.getName(this.#initialName)
  }

  get ref() {
    return CssCustomProperty.getRef(this.#initialName);
  }
}
