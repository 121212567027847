import { AgGridReact } from 'ag-grid-react';
import { sanitizeFileName } from 'lib/uploadFiles';
import { isDividerColumn } from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import React, { RefObject, useCallback } from 'react';
import { WidgetViewMode } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { ExportButton } from '@/shared/ui/ExportButton';
import { ObjectDashboardWidgetContext } from 'bundles/Shared/widgets/dashboard/widgetsHelpers';
import { DateWidgetState } from 'bundles/Shared/widgets/dashboard/widgets/common';
import {
  CellClassParams,
  CellClassRules,
  ExcelExportParams,
  ExcelStyle,
  ICellRendererParams,
} from 'ag-grid-community';
import { isTotalRow } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/ColumnDefsBuilder';

export const TABLE_EXCEL_COLORS = {
  header: '#1A2225',
  total: '#1C8336',
};
export const HEADER_COLORS = {
  font: {
    bold: true,
    color: '#FFFFFF',
  },
  interior: {
    color: TABLE_EXCEL_COLORS.header,
    pattern: 'Solid',
    patternColor: undefined,
  },
} as const satisfies Readonly<Pick<ExcelStyle, 'interior' | 'font'>>;
export const TABLE_EXCEL_STYLES = [
  {
    id: 'header',
    alignment: {
      vertical: 'Center',
    },
    ...HEADER_COLORS,
  },
  {
    id: 'totalBackground',
    alignment: {
      horizontal: 'Right',
    },
    font: {
      color: '#FFFFFF',
      bold: true,
    },
    interior: {
      color: TABLE_EXCEL_COLORS.total,
      pattern: 'Solid',
    },
  },
  {
    id: 'headerGroup',
    ...HEADER_COLORS,
  },
] as const satisfies readonly ExcelStyle[];
export type ExcelStyleId = (typeof TABLE_EXCEL_STYLES)[number]['id'];
export const TABLE_EXCEL_STYLE_IDS = Object.fromEntries(
  TABLE_EXCEL_STYLES.map((s) => [s.id, s.id]),
) as Record<ExcelStyleId, ExcelStyleId>;
export const buildExcelStyleId = (id: string) => `excel-${id}`;

export const getDefaultTableExcelExportParams = ({
  fileName,
  gridRef,
}: {
  fileName: string;
  gridRef?: RefObject<AgGridReact>;
}) => ({
  fileName: sanitizeFileName(fileName),
  columnKeys: gridRef?.current
    ? [
        ...gridRef.current.columnApi
          .getAllDisplayedColumns()
          .filter((c) => !isDividerColumn(c))
          .map((c) => c.getColId()),
      ]
    : [],
});

export const useTableWidgetExportFeature = (
  {
    mode,
    gridRef,
    ...options
  }: {
    mode: WidgetViewMode;
    gridRef: RefObject<AgGridReact>;
    isTotalRow?: (row: ICellRendererParams) => boolean;
  },
  params?: ExcelExportParams | (() => ExcelExportParams),
) => {
  const isRowTotal = options.isTotalRow ?? isTotalRow;
  const handleExport = useCallback(() => {
    if (!gridRef.current) {
      return;
    }

    gridRef.current.api.exportDataAsExcel(
      typeof params === 'function' ? params() : params,
    );
  }, [gridRef.current, params]);

  const ExportButtonComponent = useCallback(() => {
    return mode !== 'pdf' && <ExportButton onClick={handleExport} />;
  }, [mode, handleExport]);

  return {
    ExportButtonComponent,
    excelStyles: TABLE_EXCEL_STYLES,
    cellClass: ({ colDef }: CellClassParams) =>
      colDef.colId && buildExcelStyleId(colDef.colId),
    cellClassRules: {
      [TABLE_EXCEL_STYLE_IDS.totalBackground]: (cellParams) =>
        isRowTotal(cellParams),
    } as CellClassRules,
  };
};

const FILE_NAME_DELIMITER = ' -';

export const useObjectDashboardWidgetTableExport = (
  args: Parameters<typeof useTableWidgetExportFeature>[0],
  {
    state,
    context,
    widgetTitle,
  }: {
    context?: Pick<ObjectDashboardWidgetContext, 'selectedAsset'>;
    state: DateWidgetState;
    widgetTitle: string;
  },
  params?: ExcelExportParams,
) => {
  const fileName = [context?.selectedAsset?.name, widgetTitle]
    .filter(Boolean)
    .join(FILE_NAME_DELIMITER);
  return useTableWidgetExportFeature(args, () => ({
    ...getDefaultTableExcelExportParams({
      fileName,
      gridRef: args.gridRef,
    }),
    sheetName: state.date,
    ...params,
  }));
};
