import { HUGE_MODAL_Z_INDEX } from '@/bundles/Shared/constants';
import {
  InvestmentIndex,
  PostApiCapitalInvestmentDistributionsBulkApiArg,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import SelectInvestmentEntity from 'bundles/REturn/components/Ownership/selectInvestmentEntity/SelectInvestmentEntity';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import pluralize from 'pluralize';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal, ModalActions, ModalHeaderWithSubtitle } from 'stories';
import { EnterAccruedValuesStep } from './EnterAccruedValuesStep';
import { EnterAccruedAmountValuesStep } from './EnterAccruedAmountValuesStep';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  convertDollarsToCents,
  formatToDateStringForRequest,
} from '@/shared/lib/converters';

interface Props
  extends DialogProps<PostApiCapitalInvestmentDistributionsBulkApiArg['body']> {
  capitalInvestments: InvestmentIndex[];
}

const FORM_SCHEMA = yup.object().shape({
  accrued_entries: yup.array().of(
    yup.object().shape({
      capital_investment_id: yup.number().required(),
      start_date: yup.date().required(),
      end_date: yup.date().nullable(),
      period_type: yup.string().oneOf(['monthly', 'quarterly']).required(),
      amount: yup.number().required(),
    }),
  ),
});

const AddAccruedModal = ({ onClose, capitalInvestments, onSubmit }: Props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedInvestmentEntities, setSelectedInvestmentEntities] = useState<
    InvestmentIndex[]
  >([]);
  const step1Content = useMemo(
    () => (
      <SelectInvestmentEntity
        capitalInvestments={capitalInvestments}
        selectedItems={selectedInvestmentEntities}
        setSelectedItems={(selectedItems) => {
          setSelectedInvestmentEntities(selectedItems);
        }}
      />
    ),
    [selectedInvestmentEntities],
  );

  const form = useForm({
    resolver: yupResolver(FORM_SCHEMA),
    mode: 'onTouched',
  });

  useEffect(() => {
    form.reset({
      accrued_entries: selectedInvestmentEntities.map((item) => ({
        id: item.id,
        capital_investment_id: item.id,
        investmentClasses: item.investmentClasses,
        investmentEntity: item.investmentEntity,
        legalEntity: item.legalEntity,
        totalClassAContributionsAmountCents:
          item.totalClassAContributionsAmountCents,
        totalClassBContributionsAmountCents:
          item.totalClassBContributionsAmountCents,
      })),
    });
  }, [selectedInvestmentEntities]);

  const step2Content = (
    <div className="p-tw-6">
      <EnterAccruedValuesStep form={form} />
    </div>
  );

  const step3Content = (
    <div className="p-tw-6">
      <EnterAccruedAmountValuesStep form={form} editable />
    </div>
  );

  const step4Content = (
    <div className="p-tw-6">
      <EnterAccruedAmountValuesStep form={form} editable={false} />
    </div>
  );

  const steps: Record<string, React.JSX.Element> = {
    1: step1Content,
    2: step2Content,
    3: step3Content,
    4: step4Content,
  };

  const stepsLength = Object.keys(steps).length;

  const step2Valid = () => {
    const errors = form.formState.errors.accrued_entries;
    if (!errors) return true;

    return errors.every((obj) => {
      const errorKeys = Object.keys(obj);

      if (errorKeys.length === 0) return true;
      if (errorKeys.every((key) => key === 'amount')) return true;

      return false;
    });
  };

  const stepsValid: Record<string, boolean> = {
    1: selectedInvestmentEntities.length !== 0,
    2: step2Valid(),
    3: form.formState.isValid,
    4: true,
  };

  const pluralizedSelectedInvestmentEntities = `${
    selectedInvestmentEntities.length
  } ${pluralize('Investment Entity', selectedInvestmentEntities.length)}`;
  const stepsSubmitText: Record<string, string> = {
    1: `Continue ${
      selectedInvestmentEntities.length > 0
        ? `with ${pluralizedSelectedInvestmentEntities}`
        : ''
    }`,
    2: 'Next',
    3: 'Next',
    4: 'Create Accrued Preferred',
  };

  const handleSubmit = () => {
    if (currentStep !== stepsLength) {
      setCurrentStep(currentStep + 1);
      return;
    }

    const values = form.getValues();
    const payload = {
      accrued_perferred_return: values.accrued_entries.map((entry) => ({
        capital_investment_id: entry.capital_investment_id,
        start_date: formatToDateStringForRequest(entry.start_date),
        end_date: entry.end_date
          ? formatToDateStringForRequest(entry.end_date)
          : undefined,
        period_type: entry.period_type,
        amount_cents: convertDollarsToCents(entry.amount),
      })),
    };

    onSubmit(payload);
  };

  return (
    <Modal
      toggle={() => onClose()}
      size="xl"
      classes={{
        body: '!p-0',
      }}
      bodyHeight={600}
      zIndex={HUGE_MODAL_Z_INDEX + 2}
      header={
        <ModalHeaderWithSubtitle
          title="Bulk Adding of Accrued Preferred Values"
          subtitle={`Step ${currentStep} of ${stepsLength}`}
          order="subtitle-title"
        />
      }
      actions={
        <ModalActions alignItems="space-between">
          <Button
            variant="secondary"
            onClick={
              currentStep === 1
                ? onClose
                : () => setCurrentStep(currentStep - 1)
            }
          >
            {currentStep === 1 ? 'Cancel' : 'Back to Selection'}
          </Button>
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={!stepsValid[currentStep]}
          >
            {stepsSubmitText[currentStep]}
          </Button>
        </ModalActions>
      }
    >
      {steps[currentStep]}
    </Modal>
  );
};

export default AddAccruedModal;
