import { UNGROUPED_GROUP_KEY } from '@/bundles/Shared/entities/dashboard';
import { commonTableWidgetUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/common/updaters';
import { KpiTableWidgetConfigDto } from 'bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import {
  maxIdGenerator,
  transformPeriodShiftFormToDto,
  upsertColumnSettings,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { TableVizConfig } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  KpiTableWidgetColumnGroupForm,
  KpiTableWidgetConfig,
  KpiTableWidgetConfigColumnForm,
  KpiTableWidgetConfigForm,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import * as d3 from 'd3';
import { produce } from 'immer';
import { groupBy, isEmpty, orderBy } from 'lodash-es';

export const upsertColumnGroup = <
  T extends Pick<KpiTableWidgetConfig, 'viz_config'>,
>(
  columnGroup: KpiTableWidgetColumnGroupForm,
  config: T,
): T => {
  return produce(config, (draft) => {
    const vizConfig = draft.viz_config;
    const columnGroups = vizConfig.column_groups ?? [];
    vizConfig.column_groups = columnGroups;
    const columnGroupIndex =
      columnGroup.key != null
        ? columnGroups.findIndex((cg) => cg.group_id === columnGroup.key)
        : columnGroups.length;

    const newColumnGroup = produce(
      columnGroups[columnGroupIndex] ?? {
        group_id: maxIdGenerator(columnGroups, 'group_id').toString(),
        header_name: '',
        order: columnGroupIndex,
        icon: null,
      },
      (draftGroup) => {
        draftGroup.header_name = columnGroup.label;
        draftGroup.icon = columnGroup.icon ?? null;
        draftGroup.background = columnGroup.color;
        draftGroup.border_color = d3
          .color(columnGroup.color)
          ?.darker(0.2)
          .formatRgb();
      },
    );
    columnGroups[columnGroupIndex] = newColumnGroup;
  });
};

export const insertColumnInGroup = (
  columnId: string,
  groupId: string,
  viz_config: KpiTableWidgetConfig['viz_config'],
) => {
  const group = viz_config.column_groups?.find((cg) => cg.group_id === groupId);
  if (group == null) {
    return;
  }
  const column = viz_config.columns?.find((c) => c.col_id === columnId);
  if (column == null) {
    return;
  }
  column.group_id = groupId;
};

export const upsertColumn = (
  column: KpiTableWidgetConfigColumnForm,
  config: KpiTableWidgetConfig,
  {
    groupId,
  }: {
    groupId?: string;
  },
): KpiTableWidgetConfig => {
  return produce(config, (draft) => {
    let columnIndex = draft.columns.findIndex((c) => c.key === column.key);
    if (columnIndex === -1) {
      columnIndex = draft.columns.length;
    }
    draft.columns[columnIndex] = {
      label: column.label,
      expression: column.expression,
      period_shift: transformPeriodShiftFormToDto(
        column.period_shift ?? undefined,
      ),
      key: column.key ?? maxIdGenerator(draft.columns, 'key'),
      value_display_options: column.value_display_options,
      total_calculation_strategy:
        column.total_calculation_strategy ?? undefined,
    };
    if (!isEmpty(column.adjustment)) {
      draft.columns[columnIndex].adjustment_expression = column.adjustment;
    }

    if (column.sort) {
      draft.viz_config.columns = draft.viz_config.columns.map((c) => {
        if (c.col_id === column.key?.toString()) {
          return { ...c, initial_sort: column.sort ?? null };
        }

        return { ...c, initial_sort: undefined };
      });
    }

    const colId = draft.columns[columnIndex].key.toString();
    upsertColumnSettings(column, draft, { key: colId, groupId });
  });
};

export const {
  moveGroup,
  removeGroup,
  removeColumn,
  moveColumn,
  cloneColumn,
  toggleColumnHidden,
  toggleAllColumnsHidden,
  toggleGroupHidden,
} = commonTableWidgetUpdaters;

export const buildColumnGroupsMap = (columns: TableVizConfig['columns']) => {
  return groupBy(
    orderBy(columns, ['group_id', 'order'], ['asc', 'asc']),
    (c) => c.group_id ?? UNGROUPED_GROUP_KEY,
  );
};

export const updateDefaultOptions = (
  values: KpiTableWidgetConfigForm,
  widgetConfig: KpiTableWidgetConfigDto,
): KpiTableWidgetConfigDto => {
  return produce(widgetConfig, (draft) => {
    draft.hide_total_row = values.hideTotalRow ?? undefined;
    const period_shift = transformPeriodShiftFormToDto(values.period_shift);
    draft.default_options.date =
      period_shift != null
        ? {
            period_shift,
          }
        : undefined;
    if (values.datePickerSetting === 'month') {
      draft.period_types = values.availablePeriodTypes;
      draft.default_options.period_type = values.defaultPeriodType;
      return;
    }
    draft.period_types = ['week'];
    draft.default_options.period_type = 'week';
  });
};
