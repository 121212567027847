import React from 'react';
import { Field } from 'stories/Field/Field';
import { CheckListController, ListOption } from 'stories/Checkbox/CheckList';
import { mapItemToOption } from '@/shared/lib/listHelpers';
import { SharedSelectController } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import { FieldPath } from 'react-hook-form/dist/types/path/eager';
import { Control, useFormContext } from 'react-hook-form';

export function PeriodTypesField<
  TFieldValues extends Record<string, unknown> = Record<string, unknown>,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  options,
  defaultPeriodTypeFieldName = 'defaultPeriodType' as TName,
  availablePeriodTypesFieldName = 'availablePeriodTypes' as TName,
}: {
  control: Control<TFieldValues>;
  availablePeriodTypesFieldName?: TName;
  defaultPeriodTypeFieldName?: TName;
  options: ListOption[];
}) {
  const { watch } = useFormContext();
  const availablePeriodTypesField = watch(availablePeriodTypesFieldName);
  const defaultPeriodTypeField = watch(defaultPeriodTypeFieldName);
  return (
    <>
      <Field labelText="Period" required>
        <CheckListController
          name={availablePeriodTypesFieldName}
          control={control}
          unselectable={mapItemToOption(defaultPeriodTypeField)}
          items={options}
          getItemProps={(option) => ({
            disabled: defaultPeriodTypeField === option.value,
            tooltipProps: {
              arrowPosition: 'start',
              disabled: defaultPeriodTypeField !== option.value,
              titleText: "Can't remove the default period type",
            },
          })}
        />
      </Field>
      <Field labelText="Default Period Type" required>
        <SharedSelectController
          control={control}
          name={defaultPeriodTypeFieldName}
          options={options.filter((o) =>
            availablePeriodTypesField.includes(o.value),
          )}
        />
      </Field>
    </>
  );
}
