import { BaseCard } from '@/shared/ui/BaseCard';
import { Link, RouteComponentProps } from '@reach/router';
import { ItemTableCounter } from '@/shared/ui/ItemTableCounter';
import { DASHBOARD_SKELETON_JSX } from 'bundles/Settings/components/REport/Dashboards/Dashboard/config';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import {
  PageParamsProvider,
  PageParamsSearch,
} from 'bundles/Shared/components/pageParams';
import {
  DASHBOARD_ENTITY_NAME,
  ReportDashboardStatus,
  ReportDashboardType,
  useGetApiSettingsReportDashboardsQuery,
} from 'bundles/Shared/entities/dashboard';
import { DashboardFilterByStatusTabs } from 'bundles/Shared/features/dashboard/filter/byStatus';
import { DashboardFilterByTypeTabs } from 'bundles/Shared/features/dashboard/filter/byType';
import { DashboardSettingsCard } from 'bundles/Shared/widgets/dashboard/card';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import React, { useMemo, useState } from 'react';
import Button from 'stories/Button/Button';
import { IQueryParams } from 'types/Pagination';

type Props = RouteComponentProps;

type PageParams = IQueryParams & {
  status: ReportDashboardStatus | `${ReportDashboardStatus}` | null;
  type: ReportDashboardType | `${ReportDashboardType}` | null;
};

const SettingsReportDashboards: React.FC<Props> = () => {
  const [pageParams, setPageParams] = useState<PageParams>({
    query: '',
    status: null,
    type: null,
  });
  const { data, isLoading } = useGetApiSettingsReportDashboardsQuery();

  const filteredData = useMemo(
    () =>
      (data ?? []).filter((o) => {
        const matchesQuery = includesInLowerCase(
          o.name,
          pageParams.query ?? '',
        );
        const matchesStatus =
          pageParams.status == null || o.status === pageParams.status;
        const matchesType =
          pageParams.type == null || o.type === pageParams.type;
        return matchesQuery && matchesStatus && matchesType;
      }),
    [data, pageParams],
  );

  return (
    <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
      <div className="flex flex-col gap-tw-4 p-tw-6 pt-tw-9">
        <div className="flex items-center gap-tw-2">
          <DashboardFilterByStatusTabs
            status={pageParams.status}
            onChange={(status) =>
              setPageParams((prev) => ({ ...prev, status }))
            }
          />
          <DashboardFilterByTypeTabs
            type={pageParams.type}
            onChange={(type) => setPageParams((prev) => ({ ...prev, type }))}
          />
          <div className="grow" />
          <Link to={ROUTES_ROOT.settings.report.dashboards.new.fullPath}>
            <Button size="s" variant="primary">
              Create {DASHBOARD_ENTITY_NAME}
            </Button>
          </Link>
          <PageParamsSearch placeholder="Dashboard Name" />
        </div>
        <ItemTableCounter count={data?.length ?? 0} word="Items" />
        <BaseCard.Wrapper>
          {isLoading && DASHBOARD_SKELETON_JSX}
          {filteredData.map(({ id }) => (
            <DashboardSettingsCard dashboardId={id} key={id} />
          ))}
          {!isLoading && filteredData?.length === 0 && (
            <NoDataOverlay
              className="col-span-4"
              title={'No Dashboards found'}
            />
          )}
        </BaseCard.Wrapper>
      </div>
    </PageParamsProvider>
  );
};

export default SettingsReportDashboards;
