import LoadingTable from '@/bundles/Shared/components/LoadingTable';
import { WidgetProps } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { cn } from '@/shared/lib/css/cn';
import React, { ComponentProps } from 'react';
import { ClassNameProps, LayoutProps } from 'types/Props';

const Card = React.forwardRef(({ className, children }: LayoutProps, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        'relative flex  h-full max-h-full w-full flex-col overflow-auto rounded-[24px] bg-neutral-000 shadow-m',
        className,
      )}
    >
      {children}
    </div>
  );
}) satisfies React.ForwardRefExoticComponent<
  LayoutProps & Pick<React.RefAttributes<HTMLDivElement>, 'ref'>
>;

const _LoadingTable = () => (
  <LoadingTable rowHeight={32} className="grow" cols={4} />
);
const Error = ({ mode }: Pick<WidgetProps, 'mode'>) => (
  <div
    className={cn(
      'flex flex-col items-center justify-center',
      mode === 'edit' ? 'h-full' : 'p-tw-6',
    )}
  >
    <span className="text-neutral-550">Error loading data</span>
  </div>
);

function Header({
  children,
  className,
}: React.PropsWithChildren<ClassNameProps>) {
  return (
    <div
      className={cn(
        'flex flex-wrap items-center justify-between gap-tw-4 p-tw-4',
        className,
      )}
    >
      {children}
    </div>
  );
}

Header.Title = ({
  children,
  className,
}: React.PropsWithChildren<ClassNameProps>) => (
  <span className={cn('header5-bold text-neutral-850', className)}>
    {children}
  </span>
);

const Panel = ({ children }: React.PropsWithChildren) => (
  <div className="flex items-center gap-tw-2 bg-neutral-100 p-tw-4">
    {children}
  </div>
);

const PDFHeader = ({
  children,
  className,
}: React.PropsWithChildren<ClassNameProps>) => (
  <span className={cn('body-semibold mb-tw-2 text-neutral-850', className)}>
    {children}
  </span>
);

export const DashboardWidgetCard = {
  ...Card,
  PDFHeader,
  Header,
  LoadingTable: _LoadingTable,
  Error,
  Panel,
} as unknown as typeof Card & {
  PDFHeader: typeof PDFHeader;
  Header: typeof Header;
  LoadingTable: typeof _LoadingTable;
  Error: typeof Error;
  Panel: typeof Panel;
};

export const DashboardWidgetTableCard = React.forwardRef<
  HTMLDivElement,
  LayoutProps &
    ComponentProps<typeof DashboardWidgetCard.Error> & {
      isError?: boolean;
      isLoading?: boolean;
    }
>(({ isError, isLoading, className, ...props }, ref) => (
  <DashboardWidgetCard
    ref={ref}
    {...props}
    className={cn(
      {
        'h-auto rounded-none bg-[initial] shadow-none': props.mode === 'pdf',
      },
      className,
    )}
  >
    {props.children}
    {isError && !isLoading && <DashboardWidgetCard.Error {...props} />}
    {isLoading && !isError && <DashboardWidgetCard.LoadingTable />}
  </DashboardWidgetCard>
));
