import { cn } from '@/shared/lib/css/cn';
import useOutsideClick from '@/shared/lib/hooks/useOutsideClick';
import { GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { clearFocusedCellAndRange } from 'lib/ag-grid/utils';
import { ComponentProps, forwardRef, useRef } from 'react';
import { NEXT_GEN_TABLE_CONFIG } from './consts';
import { getDataPath, getRowHeight, getRowId } from './utils/tablePropsUtils';

type Props = Omit<ComponentProps<typeof AgGridReact>, 'animateRows'> & {
  hideAmountsOnExpandedRows?: boolean;
  hideShadowsPinnedColumn?: boolean;
  /**
   * @deprecated use `viz_config.auto_column instead */
  hideHeader?: boolean;
  headerBackground?: React.CSSProperties['background'];
  enableRoundedCorners?: boolean;
  classes?: {
    container?: string;
  };
};

export const NextGenTable = forwardRef<AgGridReact, Props>(
  (
    {
      className,
      hideAmountsOnExpandedRows,
      hideShadowsPinnedColumn,
      hideHeader,
      headerBackground,
      enableRoundedCorners,
      classes,
      context,
      ...props
    },
    ref,
  ) => {
    const divRef = useRef<HTMLDivElement>(null);

    useOutsideClick(divRef, () => {
      if (ref != null && 'current' in ref && ref.current?.api) {
        clearFocusedCellAndRange(ref.current?.api);
      }
    });
    const options: GridOptions = {
      groupIncludeTotalFooter: true,
      groupIncludeFooter: true,
      suppressContextMenu: true,
      enableRangeSelection: true,
      animateRows: true,
      treeData: true,
      groupDefaultExpanded: -1,
      headerHeight: NEXT_GEN_TABLE_CONFIG.row.default.minHeight,
      groupHeaderHeight: NEXT_GEN_TABLE_CONFIG.row.default.minHeight,
      getRowHeight,
      getRowId,
      getDataPath,
      ...props,
    };
    return (
      <div
        ref={divRef}
        className={cn('h-full bg-tw-white', classes?.container)}
      >
        <AgGridReact
          containerStyle={{
            '--next-gen-table-header-background-color': headerBackground,
          }}
          className={cn(
            'nextGenTable text-sm',
            {
              'nextGenTable_hide-amounts-on-expanded-rows':
                hideAmountsOnExpandedRows,
              'nextGenTable_hide-shadows_pinned_column':
                hideShadowsPinnedColumn,
              'nextGenTable_rounded-corners': enableRoundedCorners,
              'nextGenTable_hide-header': hideHeader,
            },
            options.groupIncludeFooter && 'group-include-footer',
            className,
          )}
          ref={ref}
          context={{
            options,
            ...context,
          }}
          {...options}
        />
      </div>
    );
  },
);
