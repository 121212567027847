import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { DelinquencySnapshotWidget } from 'bundles/Shared/widgets/dashboard/widgets/delinquencySnapshot/ui/DelinquencySnapshotWidget';
import * as am5 from '@amcharts/amcharts5';
import { COLOR_SET, getDefaultLabelStyleInJson } from 'lib/amcharts/utils';
import {
  getAmchartNumberFormatForByDisplayOptions,
  getCurrencyTooltipFormat,
  getPercentageTooltipFormat,
} from '@/shared/lib/formatting/charts';

export const DELINQUENCY_SNAPSHOT_WIDGET_CONFIG = {
  title: 'Delinquency Snapshot',
  icon: 'chartBar',
  Component: DelinquencySnapshotWidget,
  layout: {
    w: 1,
    h: 13,
    maxW: 2,
    maxH: 12,
    minH: 10,
    minW: 1,
  },
} as const satisfies WidgetConfiguration;

export const DELINQUENCY_SNAPSHOT_CHART_CONFIG = {
  refs: [
    {
      xAxis: {
        type: 'CategoryAxis',
        settings: {
          maxDeviation: 0.2,
          categoryField: 'label',
          renderer: {
            type: 'AxisRendererX',
            settings: {
              minGridDistance: 40,
              cellStartLocation: 0.1,
              cellEndLocation: 0.9,
            },
          },
        },
        properties: {
          data: '#data',
        },
      },
      sumYAxis: {
        type: 'ValueAxis',
        settings: {
          numberFormat: `${getAmchartNumberFormatForByDisplayOptions({
            type: 'currency',
            precision: 0,
          })}`,
          renderer: {
            type: 'AxisRendererY',
          },
        },
        children: [
          {
            index: 0,
            type: 'Label',
            settings: {
              text: 'Rent ($)',
              y: am5.p50,
              centerX: am5.p50,
              rotation: -90,
              ...getDefaultLabelStyleInJson(),
            },
          },
        ],
      },
    },
    {
      ratioYAxis: {
        type: 'ValueAxis',
        settings: {
          syncWithAxis: '#sumYAxis',
          numberFormat: `${getAmchartNumberFormatForByDisplayOptions({
            type: 'percentage',
            precision: 0,
          })}`,
          renderer: {
            type: 'AxisRendererY',
            settings: {
              opposite: true,
            },
          },
        },
        children: [
          {
            type: 'Label',
            settings: {
              text: 'Current %',
              y: am5.p50,
              centerX: am5.p50,
              rotation: -90,
              ...getDefaultLabelStyleInJson(),
            },
          },
        ],
      },
    },
    {
      series: [
        ...[
          {
            name: 'Del. Balance',
            valueYField: 'delinquentBalance',
          },
          {
            name: 'Current (30 d.)',
            valueYField: '30days',
          },
        ].map(({ name, valueYField }) => ({
          type: 'ColumnSeries',
          settings: {
            name,
            valueYField,
            yAxis: '#sumYAxis',
            xAxis: '#xAxis',
            categoryXField: 'label',
            clustered: false,
            tooltip: {
              type: 'Tooltip',
              settings: {
                labelText: getCurrencyTooltipFormat(),
              },
            },
            fill:
              valueYField === 'delinquentBalance'
                ? COLOR_SET.at(1)
                : COLOR_SET.at(-4),
          },
          properties: {
            data: '#data',
            ...(valueYField === '30days'
              ? {
                  columns: {
                    properties: {
                      template: {
                        settings: {
                          width: {
                            type: 'Percent',
                            value: 50,
                          },
                          tooltipY: 0,
                        },
                      },
                    },
                  },
                }
              : undefined),
          },
        })),
        {
          type: 'LineSeries',
          settings: {
            name: 'Current %',
            valueYField: 'percent',
            yAxis: '#ratioYAxis',
            xAxis: '#xAxis',
            categoryXField: 'label',
            tooltip: {
              type: 'Tooltip',
              settings: {
                labelText: getPercentageTooltipFormat(),
              },
            },
            fill: COLOR_SET.at(-1),
            stroke: COLOR_SET.at(-1),
          },
          properties: {
            data: '#data',
            bullets: [
              {
                type: 'Bullet',
                settings: {
                  sprite: {
                    type: 'Circle',
                    settings: {
                      radius: 4,
                      fill: "@series.get('fill')",
                    },
                  },
                },
              },
            ],
          },
        },
      ],
    },
  ],
  type: 'XYChart',
  properties: {
    series: '#series',
    xAxes: ['#xAxis'],
    yAxes: ['#sumYAxis', '#ratioYAxis'],
  },
  settings: {
    panY: false,
    layout: 'vertical',
    scrollbarX: {
      type: 'Scrollbar',
      settings: {
        orientation: 'horizontal',
        marginBottom: 20,
      },
    },
    cursor: {
      type: 'XYCursor',
      settings: {
        behavior: 'zoomX',
      },
      properties: {
        lineY: {
          settings: {
            visible: false,
          },
        },
      },
    },
  },
  children: [
    {
      type: 'Legend',
      settings: {
        centerX: {
          type: 'Percent',
          value: 50,
        },
        x: {
          type: 'Percent',
          value: 50,
        },
        layout: 'horizontal',
      },
      properties: {
        data: '#data',
      },
    },
  ],
} as const;
