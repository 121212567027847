import React, { useState } from 'react';
import { ReportSegment } from 'bundles/Shared/entities/segment/model';
import { Button, Popover } from 'stories';
import { DEFAULT_DROPDOWN_OFFSET } from 'stories/Popover/Popover';
import ItemFilterList from 'bundles/Shared/components/Filters/items/ItemFilterList';
import pluralize from 'pluralize';

interface Props {
  segments: Pick<ReportSegment, 'id' | 'title'>[];
  onChange: (segments: ReportSegment['id'][]) => void;
  defaultValue?: ReportSegment['id'][];
  value?: ReportSegment['id'][];
}

function ReportSegmentDropdown({
  value: externalValue,
  defaultValue,
  onChange,
  segments,
}: Props) {
  const [internalValue, setInternalValue] = useState(defaultValue);
  const value = externalValue ?? internalValue!;

  const handleChange = (newAssetIds: number[]) => {
    onChange(newAssetIds);
    if (externalValue !== undefined) return;
    setInternalValue(newAssetIds);
  };
  return (
    <Popover
      className="p-0"
      offset={DEFAULT_DROPDOWN_OFFSET}
      hiddenArrow
      trigger="click"
      placement={'bottom-start'}
      maxWidth={300}
      template={
        <ItemFilterList
          className="max-h-[21.875rem]"
          items={segments}
          onSelectedItemChange={(item) =>
            handleChange(
              value.includes(item.id)
                ? value.filter((id) => id !== item.id)
                : [...value, item.id],
            )
          }
          getMainFieldTitle={(item) => item.title}
          selectedItems={segments.filter((item) => value.includes(item.id))}
        />
      }
    >
      <Button size="s" iconName="segments" variant="secondary">
        {pluralize('Segment', value.length, true)}
      </Button>
    </Popover>
  );
}

export default ReportSegmentDropdown;
