import { RefObject, useCallback, useEffect } from 'react';
import OverlayScrollbars, { OverflowChangedCallback } from 'overlayscrollbars';

const BOTTOM_SHADOW_CLASS = 'sidebar-menu-scrollable-area_bottom';
const TOP_SHADOW_CLASS = 'sidebar-menu-scrollable-area_top';

const shadowModifier =
  (modifier: 'add' | 'remove') =>
  (
    classList: Element['classList'],
    position: 'top' | 'bottom' | 'top-bottom',
  ) => {
    const modifierMethod =
      modifier === 'add'
        ? (...args) => classList.add(...args)
        : (...args) => classList.remove(...args);

    if (position === 'top') {
      modifierMethod(TOP_SHADOW_CLASS);
    } else if (position === 'bottom') {
      modifierMethod(BOTTOM_SHADOW_CLASS);
    } else {
      modifierMethod(BOTTOM_SHADOW_CLASS, TOP_SHADOW_CLASS);
    }
  };

const addShadow = shadowModifier('add');
const removeShadow = shadowModifier('remove');

export const useScrollBarOverflowShadows = (
  ref: RefObject<{
    _osInstance: OverlayScrollbars | null;
    _osTargetRef: RefObject<HTMLDivElement>;
  }>,
) => {
  useEffect(() => {
    if (ref.current?._osInstance.getState().hasOverflow.y) {
      ref.current._osTargetRef.current.classList.add(
        'sidebar-menu-scrollable-area_bottom',
      );
    }
  }, [ref.current]);

  const onScroll = useCallback(() => {
    const scrollPosition = ref.current._osInstance.scroll().ratio.y;
    const scrollableAreaStyleClasses =
      ref.current._osTargetRef.current.classList;
    if (scrollPosition >= 0.99) {
      removeShadow(scrollableAreaStyleClasses, 'bottom');
    } else if (scrollPosition === 0) {
      removeShadow(scrollableAreaStyleClasses, 'top');
    } else {
      addShadow(scrollableAreaStyleClasses, 'top-bottom');
    }
  }, [ref.current]);

  const onOverflowChanged = useCallback<OverflowChangedCallback>(
    (e) => {
      if (ref.current) {
        const scrollableAreaStyleClasses =
          ref.current._osTargetRef.current.classList;
        if (e.yScrollable) {
          addShadow(scrollableAreaStyleClasses, 'bottom');
        } else {
          removeShadow(scrollableAreaStyleClasses, 'top-bottom');
        }
      }
    },
    [ref.current],
  );

  return { onOverflowChanged, onScroll };
};
