import { WidgetFullScreen } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import {
  DashboardHistoricalReviewTableWidgetDto,
  ReportDashboardType,
} from 'bundles/Shared/entities/dashboard';
import {
  DashboardWidgetCard,
  DashboardWidgetTableCard,
  DateWidgetState,
  formatDateRangeForWidgetColumnSubHeader,
  WidgetStateDate,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import {
  ColGroupDefBuilder,
  ColumnDefsBuilder,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/ColumnDefsBuilder';
import { usePinColumn } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/updaters';
import { useObjectDashboardWidgetTableExport } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import { WidgetTable } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetTable';
import {
  handleHistoricalWidgetFirstDataRendered,
  RowColDefBuilder,
} from 'bundles/Shared/widgets/dashboard/widgets/historicalTable/lib';
import {
  HistoricalReviewTableWidgetConfigModel,
  HistoricalReviewTableWidgetSection,
} from 'bundles/Shared/widgets/dashboard/widgets/historicalTable/model';
import {
  WidgetConfigProps,
  WidgetContextProps,
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import { ObjectDashboardWidgetContext } from 'bundles/Shared/widgets/dashboard/widgetsHelpers';
import { useLoadingOverlayEffect } from 'lib/ag-grid/utils';
import { useMemo, useRef } from 'react';

export type HistoricalTableWidgetState = DateWidgetState;

export function HistoricalTableWidget({
  widgetSection,
  data,
  state,
  onStateChange,
  isLoading,
  isFetching,
  isError,
  mode = 'view',
  settings,
  onSettingsChange,
  className,
  dashboardType,
  context,
}: WidgetProps<
  DashboardHistoricalReviewTableWidgetDto,
  HistoricalReviewTableWidgetSection
> &
  WidgetConfigProps<HistoricalReviewTableWidgetConfigModel> &
  WidgetStateProps<HistoricalTableWidgetState> &
  WidgetContextProps<ObjectDashboardWidgetContext>) {
  const gridRef = useRef<AgGridReact>(null);
  const wrapperDivRef = useRef<HTMLDivElement>(null);

  useLoadingOverlayEffect({
    isLoading: isFetching,
    grid: gridRef.current,
  });
  const exportFeature = useObjectDashboardWidgetTableExport(
    {
      gridRef,
      mode,
    },
    {
      state,
      widgetTitle: widgetSection.title,
      context,
    },
  );

  const onPinColumn = usePinColumn({
    settings,
    onSettingsChange,
  });
  const rowData = useMemo(() => {
    if (data == null) {
      return undefined;
    }
    const rows = data.data ?? [];

    if (rows.length === 0) {
      return [];
    }

    return rows;
  }, [data]);

  const minMaxValues = useMemo(() => {
    const rows = data?.data ?? [];
    const columns = data?.columns ?? [];

    if (rows.length === 0) {
      return {};
    }

    return rows.reduce((acc, row) => {
      const values = columns.map((column) => row[column.key.toString()]);
      const valuesWithoutZero = values.filter((v) => v !== 0);
      return {
        ...acc,
        [row.key?.toString()]: {
          min: Math.min(...values),
          max: Math.max(...values),
          minWithoutZero: Math.min(...valuesWithoutZero),
          maxWithoutZero: Math.max(...valuesWithoutZero),
        },
      };
    }, {});
  }, [data]);

  const columnDefs = useMemo<ColDef[]>(() => {
    const columns = data?.columns ?? [];
    const rows = data?.data ?? [];

    if (columns.length === 0 || rows.length === 0) {
      return [];
    }

    const colDefBuilder = new RowColDefBuilder({
      mode,
      onPinColumn,
      rows: widgetSection.widgetConfig.viz_config!.rows,
    }).withSubHeaderName((params) => {
      const colConfig = widgetSection.widgetConfig.columns?.find(
        (c) => c.key.toString() === params.columnSettings.key,
      );
      return formatDateRangeForWidgetColumnSubHeader(
        params.column,
        colConfig,
        params.columnSettings,
      );
    });
    const colGroupDefBuilder = new ColGroupDefBuilder({
      mode,
    });
    const colDefsBuilder = new ColumnDefsBuilder({
      colDefBuilder,
      mode,
      colGroupDefBuilder,
      viz_config: widgetSection.widgetConfig.viz_config,
    });
    return [
      ...colDefsBuilder.build({
        columns,
      }),
    ].filter((column) => column !== null);
  }, [JSON.stringify(data?.columns), widgetSection]);

  return (
    <DashboardWidgetTableCard
      mode={mode}
      ref={wrapperDivRef}
      isError={isError}
      isLoading={isLoading}
      className={className}
    >
      {mode === 'pdf' && (
        <DashboardWidgetCard.PDFHeader>
          {widgetSection.title}
        </DashboardWidgetCard.PDFHeader>
      )}
      {mode !== 'pdf' && (
        <DashboardWidgetCard.Header>
          <DashboardWidgetCard.Header.Title>
            {widgetSection.title}
          </DashboardWidgetCard.Header.Title>
          <GrowDiv />

          <WidgetStateDate state={state} onStateChange={onStateChange} />

          <exportFeature.ExportButtonComponent />
          <WidgetFullScreen wrapperDivRef={wrapperDivRef} />
        </DashboardWidgetCard.Header>
      )}
      {!isLoading && !isError && (
        <WidgetTable
          domLayout={
            dashboardType === ReportDashboardType.OBJECT || mode === 'pdf'
              ? 'autoHeight'
              : 'normal'
          }
          expensivePropSuppressColumnVirtualisation={mode === 'pdf'}
          context={{
            minMaxValues,
          }}
          treeData={false}
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          autoGroupColumnDef={undefined}
          mode={mode}
          excelStyles={exportFeature.excelStyles}
          defaultParams={{
            sizeColumnsToFit: false,
          }}
          onFirstDataRendered={handleHistoricalWidgetFirstDataRendered}
        />
      )}
    </DashboardWidgetTableCard>
  );
}
