import { isWidgetSectionPositionWidthFullSize } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilder';
import { cn } from '@/shared/lib/css/cn';
import { getAmchartNumberFormatForByDisplayOptions } from '@/shared/lib/formatting/charts';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5plugins_json from '@amcharts/amcharts5/plugins/json';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { AxisRenderer, DateAxis, XYChart } from '@amcharts/amcharts5/xy';
import {
  WidgetDateGranularity,
  XyChartWidgetDto,
} from 'bundles/Shared/entities/dashboard';
import {
  DashboardWidgetCard,
  DateRangeWidgetState,
  WidgetStateGranularity,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { ExportChartButton } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/ExportChartButton';
import { WidgetStateCalendarRangeSelector } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateCalendarRangeSelector';
import {
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import { XYChartWidgetSection } from 'bundles/Shared/widgets/dashboard/widgets/xyChart/model';
import {
  addExportToChart,
  applyFormatToCell,
  buildDataFieldsFromSeries,
  DEFAULT_AMCHART_EXCEL_DATE_FIELD,
  filterDateColumn,
  getColumnLetterFromCellKey,
  getFirstSheetFromWorkbook,
  hasSeriesRef,
  sanitizeCells,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib';
import { sort } from 'd3';
import { useAmchart } from 'lib/amcharts/useAmchart';
import {
  chartDateMapper,
  COLOR_SET,
  getReturnDashboardTheme,
} from 'lib/amcharts/utils';
import { useRef } from 'react';
import AnimationLoader from 'stories/AnimationLoader/AnimationLoader';
import { UnknownRecord } from 'type-fest/source/internal';

export type XYChartWidgetState = DateRangeWidgetState & {
  granularity: WidgetDateGranularity;
};

export function XYChartWidget({
  data,
  widgetSection,
  state,
  onStateChange,
  isFetching,
  className,
  mode,
}: WidgetProps<XyChartWidgetDto, XYChartWidgetSection> &
  WidgetStateProps<XYChartWidgetState>) {
  const ref = useRef(null);
  const chartRef = useRef<
    | (XYChart & {
        exporting: am5plugins_exporting.Exporting;
      })
    | null
  >(null);
  useAmchart(
    ref,
    (root) => {
      if (!data) {
        return;
      }
      const myTheme = getReturnDashboardTheme(root);
      myTheme.rule('ColorSet').set('colors', COLOR_SET);
      root.setThemes([am5themesAnimated.new(root), myTheme]);

      const parser = am5plugins_json.JsonParser.new(root);
      const config = widgetSection.widgetConfig.am_chart_config;
      const mappedData = data.items
        .map((item) => ({
          ...item,
          ...item.values,
        }))
        .map(chartDateMapper('dateFrom'));
      const dataRef = {
        data: mappedData,
      };
      parser
        .parse(
          {
            ...config,
            refs: [dataRef, ...config.refs],
          },
          {
            parent: root.container,
          },
        )
        .then(function (chart: XYChart) {
          // Chart is ready
          chartRef.current = chart;

          const firstKpi = widgetSection.widgetConfig.kpis?.at(0);
          if (firstKpi && hasSeriesRef(config)) {
            const yAxes = chart.yAxes.getIndex(0)!;
            yAxes
              .getNumberFormatter()
              .set(
                'numberFormat',
                getAmchartNumberFormatForByDisplayOptions(
                  firstKpi.value_display_options!,
                ),
              );
          }

          if (mode === 'pdf') {
            chart.set('scrollbarX', undefined);
            chart.set('scrollbarY', undefined);
          }

          (chart.xAxes.getIndex(0) as DateAxis<AxisRenderer>).set(
            'baseInterval',
            {
              timeUnit: state.granularity ?? 'month',
              count: 1,
            },
          );
          if (mode !== 'pdf') {
            chart.series.getIndex(0)?.appear(1000);
            chart.appear(1000, 100);
          }

          const { dataFieldsOrder } = addExportToChart({
            chartRef,
            root,
            title: widgetSection.title,
            data: mappedData,
            granularity: state.granularity,
            dataFields: buildDataFieldsFromSeries(chart.series.values),
          });

          chartRef.current!.exporting.events.on(
            'workbookready',
            function (event) {
              const dataSheet = getFirstSheetFromWorkbook(event.workbook);
              const cellKeys = sort(
                sanitizeCells(filterDateColumn(dataSheet as UnknownRecord)),
              );
              const kpiFieldMapping = Object.fromEntries(
                dataFieldsOrder!
                  .filter((f) => f !== DEFAULT_AMCHART_EXCEL_DATE_FIELD)
                  .map((f, index) => {
                    const matchedKpi = widgetSection.widgetConfig.kpis.find(
                      (kpi) => kpi.key.toString() === f,
                    );
                    return [index, matchedKpi];
                  }),
              );
              let kpiIndex = 0;
              let columnLetter = getColumnLetterFromCellKey(cellKeys[0]);
              cellKeys.forEach((cellKey) => {
                const kpi = kpiFieldMapping[kpiIndex];
                // eslint-disable-next-line no-param-reassign
                applyFormatToCell(
                  dataSheet[cellKey],
                  kpi?.value_display_options ?? {
                    type: 'number',
                    precision: 2,
                  },
                );
                const newColumnLetter = getColumnLetterFromCellKey(cellKey);
                if (columnLetter !== newColumnLetter) {
                  kpiIndex += 1;
                  columnLetter = newColumnLetter;
                }
              });
            },
          );
        });
    },
    [data],
  );

  return (
    <DashboardWidgetCard
      className={cn(className, mode === 'pdf' && 'rounded-[8px] shadow-none')}
    >
      {mode === 'pdf' &&
        isWidgetSectionPositionWidthFullSize(widgetSection.position) && (
          <DashboardWidgetCard.PDFHeader>
            {widgetSection.title}
          </DashboardWidgetCard.PDFHeader>
        )}
      {mode !== 'pdf' && (
        <DashboardWidgetCard.Header>
          <DashboardWidgetCard.Header.Title>
            {widgetSection.title}
          </DashboardWidgetCard.Header.Title>

          <div className="flex items-center gap-tw-2">
            <WidgetStateGranularity
              state={state}
              onStateChange={onStateChange}
              chartRef={chartRef}
              granularities={
                widgetSection.widgetConfig.default_options.granularities
              }
            />
            <WidgetStateCalendarRangeSelector
              state={state}
              onStateChange={onStateChange}
            />
            <div className="grow" />
            <ExportChartButton chartRef={chartRef} />
          </div>
        </DashboardWidgetCard.Header>
      )}
      {isFetching && <AnimationLoader />}
      <div className="h-[350px]" ref={ref} />
    </DashboardWidgetCard>
  );
}
