import {
  ColDef,
  ColGroupDef,
  Column,
  GridOptions,
  ShouldRowBeSkippedParams,
} from 'ag-grid-community';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { HeaderGroupComponent } from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderGroupComponent';
import {
  AUTO_COLUM_DEF_MAX_WIDTH,
  AUTO_COLUM_DEF_MIN_WIDTH,
  DEFAULT_GROUP_BG_CLASS,
  getFakeColumnDefs,
  WIDGET_TABLE_AUTO_GROUP_COL_DEF,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import {
  findColumnByColDef,
  getUngroupedColumns,
  isKpiTableRowAsset,
  isKpiTableRowSegment,
  isKpiTableRowTotal,
  KpiTableGroupingType,
  KpiTableWidgetDataRow,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { isGroupColDef } from 'lib/ag-grid/utils';
import { RefObject, useEffect, useMemo } from 'react';
import { cloneDeep, merge } from 'lodash-es';
import { UnknownRecord } from 'type-fest/source/internal';
import { TableVizConfig } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { produce } from 'immer';
import {
  getDefaultTableExcelExportParams,
  useTableWidgetExportFeature,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';

export const DELIMITER = '/';
export const ROW_HEIGHT = 34;
const EXTENDED_ROW_HEIGHT = 52;
const PINNED_ROW_HEIGHT = 42;

export const isDividerColumn = (col: Column) =>
  col.getColId().startsWith('divider-column-');

export const useKpiTableWidgetExportFeature = (
  args: Parameters<typeof useTableWidgetExportFeature>[0],
  {
    widgetTitle,
  }: {
    widgetTitle: string;
  },
) => {
  return useTableWidgetExportFeature(args, () => ({
    ...getDefaultTableExcelExportParams({
      fileName: widgetTitle,
      gridRef: args.gridRef,
    }),
    shouldRowBeSkipped(params: ShouldRowBeSkippedParams) {
      const row = params.node.data as KpiTableWidgetDataRow;
      return !(
        isKpiTableRowAsset(row) ||
        isKpiTableRowAsset(row) ||
        isKpiTableRowTotal(row)
      );
    },
  }));
};

export const useAutoGroupColDef = (
  groupingType: KpiTableGroupingType,
  ref?: RefObject<AgGridReact>,
) => {
  const autoGroupColDef = merge(cloneDeep(WIDGET_TABLE_AUTO_GROUP_COL_DEF), {
    minWidth: AUTO_COLUM_DEF_MIN_WIDTH,
    maxWidth: AUTO_COLUM_DEF_MAX_WIDTH,
    headerName: groupingType === 'assets' ? 'Asset' : 'Segment',
    headerComponentParams: {
      subHeaderName: groupingType === 'assets' ? 'Entity' : 'Asset',
    },
  });
  useEffect(() => {
    ref?.current?.api?.refreshHeader();
  }, [groupingType]);

  return useMemo<ColDef>(() => autoGroupColDef, [groupingType]);
};

export const zeroAndNullToTheEndComparator: ColDef['comparator'] = (
  a,
  b,
  _?,
  __?,
  isDescending = false,
  // eslint-disable-next-line max-params
) => {
  if (b === 0 || b == null) {
    return isDescending ? 1 : -1;
  }
  if (a === 0 || a == null) {
    return isDescending ? -1 : 1;
  }
  return a - b;
};

export const getDataPath: GridOptions['getDataPath'] = (row) =>
  row.path?.split(DELIMITER) as string[];

export const getRowHeight: AgGridReactProps['getRowHeight'] = (params) => {
  const row = params.node.data as KpiTableWidgetDataRow;
  if (
    row != null &&
    isKpiTableRowSegment(row) &&
    params.node.childrenAfterGroup?.length === 1
  ) {
    return EXTENDED_ROW_HEIGHT;
  }
  if (params.node.isRowPinned()) {
    return PINNED_ROW_HEIGHT;
  }
  return ROW_HEIGHT;
};

export const getRowId: GridOptions['getRowId'] = ({ data: row }) => {
  return row.path;
};

export const addDividersToColumnGroups = (
  columnGroups: ColGroupDef[],
): ColGroupDef[] => {
  if (columnGroups.length === 1) {
    return columnGroups;
  }

  const dividersCount = columnGroups.length - 1;
  return Array.from({
    length: columnGroups.length + dividersCount,
  }).map((_, index) => {
    if (index % 2 === 1) {
      return {
        headerName: '',
        groupId: `divider-group-${index}`,
        headerGroupComponent: HeaderGroupComponent,
        headerGroupComponentParams: {
          classes: {
            wrapper: '!bg-neutral-100 !border-neutral-100',
          },
        },
        children: [
          {
            width: 8,
            maxWidth: 8,
            colId: `divider-column-${index}`,
            headerComponentParams: {
              classes: {
                wrapper: '!bg-neutral-100 !border-neutral-100',
              },
            },
            cellClass: (params) => {
              if (params.node.data && isKpiTableRowTotal(params.node.data)) {
                return '!bg-neutral-700';
              }
              return '!bg-neutral-100';
            },
            cellRenderer: () => <></>,
          },
        ],
      };
    }
    return columnGroups[Math.floor(index / 2)];
  });
};

export const addHideHeadersToVizConfig = (config: TableVizConfig) => {
  return produce(config, (draft) => {
    draft.columns.forEach((draftC) => {
      draftC.header = {
        ...draftC.header,
        hide_subtitle: true,
      };
    });
  });
};

export const buildWidgetTableColumnDefs = <
  T extends {
    label: string;
    key: number;
  },
>({
  columns,
  columnToColDefMapper,
  rows,
  agGridConfig,
}: {
  columns: T[];
  columnToColDefMapper: (c: T, cg?: ColGroupDef) => ColDef;
  rows: UnknownRecord[];
  agGridConfig: Partial<GridOptions>;
}): (ColDef | ColGroupDef)[] => {
  if (columns.length === 0 || rows.length === 0) {
    return getFakeColumnDefs(
      columns.map((c) => ({
        headerName: c.label,
        cellRendererParams: {
          classes: {
            wrapper: DEFAULT_GROUP_BG_CLASS,
          },
        },
      })),
      rows.length,
    );
  }
  const { columnDefs } = agGridConfig;
  if (columnDefs?.some(isGroupColDef)) {
    const colGroupDefs = columnDefs?.filter(isGroupColDef);
    const mappedColGroupDefs = colGroupDefs.map((cg: ColGroupDef) => {
      const children = cg.children.map((c: ColDef) => {
        const column = findColumnByColDef(columns, c);

        return {
          ...(column && columnToColDefMapper(column, cg)),
          ...c,
        };
      });
      return {
        ...cg,
        groupId: cg.groupId && `group-${cg.groupId?.toString()}`,
        children,
      };
    });

    return [
      ...getUngroupedColumns(colGroupDefs, columns).map((c) => ({
        ...columnToColDefMapper(c),
        ...columnDefs.find((cd: ColDef) => cd.colId === c.key.toString()),
      })),
      ...addDividersToColumnGroups(mappedColGroupDefs),
    ];
  }
  return [...columns.map(columnToColDefMapper)];
};
