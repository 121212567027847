import { IJobCostCode } from 'types/JobCostCode';
import { ISharedDocument } from 'types/SharedDocument';
import { CamelToSnakeCase } from 'types/StringUtilityTypes';
import { ISortParams } from 'types/Sort';
import {
  TApprover,
  TPlacementKinds,
} from 'bundles/Construction/components/eSignature/types';
import {
  IFee,
  IMarkup,
} from 'bundles/Construction/components/ChangeOrderEvent/CostBreakdownDetails/types';

export type ThresholdCategory = 'COR' | 'AGG' | 'TIM';

export type TChangeOrderStatus =
  | 'PENDING'
  | 'PENDING-PROCEEDING'
  | 'PENDING-NOT PROCEEDING'
  | 'DRAFT'
  | 'VOID'
  | 'REJECTED'
  | 'DISPUTED'
  | 'APPROVED'
  | 'COMPLETED';

export type CESortFieldType =
  | CamelToSnakeCase<keyof IChangeOrderEvent>
  | 'category_number';

export interface IDrawPackagePageParams {
  sortField?: CESortFieldType;
  sortOrder?: 'asc' | 'desc';
  page?: number;
  per_page?: number;
  amount?: {
    from: string;
    to: string;
  };
}

export interface IPageParams {
  sortField?: CESortFieldType;
  sortOrder?: 'asc' | 'desc';
  page?: number;
  per_page?: number;
  statusList?: TChangeOrderStatus[];
  categoryList?: IChangeOrderEvent['category'][];
  reasonList?: string[];
  amount?: {
    from: string;
    to: string;
  };
}

export interface IFetchChangeOrderEventsParams {
  page?: number;
  per_page?: number;
  categories?: IChangeOrderEvent['category'][];
  reasons?: string[];
  statuses?: TChangeOrderStatus[];
  sort?: ISortParams<CESortFieldType>;
  values?: {
    from?: string;
    to?: string;
  };
}
export interface IReallocationDrilldownSovc
  extends Pick<IScheduleOfValueCode, 'id' | 'code' | 'name'> {
  amount: Integer | RawFloat;
}

interface IReallocationDrilldownJobCostCode extends IJobCostCode {
  sovcs: IReallocationDrilldownSovc[];
}

export interface IReallocation {
  id: number;
  approvedAt: number;
  number: number;
  value: string;
  updatedAt: number;
  requisitionNumber: number;
  approvedBy: string;
  assetId: number;
  createdAt: number;
  movedFrom: IReallocationDrilldownJobCostCode;
  movedTo: IReallocationDrilldownJobCostCode;
  title: string;
  inputType: 'manual' | 'auto';
  description: string;
  status: TChangeOrderStatus;
  contingencyImpact: any; // TODO found out type
  reallocationId: number | null;
  sharedFiles: ISharedDocument[];
  documents: ISharedDocument[];
  changeOrderEvent: IChangeOrderEvent;
  changeOrderNumber: number;
  changeOrder: IChangeOrder;
  children: IReallocation[];
}

export interface ICustomerConstructionConfig {
  id: number;
  ceESignatureAvailable: boolean;
  ceESignatureEnabled: boolean;
  coESignatureAvailable: boolean;
  coESignatureEnabled: boolean;
  dashboardVisibleAssetIds: number[];
  payAppsAvailable: boolean;
  multipleReasonsEnabled: boolean;
  changeOrderConfig: IChangeOrderConfig[];
  changeOrderReasons: IChangeOrderReason[];
  companies: string[];
  contacts: string[];
}

export interface IChangeOrderReason {
  id: string;
  category: string;
  name: string;
  categoryWithName: string;
  active: boolean;
}

export interface IScheduleOfValueCode {
  id: string;
  code: string;
  name: string;
  codeName: string;
  jobCostCode: IJobCostCode;
}

export interface IChangeOrderEventVerificationWorkCost {
  id: string;
  amount: string;
  comment: string;
  scheduleOfValueCode: IScheduleOfValueCode;
  changeOrderReason: IChangeOrderReason;
}

export interface IChangeOrderFee {
  id: string;
  code: string;
  name: string;
  codeName: string;
  percent: string;
  assetConfigId: string;
}

export interface IChangeOrderMarkup {
  id: string;
  code: string;
  name: string;
  codeName: string;
  percent: string;
  includedInFee: boolean;
  assetConfigId: string;
}

export interface IChangeOrderEventVerificationFee {
  id: string;
  amount: string;
  comment: string;
  changeOrderFee: IChangeOrderFee;
}

export interface IChangeOrderEventVerificationMarkup {
  id: string;
  amount: string;
  comment: string;
  changeOrderFee: IChangeOrderMarkup;
}

export interface IChangeOrderEventVerification {
  status: string;
  contractFileId: number;
  eSign: IESignCoordinate[];
  substantialCompletionDate: any;
  finalized: boolean;
  workCosts: IChangeOrderEventVerificationWorkCost[];
  fees: IChangeOrderEventVerificationFee[];
  markups: IChangeOrderEventVerificationMarkup[];
}

export interface IESignCoordinate {
  id: string;
  x: number;
  y: number;
  pageIndex: number;
  pageWidth: number;
  pageHeight: number;
  approverId: number;
  kind: TPlacementKinds;
}

export interface IContractFile {
  id: string;
  fileUrl: string;
  url: string;
  esignPlacements: IESignCoordinate[];
}

export interface IHistoryEvent {
  id: number;
  createdAt: number;
  createdBy: string;
  documents?: File[];
  eventComment?: string;
  kind: string;
  title: string;
}

export interface IChangeEvent {
  id: number;
  amount: Float;
  approvedDate: number;
  title: string;
  availableActions: Array<string> | null;
  availableStatuses: TChangeOrderStatus[];
  category: 'PCO' | 'COR' | 'IRC';
  changeOrder: {
    id: number;
    number: number;
  };
  code: string;
  contractFileId?: string;
  contractorDate: number;
  costBreakdownConfirmed: boolean;
  description: string;
  displayNumber: string;
  documents: File[];
  fees: IFee[];
  historyEvents: IHistoryEvent[];
  initialAmount: string;
  lastVerifiedSubstantialCompletionDate: number;
  legalEntityCode: string;
  markups: IMarkup[];
  number: number;
  probability: number;
  probabilityComment: string;
  promoted: boolean;
  reallocation: IReallocation;
  reasons: IReason[];
  status: TChangeOrderStatus;
  receivedDate: number;
  sentToDesignerOfRecordDate: number;
  reconcileCompany: ICompany;
  reconcileContact: IContact;
  startedApprovalAt: string;
  weightedAmount: Float;
  verified: boolean;
  revision: number;
  esignEnabled: boolean;
}

export interface IChangeOrderEventChangeOrder {
  id: number;
  number: number;
  approvedDate: string;
}

export interface WithChangeOrderReasons {
  changeOrderReasons: IChangeOrderReason[];
}

export interface IChangeOrderEvent {
  changeOrder: IChangeOrderEventChangeOrder;
  documents: any[];
  id: number;
  idle: boolean;
  title: string;
  category: 'PCO' | 'COR' | 'IRC';
  changeOrderReasons: IChangeOrderReason[];
  description: string;
  reconcileCompany: ICompany;
  reconcileContact: IContact;
  number: number;
  displayNumber: string;
  contractorDate: number;
  receivedDate: number;
  sentToDesignerOfRecordDate: number;
  status: TChangeOrderStatus;
  value: number;
  probability: number;
  probabilityComment: string;
  sharedFiles: any[];
  reallocation: IReallocation | null;
  weightedAmount: number;
  initialValue: number;
  verified: boolean;
  availableActions: Array<string> | null;
  internal: boolean;
  revision: number;
  code: string;
  changeOrderEventVerification: IChangeOrderEventVerification | null;
  changeOrderId: string | null;
  customerConstructionConfig: ICustomerConstructionConfig;
  assetConfig: IAssetConfig;
  threshold: IApproverThreshold;
  startedApprovalAt: string;
  approvedDate: number;
  legalEntityCode: string;
  assetName: string;
  substantialCompletionDate: string;
}

export interface IChangeOrderConfig {
  id: number;
  name: string;
  scheduleOfValueCodes: IScheduleOfValueCode[];
}

export interface IApproverThreshold {
  id: number;
  number: number;
  value: number;
  assetConfigId: number;
  internalApproverId: number;
  externalApproverIds: number[];
  category: ThresholdCategory;
  categoryMessage: string;
  approverIds: number[];
}

export interface IAssetConfig {
  id: number;
  assetId: number;
  changeOrderConfigId: number;
  hardConstructionCost: string;
  hardCostContingency: string;
  siteworkContingency: string;
  summaryWithProjected: string;
  summaryWithInternal: string;
  completedAndStored: string;
  retainage: string;
  previousCertifiedWorks: string;
  requireCorApproval: boolean;
  requireOcoApproval: boolean;
  budgetsUpdatedAt: string;
  corApprovalWorkflowEnabled: boolean;
  ceESignatureEnabled: boolean;
  coESignatureEnabled: boolean;
  requireAggregateEventsApproval: boolean;
  requireTimingApproval: boolean;
  contractorRetainageValue: string;
  hardConstructionCostCode: string;
  hardCostContingencyCode: string;
  softCostContingencyCode: string;
  hardConstructionCostLastChangedBy: string;
  hardCostContingencyLastChangedBy: string;
  siteworkContingencyLastChangedBy: string;
  completedAndStoredLastChangedBy: string;
  retainageLastChangedBy: string;
  previousCertifiedWorksLastChangedBy: string;
  hardConstructionCostLastChangedAt: string;
  hardCostContingencyLastChangedAt: string;
  siteworkContingencyLastChangedAt: string;
  completedAndStoredLastChangedAt: string;
  retainageLastChangedAt: string;
  previousCertifiedWorksLastChangedAt: string;
  changeOrderConfig: IChangeOrderConfig;
  approverThresholds: IApproverThreshold[];
  changeOrderMarkups: IChangeOrderMarkup[];
  changeOrderFees: IChangeOrderFee[];
  initialEstimatedCompletionDate: string;
  lockIndividualCorAmountThresholdChange: boolean;
  lockAggregateEventsApprovalThresholdChange: boolean;
  lockTimingThresholdChange: boolean;
}

export interface IChangeOrderEventLog {
  id?: number;
  createdAt: string;
  createdBy: string;
  data: Date;
  sharedFiles: ISharedDocument[] | 'YES';
  eventComment: string;
  kind: string;
  historyDetails: any;
}

export interface IChangeOrderVerification {
  id: number;
  changeOrderId: number;
  contractFileId: string;
  finalized: true;
  newContractSum: string;
  pendingCoAmount: string;
  priorContractSum: string;
  status: TChangeOrderStatus;
}

export interface IChangeOrder {
  contractFileId: string;
  signaturePlacementsComplete: boolean;
  documents: ISharedDocument[];
  id: number;
  legalEntityCode: string;
  earliestDraftCo: {
    id: number;
    number: number;
  };
  approvers: TApprover[];
  amount: string;
  approvedAt: DateString;
  assetId: number;
  idle: boolean;
  inReallocation: number;
  coDate: number;
  status: TChangeOrderStatus;
  architectApprovedDate: number;
  clientApprovedDate: number;
  number: number;
  changeOrderEventIds: IChangeOrderEvent[];
  changeOrderEvents: IChangeOrderEvent[];
  value: number;
  receivedDate: string;
  description: string;
  sharedFiles: ISharedDocument[];
  timingImpact: number;
  timingImpactDays: number;
  lastVerifiedSubstantialCompletionDate: string;
  substantialCompletionDate: string;
  lastVerifiedContractSum: string;
  projectedPriorContractSum: string;
  reallocationDate: number | null;
  reallocationId: number | null;
  updatedAt: number;
  esignPlacement: object;
  canApprove?: boolean;
  date?: UnixTime;
  daysAwaitingApproval?: number;
  historyEvents?: IHistoryEvent[];
}

export enum TContingencyCategory {
  Used = 'USED',
  Pending = 'PENDING',
  IRC = 'IRC',
  Remaining = 'REMAINING',
}

export interface IChangeOrderEventStatisticsByStatus {
  approvedPercentOfContingency: string;
  approvedTotal: string;
  completedPercentOfContingency: string;
  completedTotal: string;
  draftPercentOfContingency: string;
  draftTotal: string;
  pendingPercentOfContingency: string;
  pendingTotal: string;
}

interface IChangeOrders {
  id: number;
  number: number;
  timingImpactDays: number;
}

export interface IChangeOrderEventStatisticsByTiming {
  days: number;
  changeOrders: IChangeOrders[];
  substantialCompletionDate: number;
}

export type TFilterCardType = 'completed' | 'approved' | 'pending' | 'irc';

export enum DefaultTableBlocks {
  KEY_INFO = 'keyInfo',
  ADDITIONAL_DETAILS = 'additionalDetails',
  FINANCIAL_IMPACT = 'financialImpact',
}

export enum CostBreakDownBlocks {
  WORK_COSTS = 'workCosts',
  CONTRACTOR_MARKUPS = 'contractorMarkups',
  CONTRACTOR_FEES = 'contractorFees',
}

export enum CostBreakDownTHeads {
  REASON = 'REASON',
  COST_CODE = 'COST CODE',
  AMOUNT = 'AMOUNT',
  COMMENT = 'COMMENT',
}

export enum CostBreakDownCellKeys {
  REASON = 'reason',
  COST_CODE = 'schedule_of_value_code',
  AMOUNT = 'amount',
  COMMENT = 'comment',
}

export enum CostBreakDownFooters {
  TOTAL_COST_OF_WORK = 'Total Cost of Work',
  TOTAL_MARKUPS = 'Total Marcups',
  TOTAL_FEES = 'Total Fees',
}

interface ICostBreakDownTableParts {
  tHeads: string[];
  cellKeys: string[];
  totalAmountText: string;
}

export type TCostBreakdownTable = {
  [key in CostBreakDownBlocks]: ICostBreakDownTableParts;
};

export enum HistoryStatusesWithDetails {
  UPDATE = 'update',
  STATUS_CHANGE = 'status_changed',
  REVISE = 'revise',
  REJECT = 'reject',
  PROMOTE = 'promote',
  VERIFY = 'verify',
  COST_BREAKDOWN_CONFIRMED = 'cost_breakdown_confirmed',
  APPROVED_EMAIL = 'approved_email',
}

interface IHistoryDetailsModalHeader {
  iconName: string;
  iconColor: string;
  title: string;
}

export type TModalHeader = {
  [key in HistoryStatusesWithDetails]: IHistoryDetailsModalHeader;
};

interface ICostBreakdownValueItem {
  id: number;
  amount: string;
  reason: string;
  comment: string;
  schedule_of_value_code: string;
}

interface ICostBreakdownAttribute {
  attribute: 'workCosts' | 'contractorMarkups' | 'contractorFees';
  title: string;
  value: {
    old?: ICostBreakdownValueItem[];
    current: ICostBreakdownValueItem[];
  };
}

interface IDetailAttribute {
  attribute: string;
  title: string;
  value: {
    old?: string | number | string[];
    current: string | number | string[];
  };
}

export interface IEventDetails {
  id: number;
  createdAt: number;
  createdBy: string;
  kind: `${HistoryStatusesWithDetails}`;
  event_comment?: string;
  historyDetails: {
    costBreakdown?: ICostBreakdownAttribute[];
    keyInfo?: IDetailAttribute[];
    additionalDetails?: IDetailAttribute[];
    financialImpact?: IDetailAttribute[];
  };
  constructionWorkflowEvent?: {
    asset: {
      id: number;
      name: string;
      objectType: string;
      offering: boolean;
      slug: string;
      termsAccepted: boolean;
    };
    createdAt: number;
    emailMessages: object[];
    eventType: string;
    subject: string;
    targetable?: {
      id: number;
      title: string;
    };
  };
}

export interface IHardCostCode {
  id: number;
  code: string;
  fullName: string;
  name: string;
}

export interface IReason {
  id: number;
  name: string;
  category: string;
  active: boolean;
}

export interface ICompany {
  id: number;
  code: string;
  name: string;
}

export interface IContact {
  id: number;
  code: string;
  name: string;
}
