import { produce } from 'immer';
import {
  KpiTableSingleDateWidgetConfig,
  KpiTableSingleDateWidgetConfigColumnForm,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate';
import {
  maxIdGenerator,
  transformPeriodShiftFormToDto,
  transformPeriodTypeFormToDto,
  upsertColumnSettings,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { isEmpty } from 'lodash-es';
import { KpiTableBulkGroupForm } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/bulk-group.form';
import { TableVizConfig } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  buildColumnGroupsMap,
  moveColumn,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { KpiTableSingleDateWidgetConfigDto } from 'bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { KpiTableWidgetSingleDateConfigForm } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/component.form';

export const upsertColumn = (
  column: KpiTableSingleDateWidgetConfigColumnForm,
  config: KpiTableSingleDateWidgetConfig,
  {
    groupId,
  }: {
    groupId?: string;
  },
) => {
  return produce(config, (draft) => {
    let columnIndex = draft.columns.findIndex((c) => c.key === column?.key);
    if (columnIndex === -1) {
      columnIndex = draft.columns.length;
    }

    draft.columns[columnIndex] = {
      label: column.label,
      expression: column.expression,
      period_shift: transformPeriodShiftFormToDto(
        column.period_shift ?? undefined,
      ),
      period_type: transformPeriodTypeFormToDto(column.period_type),
      key: column?.key ?? maxIdGenerator(draft.columns, 'key'),
      value_display_options: column.value_display_options,
      total_calculation_strategy:
        column.total_calculation_strategy ?? undefined,
    };
    if (!isEmpty(column.adjustment)) {
      draft.columns[columnIndex].adjustment_expression = column.adjustment;
    }
    const colId = draft.columns[columnIndex].key.toString();
    upsertColumnSettings(column, draft, {
      key: colId,
      groupId,
    });
  });
};

export const bulkUpdateGroup = (
  groupId: string,
  groupForm: KpiTableBulkGroupForm,
  config: KpiTableSingleDateWidgetConfig,
) => {
  return produce(config, (draft) => {
    const groupIndex = draft.viz_config.column_groups.findIndex(
      (g) => g.group_id === groupId,
    );
    if (groupIndex === -1) {
      return;
    }
    draft.viz_config.column_groups[groupIndex].child_can_override_period =
      !groupForm.can_override ?? false;

    draft.viz_config.columns
      .filter((c) => c.group_id === groupId)
      .forEach((draftC) => {
        draftC.header = groupForm.header;
        const columnConfig = draft.columns.find(
          (col) => col.key.toString() === draftC.key,
        );
        if (columnConfig) {
          columnConfig.period_shift = transformPeriodShiftFormToDto(
            groupForm.period_shift,
          );
          columnConfig.period_type = transformPeriodTypeFormToDto(
            groupForm.period_type,
          );
        }
      });
  });
};

export const findFirstColumnSettingsInGroup = <
  T extends {
    key: number;
  },
>({
  groupId,
  viz_config,
  columnsConfig,
}: {
  groupId: string;
  viz_config: TableVizConfig;
  columnsConfig: T[];
}) => {
  const groupColumns = viz_config.columns.filter((c) => c.group_id === groupId);
  const [firstColumnSettingsInGroup] = groupColumns;

  const firstColumn = columnsConfig.find(
    (c) => c.key.toString() === firstColumnSettingsInGroup?.key,
  )!;

  return {
    firstColumnSettingsInGroup,
    firstColumn,
  } as const;
};

export const moveColumnAndSyncGroup = (
  moveArg: Parameters<typeof moveColumn>[0],
  config: KpiTableSingleDateWidgetConfig,
) => {
  let updatedConfig = config;
  const columnGroup = config.viz_config.column_groups.find(
    (g) => g.group_id === moveArg.targetGroupId,
  );
  if (
    columnGroup != null &&
    moveArg.targetGroupId !== moveArg.sourceGroupId &&
    config.viz_config.columns.filter(
      (c) => c.group_id === moveArg.targetGroupId,
    ).length > 0 &&
    columnGroup.child_can_override_period === false
  ) {
    updatedConfig = produce(config, (draft) => {
      const groupedColumns = buildColumnGroupsMap(draft.viz_config.columns);
      const columnSettings =
        groupedColumns[moveArg.sourceGroupId][moveArg.fromIndex];
      const columnConfig = draft.columns.find(
        (c) => c.key.toString() === columnSettings.key,
      );
      const { firstColumnSettingsInGroup, firstColumn } =
        findFirstColumnSettingsInGroup({
          groupId: moveArg.targetGroupId,
          viz_config: draft.viz_config,
          columnsConfig: draft.columns,
        });

      if (!firstColumnSettingsInGroup || !firstColumn) {
        return;
      }

      columnSettings.header = firstColumnSettingsInGroup.header;
      columnSettings.value_display_options = firstColumn.value_display_options;

      if (columnConfig) {
        columnConfig.period_shift = firstColumn.period_shift;
        columnConfig.period_type = firstColumn.period_type;
      }
    });
  }
  return moveColumn(moveArg, updatedConfig);
};

export const updateDefaultOptions = (
  values: KpiTableWidgetSingleDateConfigForm,
  widgetConfig: KpiTableSingleDateWidgetConfigDto,
): KpiTableSingleDateWidgetConfigDto => {
  return produce(widgetConfig, (draft) => {
    draft.default_options.date = {
      period_shift: transformPeriodShiftFormToDto(values.period_shift)!,
    };
    draft.hide_total_row = values.hide_total_row ?? undefined;
  });
};
