import React, { useCallback, useEffect } from 'react';
import {
  FORMULA_KEYWORDS,
  FORMULA_LANGUAGE_ID,
  FORMULA_LANGUAGE_THEME,
  setupMonaco,
} from '../config';
import { groupBy } from 'lodash-es';
import {
  FormulaInvalidReference,
  ShowAutocompletion,
} from 'bundles/Shared/entities/formula';
import styles from 'bundles/Shared/entities/formula/ui/ExpressionEditor.module.scss';
import { cn } from '@/shared/lib/css/cn';
import { CodeEditor } from '@/shared/ui/CodeEditor/component';
import { useCodeEditorRef } from '@/shared/ui/CodeEditor/lib';

interface Props
  extends Omit<React.ComponentProps<typeof CodeEditor>, 'language' | 'theme'> {
  suggestions?: string[];
  errors?: FormulaInvalidReference[];
}

const DEFAULT_OPTIONS = {
  wordBasedSuggestions: false,
  wordWrap: true,
  fontSize: 14,
  fontWeight: 500,
  fontFamily: 'Poppins',
  lineNumbersMinChars: 2,
  minimap: {
    enabled: false,
  },
};

export function ExpressionEditor({
  suggestions,
  errors,
  onChange,
  options,
  ...props
}: Props) {
  const codeEditorRef = useCodeEditorRef();

  useEffect(() => {
    const suggestionsNamespaces = (suggestions ?? []).map((suggestion) =>
      suggestion.split('.'),
    );
    const groupedByNamespace = groupBy(
      suggestionsNamespaces,
      (suggestion) => suggestion[0],
    );

    const suggestionsEntries = Object.entries(groupedByNamespace).map(
      ([key, value]) => ({
        [key]: Object.fromEntries(
          value.map((suggestion) => {
            return [suggestion[1], null];
          }),
        ),
      }),
    );
    const suggestionsObject = Object.assign({}, ...suggestionsEntries);

    const completionProviderHandle =
      codeEditorRef.current?.languages.registerCompletionItemProvider(
        FORMULA_LANGUAGE_ID,
        {
          triggerCharacters: ['.'],
          provideCompletionItems: (model, position, token) => ({
            suggestions: [...FORMULA_KEYWORDS]
              .map((keyword) => ({
                label: keyword,
                kind: 17, // monaco.languages.CompletionItemKind.Keyword
                insertText: keyword,
              }))
              .concat(
                // eslint-disable-next-line new-cap
                ShowAutocompletion(suggestionsObject, codeEditorRef.current)(
                  model,
                  position,
                  token,
                ),
              ),
          }),
        },
      );

    return () => {
      completionProviderHandle?.dispose();
    };
  }, [suggestions]);

  const validateValue = useCallback(
    (editor, monaco) => {
      const model = editor.getModel();

      const textToValidate: string = model.getValue();

      const markers = errors
        ?.map((error) => {
          return [...textToValidate.matchAll(RegExp(error.reference, 'g'))].map(
            (match) => {
              return {
                startLineNumber: 1,
                severity: codeEditorRef.current.MarkerSeverity.Error,
                startColumn: match.index + 1,
                endColumn: match.index + match[0].length + 1,
                message: error,
                endLineNumber: 1,
              };
            },
          );
        })
        .flat();

      // change mySpecialLanguage to whatever your language id is
      monaco.editor.setModelMarkers(model, FORMULA_LANGUAGE_ID, markers);
    },
    [errors],
  );

  return (
    <CodeEditor
      className={cn('overflow-hidden rounded-lg', styles.formulaEditor)}
      theme={FORMULA_LANGUAGE_THEME}
      language={FORMULA_LANGUAGE_ID}
      beforeMount={(monaco) => {
        codeEditorRef.current = monaco;
        setupMonaco(monaco);
      }}
      onChange={(value, e) => {
        onChange?.(value, e);
      }}
      onMount={(editor, monaco) => {
        validateValue(editor, monaco);
        editor.onDidChangeModelContent(() => {
          validateValue(editor, monaco);
        });
      }}
      options={{
        ...DEFAULT_OPTIONS,
        ...options,
      }}
      {...props}
    />
  );
}
