import {
  selectReportTableConfig,
  selectSelectedLegalEntities,
  updateFilterByPeriodsType,
  updatePeriodItems,
} from '@/bundles/REport/reducers/financialsSlice';
import { LEFT_POSITIONED_MODAL_Z_INDEX } from '@/bundles/Shared/constants';
import { cn } from '@/shared/lib/css/cn';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import dayjs from 'dayjs';
import { groupBy } from 'lodash-es';
import { ComponentProps, useMemo } from 'react';
import { PossiblePeriodsGroupedByYear } from 'stories/FlexibleFilterByPeriods/types';
import {
  AnimationLoader,
  Button,
  FlexibleFilterByPeriods,
} from 'stories/index';
import { resolveDefaultPeriodItems } from '../utils';

export function useDatePickerFlagsAndProps() {
  const dispatch = useAppDispatch();
  const reportTableConfig = useAppSelector(selectReportTableConfig);
  const selectedLegalEntities = useAppSelector(selectSelectedLegalEntities);
  const financialsSlice = useAppSelector((state) => state.financials);
  const {
    periodItems,
    possiblePeriods,
    filterByPeriodsType,
    isFullHistoryActive,
  } = financialsSlice;

  const isPossiblePeriodsEmpty = possiblePeriods.length === 0;
  const isLegalEntitiesEmpty = selectedLegalEntities.length === 0;

  const defaultPeriodItems = useMemo(() => {
    const groupedPossiblePeriods = groupBy(possiblePeriods, (period) =>
      dayjs(period).year(),
    ) as PossiblePeriodsGroupedByYear;
    const { periodsType, withOnlyBudgetColumns } = reportTableConfig;
    return resolveDefaultPeriodItems({
      periodsType,
      groupedPeriods: groupedPossiblePeriods,
      withOnlyBudgetColumns,
    });
  }, [reportTableConfig, possiblePeriods]);

  const filterByPeriodsProps = {
    buttonProps: {
      size: 's',
    },
    disableUpdatePeriodItemsOnFilterByPeriodsTypeUpdate: isFullHistoryActive,
    periodItems,
    possiblePeriods,
    defaultPeriodItems,
    filterByPeriodsType,
    onUpdatePeriodItems: (arr) => dispatch(updatePeriodItems(arr)),
    onUpdateFilterByPeriodsType: (type) =>
      dispatch(updateFilterByPeriodsType(type)),
    noPossiblePeriodsCase: {
      label: 'No data',
      active: isPossiblePeriodsEmpty || isLegalEntitiesEmpty,
    },
    popoverProps: {
      zIndex: LEFT_POSITIONED_MODAL_Z_INDEX - 1,
      placement: 'top-start',
    },
  } satisfies ComponentProps<typeof FlexibleFilterByPeriods>;

  return {
    filterByPeriodsProps,
    reportTableConfig,
  };
}

export function FinancialsDatePicker({
  possiblePeriodsFetching,
}: {
  possiblePeriodsFetching: boolean;
}) {
  const { filterByPeriodsProps, reportTableConfig } =
    useDatePickerFlagsAndProps();

  if (possiblePeriodsFetching)
    return (
      <Button
        variant="secondary"
        className={cn('min-w-[120px] font-medium leading-none text-info-055')}
        {...filterByPeriodsProps.buttonProps}
      >
        <AnimationLoader
          className="static"
          classNameWrapper="w-[16px] h-[16px] bg-transparent"
        />
      </Button>
    );

  switch (reportTableConfig.periodsType) {
    case 'total':
      return (
        <FlexibleFilterByPeriods
          {...filterByPeriodsProps}
          fromToMode
          isSingleMonthSelection
        />
      );

    case 'variance_report':
    case 'single_mtd':
    case 'single_mtd_and_ytd':
    case 'single_mtd_qtd_and_ytd':
    case 'single_mtd_qtd_and_itd':
    case 'single_mtd_qtd_and_uw_itd':
    case 'single_qtd_and_ytd':
    case 'single_qtd_ytd_and_itd':
    case 'single_qtd_ytd_and_uw_itd':
    case 'single_t3_and_t12':
      return (
        <FlexibleFilterByPeriods
          {...filterByPeriodsProps}
          isSingleMonthSelection
        />
      );

    default:
      return <FlexibleFilterByPeriods {...filterByPeriodsProps} />;
  }
}
