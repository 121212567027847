/* eslint-disable max-len */
import JCCForReallocations from 'bundles/Construction/reducers/JCCReducer';
import notifications from 'bundles/Notifications/reducers/NotificationsReducer';
import budgets from 'bundles/REconcile/reducers/budgetsReducer';
import developmentBudget from 'bundles/REconcile/reducers/developmentBudgetSlice';
import developmentBudgetVariance from 'bundles/REconcile/reducers/developmentBudgetVarianceSlice';
import developmentLegalEntities from 'bundles/REconcile/reducers/developmentLegalEntitiesSlice';
import userRoles from 'bundles/UserManagement/reducers/userRolesSlice';
import { combineReducers } from 'redux';

import { genericSlice } from '@/app/reducers/genericSlice';
import { emptySplitApi } from '@/app/stores/api';
import financialsSliceReducer from '@/bundles/REport/reducers/financialsSlice';
import { comparisonDashboardSlice } from '@/bundles/Shared/entities/dashboard/model/slices/comparisonSlice';
import { widgetFullscreenSlice } from '@/bundles/Shared/widgets/dashboard/widgets/common/model/widgetFullscreenSlice';
import {
  impersonationApi,
  impersonationSlice,
} from '@/entities/impersonation/api';
import { reportWidgetsSlice } from '@/entities/report/reportBuilder';
import reportBuilderSliceReducer from '@/entities/report/reportBuilder/model/slices/reportBuilderSlice';
import { capitalGeneralLedgersApi } from '@/entities/return/api/capitalGeneralLedgersApi';
import { objectFoldersApi } from 'bundles/Assets/components/Media/api/objectFoldersApi';
import assetsSlice from 'bundles/Assets/reducers/AssetsSlice';
import changeOrderConfigs from 'bundles/Assets/reducers/ChangeOrderConfigsReducer';
import customerConfig from 'bundles/Assets/reducers/CustomerConfigReducer';
import sharedFiles from 'bundles/Assets/reducers/asset/FileTrackerReducer';
import { coContractFileApi } from 'bundles/Construction/api/coContractFile';
import { reconcileCompanies } from 'bundles/Construction/api/companies';
import { reconcileCoreApi } from 'bundles/Construction/api/core';
import { reconcileInvoiceApi } from 'bundles/Construction/api/invoices';
import { reconcileLegalEntityProjectSummaryApi } from 'bundles/Construction/api/projectSummary';
import changeOrderManagement from 'bundles/Construction/reducers/ChangeOrderManagementReducer';
import construction from 'bundles/Construction/reducers/ConstructionReportReducer';
import payApps from 'bundles/Construction/reducers/PayAppReducer';
import reallocations from 'bundles/Construction/reducers/ReallocationReducer';
import changeEvent from 'bundles/Construction/reducers/ReconcileChangeEventSlice';
import reallocation from 'bundles/Construction/reducers/ReconcileReallocationSlice';
import reconcile from 'bundles/Construction/reducers/ReconcileSlice';
import reconcileInvoiceState from 'bundles/Construction/reducers/reconcileInvoiceStateSlice';
import usersForReconcileSettings from 'bundles/Construction/reducers/userReducer';
import draws from 'bundles/DrawPackage/slices/ReconcileDrawSlice';
import invoices from 'bundles/DrawPackage/slices/ReconcileInvoicesSlice';
import { feedMessagesApi } from 'bundles/InvestmentObjects/components/Overview/Posts/api/feedMessagesApi';
import { reconcileChangeOrderApproversApi } from 'bundles/REconcile/components/development/api/changeOrderApprovers';
import { reconcileDevelopmentLegalEntitiesApi } from 'bundles/REconcile/components/development/api/legalEntities';
import { reconcileLineItemApi } from 'bundles/REconcile/components/development/api/lineItems';
import { reconcileDevelopmentProjectsApi } from 'bundles/REconcile/components/development/api/projects';
import { reconcileSignatureFieldsApi } from 'bundles/REconcile/components/development/api/signatureFields';
import { reconcileDevelopmentSnapshotSettingsApi } from 'bundles/REconcile/components/development/api/snapthotSettings';
import { reconcileRequisitionTransactionsApi } from 'bundles/REconcile/components/development/api/transactions';
import { reconcileFundingSourcesApi } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/api/reconcileFundingSourcesApi';
import requisitionsTableSlice from 'bundles/REconcile/components/development/requisitions/shared/requisitionsTable/slices/RequisitionsTableSlice';
import { reconcileOperationalBudgetsApi } from 'bundles/REconcile/components/operational/api/legalEntities';
import developmentLegalEntity from 'bundles/REconcile/reducers/developmentLegalEntitySlice';
import underwritingBudgetSlice from 'bundles/REconcile/underwritting/ctx/underwritingBudgetSlice';
import { reportFinancialsApi } from 'bundles/REport/api/financialsApi';
import returnInvestmentObject from 'bundles/REturn/reducers/investmentObjectSlice';
import deals from 'bundles/Scoreboard/Pipeline/reducers/MyDealsReducer';
import { coreAssetsApi } from 'bundles/Settings/components/Portal/AssetDashboard/api/coreAssetsApi';
import coreEntityReducer from 'bundles/Settings/components/Portal/AssetDashboard/api/coreEntitySlice';
import { coreFundsApi } from 'bundles/Settings/components/Portal/FundDashboard/api/coreFundsApi';
import { investmentEntitiesApi } from 'bundles/Settings/components/Portal/InvestmentEntities/api/investmentEntitiesApi';
import { settingsCoreLegalEntityActivityLogsApi } from 'bundles/Settings/components/Portal/LegalEntities/api/settingsCoreLegalEntityActivityLogsApi';
import dealPathSettings from 'bundles/Settings/reducers/dealPathSettingsReducer';
import leftSidebar from 'bundles/Shared/components/LeftSidebar/reducers/leftSidebarSlice';
import {
  objectDashboardSlice,
  reportDashboardSettingsSlice,
} from 'bundles/Shared/entities/dashboard';
import { formulasSettingsSlice } from 'bundles/Shared/entities/formula/model';
import userActivitySlice from 'bundles/Shared/entities/user/slices/userActivitySlice';
import { settingsCoreTagsApi } from 'bundles/UserManagement/api/settingsCoreTagsApi';
import { settingsCoreUsersApi } from 'bundles/UserManagement/api/settingsCoreUsersApi';
import userManagement from 'bundles/UserManagement/reducers/UsersReducer';

const reconcileReducers = {
  [reconcileInvoiceApi.reducerPath]: reconcileInvoiceApi.reducer,
  [reconcileLineItemApi.reducerPath]: reconcileLineItemApi.reducer,
  [reconcileCoreApi.reducerPath]: reconcileCoreApi.reducer,
  [reconcileRequisitionTransactionsApi.reducerPath]:
    reconcileRequisitionTransactionsApi.reducer,
  [reconcileDevelopmentProjectsApi.reducerPath]:
    reconcileDevelopmentProjectsApi.reducer,
  [reconcileChangeOrderApproversApi.reducerPath]:
    reconcileChangeOrderApproversApi.reducer,
  [reconcileSignatureFieldsApi.reducerPath]:
    reconcileSignatureFieldsApi.reducer,
  [coContractFileApi.reducerPath]: coContractFileApi.reducer,
  [reconcileDevelopmentSnapshotSettingsApi.reducerPath]:
    reconcileDevelopmentSnapshotSettingsApi.reducer,
  [reconcileDevelopmentLegalEntitiesApi.reducerPath]:
    reconcileDevelopmentLegalEntitiesApi.reducer,
  [reconcileLegalEntityProjectSummaryApi.reducerPath]:
    reconcileLegalEntityProjectSummaryApi.reducer,
  [reconcileLegalEntityProjectSummaryApi.reducerPath]:
    reconcileLegalEntityProjectSummaryApi.reducer,
  [reconcileFundingSourcesApi.reducerPath]: reconcileFundingSourcesApi.reducer,
  [reconcileCompanies.reducerPath]: reconcileCompanies.reducer,
  [reconcileOperationalBudgetsApi.reducerPath]:
    reconcileOperationalBudgetsApi.reducer,
  [emptySplitApi.reducerPath]: emptySplitApi.reducer,
};

const reportReducers = {
  [reportFinancialsApi.reducerPath]: reportFinancialsApi.reducer,
  reportComparisonDashboard: comparisonDashboardSlice.reducer,
  reportDashboard: objectDashboardSlice.reducer,
  reportDashboardSettings: reportDashboardSettingsSlice.reducer,
  reportWidgets: reportWidgetsSlice.reducer,
};

const coreReducers = {
  [coreAssetsApi.reducerPath]: coreAssetsApi.reducer,
  [coreFundsApi.reducerPath]: coreFundsApi.reducer,
  [objectFoldersApi.reducerPath]: objectFoldersApi.reducer,
  [settingsCoreTagsApi.reducerPath]: settingsCoreTagsApi.reducer,
  [settingsCoreUsersApi.reducerPath]: settingsCoreUsersApi.reducer,
  [investmentEntitiesApi.reducerPath]: investmentEntitiesApi.reducer,
  [settingsCoreLegalEntityActivityLogsApi.reducerPath]:
    settingsCoreLegalEntityActivityLogsApi.reducer,
  [feedMessagesApi.reducerPath]: feedMessagesApi.reducer,
  [objectFoldersApi.reducerPath]: objectFoldersApi.reducer,
  [impersonationApi.reducerPath]: impersonationApi.reducer,
  [impersonationSlice.name]: impersonationSlice.reducer,
};

const returnReducers = {
  [capitalGeneralLedgersApi.reducerPath]: capitalGeneralLedgersApi.reducer,
};

const rootReducer = combineReducers({
  assetsSlice,
  changeOrderConfigs,
  changeOrderManagement,
  customerConfig,
  documents: sharedFiles,
  notifications,
  reallocations,
  reallocation,
  changeEvent,
  usersForReconcileSettings,
  JCCForReallocations,
  dealPathSettings,
  deals,
  users: userManagement,
  financials: financialsSliceReducer,
  reportBuilder: reportBuilderSliceReducer,
  underwritingBudgetSlice,
  payApps,
  userManagement,
  construction,
  sharedFiles,
  budgets,
  developmentBudget,
  developmentLegalEntities,
  userRoles,
  developmentBudgetVariance,
  reconcile,
  developmentLegalEntity,
  returnInvestmentObject,
  leftSidebar,
  widgetFullscreen: widgetFullscreenSlice.reducer,
  reconcileInvoiceState,
  invoices,
  draws,
  generic: genericSlice.reducer,
  requisitionsTable: requisitionsTableSlice.reducer,
  formulasSettings: formulasSettingsSlice.reducer,
  coreEntity: coreEntityReducer,
  userActivity: userActivitySlice,
  ...reconcileReducers,
  ...reportReducers,
  ...coreReducers,
  ...returnReducers,
});

export default rootReducer;
