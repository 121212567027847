import DashboardCard from '@/pages/portfolio/widgets/Dashboard/cards/DashboardCard';
import { Logo } from '@/bundles/Shared/components/LeftSidebar/Logo/Logo';
import { DashboardDividerSection } from '@/bundles/Shared/entities/dashboard';
import { KpiTableSingleDateWidget } from '@/bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate';
import { MOCK_TEMPLATE_BUILDER_DATA } from '@/entities/report/templateBuilder/config';
import http from '@/lib/http';
import saveFileOnUserDevice from '@/shared/lib/browser/saveFileOnUserDevice';
import { cn } from '@/shared/lib/css/cn';
import { Button } from '@/stories';
import { RouteComponentProps } from '@reach/router';
import { stringify } from 'qs';

const PDFPage = ({
  className,
  pageWide,
  ...props
}: React.HTMLAttributes<HTMLElement> & {
  pageWide?: boolean;
}) => (
  <section
    className={cn(
      'flex break-after-page flex-col gap-tw-4 overflow-hidden last-of-type:break-after-avoid print:mx-tw-6 print:my-tw-6 print:h-[initial] print:overflow-hidden ',
      className,
      pageWide && 'page-wide',
    )}
  >
    <Logo
      className="mb-tw-2 hidden h-[3.5rem] max-w-full object-contain print:block"
      variant="large"
    />
    {props.children}
  </section>
);

export const ReportTemplateBuilderPage: React.FC<RouteComponentProps> = () => {
  return (
    <div className="mx-auto flex w-[1200px] max-w-[1200px] flex-col gap-tw-4 py-tw-6">
      <div className="flex items-center gap-tw-4 print:hidden">
        <Button
          className="w-max"
          onClick={async () => {
            try {
              const res = await http.get(
                `/report/demo_print_pdf${stringify(
                  {
                    url: 'https://whiskey.symmetre.email/settings/report/template-builder',
                  },
                  {
                    addQueryPrefix: true,
                  },
                )}`,
              );
              if (!res.ok) throw new Error();

              const file = await res.blob();

              saveFileOnUserDevice(
                file,
                `${window.symmetreConfig.customer.name}_pdf`,
              );
            } catch {
              toastr.error('Error');
            }
          }}
        >
          Get PDF
        </Button>
        <Button
          onClick={() => {
            window.print();
          }}
        >
          Print PDF (Native)
        </Button>
      </div>
      <PDFPage>
        <DashboardCard className="h-max">
          <DashboardDividerSection
            widgetSection={{
              id: '39',
              color: 'rgba(0, 146, 238, 1)',
              description: MOCK_TEMPLATE_BUILDER_DATA.textArea.description,
              sectionId: '9a03d57f-d2b7-4906-9844-ae5e5ddde4bb',
              sectionType: 'divider',
              title: 'Text-area',
              widgetType: 'divider',
            }}
          />
        </DashboardCard>
        <DashboardCard className="h-max">
          <DashboardDividerSection
            widgetSection={{
              id: '39',
              color: 'rgba(0, 146, 238, 1)',
              description: MOCK_TEMPLATE_BUILDER_DATA.textArea.description,
              sectionId: '9a03d57f-d2b7-4906-9844-ae5e5ddde4bb',
              sectionType: 'divider',
              title: 'Text-area',
              widgetType: 'divider',
            }}
          />
        </DashboardCard>
      </PDFPage>
      <PDFPage className="h-max">
        <KpiTableSingleDateWidget
          mode="pdf"
          state={MOCK_TEMPLATE_BUILDER_DATA.kpiTableSingleDate.state}
          isError={false}
          isLoading={false}
          data={MOCK_TEMPLATE_BUILDER_DATA.kpiTableSingleDate.data}
          widgetSection={
            MOCK_TEMPLATE_BUILDER_DATA.kpiTableSingleDate.widgetSection
          }
        />
      </PDFPage>
      <PDFPage className="h-[500px]">
        <KpiTableSingleDateWidget
          mode="pdf"
          state={MOCK_TEMPLATE_BUILDER_DATA.kpiTableSingleDate.state}
          isError={false}
          isLoading={false}
          data={MOCK_TEMPLATE_BUILDER_DATA.kpiTableSingleDate.data}
          widgetSection={
            MOCK_TEMPLATE_BUILDER_DATA.kpiTableSingleDate.widgetSection
          }
        />
      </PDFPage>
      <PDFPage pageWide className="h-[500px]">
        <KpiTableSingleDateWidget
          mode="pdf"
          state={MOCK_TEMPLATE_BUILDER_DATA.kpiTableSingleDate.state}
          isError={false}
          isLoading={false}
          data={MOCK_TEMPLATE_BUILDER_DATA.kpiTableSingleDate.data}
          widgetSection={
            MOCK_TEMPLATE_BUILDER_DATA.kpiTableSingleDate.widgetSection
          }
        />
      </PDFPage>
    </div>
  );
};
