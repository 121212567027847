import React, { useLayoutEffect, useMemo, useState } from 'react';
import { cn } from '@/shared/lib/css/cn';
import useMediaQuery, {
  MEDIUM_WIDTH_MEDIA_QUERY,
} from '@/shared/lib/hooks/useMediaQuery';
import OpenDealsCarousel from '@/pages/portfolio/widgets/OpenDeals/OpenDealsCarousel';
import {
  PortfolioActivityLog,
  PortfolioTransactionDetailsModal,
} from '@/widgets/portfolio';
import styles from './index.module.scss';
import { Dashboard } from '@/pages/portfolio/widgets/Dashboard/Dashboard';
import Funds from '@/pages/portfolio/widgets/Funds';
import Assets from '@/pages/portfolio/widgets/Assets';
import {
  TabsContextProvider,
  TabsContextValue,
  useTabs,
  useTabsContext,
} from 'stories/Tabs/useTabs';
import {
  IThinTabItem,
  ThinTabGroup,
} from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import {
  useGetApiPortfolioActivityLogItemsQuery,
  useGetApiPortfolioDetailsQuery,
  useGetApiPortfolioDocumentsRecentQuery,
} from 'bundles/Shared/shared/api/portfolioEnhancedApi';
import { formatDate, formatUnixDate } from '@/shared/lib/formatting/dates';
import { useModal } from '@/shared/lib/hooks/useModal';

import { FeedMessageModal } from '@/widgets/core/feedMessageModal';
import {
  Button,
  CurrencyFormatter,
  DocumentCard,
  IconButton,
  Label,
} from '@/stories';
import {
  PageParamsProvider,
  usePageParamsContext,
} from 'bundles/Shared/components/pageParams';
import { GetApiPortfolioActivityLogItemsApiArg } from 'bundles/Shared/shared/api/portfolioGeneratedApi';
import { capitalize, startCase } from 'lodash-es';
import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';
import { useNavigate } from '@reach/router';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import DownloadButton from 'bundles/Assets/components/SharedFiles/Table/buttons/DownloadButton';
import { generateLinkBySharedFileId } from '@/entities/sharedFile/lib';
import { isDistributionActivityLogItem } from '@/widgets/portfolio/lib';
import pluralize from 'pluralize';
import { convertCentsToDollars } from '@/shared/lib/converters';

type PortfolioPageParams = {
  activityLogType?: GetApiPortfolioActivityLogItemsApiArg['type'] | null;
};

const TABS = [
  {
    id: 'whats-new',
    label: "What's New",
  },
  {
    id: 'activity-log',
    label: 'Activity Log',
  },
] as const satisfies readonly IThinTabItem[];

const WhatsNewGroup = ({
  children,
  entity,
  count,
}: React.PropsWithChildren<{
  entity:
    | NonNullable<GetApiPortfolioActivityLogItemsApiArg['type']>
    | 'document';
  count: number;
}>) => {
  const navigate = useNavigate();
  const { setTab } = useTabsContext();
  const { setPageParams } = usePageParamsContext<PortfolioPageParams>();
  return (
    <div className="flex flex-col gap-tw-2.5 py-tw-4">
      <div className="flex gap-tw-2 px-tw-2">
        <span className="inline-semibold uppercase text-neutral-500">
          {count > 0
            ? pluralize(startCase(entity), count)
            : `No new ${startCase(entity)} yet`}
        </span>
        {count > 0 && (
          <span className="inline-semibold text-neutral-800">{count}</span>
        )}
        <div className="grow" />
        <Button
          onClick={() => {
            if (entity !== 'document') {
              setTab(TABS[1]);
              setPageParams({ activityLogType: entity });
              return;
            }
            navigate(ROUTES_ROOT.documents.fullPath);
          }}
          variant="secondary"
          size="xs"
        >
          Show All
        </Button>
      </div>
      {children}
    </div>
  );
};

const BaseActivityCard = ({
  children,
  className,
  ...props
}: React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) => (
  <div
    className={cn(
      'group flex cursor-pointer items-center gap-tw-2 rounded-2xl bg-neutral-000 p-tw-4 shadow-s',
    )}
    {...props}
  >
    <div className="flex grow flex-col gap-tw-0.5">{children}</div>

    <IconButton
      className="text-neutral-100 group-hover:text-neutral-500"
      iconName="arrowRight"
    />
  </div>
);

const CardListSkeleton = ({ children }: React.PropsWithChildren) => (
  <div className="flex flex-col gap-tw-1">
    {Array.from({ length: 5 }).map((_, i) => (
      <SkeletonBlock key={i} className="h-[50px] w-full" />
    ))}
  </div>
);

const TransactionsList = () => {
  const { openModal } = useModal();
  const { data, isLoading } = useGetApiPortfolioActivityLogItemsQuery({
    type: 'transactions',
  });

  if (isLoading) return <CardListSkeleton />;

  if (!data) return null;

  return (
    <div className="flex flex-col gap-tw-1">
      {data.items.map((item) => {
        const { id, objectName, date, amountCents } =
          item.contribution! ?? item.distribution!;
        const type = item.contribution ? 'Contribution' : 'Distribution';
        return (
          <BaseActivityCard
            onClick={() => {
              openModal(PortfolioTransactionDetailsModal, {
                kind: item.contribution ? 'contribution' : 'distribution',
                id: item.contribution?.id ?? item.distribution?.id,
              });
            }}
            key={id}
          >
            <div className="flex justify-between gap-tw-2">
              <span className="inline-semibold text-neutral-999">
                {objectName}
              </span>
              <CurrencyFormatter
                classes={{
                  allParts: 'inline-regular',
                  value: 'text-neutral-850',
                }}
                value={convertCentsToDollars(amountCents)}
              />
            </div>
            <div className="secondary-regular flex gap-tw-1 text-neutral-500">
              <span>{formatDate(date as DateString, 'MM-DD-YYYY')}</span>
              <span>•</span>
              <span>
                {isDistributionActivityLogItem(item)
                  ? item.distribution.kinds?.map(startCase).join(', ')
                  : `Class ${capitalize(item.contribution!.investmentClass)}`}
              </span>
              <div className="grow" />
              <span>{type}</span>
            </div>
          </BaseActivityCard>
        );
      })}
    </div>
  );
};

const FeedMessagesList = () => {
  const { openModal } = useModal();
  const { data, isLoading } = useGetApiPortfolioActivityLogItemsQuery({
    type: 'feed_messages',
  });
  if (isLoading) return <CardListSkeleton />;
  if (!data) return null;
  return (
    <div className="flex flex-col gap-tw-1">
      {data.items.map((item) => {
        const { id, objectName, createdBy, createdAt, feedType } =
          item.feedMessage!;
        return (
          <BaseActivityCard
            onClick={() => {
              openModal(FeedMessageModal, { id });
            }}
            key={id}
          >
            <div className="flex items-center justify-between">
              <span className="inline-semibold text-neutral-999">
                {objectName}
              </span>
              <Label color={feedType.color!}>{feedType.title}</Label>
            </div>
            <div className="secondary-regular flex gap-tw-1 text-neutral-500">
              <span>{formatUnixDate(createdAt as UnixTime, 'MM-DD-YYYY')}</span>
              <span>•</span>
              <span>{createdBy.fullName}</span>
            </div>
          </BaseActivityCard>
        );
      })}
    </div>
  );
};

const TransactionsGroup = () => {
  const { data, isLoading } = useGetApiPortfolioActivityLogItemsQuery({
    type: 'transactions',
  });

  if (!isLoading && data && data.items.length === 0) {
    return null;
  }

  return (
    <WhatsNewGroup entity="transactions" count={data?.meta.totalSize ?? 0}>
      <TransactionsList />
    </WhatsNewGroup>
  );
};
const FeedMessageGroup = () => {
  const { data, isLoading } = useGetApiPortfolioActivityLogItemsQuery({
    type: 'feed_messages',
  });

  if (!isLoading && data && data.items.length === 0) {
    return null;
  }

  return (
    <WhatsNewGroup entity="feed_messages" count={data?.meta.totalSize ?? 0}>
      <FeedMessagesList />
    </WhatsNewGroup>
  );
};

const DocumentsList = () => {
  const { data, isLoading } = useGetApiPortfolioDocumentsRecentQuery({});

  if (isLoading) return <CardListSkeleton />;
  if (!data) return null;

  return (
    <div className="flex flex-col gap-tw-1">
      {data.items.map((item) => {
        const { id, extension, title, size, url, confidential } = item;
        return (
          <DocumentCard
            className="border-0"
            key={id}
            fileExtension={extension}
            filename={title}
            fileSize={size}
            link={generateLinkBySharedFileId(id)}
            actions={<DownloadButton className="inline-flex" row={item} />}
          />
        );
      })}
    </div>
  );
};

const DocumentsGroup = () => {
  const { data } = useGetApiPortfolioDocumentsRecentQuery({});

  return (
    <WhatsNewGroup entity="document" count={data?.meta.totalCount ?? 0}>
      <DocumentsList />
    </WhatsNewGroup>
  );
};

export const PortfolioPage = () => {
  const [pageParams, setPageParams] = useState<PortfolioPageParams>({});
  const isMediumWidth = useMediaQuery(MEDIUM_WIDTH_MEDIA_QUERY);
  const { data, isLoading } = useGetApiPortfolioDetailsQuery();
  const { tab, thinTabGroupProps, setTab } = useTabs(TABS, TABS[0]);
  const tabsContextValue = useMemo<TabsContextValue<(typeof TABS)[number]>>(
    () => ({
      tab,
      setTab,
    }),
    [],
  );
  const dataExists = data?.dataExists;

  useLayoutEffect(() => {
    const footerElem = document.querySelector('footer.footer');

    footerElem?.classList.add(styles.customFooter);

    return () => {
      footerElem?.classList.remove(styles.customFooter);
    };
  }, []);

  if (isLoading)
    return (
      <div className="grid h-screen gap-tw-4 p-tw-4 md:grid-cols-[1fr_380px]">
        <SkeletonBlock className="h-full w-full" />
        <SkeletonBlock className="h-full w-full" />
      </div>
    );

  const offerings = [...(data?.assets ?? []), ...(data?.funds ?? [])].filter(
    (item) => item.offering,
  );
  const hasOfferings = offerings.length > 0;
  return (
    <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
      <div
        className={cn(
          styles.container,
          'mt-tw-4 md:mx-m md:grid-cols-[1fr_380px] md:py-l 1.5xl:mx-l',
        )}
      >
        {!isMediumWidth && offerings.length > 0 && (
          <div className="max-w-[calc(100vw_-_16px)] md:w-[initial]">
            <div className="">
              <h5 className="light-60 header5-regular mb-m">Open Deals</h5>
              <OpenDealsCarousel />
            </div>
          </div>
        )}

        <div className={cn(styles.mainContent, 'px-s md:px-0')}>
          {dataExists ? (
            <Dashboard />
          ) : (
            <>
              <Funds />
              <Assets />
            </>
          )}
        </div>

        {isMediumWidth && (
          <div className={cn(styles.rightSidebar)}>
            <div className="sticky top-0 flex flex-col gap-tw-4">
              {hasOfferings && <OpenDealsCarousel />}

              {
                <div
                  className={cn(
                    'flex flex-col gap-tw-2 rounded-2xl bg-neutral-000 p-tw-2',
                    tab?.id === 'whats-new' && 'pb-0',
                    !hasOfferings && 'sticky top-0',
                  )}
                >
                  <TabsContextProvider value={tabsContextValue}>
                    <ThinTabGroup fullWidth {...thinTabGroupProps} />
                    {tab?.id === 'whats-new' && (
                      <div className="flex flex-col">
                        <FeedMessageGroup />
                        <TransactionsGroup />
                        <DocumentsGroup />
                      </div>
                    )}
                  </TabsContextProvider>
                  {tab?.id === 'activity-log' && (
                    <PortfolioActivityLog
                      type={pageParams.activityLogType}
                      onTypeChange={(type) =>
                        setPageParams({ activityLogType: type })
                      }
                    />
                  )}
                </div>
              }
            </div>
          </div>
        )}
      </div>
    </PageParamsProvider>
  );
};
