import { sleep } from '@amcharts/amcharts5/.internal/core/util/Time';
import { RowDataChangedEvent } from 'ag-grid-community';

export const sleepUntilAgGridSetsRowDataInTime = async () => {
  await sleep(200);
};
export const sleepUntilWidgetStateIsChanged = async () => {
  await sleep(0);
};

export const handleOnRowDataChangedAutoSizeAllColumns = async ({
  event,
  skipHeader = true,
}: {
  event: RowDataChangedEvent;
  skipHeader?: boolean;
}) => {
  await sleepUntilAgGridSetsRowDataInTime();
  event.columnApi.autoSizeAllColumns(skipHeader);
};
