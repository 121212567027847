import {
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import { AverageActualRentChartWidgetDto } from 'bundles/Shared/shared/api/dashboardsGeneratedApi';
import React, { useRef } from 'react';
import { AxisRenderer, DateAxis, XYChart } from '@amcharts/amcharts5/xy';
import { useAmchart } from 'lib/amcharts/useAmchart';
import {
  chartDateMapper,
  COLOR_SET,
  getReturnDashboardTheme,
} from 'lib/amcharts/utils';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_json from '@amcharts/amcharts5/plugins/json';
import { groupBy, omit, orderBy, sumBy, uniqBy } from 'lodash-es';
import * as am5 from '@amcharts/amcharts5';
import { addExportToChart } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib';
import {
  DashboardWidgetCard,
  WidgetStateGranularity,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { WidgetStateCalendarRangeSelector } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateCalendarRangeSelector';
import { Tumbler } from '@/stories';
import { ExportChartButton } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/ExportChartButton';
import AnimationLoader from 'stories/AnimationLoader/AnimationLoader';
import { XYChartWidgetState } from 'bundles/Shared/widgets/dashboard/widgets/xyChart/widget';
import {
  AVERAGE_EFFECTIVE_AMCHART_CONFIG,
  AVG_EFFECTIVE_RENT_CHART_AREA_SERIES_TEMPLATE,
  AVG_EFFECTIVE_RENT_CHART_LINE_SERIES_TEMPLATE,
} from 'bundles/Shared/widgets/dashboard/widgets/averageActualRentChart/config';
import { XYChartWidgetSection } from 'bundles/Shared/widgets/dashboard/widgets/xyChart/model';

export function AverageXYChartWidget({
  data,
  widgetSection,
  state,
  onStateChange,
  isFetching,
}: WidgetProps<AverageActualRentChartWidgetDto, XYChartWidgetSection> &
  WidgetStateProps<
    XYChartWidgetState & {
      showFloorplans: boolean;
    }
  >) {
  const ref = useRef(null);
  const chartRef = useRef<XYChart | null>(null);
  useAmchart(
    ref,
    (root) => {
      if (!data) {
        return;
      }
      const myTheme = getReturnDashboardTheme(root);
      myTheme.rule('ColorSet').set('colors', COLOR_SET);
      root.setThemes([am5themesAnimated.new(root), myTheme]);

      const parser = am5plugins_json.JsonParser.new(root);
      const config = AVERAGE_EFFECTIVE_AMCHART_CONFIG;
      const mappedData = orderBy(
        data.items.map(chartDateMapper('dateFrom')),
        'dateFrom',
        'asc',
      ).map((d) => ({
        ...omit(d, 'type'),
        _type: d.type,
      }));

      const getRefs = () => {
        const averageData = mappedData.filter((d) => d._type === 'average');
        const unitData = mappedData.filter(
          (d) =>
            d._type !== 'average' &&
            (state.showFloorplans
              ? d._type === 'source_unit_type'
              : d._type === 'unit_type'),
        );
        const dataRef = {
          data: unitData,
          averageData,
        };
        const series = uniqBy(
          unitData.filter((d) => d._type !== 'average'),
          'label',
        );

        return [
          dataRef,
          ...AVERAGE_EFFECTIVE_AMCHART_CONFIG.refs,
          {
            lineSeries: [
              AVG_EFFECTIVE_RENT_CHART_AREA_SERIES_TEMPLATE,
              ...series.map((s) => ({
                ...AVG_EFFECTIVE_RENT_CHART_LINE_SERIES_TEMPLATE,
                settings: {
                  ...AVG_EFFECTIVE_RENT_CHART_LINE_SERIES_TEMPLATE.settings,
                  name: s.label,
                  valueYField: 'value',
                },
                properties: {
                  ...AVG_EFFECTIVE_RENT_CHART_LINE_SERIES_TEMPLATE.properties,
                  data: unitData.filter((d) => d.label === s.label),
                },
              })),
            ],
          },
        ];
      };
      parser
        .parse(
          {
            ...config,
            refs: getRefs(),
          },
          {
            parent: root.container,
          },
        )
        .then(function (chart: XYChart) {
          // Chart is ready
          chartRef.current = chart;
          const legend = chart.rightAxesContainer.children.push(
            am5.Legend.new(root, {
              width: 150,
              paddingLeft: 15,
              height: am5.percent(100),
              verticalScrollbar: am5.Scrollbar.new(root, {
                orientation: 'vertical',
              }),
            }),
          );
          legend.itemContainers.template.set('width', am5.p100);
          legend.valueLabels.template.setAll({
            width: am5.p100,
            textAlign: 'right',
          });
          legend.data.setAll(chart.series.values);

          (chart.xAxes.getIndex(0) as DateAxis<AxisRenderer>).set(
            'baseInterval',
            {
              timeUnit: state.granularity,
              count: 1,
            },
          );
          chart.series.getIndex(0)?.appear(1000);
          chart.appear(1000, 100);
          const groupedByDate = groupBy(mappedData, 'dateFrom');
          const dataForExport = Object.entries(groupedByDate).map(
            ([dateFrom, unitTypes]) => {
              const groupedByType = groupBy(unitTypes, 'label');
              const sumsByType = Object.entries(groupedByType).map(
                ([label, items]) => {
                  return [label, sumBy(items, 'value')];
                },
              );
              return {
                dateFrom: Number(dateFrom),
                ...Object.fromEntries(sumsByType),
              };
            },
          );
          addExportToChart({
            chartRef: ref,
            root,
            title: widgetSection.title,
            data: dataForExport,
            granularity: state.granularity,
            dataFields: {
              ...Object.fromEntries(
                uniqBy(mappedData, 'label').map((d) => [d.label, d.label]),
              ),
            },
          });
        });
    },
    [data, state.showFloorplans],
  );

  return (
    <DashboardWidgetCard>
      <DashboardWidgetCard.Header>
        <DashboardWidgetCard.Header.Title>
          {widgetSection.title}
        </DashboardWidgetCard.Header.Title>
        <div className="flex items-center gap-tw-2">
          <WidgetStateGranularity
            state={state}
            onStateChange={onStateChange}
            chartRef={chartRef}
            granularities={widgetSection.defaultOptions.granularities}
          />
          <WidgetStateCalendarRangeSelector
            state={state}
            onStateChange={onStateChange}
          />
          <Tumbler
            checked={state.showFloorplans}
            onChange={(e) => {
              onStateChange({
                ...state,
                showFloorplans: e.target.checked,
              });
            }}
          >
            Floorplans
          </Tumbler>
          <div className="grow" />
          <ExportChartButton chartRef={ref} />
        </div>
      </DashboardWidgetCard.Header>
      {isFetching && <AnimationLoader />}
      <div className="h-[350px]" ref={ref} />
    </DashboardWidgetCard>
  );
}
