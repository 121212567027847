import { Contribution, Distribution } from '@/entities/return';
import { TransactionSource } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { Dropdown, DropdownItem, IconButton } from 'stories/index';
import { entryHasReportLineItem } from '../utils';
import { HasNoSources } from './HasNoSources';
import { InfoOfCreation } from './InfoOfCreation';
import LinkedComponent from './LinkedComponent';
import { NotLinkedColumn } from './NotLinkedColumn';

interface Props {
  entry: Contribution | Distribution;
  type: TransactionSource['kind'];
  noSources: boolean;
  actions: {
    onRemoveEntry: (row: Contribution | Distribution) => Promise<void>;
    onEditManualEntry: (row: Contribution | Distribution) => Promise<void>;
  };
}

function MainColumnFormatter({ entry, type, noSources, actions }: Props) {
  const createSharedProps = {
    type,
    createInfo: <InfoOfCreation {...entry} />,
  } as const;

  if (entryHasReportLineItem(entry)) {
    const removeButton = (
      <IconButton
        variant="white"
        iconName="trash"
        onClick={() => actions.onRemoveEntry(entry)}
      />
    );

    return (
      <LinkedComponent
        {...createSharedProps}
        removeButton={removeButton}
        entry={entry}
      />
    );
  }

  const actionDropdown = (
    <Dropdown
      classNameContainer="h-min"
      appendTo={document.body}
      items={[
        <DropdownItem
          key="edit"
          iconName="editAlt"
          onClick={() => actions.onEditManualEntry(entry)}
        >
          Edit
        </DropdownItem>,
        <DropdownItem
          key="remove"
          iconName="trash"
          onClick={() => actions.onRemoveEntry(entry)}
        >
          Remove
        </DropdownItem>,
      ]}
    >
      <IconButton variant="secondary" iconName="more" />
    </Dropdown>
  );

  if (noSources)
    return (
      <HasNoSources {...createSharedProps} actionDropdown={actionDropdown} />
    );

  return (
    <NotLinkedColumn
      {...createSharedProps}
      actionDropdown={actionDropdown}
      entry={entry}
    />
  );
}

export default MainColumnFormatter;
