import { WIDGETS_CONFIG_MAP } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  useGetApiReportEagleEyeDashboardsByEagleEyeDashboardIdWidgetSectionsAndIdQuery,
  useGetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdQuery,
} from 'bundles/Shared/shared/api/dashboardSettingsEnhancedApi';
import { WidgetProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { useCallback, useMemo } from 'react';

import ErrorBoundary from '@/app/ui/ErrorBoundary';
import { DisplayedGroupsWidgetContext } from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import segment from 'bundles/Shared/components/Filters/segment/Segment';
import {
  EAGLE_EYE_DASHBOARD_SECTION_TYPE,
  GetApiReportEagleEyeDashboardsByEagleEyeDashboardIdWidgetSectionsAndIdApiArg,
  ReportDashboardSegment,
  ReportDashboardType,
  selectWidgetStateFromDashboardSettingsById,
  updateDashboardWidgetState,
} from 'bundles/Shared/entities/dashboard';
import { WidgetErrorBoundary } from 'bundles/Shared/widgets/dashboard/widgetsHelpers/ui/WidgetErrorBoundary';
import { useInView } from 'react-intersection-observer';
import { UnknownRecord } from 'type-fest/source/internal';

type Props = Omit<
  WidgetProps,
  'onStateChange' | 'data' | 'state' | 'dashboardType' | 'context'
> &
  EagleEyeDashboardWidgetContext;

export type EagleEyeDashboardWidgetContext = DisplayedGroupsWidgetContext & {
  segments: ReportDashboardSegment[];
  assets: ReportDashboardSegment[];
};

export function EagleEyeDashboardWidget(props: Props) {
  const { ref, inView } = useInView({
    threshold: 0.5,
    delay: 500,
    root: null,
    triggerOnce: true,
  });
  const dispatch = useAppDispatch();
  const { mode, dashboardId, widgetSection, boardId, assets, segments } = props;
  const isDivider =
    widgetSection.widgetType === EAGLE_EYE_DASHBOARD_SECTION_TYPE.DIVIDER;
  const widgetId = widgetSection.id;
  const state = useAppSelector(
    selectWidgetStateFromDashboardSettingsById({
      widgetId,
      dashboardId,
      boardId,
    }),
  ) as UnknownRecord;

  const context = useMemo<EagleEyeDashboardWidgetContext>(
    () => ({
      columnVisibilityEnabled: true,
      segments,
      assets,
    }),
    [assets, segment],
  );

  const handleStateChange = useCallback(
    (widgetState: UnknownRecord) => {
      dispatch(
        updateDashboardWidgetState({
          widgetState,
          dashboardId,
          id: widgetId,
          boardId,
        }),
      );
    },
    [dashboardId, widgetId],
  );

  const getRequestArgs = () => {
    if (!state) {
      return {};
    }
    return {
      id: widgetId,
      boardId,
      eagleEyeDashboardId: dashboardId,
      date: (state.date as string) ?? formatToDateStringForRequest(new Date()),
      dateFrom: state?.dateFrom,
      dateTo: state?.dateTo,
      granularity: state?.granularity,
      groupingType: state?.groupingType,
      periodType: state.periodType,
      assetIds: state?.assets,
      segmentIds: state?.segments,
      legalEntityIds: state?.legalEntities,
      filters: state.filters ? JSON.stringify(state.filters) : undefined,
      query: state?.query,
      page: state?.page,
      perPage: state.perPage,
    } as GetApiReportEagleEyeDashboardsByEagleEyeDashboardIdWidgetSectionsAndIdApiArg;
  };
  const viewData =
    useGetApiReportEagleEyeDashboardsByEagleEyeDashboardIdWidgetSectionsAndIdQuery(
      getRequestArgs() as GetApiReportEagleEyeDashboardsByEagleEyeDashboardIdWidgetSectionsAndIdApiArg,
      {
        skip: mode === 'edit' || isDivider || !inView,
      },
    );

  const settingsData =
    useGetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdQuery(
      getRequestArgs(),
      {
        skip: mode === 'view' || isDivider || !inView,
      },
    );

  const { Component } = WIDGETS_CONFIG_MAP[widgetSection.widgetType] ?? {};

  if (Component == null) {
    return null;
  }

  const getData = () => {
    if (mode === 'edit') {
      return settingsData.data;
    }
    return viewData.data;
  };

  return (
    <ErrorBoundary
      fallback={<WidgetErrorBoundary title={widgetSection.title} />}
    >
      <div className="h-full" ref={ref}>
        <Component
          isLoading={viewData.isLoading || settingsData.isLoading}
          isError={viewData.isError || settingsData.isError}
          isFetching={viewData.isFetching || settingsData.isFetching}
          onStateChange={handleStateChange}
          data={getData()}
          state={state ?? {}}
          dashboardType={ReportDashboardType.EAGLE_EYE}
          context={context}
          {...props}
        />
      </div>
    </ErrorBoundary>
  );
}
