import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  COMPARISON_DASHBOARD_WIDGETS_CONFIG_MAP,
  ComparisonDashboardWidgetStateIntersection,
} from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { WidgetProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { useCallback } from 'react';

import {
  selectReportComparisonDashboardMetadataById,
  selectReportComparisonDashboardSelectedObjectLegalEntityIds,
} from '@/bundles/Shared/entities/dashboard/model/slices/comparisonSlice';
import { cn } from '@/shared/lib/css/cn';
import {
  buildLayoutsId,
  GetApiReportComparisonDashboardsByComparisonDashboardIdWidgetSectionsAndIdApiArg,
  ReportDashboardType,
  updateDashboardWidgetState,
  useGetApiReportComparisonDashboardsByComparisonDashboardIdWidgetSectionsAndIdQuery,
} from 'bundles/Shared/entities/dashboard';
import { useInView } from 'react-intersection-observer';
import { UnknownRecord } from 'type-fest/source/internal';
import { DateWidgetState } from '@/bundles/Shared/widgets/dashboard/widgets/common/model';

type Props = Pick<
  WidgetProps,
  'mode' | 'dashboardId' | 'widgetSection' | 'boardId'
> & {
  selectedAssetId?: number;
};

export function ComparisonDashboardWidget(props: Props) {
  const { ref, inView } = useInView({
    threshold: 0.5,
    delay: 500,
    root: null,
    triggerOnce: true,
  });
  const dispatch = useAppDispatch();
  const { selectedAssetId, mode, dashboardId, widgetSection, boardId } = props;
  const widgetId = widgetSection.id;
  const { widgetsState } = useAppSelector((state) =>
    selectReportComparisonDashboardMetadataById(
      state,
      buildLayoutsId({
        dashboardId,
        boardId,
      }),
    ),
  )!;
  const objectLegalEntityIds = useAppSelector((state) =>
    selectReportComparisonDashboardSelectedObjectLegalEntityIds(state, {
      dashboardId,
      selectedObjectId: selectedAssetId,
    }),
  );
  const state = widgetsState[
    widgetId
  ] as unknown as ComparisonDashboardWidgetStateIntersection;

  const handleStateChange = useCallback(
    (newState: UnknownRecord) => {
      dispatch(
        updateDashboardWidgetState({
          boardId,
          id: widgetId,
          widgetState: newState as unknown as DateWidgetState,
          dashboardId,
        }),
      );
    },
    [dashboardId, widgetId],
  );

  const getRequestArgs = () => {
    if (!state) {
      return {} as GetApiReportComparisonDashboardsByComparisonDashboardIdWidgetSectionsAndIdApiArg;
    }
    return {
      legalEntityIds: objectLegalEntityIds,
      id: widgetId,
      groupingType: 'assets',
      assetIds: [selectedAssetId],
      date: state.date,
      comparisonDashboardId: dashboardId,
      periodType: state.periodType,
    } as GetApiReportComparisonDashboardsByComparisonDashboardIdWidgetSectionsAndIdApiArg;
  };
  const { data, isLoading, isFetching, isError } =
    useGetApiReportComparisonDashboardsByComparisonDashboardIdWidgetSectionsAndIdQuery(
      getRequestArgs(),
      {
        skip: !inView || !selectedAssetId || objectLegalEntityIds.length === 0,
      },
    );

  const { Component } =
    // @ts-expect-error
    COMPARISON_DASHBOARD_WIDGETS_CONFIG_MAP[widgetSection.widgetType] ?? {};

  if (Component == null) {
    return null;
  }

  return (
    <div className={cn(mode === 'edit' && 'h-full')} ref={ref}>
      <Component
        dashboardType={ReportDashboardType.COMPARISON_MODE}
        isLoading={isLoading}
        isError={isError}
        isFetching={isFetching}
        onStateChange={handleStateChange}
        data={data}
        state={state}
        context={{
          objectLegalEntityIds,
          columnVisibilityEnabled: true,
        }}
        {...props}
      />
    </div>
  );
}
