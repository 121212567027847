import { FinancialTableSingeDateWidgetNS } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate';
import { ExcelStyle } from 'ag-grid-community';
import { useMemo } from 'react';
import { DEFAULT_FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN } from 'bundles/Shared/widgets/dashboard/widgets/common/financialTable/config/column.form';
import { buildAmchartExcelStyleNumberFormat } from '@/shared/lib/formatting/charts';
import { buildExcelStyleId } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';

export const useFinancialTableWidgetExcelStyles = ({
  columns,
  rowsDisplayOptionsExcelStyles,
}: {
  columns: FinancialTableSingeDateWidgetNS.Column[];
  rowsDisplayOptionsExcelStyles: ExcelStyle[];
}) => {
  return useMemo<ExcelStyle[]>(() => {
    return columns
      .map((column) => ({
        id: buildExcelStyleId(column.key.toString()),
        ...buildAmchartExcelStyleNumberFormat(
          // @ts-expect-error
          column.valueDisplayOptionsOverride ??
            DEFAULT_FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN.value_display_options,
        ),
      }))
      .concat(rowsDisplayOptionsExcelStyles);
  }, [columns]);
};
