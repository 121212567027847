import { ValueDisplayOptions } from '@/shared/lib/formatting/displayOptions';
import {
  EAGLE_EYE_DASHBOARD_SECTION_TYPE,
  OBJECT_DASHBOARD_SECTION_TYPE,
  PeriodShiftConfig,
  ReportEagleEyeDashboardSection,
  ReportObjectDashboardSection,
  WidgetDateGranularity,
} from 'bundles/Shared/entities/dashboard';
import { XYChartWidgetSection } from 'bundles/Shared/widgets/dashboard/widgets/xyChart/model';
import { AmChartConfig } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib';

export type XyChartSingleKpiWidgetConfig = Omit<XYChartWidgetConfig, 'kpis'> & {
  kpis: {
    key: number;
    expression: string;
    label: string;
    value_display_options: ValueDisplayOptions;
  }[];
};
export type XYChartSingleKpiWidgetSection = XYChartWidgetSection & {
  widgetType: typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.XY_CHART_SINGLE_KPI;
  defaultOptions: XYChartWidgetSection['defaultOptions'] & {
    groupingType: 'property' | 'unit';
  };
  widgetConfig: XyChartSingleKpiWidgetConfig;
};
export type XYChartWidgetConfigKpi = {
  key: number;
  expression: string;
  label: string;
  value_display_options?: ValueDisplayOptions;
};
export type XYChartWidgetConfig = {
  am_chart_config: AmChartConfig;
  kpis: XYChartWidgetConfigKpi[];
  default_options: {
    granularities: WidgetDateGranularity[];
    granularity: WidgetDateGranularity;
    date_from?: {
      period_type: WidgetDateGranularity;
      period_shift?: PeriodShiftConfig;
    };
    date_to?: {
      period_type: WidgetDateGranularity;
      period_shift?: PeriodShiftConfig;
    };
  };
  granularities: WidgetDateGranularity[];
};
export const isXYChartWidgetSection = (
  section: ReportObjectDashboardSection,
): section is XYChartWidgetSection =>
  section.widgetType === OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART;
export const isXYChartSingleKpiWidgetSection = (
  section: ReportEagleEyeDashboardSection,
) =>
  section.widgetType === EAGLE_EYE_DASHBOARD_SECTION_TYPE.XY_CHART_SINGLE_KPI;
export const isAverageActualRentChartWidgetSection = (
  section: ReportObjectDashboardSection,
): section is XYChartWidgetSection =>
  section.widgetType ===
  OBJECT_DASHBOARD_SECTION_TYPE.AVERAGE_ACTUAL_RENT_CHART;
