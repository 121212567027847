import {
  WidgetContextProps,
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import { XyChartSingleKpiWidgetDto } from 'bundles/Shared/shared/api/dashboardsGeneratedApi';
import { XYChartSingleKpiWidgetSection } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/model';
import { EagleEyeDashboardWidgetContext } from 'bundles/Shared/widgets/dashboard/widgetsHelpers';
import React, { useMemo, useRef } from 'react';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import {
  addCenteredLegend,
  addCursor,
  addDateXAxis,
  addLabelToYAxis,
  addValueYAxis,
  chartDateMapper,
  createXYChart,
  getDefaultLabelStyle,
  getReturnDashboardTheme,
} from 'lib/amcharts/utils';
import { intersection, omit } from 'lodash-es';
import { useAmchart } from 'lib/amcharts/useAmchart';
import { sanitizeFileName } from 'lib/uploadFiles';
import {
  DEFAULT_AMCHART_DATE_FORMATS,
  getAmchartNumberFormatForByDisplayOptions,
} from '@/shared/lib/formatting/charts';
import {
  applyFormatToCell,
  getFirstSheetFromWorkbook,
  getSeriesColor,
  sanitizeCells,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib';
import { PIPELINE_CHART_COLOR_SET } from 'bundles/Scoreboard/Pipeline/components/chartUtils';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5 from '@amcharts/amcharts5';
import { mapListToIds } from '@/shared/lib/listHelpers';
import ReportSegmentDropdown from 'bundles/Shared/entities/segment/ui/ReportSegmentDropdown';
import { AssetDropdown } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/ui/AssetDropdown';
import {
  DashboardWidgetCard,
  DateRangeWidgetState,
  WidgetStateGranularity,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { cn } from '@/shared/lib/css/cn';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { ThinTabGroup } from '@/stories';
import SecondaryDropdown from 'bundles/Shared/components/SecondaryDropdown';
import { WidgetStateCalendarRangeSelector } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateCalendarRangeSelector';
import { ExportChartButton } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/ExportChartButton';
import AnimationLoader from 'stories/AnimationLoader/AnimationLoader';
import {
  ReportDashboardAsset,
  ReportDashboardSegment,
  WidgetDateGranularity,
} from 'bundles/Shared/entities/dashboard';

type GroupingType = 'assets' | 'segments';
export type XYChartSingleKpiWidgetState = DateRangeWidgetState & {
  granularity: WidgetDateGranularity;
  assets: ReportDashboardAsset['id'][];
  segments: ReportDashboardSegment['id'][];
  groupingType: GroupingType;
  kpi?: number;
};

export function EagleEyeXYChartWidget({
  data,
  mode,
  widgetSection,
  state,
  onStateChange,
  isFetching,
  context,
}: WidgetProps<XyChartSingleKpiWidgetDto, XYChartSingleKpiWidgetSection> &
  WidgetStateProps<XYChartSingleKpiWidgetState> &
  WidgetContextProps<EagleEyeDashboardWidgetContext>) {
  const selectedKpi = widgetSection.widgetConfig.kpis.find(
    (kpi) => kpi.key === state.kpi,
  );
  const ref = useRef<{
    exporting: am5plugins_exporting.Exporting;
  }>(null);

  const items = useMemo(() => {
    const mappedItems = data?.items
      .map(chartDateMapper('dateFrom'))
      .map((item) => ({
        ...omit(item, 'values'),
        ...item.values,
      }));
    const filteredItems = mappedItems?.filter(
      (item) => item.kpiKey === selectedKpi?.key,
    );
    return filteredItems ?? [];
  }, [data, selectedKpi]);

  useAmchart(
    ref,
    (root) => {
      if (context.assets == null || context.segments == null) {
        return;
      }
      const seriesNames =
        state.groupingType === 'assets'
          ? state.assets.map(
              (id) => context.assets.find((item) => item.id === id)!.name,
            )
          : state.segments.map(
              (id) => context.segments.find((item) => item.id === id)!.title,
            );

      ref.current!.exporting = am5plugins_exporting.Exporting.new(root, {
        filePrefix: sanitizeFileName(widgetSection.title),
        dataSource: items,
        dateFields: ['dateFrom'],
        dateFormat: DEFAULT_AMCHART_DATE_FORMATS[state.granularity],
        numericFields: seriesNames,
        dataFields: {
          dateFrom: 'Date',
          ...Object.fromEntries(
            seriesNames.map((seriesName) => [seriesName, seriesName]),
          ),
        },
      });

      ref.current!.exporting.events.on('workbookready', function (event) {
        if (selectedKpi?.value_display_options == null) {
          return;
        }
        const dataSheet = getFirstSheetFromWorkbook(event.workbook);
        const cellKeys = sanitizeCells(dataSheet);
        cellKeys.forEach((cellKey) => {
          // eslint-disable-next-line no-param-reassign
          applyFormatToCell(
            dataSheet[cellKey],
            selectedKpi.value_display_options!,
          );
        });
      });

      const myTheme = getReturnDashboardTheme(root);
      myTheme.rule('ColorSet').set('colors', PIPELINE_CHART_COLOR_SET);
      root.setThemes([am5themesAnimated.new(root), myTheme]);

      const chart = createXYChart(root, {});
      root.numberFormatter.setAll({
        numberFormat: getAmchartNumberFormatForByDisplayOptions(
          selectedKpi?.value_display_options ?? null,
          {
            abbreviate: false,
          },
        ),
      });
      const { xAxis } = addDateXAxis(root, chart, {
        baseInterval: { timeUnit: state.granularity, count: 1 },
        paddingTop: 10,
      });

      xAxis.data.setAll(items);

      const { yAxis } = addValueYAxis(root, chart, {
        numberFormat: getAmchartNumberFormatForByDisplayOptions(
          selectedKpi?.value_display_options ?? null,
          {
            abbreviate: false,
          },
        ),
      });
      addLabelToYAxis(root, yAxis, {
        text: selectedKpi?.label,
        ...getDefaultLabelStyle(),
      });

      const createSeries = (seriesName: string, index: number) => {
        const color = getSeriesColor({
          seriesName,
          index,
        });

        const lineSeries = chart.series.push(
          am5xy.LineSeries.new(root, {
            // todo replace assets and segments with objects prop
            name: seriesName,
            xAxis,
            yAxis,
            valueYField: seriesName,
            valueXField: 'dateFrom',
            tooltip: am5.Tooltip.new(root, {
              labelText: '{name}: {valueY}',
            }),
            fill: color,
            stroke: color,
          }),
        );

        lineSeries.strokes.template.setAll({
          strokeWidth: 2,
          strokeOpacity: 1,
        });

        lineSeries.bullets.push(() =>
          am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              radius: 4,
              fill: lineSeries.get('fill'),
            }),
          }),
        );

        lineSeries.data.setAll(items);
      };

      seriesNames.forEach((seriesName, index) => {
        createSeries(seriesName, index);
      });

      addCursor(root, chart, {
        xAxis,
      });

      addCenteredLegend(root, chart, {
        y: am5.percent(100) - 100,
        maxHeight: 300,
        layout: am5.GridLayout.new(root, {
          fixedWidthGrid: true,
        }),
      });
    },
    [items],
  );
  const getFilterByObjects = () => {
    const getStateChangeHandler = (key: GroupingType) => (ids: number[]) => {
      onStateChange({
        ...state,
        [key]: ids,
      });
    };

    const getValue = (key: GroupingType) => {
      const contextObjectIds = mapListToIds(context[key] ?? []);
      if (state[key] != null) {
        // filter set object can include extra objects, which are not in the dashboard context
        // TODO: move to slice
        return intersection(state[key], contextObjectIds);
      }
      return contextObjectIds;
    };

    return state.groupingType === 'segments' ? (
      <ReportSegmentDropdown
        segments={context.segments ?? []}
        value={getValue('segments')}
        onChange={getStateChangeHandler('segments')}
      />
    ) : (
      <AssetDropdown
        assets={context.assets ?? []}
        value={getValue('assets')}
        onChange={getStateChangeHandler('assets')}
      />
    );
  };
  return (
    <DashboardWidgetCard className={cn(mode === 'edit' && 'h-[500px]')}>
      <DashboardWidgetCard.Header className="gap-tw-2">
        <DashboardWidgetCard.Header.Title>
          {widgetSection.title}
        </DashboardWidgetCard.Header.Title>
        <GrowDiv />
        <ThinTabGroup
          selectedItem={state.groupingType}
          onSelectedItemChange={(newItem) => {
            onStateChange({
              ...state,
              groupingType: newItem.id,
            });
          }}
          items={[
            {
              label: 'Assets',
              id: 'assets',
            },
            {
              label: 'Segments',
              id: 'segments',
            },
          ]}
        />
      </DashboardWidgetCard.Header>
      <DashboardWidgetCard.Panel>
        <SecondaryDropdown
          maxWidth="max-content"
          items={
            widgetSection.widgetConfig.kpis?.map((kpi) => ({
              label: kpi.label,
              id: kpi.key,
            })) ?? []
          }
          selectedItem={state.kpi}
          onSelectedChange={(key) =>
            onStateChange({
              ...state,
              kpi: key,
            })
          }
        />
        {getFilterByObjects()}
        <WidgetStateGranularity
          state={state}
          onStateChange={onStateChange}
          chartRef={ref}
          granularities={widgetSection.defaultOptions.granularities}
        />
        <WidgetStateCalendarRangeSelector
          state={state}
          onStateChange={onStateChange}
        />
        <div className="grow" />
        <ExportChartButton chartRef={ref} />
      </DashboardWidgetCard.Panel>
      {isFetching && <AnimationLoader />}
      <div className="grow" ref={ref} />
    </DashboardWidgetCard>
  );
}
