import { cn } from '@/shared/lib/css/cn';
import React from 'react';

interface Props {
  className?: string;
  title?: string;
}

function DashboardCard({
  title,
  className,
  children,
}: React.PropsWithChildren<Props>) {
  return (
    <div
      className={cn(
        'flex flex-col gap-m rounded-[16px] bg-white p-m',
        className,
      )}
    >
      {title != null && <h6 className="dark-60 header6-bold">{title}</h6>}
      {children}
    </div>
  );
}

export default DashboardCard;
