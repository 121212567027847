import { useReportSettingsDashboardScreenParams } from 'bundles/Settings/components/REport/Dashboards/Dashboard/lib';
import { selectReportDashboardMetadataById } from 'bundles/Shared/entities/dashboard';
import { ReportDashboardDateFilterBlock } from 'bundles/Shared/features/dashboard/object/filter/byDate';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { selectReportComparisonDashboardMetadataById } from '@/bundles/Shared/entities/dashboard/model/slices/comparisonSlice';

export const SettingsReportComparisonDashboardDateFilter = () => {
  const { dashboardId } = useReportSettingsDashboardScreenParams();
  const dashboardState = useAppSelector((state) =>
    selectReportComparisonDashboardMetadataById(state, dashboardId ?? ''),
);
  const { date } = dashboardState ?? {};

  return (
    <ReportDashboardDateFilterBlock
      dashboardId={dashboardId}
      date={date ?? null}
    />
  );
}

export function SettingsReportObjectDashboardDateFilter() {
  const { dashboardId } = useReportSettingsDashboardScreenParams();
  const dashboardState = useAppSelector((state) =>
    selectReportDashboardMetadataById(state, dashboardId ?? ''),
  );
  const { date } = dashboardState ?? {};

  return (
    <ReportDashboardDateFilterBlock
      dashboardId={dashboardId}
      date={date ?? null}
    />
  );
}
