import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { DashboardDividerSection } from 'bundles/Shared/widgets/dashboard/widgets/divider/ui/DashboardDividerSection';

export const DIVIDER_CONFIG: WidgetConfiguration = {
  icon: 'divider',
  title: 'Divider',
  Component: DashboardDividerSection,
  layout: {
    minW: 2,
    maxW: 2,
    minH: 4,
    maxH: 6,
    w: 2,
    h: 4,
  },
};
