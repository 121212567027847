import React from 'react';
import { IconButton } from 'stories/index';
import { cn } from '@/shared/lib/css/cn';
import { DRAG_N_DROP_WIDGET_BUTTON_TOOLTIP } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/config';

const SCORECARD_SECTION_DRAG_BUTTON_HANDLE = 'scorecard-section-drag-button';

function DashboardSectionDragButton() {
  return (
    <IconButton
      className={cn(SCORECARD_SECTION_DRAG_BUTTON_HANDLE, '!cursor-move')}
      size="s"
      variant="secondary"
      iconName="move"
      tooltipProps={{
        mainText: DRAG_N_DROP_WIDGET_BUTTON_TOOLTIP,
        placement: 'top-start',
        arrowPosition: 'start',
      }}
    />
  );
}

DashboardSectionDragButton.HANDLE = `.${SCORECARD_SECTION_DRAG_BUTTON_HANDLE}`;

export default DashboardSectionDragButton;
