import { navigate } from '@reach/router';
// @ts-ignore we have no declaration file for this pkg
import ahoy from '@symmetre-inc/ahoy';
import axios from 'axios';
import { getToken, inImpersonationMode } from './currentUser';

const { fetch: originalFetch } = window;

export const API_URL: string = import.meta.env.VITE_SYMMETRE_API_URL;
export const DEFAULT_MOCK_API_ID = '92099872';
export const MOCK_API_URL =
  'https://stoplight.io/mocks/symmetre/open-api/apiId';

interface IMockApiUrlParams {
  mockServerBranchName?: string;
  apiId?: string;
}

export const resolveMockApiUrl = ({
  mockServerBranchName,
  apiId = DEFAULT_MOCK_API_ID,
}: IMockApiUrlParams) => {
  const mockApiUrlWithId = MOCK_API_URL.replace('apiId', apiId);
  if (!mockServerBranchName) return mockApiUrlWithId;

  const str = '/open-api';

  return mockApiUrlWithId.replace(str, `${str}:${mockServerBranchName}`);
};

const STAGING_SUBDOMAINS = [
  'whiskey',
  'tango',
  'foxtrot',
  'uniform',
  'rum',
] as const;

export const [
  WHISKEY_SUBDOMAIN,
  TANGO_SUBDOMAIN,
  FOXTROT_SUBDOMAIN,
  UNIFORM_SUBDOMAIN,
  RUM_SUBDOMAIN,
] = STAGING_SUBDOMAINS;

const CLIENT_SUBDOMAINS = ['subtext', 'greenstreet', 'jg2'] as const;

export const [SUBTEXT_SUBDOMAIN, GREENSTREET_SUBDOMAIN, JG2_SUBDOMAIN] =
  CLIENT_SUBDOMAINS;

export type StagingSubdomains = (typeof STAGING_SUBDOMAINS)[number];
export type ClientSubdomains = (typeof CLIENT_SUBDOMAINS)[number];
export type AllSubdomains = StagingSubdomains | ClientSubdomains;

export const DOMAINS = ['symmetre', 'lvh', 'vercel'] as const;

export const [SYMMETRE_DOMAIN, LOCAL_DEV_DOMAIN, VERCEL_DOMAIN] = DOMAINS;

export const [SUBDOMAIN, DOMAIN] = window.location.host.split('.') as [
  AllSubdomains,
  (typeof DOMAINS)[number],
];

export const IS_VERCEL_PREVIEW = DOMAIN === VERCEL_DOMAIN;
export const IS_SYMMETRE_DOMAIN = DOMAIN === SYMMETRE_DOMAIN;
export const IS_WHISKEY_SUBDOMAIN = SUBDOMAIN === WHISKEY_SUBDOMAIN;
export const IS_RUM_SUBDOMAIN = SUBDOMAIN === RUM_SUBDOMAIN;
export const IS_STAGING =
  STAGING_SUBDOMAINS.includes(SUBDOMAIN) || IS_VERCEL_PREVIEW;

export const IS_UNIFORM = [UNIFORM_SUBDOMAIN, RUM_SUBDOMAIN].includes(
  SUBDOMAIN,
);

export const isSubdomain = (s: AllSubdomains) => s === SUBDOMAIN;

// default to rum for FE / Vercel preview apps
export const STAGING_VERCEL_SUBDOMAINS = [WHISKEY_SUBDOMAIN];
export const customerName =
  API_URL === 'https://api.symmetre.email' &&
  !STAGING_VERCEL_SUBDOMAINS.includes(SUBDOMAIN)
    ? RUM_SUBDOMAIN
    : SUBDOMAIN;

const defaultHeaders = {
  'Content-Type': 'application/json',
};

ahoy.configure({
  urlPrefix: API_URL,
  visitDuration: 15, // minutes
  keepAlive: true,
});

const addAhoyHeaders = (headers: { [key: string]: string }) => {
  const ahoyHeaders = {
    'Ahoy-Visit': ahoy.getVisitId(),
    'Ahoy-Visitor': ahoy.getVisitorId(),
  };

  if (!inImpersonationMode()) Object.assign(headers, ahoyHeaders);

  return headers;
};

export const requireAuthorizationHeaders = (headers: {
  'Content-Type'?: string;
}) =>
  addAhoyHeaders({
    ...headers,
    Authorization: `Bearer ${getToken()}`,
    'X-Customer': customerName,
  });

const AUTH_PAGES = [
  '/users/sign_in',
  '/users/invitation/accept',
  '/users/password/new',
  '/users/password/edit',
];
// Add intercepting response to redirect the user to login if his token is expired
window.fetch = async (...args) => {
  const [resource, config] = args;

  const response = await originalFetch(resource, {
    headers: addAhoyHeaders({
      ...defaultHeaders,
      Authorization: `Bearer ${getToken()}`,
      'X-Customer': customerName,
    }),
    ...config,
  });

  // Might differentiate different status codes for non logged in users or for expired tokens
  // E.g. expired token error message might be shown to user
  if (response.status === 401) {
    // AD-hoc which triggers reset data if session has been expired
    window.adHocResetData && window.adHocResetData();

    if (!AUTH_PAGES.includes(window.location.pathname)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate('/users/sign_in', { state: { from: window.location.href } });
    }
    throw response;
  }
  return response;
};

const DEFAULT_MOCK_PARAMS: IMockApiUrlParams & { mocked: boolean } = {
  mocked: false,
};

const get =
  ({ mocked, ...mockParams } = DEFAULT_MOCK_PARAMS) =>
  (path: string, options?: Exclude<RequestInit, 'method'>) =>
    fetch(`${mocked ? resolveMockApiUrl(mockParams) : API_URL}/api${path}`, {
      method: 'GET',
      ...options,
    });
const post =
  ({ mocked, ...mockParams } = DEFAULT_MOCK_PARAMS) =>
  (path: string, data: any, options?: Exclude<RequestInit, 'method'>) =>
    fetch(`${mocked ? resolveMockApiUrl(mockParams) : API_URL}/api${path}`, {
      method: 'POST',
      body: JSON.stringify(data),
      ...options,
    });

const put =
  (mockParams = DEFAULT_MOCK_PARAMS) =>
  (path: string, data: any, options?: Exclude<RequestInit, 'method'>) =>
    post(mockParams)(path, data, { ...options, method: 'PUT' });

const del =
  ({ mocked, mockServerBranchName } = DEFAULT_MOCK_PARAMS) =>
  (
    path: string,
    data?: Record<string, unknown>,
    options?: Exclude<RequestInit, 'method'>,
  ) =>
    post({ mocked, mockServerBranchName })(path, data, {
      ...options,
      method: 'DELETE',
    });

export async function create(
  url: string,
  data: Record<string, unknown>,
  name: string,
) {
  const res = await post()(url, data);
  const responseData = await res.json();
  if (
    typeof responseData === 'object' &&
    responseData != null &&
    'errors' in responseData
  ) {
    window.toastr.error(`${responseData.errors} \n`);
    return false;
  }

  window.toastr.success(`${name} has been created`);
  return responseData;
}

export async function update(
  url: string,
  data: Record<string, unknown>,
  name: string,
) {
  const res = await put()(`${url}/${data.id}`, data);
  const responseData = await res.json();
  if (
    typeof responseData === 'object' &&
    responseData != null &&
    'errors' in responseData
  ) {
    window.toastr.error(`${responseData.errors}\n`);
    return false;
  }
  window.toastr.success(`${name} has been updated`);
  return responseData;
}

export async function destroy(
  url: string,
  data: Record<string, unknown>,
  name: string,
) {
  const res = await del()(`${url}/${data.id}`);
  const responseData = await res.json();

  if (
    typeof responseData === 'object' &&
    responseData != null &&
    'errors' in responseData
  ) {
    window.toastr.error(`${responseData.errors}\n`);
    return false;
  }

  window.toastr.success(`${name} has been deleted`);
  return true;
}

export const apiClient = axios.create({
  baseURL: API_URL,
  headers: addAhoyHeaders({
    'Content-Type': 'application/json',
    'X-Customer': customerName,
  }),
});

const httpMock = (mockParams?: IMockApiUrlParams) => ({
  get: get({ mocked: true, ...mockParams }),
  post: post({ mocked: true, ...mockParams }),
  put: put({ mocked: true, ...mockParams }),
  del: del({ mocked: true, ...mockParams }),
  create,
  update,
  destroy,
  apiClient,
});

export default {
  get: get(),
  post: post(),
  put: put(),
  del: del(),
  create,
  update,
  destroy,
  apiClient,
  mock: httpMock,
};
