import {
  DeleteApiCapitalInvestmentContributionsByIdApiResponse,
  DeleteApiCapitalInvestmentDistributionsByIdApiResponse,
  DeleteApiCapitalInvestmentsByIdApiResponse,
  DeleteApiCapitalUnitPricesByIdApiResponse,
  PostApiCapitalInvestmentContributionsBulkApiResponse,
  PostApiCapitalInvestmentDistributionsBulkApiResponse,
  PostApiCapitalInvestmentsApiResponse,
  PostApiCapitalPreferredReturnsApiResponse,
  PostApiCapitalUnitPricesApiResponse,
  PutApiCapitalInvestmentContributionsByIdApiResponse,
  PutApiCapitalInvestmentDistributionsByIdApiArg,
  PutApiCapitalInvestmentsByIdApiResponse,
  capitalInvestmentObjectsApi,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';

export const capitalInvestmentObjectsEnhancedApi =
  capitalInvestmentObjectsApi.enhanceEndpoints({
    addTagTypes: [
      'CapitalInvestmentObject',
      'CapitalInvestmentObjectList',
      'TransactionSources',
      'CapitalInvestments',
      'PreferredReturn',
      'UnitPrices',
      'Distributions',
      'Commitments',
      'Contributions',
      'AccruedPreferred',
    ],
    endpoints: {
      getApiCapitalInvestmentObjectSummaries: {
        providesTags: ['CapitalInvestmentObjectList'],
      },
      getApiCapitalInvestmentObjectsById: {
        providesTags: ['CapitalInvestmentObject'],
      },
      getApiCapitalInvestmentObjectsByIdPreferredReturn: {
        providesTags: ['PreferredReturn'],
      },
      putApiCapitalInvestmentObjectsById: {
        transformResponse: (
          response: PutApiCapitalInvestmentsByIdApiResponse,
        ) => {
          toastr.success('Successfully updated');
          return response;
        },
        async onQueryStarted({ body, id }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            capitalInvestmentObjectsEnhancedApi.util.updateQueryData(
              'getApiCapitalInvestmentObjectsById',
              { id },
              (draft) => {
                Object.assign(draft, {
                  fromSourceEnabled: body.from_source_enabled,
                });
              },
            ),
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
      },
      postApiCapitalPreferredReturns: {
        invalidatesTags: ['PreferredReturn', 'CapitalInvestmentObject'],
        transformResponse: (
          response: PostApiCapitalPreferredReturnsApiResponse,
        ) => {
          toastr.success('Preferred REturn created');
          return response;
        },
      },
      putApiCapitalPreferredReturnsById: {
        invalidatesTags: ['PreferredReturn'],
        transformResponse: (
          response: PostApiCapitalPreferredReturnsApiResponse,
        ) => {
          toastr.success('Preferred REturn updated');
          return response;
        },
      },
      getApiCapitalTransactionSources: {
        providesTags: ['TransactionSources'],
      },

      // #region Capital Unit Prices
      getApiCapitalUnitPrices: {
        providesTags: ['UnitPrices'],
      },
      deleteApiCapitalUnitPricesById: {
        transformResponse: (
          response: DeleteApiCapitalUnitPricesByIdApiResponse,
        ) => {
          toastr.success('Unit Price deleted');
          return response;
        },
        invalidatesTags: ['UnitPrices', 'CapitalInvestments'],
      },
      putApiCapitalUnitPricesById: {
        transformResponse: (response: PostApiCapitalUnitPricesApiResponse) => {
          toastr.success('Unit Price updated');
          return response;
        },
        invalidatesTags: ['UnitPrices', 'CapitalInvestments'],
      },
      postApiCapitalUnitPrices: {
        transformResponse: (response: PostApiCapitalUnitPricesApiResponse) => {
          toastr.success('Unit Price created');
          return response;
        },
        invalidatesTags: ['UnitPrices', 'CapitalInvestments'],
      },
      // #endregion

      // #region Capital Investments
      getApiCapitalInvestments: {
        providesTags: ['CapitalInvestments'],
      },
      postApiCapitalInvestments: {
        invalidatesTags: ['CapitalInvestments'],
        transformResponse: (response: PostApiCapitalInvestmentsApiResponse) => {
          toastr.success('Successfully created');
          return response;
        },
      },
      putApiCapitalInvestmentsById: {
        invalidatesTags: ['CapitalInvestments'],
        transformResponse: (
          response: PutApiCapitalInvestmentsByIdApiResponse,
        ) => {
          toastr.success('Successfully updated');
          return response;
        },
      },
      deleteApiCapitalInvestmentsById: {
        invalidatesTags: ['CapitalInvestments'],
        transformResponse: (
          response: DeleteApiCapitalInvestmentsByIdApiResponse,
        ) => {
          toastr.success('Successfully deleted');
          return response;
        },
      },
      // #endregion

      // #region Distributions
      getApiCapitalInvestmentDistributions: {
        providesTags: ['Distributions'],
      },
      postApiCapitalInvestmentDistributionsBulk: {
        invalidatesTags: ['Distributions', 'CapitalInvestments'],
        transformResponse: (
          response: PostApiCapitalInvestmentDistributionsBulkApiResponse,
        ) => {
          toastr.success('Successfully updated');
          return response;
        },
      },
      putApiCapitalInvestmentDistributionsById: {
        invalidatesTags: ['Distributions', 'CapitalInvestments'],
        transformResponse: (
          response: PutApiCapitalInvestmentDistributionsByIdApiArg,
        ) => {
          toastr.success('Distribution has been updated');
          return response;
        },
      },
      deleteApiCapitalInvestmentDistributionsById: {
        invalidatesTags: ['Distributions', 'CapitalInvestments'],
        transformResponse: (
          response: DeleteApiCapitalInvestmentDistributionsByIdApiResponse,
        ) => {
          toastr.success('Distribution has been removed');
          return response;
        },
      },
      postApiCapitalInvestmentDistributionTransactionMappingsBulk: {
        transformResponse: (response) => {
          toastr.success('Transaction has been linked');
          return response;
        },
        invalidatesTags: ['CapitalInvestments', 'Distributions'],
      },
      // #endregion

      // #region Commitments

      getApiCapitalInvestmentCommitments: {
        providesTags: ['Commitments'],
      },

      deleteApiCapitalInvestmentCommitmentsById: {
        invalidatesTags: ['Commitments', 'CapitalInvestments', 'Contributions'],
        transformResponse: (response) => {
          toastr.success('Commitment has been removed');
          return response;
        },
      },

      postApiCapitalInvestmentCommitmentsBulk: {
        invalidatesTags: ['Commitments', 'CapitalInvestments', 'Contributions'],
        transformResponse: (response) => {
          toastr.success('Commitment(s) has been added');
          return response;
        },
      },

      putApiCapitalInvestmentCommitmentsById: {
        invalidatesTags: ['Commitments', 'CapitalInvestments', 'Contributions'],
        transformResponse: (response) => {
          toastr.success('Commitment has been updated');
          return response;
        },
      },

      // #endregion

      // #region Contributions
      getApiCapitalInvestmentContributions: {
        providesTags: ['Contributions'],
      },
      postApiCapitalInvestmentContributionsBulk: {
        invalidatesTags: [
          'Contributions',
          'CapitalInvestments',
          'AccruedPreferred',
        ],
        transformResponse: (
          response: PostApiCapitalInvestmentContributionsBulkApiResponse,
        ) => {
          toastr.success('Successfully updated');
          return response;
        },
      },

      postApiCapitalInvestmentContributionTransactionMappingsBulk: {
        invalidatesTags: [
          'CapitalInvestments',
          'Contributions',
          'AccruedPreferred',
        ],
        transformResponse: (response) => {
          toastr.success('Transaction has been linked');
          return response;
        },
      },
      putApiCapitalInvestmentContributionsById: {
        invalidatesTags: [
          'Contributions',
          'CapitalInvestments',
          'AccruedPreferred',
        ],
        transformResponse: (
          response: PutApiCapitalInvestmentContributionsByIdApiResponse,
        ) => {
          toastr.success('Contribution updated');
          return response;
        },
      },
      deleteApiCapitalInvestmentContributionsById: {
        invalidatesTags: [
          'Contributions',
          'CapitalInvestments',
          'AccruedPreferred',
        ],
        transformResponse: (
          response: DeleteApiCapitalInvestmentContributionsByIdApiResponse,
        ) => {
          toastr.success('Contribution removed');
          return response;
        },
      },

      getApiCapitalInvestmentAccruedPreferredReturns: {
        providesTags: ['AccruedPreferred'],
      },
      postApiCapitalInvestmentAccruedPreferredReturnsBulk: {
        invalidatesTags: ['AccruedPreferred', 'CapitalInvestments'],
        transformResponse: (response) => {
          toastr.success('Accrued Pref. successfully added');
          return response;
        },
      },
      putApiCapitalInvestmentAccruedPreferredReturnsById: {
        invalidatesTags: ['AccruedPreferred', 'CapitalInvestments'],
        transformResponse: (response) => {
          toastr.success('Accrued Preferred Returns updated');
          return response;
        },
      },
      deleteApiCapitalInvestmentAccruedPreferredReturnsById: {
        invalidatesTags: ['AccruedPreferred', 'CapitalInvestments'],
        transformResponse: (response) => {
          toastr.success('Accrued Preferred Returns removed');
          return response;
        },
      },
      putApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAccruedEntriesAndId:
        {
          invalidatesTags: ['AccruedPreferred', 'CapitalInvestments'],
          transformResponse: (response) => {
            toastr.success('Accrued Preferred Returns updated');
            return response;
          },
        },
    },
  });

export const {
  // Capital Transaction Sources
  useGetApiCapitalTransactionSourcesQuery,

  // Investment Objects
  useGetApiCapitalInvestmentObjectSummariesQuery,
  useGetApiCapitalInvestmentObjectsByIdQuery,
  usePutApiCapitalInvestmentObjectsByIdMutation,

  // Preferred Returns
  useGetApiCapitalInvestmentObjectsByIdPreferredReturnQuery,
  usePostApiCapitalPreferredReturnsMutation,
  usePutApiCapitalPreferredReturnsByIdMutation,

  // Unit Prices
  useGetApiCapitalUnitPricesQuery,
  usePostApiCapitalUnitPricesMutation,
  usePutApiCapitalUnitPricesByIdMutation,
  useDeleteApiCapitalUnitPricesByIdMutation,

  // Capital Investments
  useGetApiCapitalInvestmentsQuery,
  usePostApiCapitalInvestmentsMutation,
  useDeleteApiCapitalInvestmentsByIdMutation,
  usePutApiCapitalInvestmentsByIdMutation,

  // Contributions
  useGetApiCapitalInvestmentContributionsQuery,
  useDeleteApiCapitalInvestmentContributionsByIdMutation,
  usePostApiCapitalInvestmentContributionsBulkMutation,
  usePutApiCapitalInvestmentContributionsByIdMutation,
  // Transaction Mappings For Contributions
  usePostApiCapitalInvestmentContributionTransactionMappingsBulkMutation,

  // Distributions
  useGetApiCapitalInvestmentDistributionsQuery,
  useDeleteApiCapitalInvestmentDistributionsByIdMutation,
  usePostApiCapitalInvestmentDistributionsBulkMutation,
  usePutApiCapitalInvestmentDistributionsByIdMutation,
  // Transaction Mappings For Distributions
  usePostApiCapitalInvestmentDistributionTransactionMappingsBulkMutation,

  // Commitments
  useGetApiCapitalInvestmentCommitmentsQuery,
  usePostApiCapitalInvestmentCommitmentsBulkMutation,
  usePutApiCapitalInvestmentCommitmentsByIdMutation,
  useDeleteApiCapitalInvestmentCommitmentsByIdMutation,

  // Accrued
  useGetApiCapitalInvestmentAccruedPreferredReturnsQuery,
  usePostApiCapitalInvestmentAccruedPreferredReturnsBulkMutation,
  usePutApiCapitalInvestmentAccruedPreferredReturnsByIdMutation,
  useDeleteApiCapitalInvestmentAccruedPreferredReturnsByIdMutation,

  // Accrued Entries
  usePostApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAccruedEntriesMutation,
  usePutApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAccruedEntriesAndIdMutation,
} = capitalInvestmentObjectsEnhancedApi;
