import { GroupCellRenderer } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/GroupCellRenderer';
import { CssVar } from '@/shared/config/cssVar';
import { cn } from '@/shared/lib/css/cn';
import { isSameDay, isSameQuarter, isSameYear } from '@/shared/lib/date';
import { isElementScrollWidthGreaterThanClientWidth } from '@/shared/lib/dom/utils';
import { formatDate } from '@/shared/lib/formatting/dates';
import { useIsTextTruncated } from '@/shared/lib/hooks/useIsTextTruncated';
import { Icon, Tooltip } from '@/stories';
import {
  ColDef,
  GridOptions,
  ICellRendererParams,
  IHeaderParams,
} from 'ag-grid-community';
import { BasicCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/BasicCellRenderer';
import { CELL_CLASS_NAMES } from 'bundles/Shared/components/AgGrid/Table/classNames';
import { NEXT_GEN_TABLE_CONFIG } from 'bundles/Shared/components/AgGrid/Table/consts';
import { TrailingPeriodType } from 'bundles/Shared/entities/dashboard';
import { WidgetTableGroupHeaderGroup } from 'bundles/Shared/widgets/dashboard/widgets/common';
import { TimePeriodType } from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { WidgetTableHeaderGroupComponentParams } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetHeaderGroup';
import {
  TableVizConfigColumn,
  TableVizConfigColumnGroup,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  buildExcelStyleId,
  TABLE_EXCEL_STYLE_IDS,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import { WidgetProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import dayjs, { Dayjs } from 'dayjs';
import { AUTO_GROUP_COLUMN_KEY } from 'lib/ag-grid/constants';
import React, { useMemo } from 'react';
import AnimationLoader from 'stories/AnimationLoader/AnimationLoader';
import { IOutputFormatterProps } from 'stories/ValueFormatters/OutputFormatter';
import {
  HeaderComponentWithSubHeader,
  HeaderWithSubHeaderParams,
} from './HeaderComponentWithSubHeader';

export const COLUMN_DEF_MIN_WIDTH = 60;
export const DEFAULT_GROUP_BG_CLASS = '!bg-primary-090';
export const DEFAULT_GROUP_BG_COLOR = CssVar.primary090;

export const AUTO_COLUM_DEF_MIN_WIDTH = 200;
export const AUTO_COLUM_DEF_MAX_WIDTH = 600;

export const DEFAULT_GROUP_TEXT_CLASS = 'text-neutral-900';
export const WIDGET_TABLE_AUTO_GROUP_COL_DEF = {
  ...NEXT_GEN_TABLE_CONFIG.column.autoGroupDef,
  colId: AUTO_GROUP_COLUMN_KEY,
  minWidth: AUTO_COLUM_DEF_MIN_WIDTH,
  sortable: true,
  headerComponentParams: {
    classes: {
      wrapper: DEFAULT_GROUP_BG_CLASS,
      inner: '!justify-center',
    },
  },
  cellRenderer: (p) => <GroupCellRenderer {...p} />,
} as const satisfies ColDef;

export const useWidgetTableDefaultGroupDef = (mode: WidgetProps['mode']) => {
  return useMemo(
    () => ({
      headerGroupComponent: WidgetTableGroupHeaderGroup,
      headerGroupComponentParams: {
        classes: {
          wrapper: DEFAULT_GROUP_BG_CLASS,
        },
        mode,
      } satisfies WidgetTableHeaderGroupComponentParams,
    }),
    [mode],
  );
};

export const PIN_TOOLTIP_TEXT =
  'By default, the first column will be shown in the collapsed view. You can choose which columns to show when the table is collapsed';

export const PinColumnAction = ({
  mode,
  onPinColumn,
  ...params
}: IHeaderParams & {
  mode: WidgetProps['mode'];
  onPinColumn?: (colId: string) => void;
}) => {
  const parent = params.column.getParent();
  const colGroupDef = parent?.getColGroupDef();
  const colDef = params.column.getColDef();

  return mode === 'edit' && colGroupDef && colGroupDef.children?.length > 1 ? (
    <Tooltip delay={700} mainText={PIN_TOOLTIP_TEXT}>
      <Icon
        onClick={() =>
          onPinColumn?.(
            params.column.getUserProvidedColDef()?.colId ??
              params.column.getColId(),
          )
        }
        className={cn(
          'cursor-pointer text-neutral-000 opacity-20 hover:opacity-100',
          colDef.columnGroupShow == null && 'opacity-100',
        )}
        iconName="menuPin"
      />
    </Tooltip>
  ) : null;
};

export const DEFAULT_WIDGET_TABLE_COL_DEF = {
  ...NEXT_GEN_TABLE_CONFIG.column.defaultDef,
  headerComponentParams: {
    enableMenu: false,
  },
  cellRenderer: (params: ICellRendererParams) => {
    const textRef = React.useRef<HTMLSpanElement>(null);
    const overflowedByWidth = useIsTextTruncated(textRef, params.value);

    return (
      <BasicCellRenderer {...params}>
        <Tooltip
          disabled={!overflowedByWidth}
          mainText={params.value}
          classes={{ spanContainer: 'min-w-0' }}
        >
          <span className="text-ellipsis" ref={textRef}>
            {params.value ?? '-'}
          </span>
        </Tooltip>
      </BasicCellRenderer>
    );
  },
  headerComponent: HeaderComponentWithSubHeader,
} as const satisfies ColDef;

export const resolveDefaultColumnMinWidth = (isCompactSelected: boolean) =>
  isCompactSelected
    ? COLUMN_DEF_MIN_WIDTH
    : NEXT_GEN_TABLE_CONFIG.column.default.width;

export const DEFAULT_WIDGET_TABLE_PROPS = {
  domLayout: 'autoHeight',
  noRowsOverlayComponent: () => null,
  loadingOverlayComponent: AnimationLoader.Widget,
  treeData: false,
  groupIncludeTotalFooter: false,
  defaultColDef: DEFAULT_WIDGET_TABLE_COL_DEF,
  headerHeight: 40,
  rowHeight: 40,
  getRowId: undefined,
} as const satisfies GridOptions;

export const useWidgetTableDefaultColDef = ({
  mode,
  onPinColumn,
  isCompactSelected = false,
  deps = [],
}: {
  mode: WidgetProps['mode'];
  onPinColumn?: (colId: string) => void;
  isCompactSelected?: boolean;
  deps?: React.DependencyList;
}): Partial<ColDef> => {
  const defaultColDef = {
    ...DEFAULT_WIDGET_TABLE_PROPS.defaultColDef,
    resizable: true,
    flex: undefined,
    sortable: mode != 'pdf',
    minWidth: resolveDefaultColumnMinWidth(isCompactSelected),
    headerComponentParams: (params: IHeaderParams) => {
      const parent = params.column.getParent();

      const colGroupDef = parent?.getColGroupDef();
      const { style } = colGroupDef?.headerGroupComponentParams ?? {};

      return {
        classes: {
          // don't apply bg class to the column in groupclasses: {
          subHeader: colGroupDef != null ? 'text-neutral-000' : '',
          wrapper: cn(style == null && DEFAULT_GROUP_BG_CLASS),
        },
        style,
        actions: (
          <PinColumnAction {...params} mode={mode} onPinColumn={onPinColumn} />
        ),
      } satisfies HeaderWithSubHeaderParams;
    },
    cellClassRules: {
      [TABLE_EXCEL_STYLE_IDS.totalBackground]: (params) =>
        params.data?.type === 'total',
    },
    cellClass: ({ colDef }) => colDef.colId && buildExcelStyleId(colDef.colId),
    cellRendererParams: ({ data: row, colDef }: ICellRendererParams) => {
      const isTotalRow = row && row?.type === 'total';
      const formatterParams = isTotalRow
        ? ({
            classes: {
              allParts: 'text-neutral-000',
            },
          } satisfies Partial<IOutputFormatterProps>)
        : undefined;

      return {
        formatterParams,
        classes: {
          wrapper: cn(
            colDef?.colId === AUTO_GROUP_COLUMN_KEY
              ? CELL_CLASS_NAMES.AutoGroupCell.wrapper.basic
              : CELL_CLASS_NAMES.CommonCell.wrapper.basic,
            isTotalRow &&
              '!bg-neutral-850 !border-neutral-700 !text-neutral-000',
          ),
          inner: cn(
            colDef?.colId === AUTO_GROUP_COLUMN_KEY
              ? CELL_CLASS_NAMES.AutoGroupCell.inner.basic
              : CELL_CLASS_NAMES.CommonCell.inner.basic,
            colDef?.colId === AUTO_GROUP_COLUMN_KEY && '!justify-start',
            mode === 'pdf' && '!items-start',
          ),
        },
      };
    },
  } as const satisfies Partial<ColDef>;

  return useMemo(
    () => defaultColDef,
    [mode, onPinColumn, isCompactSelected, ...deps],
  );
};

export const formatDateRangeForWidgetColumnSubHeader = (
  column: {
    dateFrom: string;
    dateTo: string;
  },
  columnConfig: {
    period_type?: TimePeriodType | TrailingPeriodType;
  },
  columnSettings?: Pick<TableVizConfigColumn, 'header'>,
) => {
  if (columnSettings?.header?.hide_subtitle === true) {
    return '';
  }
  if (!column || !columnConfig) return '';

  const { dateFrom, dateTo } = column;
  const { period_type } = columnConfig;
  const trailingPeriodType =
    typeof period_type === 'object' ? period_type.type : period_type;
  const dateFromDayjs = dayjs(dateFrom ?? undefined);
  const dateToDayjs = dayjs(dateTo ?? undefined);

  const isSameDate = isSameDay(dateFromDayjs.toDate(), dateTo);

  const format = trailingPeriodType === 'month' ? 'MMM-YY' : 'MM/DD/YYYY';
  if (trailingPeriodType === 'quarter') {
    const yearFormat = 'YY';
    const formatDateToQuarter = (date: Dayjs) => {
      return `Q${date.quarter()} ${date.format(yearFormat)}`;
    };
    if (
      isSameQuarter(dateFromDayjs.toDate(), dateToDayjs.toDate()) &&
      isSameYear(dateFromDayjs.toDate(), dateToDayjs.toDate())
    )
      return formatDateToQuarter(dateFromDayjs);
    return `${formatDateToQuarter(dateFromDayjs)} - ${formatDateToQuarter(
      dateToDayjs,
    )}`;
  }
  if (trailingPeriodType === 'year') {
    const yearFormat = 'YYYY';
    if (isSameYear(dateFromDayjs.toDate(), dateToDayjs.toDate()))
      return formatDate(dateFromDayjs, yearFormat);
    return `${formatDate(dateFromDayjs, yearFormat)} - ${formatDate(
      dateToDayjs,
      yearFormat,
    )}`;
  }
  const isMonth =
    dateFromDayjs.isSame(dateFromDayjs.startOf('month'), 'day') &&
    dateToDayjs.isSame(dateToDayjs.endOf('month'), 'day') &&
    dateFromDayjs.month() === dateToDayjs.month();

  let subHeaderName = '';

  if (isSameDate) {
    subHeaderName = formatDate(dateFromDayjs, format);
  }
  if (isMonth) {
    subHeaderName = formatDate(dateFromDayjs, 'MMM-YY');
  }
  if (!isSameDate && !isMonth) {
    subHeaderName = `${formatDate(dateFromDayjs, format)} - ${formatDate(
      dateToDayjs,
      format,
    )}`;
  }
  return subHeaderName;
};

export const formatDateRangeForWidgetGroupHeaderName = (
  column:
    | {
        dateFrom: string;
        dateTo: string;
      }
    | undefined,
  columnConfig:
    | {
        period_type?: TimePeriodType | TrailingPeriodType;
      }
    | undefined,
  group: Pick<
    TableVizConfigColumnGroup,
    'child_can_override_period' | 'header_name'
  >,
) => {
  if (
    group.child_can_override_period == null ||
    group.child_can_override_period ||
    !column ||
    !columnConfig
  ) {
    return group.header_name;
  }
  return `${group.header_name} ${formatDateRangeForWidgetColumnSubHeader(
    column,
    columnConfig,
  )}`;
};

export const getFakeColumnDefs = (
  columns: Pick<ColDef, 'headerName' | 'cellRendererParams'>[],
  rowCount: number,
) => {
  const cellRenderer = (params: ICellRendererParams) => {
    return (
      <BasicCellRenderer
        classes={{
          wrapper: cn(
            CELL_CLASS_NAMES.CommonCell.wrapper.basic,
            '!bg-neutral-100 !border-neutral-200',
          ),
        }}
        {...params}
      />
    );
  };

  if (columns.length === 0 || rowCount === 0) {
    const fakeColumnDefs =
      columns.length === 0
        ? [
            {
              headerName: 'Data Periods',
              cellRenderer,
            },
          ]
        : columns.map(({ headerName }) => ({
            headerName,
            cellRenderer,
          }));
    return [
      {
        headerName: 'Computed Rows',
        headerComponentParams: {
          subHeaderName: 'Formulas & Variables',
        },
        cellRenderer,
      },
      ...fakeColumnDefs,
    ];
  }
  return [];
};

export const getFakeRowData = (columns: { key: number }[]) =>
  Array.from({ length: 10 }).map((_) =>
    Object.fromEntries(columns.map((column) => [column.key, 0])),
  );

export const postProcessPopup: GridOptions['postProcessPopup'] = (params) => {
  // ag-grid popup filter adjustment
  if (params.column) {
    // helper function to convert and adjust style properties
    const adjustStyleProperty = (
      property: 'top' | 'left',
      adjustment: number,
    ) => {
      const currentValue = parseInt(
        params.ePopup.style[property].replace('px', ''),
        10,
      );
      // eslint-disable-next-line no-param-reassign
      params.ePopup.style[property] = `${currentValue + adjustment}px`;
    };

    adjustStyleProperty('top', 25);
    adjustStyleProperty('left', 10);
  }
};

/**
 * In order to know if an element is truncated we need to run effect on every single re-render
 * Therefore, this hook contains a useEffect without deps (meaning runs on every single re-render)
 */
export const useTruncatedHeaderTextFlags = () => {
  const [truncatedTextFlags, setTruncatedTextFlags] = React.useState<{
    header: boolean;
    subheader: boolean;
  }>({
    header: false,
    subheader: false,
  });
  const headerRef = React.useRef<HTMLParagraphElement>(null);
  const subheaderRef = React.useRef<HTMLParagraphElement>(null);

  React.useEffect(() => {
    const compareSize = () => {
      const isHeaderTruncated = isElementScrollWidthGreaterThanClientWidth(
        headerRef.current,
      );
      const isSubHeaderTruncated = isElementScrollWidthGreaterThanClientWidth(
        subheaderRef.current,
      );
      if (
        isHeaderTruncated === truncatedTextFlags.header &&
        isSubHeaderTruncated === truncatedTextFlags.subheader
      )
        return;

      setTruncatedTextFlags({
        header: isHeaderTruncated,
        subheader: isSubHeaderTruncated,
      });
    };
    compareSize();

    window.addEventListener('resize', compareSize);

    return () => {
      window.removeEventListener('resize', compareSize);
    };
  });

  return {
    headerElementRef: headerRef,
    subheaderElementRef: subheaderRef,
    truncatedTextFlags,
  };
};
