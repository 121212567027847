import FilterItem from '@/bundles/Shared/components/Filters/common/filterItem/FilterItem';
import {
  filterKnownWidgetTypes,
  reportWidgetsSliceActions,
  useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdQuery,
} from '@/entities/report/reportBuilder';

import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import { useResetAllModalsOnUnmount } from '@/shared/lib/hooks/useModal';
import {
  ROUTES_ROOT,
  TRouteQueryParams,
  generateUrl,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';

import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import { GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse } from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { useDebouncedPutApiCoreAssetsReportBuilderReportsById } from '@/entities/report/reportBuilder/lib/useDebouncedPutApiCoreAssetsReportBuilderReportsById';
import { useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdfQuery } from '@/entities/report/reportBuilder/lib/useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdfQuery';
import { WidgetConfigCard } from '@/entities/report/reportBuilder/ui/WidgetConfigCard';
import { useDeleteReport } from '@/features/report/report/deleteReport';
import { PublishReportButton } from '@/features/report/report/publishReport';
import { currentUserAllowedToSeeNavigateToReportViewPage } from '@/pages/reports/report/view/permissions';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { AnimationLoader, Button, IconButton, Input } from '@/stories';
import Calendar from '@/stories/FlexibleFilterByPeriods/calendar/Calendar';
import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { SaveStatus } from '@/shared/ui/SaveStatus';
import { formatToDateStringForRequest } from '@/shared/lib/converters';

const Builder = ({
  data,
  isFetching,
}: {
  data:
    | GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse
    | undefined;
  isFetching: boolean;
}) => {
  const { assetId } = useQueryParams<TRouteQueryParams['/reports/:id']>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const savingUpdates = useAppSelector(
    (s) => s.reportBuilder.pendingRequestIds.length > 0,
  );

  const methods = useForm({
    defaultValues: {
      name: data?.report?.name ?? '',
      subtitle: data?.report?.subtitle ?? '',
      date: data?.report?.date,
      legalEntityIds: mapListToIds(data?.report?.legalEntities ?? []),
    },
  });
  const { register, watch, setValue: setFormValue } = methods;

  const reportWidgetsState = useAppSelector((state) => state.reportWidgets);

  const allWidgets = useMemo(() => {
    return data?.report?.groups.flatMap((g) =>
      g.widgets.filter(filterKnownWidgetTypes),
    );
  }, [data]);

  const { dataObjectURL: pdfSrc, isLoading: isPDFLoading } =
    useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdfQuery();

  const { onReportUpdate, updateOptions } =
    useDebouncedPutApiCoreAssetsReportBuilderReportsById({
      assetId: Number(assetId),
      reportData: data,
      methods,
    });

  const [handleDeleteReport, { isLoading: isDeletingReport }] = useDeleteReport(
    {
      assetId: Number(assetId),
    },
  );

  const { navigateBack } = useNavigateBack({
    fallbackUrl: ROUTES_ROOT.assets.fullPath,
  });

  const formLegalEntityIds = watch('legalEntityIds');

  useResetAllModalsOnUnmount();

  const isReportLockedToUpdate = data?.report.status === 'published';

  return (
    <div className="grid min-h-screen">
      <div className="fixed z-[1000] flex max-h-screen min-h-screen w-[500px] flex-col bg-neutral-100 print:hidden">
        <div className="flex items-center gap-tw-4 px-tw-6 py-tw-4">
          <IconButton
            iconName="arrowLeft"
            className="h-[54px]"
            disabled={updateOptions.isLoading}
            onClick={navigateBack}
          />
          <div className="flex flex-col gap-tw-1">
            <p className="secondary-semibold text-neutral-550">
              {data?.meta.asset.name}
            </p>
            <p className="header6-bold text-neutral-800">
              {data?.report?.name ?? ''}
            </p>
          </div>
          <div className="flex items-center gap-tw-2">
            <IconButton
              iconName="trashAlt"
              onClick={async () => {
                if (data?.report != null) {
                  const response = await handleDeleteReport(data.report.id);

                  if (
                    (response != null && 'error' in response) ||
                    response == null
                  ) {
                    return;
                  }

                  navigateBack();
                }
              }}
              size="m"
              disabled={isDeletingReport || updateOptions.isLoading}
              variant="secondary"
            />
            {currentUserAllowedToSeeNavigateToReportViewPage() && (
              <IconButton
                iconName="eye"
                size="m"
                variant="secondary"
                onClick={() => {
                  navigate(
                    generateUrl(ROUTES_ROOT.reports.report.view.fullPath, {
                      pathParams: {
                        id: data?.report?.id ?? '',
                      },
                      queryParams: {
                        assetId,
                      },
                    }),
                  );
                }}
              />
            )}
            <SaveStatus saving={savingUpdates} />
          </div>
        </div>

        <div className="flex grow flex-col gap-tw-6 overflow-y-auto px-tw-6 py-tw-2">
          {data && (
            <div className="flex flex-col gap-tw-4 rounded bg-neutral-000 p-tw-2">
              <div className="flex flex-col gap-tw-2 px-tw-1">
                <p className="inline-semibold p-tw-1 text-neutral-850">
                  Report Title
                </p>
                <Input
                  disabled={isReportLockedToUpdate}
                  classes={{ backLayer: 'bg-transparent' }}
                  {...register('name')}
                  onBlur={onReportUpdate}
                />
              </div>
              <div className="flex flex-col gap-tw-2 px-tw-1">
                <p className="inline-semibold p-tw-1 text-neutral-850">
                  Report Subtitle
                </p>
                <Input
                  disabled={isReportLockedToUpdate}
                  classes={{ backLayer: 'bg-transparent' }}
                  {...register('subtitle')}
                  onBlur={onReportUpdate}
                />
              </div>
              <hr className="m-0 h-[1px] bg-neutral-150" />
              <div className="flex flex-col gap-tw-2 px-tw-1">
                <p className="inline-semibold p-tw-1 text-neutral-850">
                  Legal Entities
                </p>
                <div className="flex flex-wrap gap-tw-2 p-tw-1">
                  {(data.meta?.asset.legalEntities ?? []).map((l) => {
                    const isOnlyOneLeft = formLegalEntityIds.length === 1;
                    const hideBtnDisabled =
                      (isOnlyOneLeft && formLegalEntityIds.includes(l.id)) ||
                      isReportLockedToUpdate;

                    return (
                      <FilterItem
                        key={l.id}
                        label={l.name}
                        hidden={!formLegalEntityIds.includes(l.id)}
                        hideBtnDisabled={hideBtnDisabled}
                        tooltipProps={
                          hideBtnDisabled
                            ? {
                                mainText:
                                  'At least one Legal Entity should be active',
                              }
                            : undefined
                        }
                        onHide={() => {
                          const newLEIds = formLegalEntityIds.includes(l.id)
                            ? formLegalEntityIds.filter((id) => id !== l.id)
                            : [...formLegalEntityIds, l.id];

                          setFormValue('legalEntityIds', newLEIds, {
                            shouldTouch: true,
                            shouldDirty: true,
                          });
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          <div className="flex flex-col gap-tw-4">
            <div className="-mb-tw-2 flex items-center justify-between">
              <p className="secondary-semibold uppercase text-neutral-550">
                widgets
              </p>
              {!isReportLockedToUpdate && (
                <Calendar
                  closeOnDateUpdate
                  selectionMode="daily"
                  value={[dayjs(reportWidgetsState.date ?? new Date())]}
                  onChange={([item]) => {
                    dispatch(
                      reportWidgetsSliceActions.updateReportDate({
                        date: formatToDateStringForRequest(item),
                      }),
                    );
                    setFormValue('date', formatToDateStringForRequest(item), {
                      shouldTouch: true,
                      shouldDirty: true,
                    });
                  }}
                />
              )}
            </div>
            <div className="flex flex-col gap-tw-2">
              {allWidgets?.map((w, idx) => (
                <WidgetConfigCard
                  disabled={isReportLockedToUpdate}
                  key={w.id}
                  widgetSection={w}
                  index={idx}
                  isLoading={isFetching}
                />
              ))}
            </div>
          </div>
        </div>
        <div
          className="
        flex gap-tw-2 bg-neutral-000 px-tw-6 py-tw-4"
        >
          <Button onClick={navigateBack}>Cancel</Button>
          <div className="grow" />
          {data && !isReportLockedToUpdate && (
            <PublishReportButton
              assetId={Number(assetId)}
              reportId={data.report.id}
            />
          )}
        </div>
      </div>
      {isPDFLoading && pdfSrc == null && (
        <div className="relative ml-[500px]">
          <AnimationLoader className="static h-full w-full" />
        </div>
      )}
      {pdfSrc && (
        <div className="relative ml-auto flex h-full w-[calc(100%_-_500px)] ">
          <iframe
            title="pdf-preview"
            src={pdfSrc}
            className="flex h-full w-full"
          />
          {isPDFLoading && <AnimationLoader withBg className="h-full w-full" />}
        </div>
      )}
      {!isPDFLoading && pdfSrc == null && (
        <div className="ml-auto flex h-full w-[calc(100%_-_500px)] flex-col">
          <NoDataOverlay title="Couldn't generate PDF" />
        </div>
      )}
    </div>
  );
};
export const ReportPage: React.FC<RouteComponentProps> = () => {
  const params = useParams();
  const queryParams = useQueryParams<TRouteQueryParams['/reports/:id']>();

  const { data, isFetching, isLoading } =
    useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdQuery(
      {
        id: params.id,
        assetId: Number(queryParams.assetId),
      },
      {
        skip: queryParams.assetId == null,
      },
    );

  return (
    <>
      {isLoading && <AnimationLoader className="static h-[150px]" />}
      {!isLoading && <Builder data={data} isFetching={isFetching} />}
    </>
  );
};
