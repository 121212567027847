import {
  REMOVE_WIDGET_BUTTON_TOOLTIP,
  WIDGET_SETTINGS_BUTTON_TOOLTIP,
} from '@/bundles/Settings/components/REport/Dashboards/Dashboard/config';
import { WIDGETS_CONFIG_MAP } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { TippyProps } from '@tippyjs/react';
import Tippy from '@tippyjs/react/headless';
import { AllWidgetTypes } from 'bundles/Shared/entities/dashboard';
import { currentUserIsSreAdmin } from 'lib/permissions';
import React from 'react';
import { Button, Icon } from 'stories/index';

type Props = Omit<TippyProps, 'render'> & {
  widget: {
    id: string;
    widgetType: AllWidgetTypes;
    sectionType?: AllWidgetTypes;
  };
  onEdit?: VoidFunction;
  onRemove?: VoidFunction;
  settings?: React.ReactNode;
};

export function DashboardWidgetEditPanel({
  widget,
  settings,
  onEdit,
  onRemove,
  ...props
}: Props) {
  const { title, icon } =
    WIDGETS_CONFIG_MAP[widget.sectionType ?? widget.widgetType];

  return (
    <Tippy
      interactive
      trigger="click"
      appendTo={document.body}
      placement="top-end"
      onClickOutside={(instance) => instance.hide()}
      render={(attrs) => (
        <div
          className="flex w-[280px] flex-col gap-tw-2 !rounded-lg bg-neutral-000 p-tw-2"
          {...attrs}
        >
          <div className="flex w-full items-center gap-tw-1 p-tw-1">
            <Icon iconName={icon} className="text-violet-040" />
            <span className="secondary-semibold text-ellipsis text-neutral-800">
              <span className="text-neutral-550">Widget: </span>
              {title}
            </span>
            <div className="grow" />
            {onEdit && currentUserIsSreAdmin() && (
              <Button
                tooltipProps={{
                  mainText: WIDGET_SETTINGS_BUTTON_TOOLTIP,
                }}
                onClick={onEdit}
                size="xs"
                variant="secondary"
              >
                Edit
              </Button>
            )}
          </div>
          {settings}
          <Button
            onClick={onRemove}
            size="xs"
            tooltipProps={{
              mainText: REMOVE_WIDGET_BUTTON_TOOLTIP,
              classes: {
                spanContainer: 'w-max',
              },
            }}
            iconName="trash"
            variant="danger"
            className="self-start"
          >
            Remove
          </Button>
        </div>
      )}
      {...props}
    />
  );
}
