import dayjs from 'dayjs';

export const getYears = ({
  currentYear,
  range,
  maxYear = Number.MAX_SAFE_INTEGER,
  minYear = 1,
}: {
  currentYear: number;
  range: number;
  maxYear?: number;
  minYear?: number;
}) => {
  const years: Array<{
    value: number;
    label: string;
  }> = [];
  const startYear = Math.max(minYear, currentYear - range);
  const endYear = Math.min(maxYear, currentYear + range);

  for (let i = startYear; i <= endYear; i++) {
    years.push({ label: String(i).padStart(4, '0'), value: i });
  }

  return years;
};

export function startOfWeek(date: Date, initialWeekStart = 0) {
  const weekStart = initialWeekStart % 7;

  const day = dayjs(date);
  const weekDay = day.day();
  const diff = (weekDay < weekStart ? 7 : 0) + weekDay - weekStart;

  return day.date(day.date() - diff).toDate();
}

export function endOfWeek(date: Date, weekStart = 0) {
  const day = dayjs(startOfWeek(date, weekStart));

  return day.date(day.date() + 6).toDate();
}

export const getWeeks = (
  viewDate: Date,
  weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6,
) => {
  const start = startOfWeek(
    dayjs(viewDate).startOf('month').toDate(),
    weekStartsOn,
  );
  const end = endOfWeek(dayjs(viewDate).endOf('month').toDate(), weekStartsOn);

  let count = 0;
  let current = start;
  const nestedWeeks: Date[][] = [];
  let lastDay = null;
  while (dayjs(current).isBefore(end)) {
    const weekNumber = Math.floor(count / 7);
    nestedWeeks[weekNumber] = nestedWeeks[weekNumber] || [];
    const day = current.getDay();
    if (lastDay !== day) {
      lastDay = day;
      nestedWeeks[weekNumber].push(current);
      count += 1;
    }
    current = dayjs(current).add(1, 'day').toDate();
  }
  return nestedWeeks;
};

export const isFirstDay = (day: Date, dayOfWeek: number) =>
  dayOfWeek === 0 || dayjs(day).date() === 1;

export const isLastDay = (day: Date, dayOfWeek: number) =>
  dayOfWeek === 6 || dayjs(day).endOf('day').isSame(dayjs(day).endOf('month'));

export const navigateDate = (date?: Date | null, key?: string) => {
  let newDate = dayjs(date ?? new Date());

  switch (key) {
    case 'ArrowRight':
      newDate = newDate.add(1, 'day');
      break;
    case 'ArrowLeft':
      newDate = newDate.subtract(1, 'day');
      break;
    case 'ArrowUp':
      newDate = newDate.subtract(1, 'week');
      break;
    case 'ArrowDown':
      newDate = newDate.add(1, 'week');
      break;
  }

  return newDate.toDate();
};

export const setTimeEqual = (to: Date, from?: Date | null) => {
  if (from) {
    to.setHours(from.getHours());
    to.setMinutes(from.getMinutes());
    to.setSeconds(from.getSeconds());
    to.setMilliseconds(from.getMilliseconds());
  }

  return to;
};

/**
 * Allows you to determine whether the original date meets the specified `min` and/or `max` restrictions
 */
export function isDayMinMaxRestricted(
  day: Date,
  options: { min?: Date; max?: Date; withTime?: boolean } = {},
) {
  const { min, max, withTime = false } = options;
  if (
    !withTime &&
    ((min && dayjs(day).isSame(min, 'day')) ||
      (max && dayjs(day).isSame(max, 'day')))
  ) {
    return false;
  }
  return Boolean(
    (min && dayjs(day).isBefore(min, 'day')) ||
      (max && dayjs(day).isAfter(max, 'day')),
  );
}
