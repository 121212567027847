/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unsafe-return */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export function ShowAutocompletion(obj: Record<string, string>, monaco: any) {
  // monaco.languages.typescript.javascriptDefaults.setCompilerOptions({ noLib: true });

  // Helper function to return the monaco completion item type of a thing
  function getType(thing: any, isMember: boolean | undefined) {
    const finalIsMember = Boolean(isMember); // Give isMember a default value of false

    switch ((typeof thing).toLowerCase()) {
      case 'object':
        return monaco.languages.CompletionItemKind.Class;

      case 'function':
        return finalIsMember
          ? monaco.languages.CompletionItemKind.Method
          : monaco.languages.CompletionItemKind.Function;

      default:
        return finalIsMember
          ? monaco.languages.CompletionItemKind.Property
          : monaco.languages.CompletionItemKind.Variable;
    }
  }
  return (model: any, position: any, token: any) => {
    // Split everything the user has typed on the current line up at each space, and only look at the last word
    const last_chars: string = model.getValueInRange({
      startLineNumber: position.lineNumber,
      startColumn: 0,
      endLineNumber: position.lineNumber,
      endColumn: position.column,
    });
    const words = last_chars.replace('\t', '').split(' ');
    const active_typing = words[words.length - 1]; // What the user is currently typing (everything after the last space)

    // If the last character typed is a period then we need to look at member objects of the obj object
    const is_member = active_typing.includes('.');

    // Array of autocompletion results
    const result: {
      label: string;
      kind: number;
      detail: string;
      insertText: string;
    }[] = [];

    // Used for generic handling between member and non-member objects
    let last_token = obj;
    let prefix = '';

    if (is_member) {
      // Is a member, get a list of all members, and the prefix
      const parents = [active_typing.split('.')[0]];
      last_token = obj[parents[0]];
      [prefix] = parents;

      // Loop through all the parents the current one will have (to generate prefix)
      for (let i = 1; i < parents.length; i++) {
        if (last_token.hasOwnProperty(parents[i])) {
          prefix += `.${parents[i]}`;
          last_token = last_token[parents[i]];
        } else {
          // Not valid
          return result;
        }
      }

      prefix += '.';
    }

    // Get all the child properties of the last token
    for (const prop in last_token) {
      // Do not show properites that begin with "__"
      if (last_token.hasOwnProperty(prop) && !prop.startsWith('__')) {
        // Get the detail type (try-catch) incase object does not have prototype
        let details = '';
        try {
          details = last_token[prop].__proto__.constructor.name;
        } catch (e) {
          details = typeof last_token[prop];
        }

        // Create completion object
        const to_push = {
          label: prefix + prop,
          kind: getType(last_token[prop], is_member),
          detail: details,
          insertText: prop,
        };

        // Add to final results
        result.push(to_push);
      }
    }

    return result;
  };
}
