import printDocument from '@/shared/lib/browser/printDocument';
import { useState } from 'react';
import { DropdownItem, IconButton, OverlaySpinner } from 'stories';
import { ISharedDocument } from 'types/SharedDocument';

interface Props {
  row: ISharedDocument;
}

function PrintButton({ row }: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const onLoadingStart = () => setIsLoading(true);
  const onLoadingEnd = () => setIsLoading(false);
  const LoadingButton = () => (
    <button type="button" className="sre-icon-button mr-s">
      <OverlaySpinner size="small" inline />
    </button>
  );

  const onClick = () =>
    printDocument({
      file: row,
      onLoadingStart,
      onLoadingEnd,
    });

  if (isLoading) return <LoadingButton />;

  return (
    <IconButton
      id={`item-${row.id}-print`}
      onClick={onClick}
      iconName="print"
      size="m"
      data-tip="Print"
      className="sre-icon-button_size-mobile-l bg-white"
    />
  );
}

export function DropdownItemPrintButton({ row }: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const onLoadingStart = () => setIsLoading(true);
  const onLoadingEnd = () => setIsLoading(false);

  const onClick = () =>
    printDocument({
      file: row,
      onLoadingStart,
      onLoadingEnd,
    });

  return (
    <DropdownItem disabled={isLoading} iconName="print" onClick={onClick}>
      {isLoading ? <OverlaySpinner size="small" inline /> : 'Print File'}
    </DropdownItem>
  );
}

export default PrintButton;
