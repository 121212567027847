import React from 'react';
import pluralize from 'pluralize';
import { round } from 'lodash-es';

type Props = {
  units: number | undefined;
};

function UnitQuantity({ units }: Props) {
  if (!units || units < 0) return null;

  return (
    <span className="secondary-regular whitespace-nowrap text-xs font-normal text-neutral-500">
      {' '}
      ({round(units, 2)} {pluralize('unit', units)})
    </span>
  );
}

export default UnitQuantity;
