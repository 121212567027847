import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { Icon } from '@/stories';
import { cn } from '@/shared/lib/css/cn';
import { DELIMITER } from 'bundles/Shared/widgets/dashboard/widgets/kpiTable/lib';
import { BasicAutoGroupCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupCellRenderer';
import { KpiTableWidgetDataRow } from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';

export const GroupCellRenderer = (params: ICellRendererParams) => {
  const row = params.node.data as KpiTableWidgetDataRow;
  const isAssetRow = row && row.type === 'asset';
  const isSegmentRow = row && row.type === 'segment';

  if (
    (isAssetRow &&
      !row.path?.includes(DELIMITER) &&
      params.node.childrenAfterGroup &&
      params.node.childrenAfterGroup.length > 0) ||
    isSegmentRow
  ) {
    const getSubRow = () => {
      if (isSegmentRow && params.node.childrenAfterGroup?.length === 1) {
        return params.node.childrenAfterGroup?.[0].data.label;
      }
      return null;
    };
    return (
      <BasicAutoGroupCellRenderer {...params}>
        <div className={cn('flex flex-col')}>
          <div className="flex items-center gap-tw-1">
            <Icon
              className="text-neutral-450"
              iconName={isAssetRow ? 'asset' : 'segments'}
            />
            <span className="inline-semibold text-neutral-900">
              {params.value}
            </span>
          </div>
          {getSubRow() && (
            <p className="secondary-regular pl-tw-5 text-neutral-700">
              {getSubRow()}
            </p>
          )}
        </div>
      </BasicAutoGroupCellRenderer>
    );
  }
  return (
    <BasicAutoGroupCellRenderer {...params}>
      {params.value}
    </BasicAutoGroupCellRenderer>
  );
};
