import { navigate } from '@reach/router';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { Button } from 'stories/index';
import { getPeriodsTypeByValue } from 'bundles/Settings/components/REport/TableBuilder/helpers/utils';
import { PermissionListLine } from 'bundles/Shared/components/Permissions/PermissionListLine';
import { cn } from '@/shared/lib/css/cn';
import { PeriodLimitations } from 'bundles/Settings/components/REport/TableBuilder/PeriodLimitation/PeriodLimitation';
import { IReportTableConfig } from 'types/ReportTableConfig';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { formatUnixDate } from '@/shared/lib/formatting/dates';

export enum TColumnIdsReportTableConfig {
  NAME = 'name',
  PERIOD_TYPE = 'periodsType',
  SOURCE = 'source',
  ACCESS = 'access',
  PERIOD_LIMITATIONS = 'periodsLimitations',
}

export const useReportTableConfigColumns = (
  handleViewPermissions: (config: IReportTableConfig) => Promise<void>,
): {
  columns: IColumn<IReportTableConfig>[];
} => ({
  columns: [
    {
      dataField: TColumnIdsReportTableConfig.NAME,
      text: 'Table Name',
      formatter: ({ row }) => (
        <div className="group inline-semibold flex justify-between text-neutral-900">
          <div>{row.name}</div>
          <Button
            className="hidden group-hover:flex"
            size="xs"
            variant="secondary"
            onClick={() =>
              navigate(
                generateUrl(
                  ROUTES_ROOT.settings.report.tableBuilder.tableSlug.fullPath,
                  {
                    pathParams: {
                      tableSlug: row.slug,
                    },
                  },
                ),
              )
            }
          >
            Open
          </Button>
        </div>
      ),
      headerStyle: {
        width: 340,
      },
      classes: 'h-[38px]',
    },
    {
      dataField: TColumnIdsReportTableConfig.PERIOD_TYPE,
      text: 'Format',

      formatter: ({ row }) => (
        <div>{getPeriodsTypeByValue(row.periodsType)?.label}</div>
      ),
    },
    {
      dataField: TColumnIdsReportTableConfig.SOURCE,
      text: 'Source',
      formatter: ({ row }) => (
        <div className="text-neutral-500">
          {row?.rowsConfigSourceTable?.name}
        </div>
      ),
      headerStyle: {
        width: 200,
      },
    },
    {
      dataField: TColumnIdsReportTableConfig.ACCESS,
      text: 'Access',
      formatter: ({ row }) => (
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <PermissionListLine
            onClick={() => handleViewPermissions(row)}
            permissions={{
              isPublic: row.permitted.public,
              directTags: [],
              directInvestmentEntities: [],
              directRoles: row.permitted.directRoles,
              directUsers: row.permitted.directUsers,
              indirectUsers: row.permitted.allUsers,
            }}
            hidePublicIcon
            pseudoLinkClassName={cn('font-semibold', {
              ['text-neutral-500']: row.permitted.public,
            })}
          />
        </div>
      ),
      headerClasses: 'min-w-[110px]',
    },
    {
      dataField: TColumnIdsReportTableConfig.PERIOD_LIMITATIONS,
      text: 'Period Limitations',
      formatter: ({ row }) => (
        <PeriodLimitations
          periodLimitations={row.periodsLimitations}
          readOnlyMode
          pseudoLinkClassName="inline-semibold text-neutral-900"
        />
      ),
      headerStyle: {
        width: 200,
      },
    },
    {
      dataField: 'updatedAt',
      text: 'Last Updated',
      classes: 'text-neutral-500',
      formatter: ({ row }) => formatUnixDate(row.updatedAt, 'LL'),
    },
  ],
});
