import React from 'react';
import { Icon, Modal, ModalHeaderWithSubtitle } from '@/stories';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { useGetApiReportRentRollUnitLeaseHistoryQuery } from 'bundles/Shared/shared/api/reportOperationalSettingsApiExtended';
import { WidgetTable } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetTable';
import { LEASE_DETAILS_MODAL_DEFAULT_AG_GRID_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/unitTable/config';
import { useWidgetTableDefaultColDef } from 'bundles/Shared/widgets/dashboard/widgets/common';

type Props = DialogProps & {
  unitId: string;
  unitLabel: string;
};

export function LeaseDetailsModal({
  onClose,
  unitId,
  unitLabel,
  onSubmit,
}: Props) {
  const { data } = useGetApiReportRentRollUnitLeaseHistoryQuery({
    unitId,
  });
  const defaultColDef = useWidgetTableDefaultColDef({
    mode: 'view',
  });
  return (
    <Modal
      size="huge"
      toggle={onClose}
      header={
        <ModalHeaderWithSubtitle
          classes={{
            subtitle: 'flex items-center gap-tw-1',
          }}
          subtitle={
            <>
              <Icon className="text-violet-055" iconName="unit" /> Unit{' '}
              {unitLabel}
            </>
          }
          title="Lease Details"
        />
      }
    >
      <WidgetTable
        mode="view"
        rowData={data}
        treeData={false}
        defaultColDef={defaultColDef}
        domLayout="autoHeight"
        classes={{
          container: 'h-auto',
        }}
        {...LEASE_DETAILS_MODAL_DEFAULT_AG_GRID_CONFIG}
      />
    </Modal>
  );
}
