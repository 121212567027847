import {
  CellClickedEvent,
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { CornerArrow } from 'bundles/Shared/components/AgGrid/Table/cellComponents/CornerArrow';
import { CurrencyCellRendererProps } from 'bundles/Shared/components/AgGrid/Table/cellComponents/CurrencyCellRenderer';
import { HeaderComponentClasses } from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderComponent';
import {
  HeaderGroupComponent,
  HeaderGroupComponentClasses,
} from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderGroupComponent';
import { NEXT_GEN_TABLE_CONFIG } from 'bundles/Shared/components/AgGrid/Table/consts';
import { getReportFinancialsCellRendererParams } from 'bundles/Shared/components/AgGrid/Table/utils/getCurrencyCellRendererProps';
import { useMemo } from 'react';
import { IReportTableConfig } from 'types/ReportTableConfig';
import { createPlaceholderColGroupDef } from '../../columnDef/resolveColumnGroup';
import { TOOLTIP_FOR_UNPUBLISHED_DATA_JSX } from '../../dumbJSX';
import {
  IColumn,
  IColumnGroup,
  IGlRow,
  IRow,
  isRowWithColoredBG,
} from '../../types';
import { FONT_COLOR_RULES, getColId, isCellClickable } from '../../utils';

import { getDefaultAgGridNumberColDef } from '@/shared/lib/formatting/table';
import { LinkableCellRendererProps } from '@/bundles/Shared/components/AgGrid/Table/types';

export type ColDefParams = {
  colGroup: IColumnGroup;
  colGroupChild: IColumn;
};

type ColDefActions = {
  actions: ColGroupActions;
};

type CreateBaseColGroupDef = (params: ColDefParams) => ColDef;

const createBaseColGroupDef: CreateBaseColGroupDef = ({
  colGroup,
  colGroupChild,
}): ColDef => ({
  colId: getColId(colGroup, colGroupChild),
  field: colGroupChild.key,
  headerName: colGroupChild.label,
});

type GetValueGetter = (
  params: ColDefParams,
) => (vParams: ValueGetterParams) => number | null;

const getValueGetter: GetValueGetter =
  ({ colGroupChild }) =>
  ({ node }) => {
    if (Object.keys(node?.data?.data ?? {}).length > 0) {
      const value = node?.data?.data?.[colGroupChild.key]?.value as
        | number
        | undefined;

      return value ?? null;
    }
    return null;
  };

type GetOnCellClicked = (
  params: ColDefParams & ColDefActions,
) => (event: CellClickedEvent) => void;

const getOnCellClicked: GetOnCellClicked =
  ({ actions, colGroupChild }) =>
  ({ node }) => {
    if (isCellClickable(node.data, colGroupChild)) {
      actions.onActiveCellClick?.(node.data, colGroupChild);
    }
  };

type GetCellRenderer = (
  params: ColDefParams,
) => (cParams: ICellRendererParams) => JSX.Element;

const getCellRenderer: GetCellRenderer =
  ({ colGroupChild }) =>
  (params) => {
    const row = params.node.data as IRow | undefined;
    const format = colGroupChild?.display?.formatter ?? row?.display.formatter;
    const colDef = getDefaultAgGridNumberColDef({
      type: format ?? 'currency',
    });
    const cellJSX = colDef.cellRenderer(params);

    return cellJSX;
  };

const hasNegativeRedRule = ({
  colGroupChild,
  ...params
}: Pick<ColDefParams, 'colGroupChild'> & ICellRendererParams): boolean =>
  Boolean(
    colGroupChild.display.colorRule &&
      FONT_COLOR_RULES[colGroupChild.display.colorRule](params.value).red,
  );

type CreateColGroupChildDef = (params: ColDefParams & ColDefActions) => ColDef;

type GetCellRendererParams = (
  params: ColDefParams,
) => (cParams: ICellRendererParams) => CurrencyCellRendererProps;

const RED_TEXT_CLASS = 'text-danger-050';
const NEGATIVE_RED_RULE_OPTIONS = {
  innerClassName: RED_TEXT_CLASS,
  formatterParams: {
    classes: {
      allParts: RED_TEXT_CLASS,
    },
  },
} as const;

const getCellRendererParams: GetCellRendererParams =
  (colDefParams) => (params) => {
    const options =
      hasNegativeRedRule({ ...colDefParams, ...params }) &&
      !isRowWithColoredBG(params.data)
        ? NEGATIVE_RED_RULE_OPTIONS
        : {};

    return {
      linkable: isCellClickable(params.node.data, colDefParams.colGroupChild),
      ...getReportFinancialsCellRendererParams(params, options, colDefParams),
    } satisfies LinkableCellRendererProps;
  };

const createColGroupChildDef: CreateColGroupChildDef = ({
  actions,
  ...colDefParams
}) => ({
  ...createBaseColGroupDef(colDefParams),
  headerComponentParams: {
    classes: {
      inner: colDefParams.colGroupChild.containsUnpublished
        ? '!justify-between'
        : '',
    },
    reactNode:
      colDefParams.colGroupChild.containsUnpublished &&
      TOOLTIP_FOR_UNPUBLISHED_DATA_JSX,
  } satisfies HeaderComponentClasses,
  cellRendererParams: getCellRendererParams(colDefParams),
  cellRenderer: getCellRenderer(colDefParams),
  valueGetter: getValueGetter(colDefParams),
  onCellClicked: getOnCellClicked({
    ...colDefParams,
    actions,
  }),
});

function createColGroupDef({
  colGroup,
  createChildDef,
  actions,
}: {
  colGroup: IColumnGroup;
  createChildDef: CreateColGroupChildDef;
  actions: ColGroupActions;
}): ColGroupDef {
  const MIN_COLS_FOR_CENTERED_HEADER = 2;
  return {
    headerName: colGroup.label,
    headerGroupComponent: HeaderGroupComponent,
    headerGroupComponentParams: {
      classes: {
        inner:
          colGroup.children.length >= MIN_COLS_FOR_CENTERED_HEADER
            ? '!justify-center'
            : '',
      },
    } satisfies HeaderGroupComponentClasses,
    children: colGroup.children.map((colGroupChild) =>
      createChildDef({
        colGroup,
        colGroupChild,
        actions,
      }),
    ),
  } satisfies ColGroupDef;
}

type CreateColGroupDef = (
  params: Pick<ColDefParams, 'colGroup'> & ColDefActions,
) => ColGroupDef;

const TOTALS_COL_WIDTH = NEXT_GEN_TABLE_CONFIG.column.totals.width;
const MTD_AND_TOTAL_COL_WIDTH = NEXT_GEN_TABLE_CONFIG.column.mtdAndTotal.width;

const resolveColWidth = (isTotals: boolean) =>
  isTotals ? TOTALS_COL_WIDTH : MTD_AND_TOTAL_COL_WIDTH;

const createColumnDefWithPinnedTotals: CreateColGroupDef = ({
  colGroup,
  actions,
}) =>
  createColGroupDef({
    colGroup,
    actions,
    createChildDef: (params) => ({
      width: resolveColWidth(colGroup.type === 'totals'),
      minWidth: resolveColWidth(colGroup.type === 'totals'),
      pinned: colGroup.type === 'totals' && 'right',
      ...createColGroupChildDef(params),
    }),
  });

export function insertBetweenPlaceholderColumnGroupDef(
  colGroupDefs: ColGroupDef[],
  args?: Partial<ColDef>,
  groupArgs?: Partial<ColGroupDef>,
) {
  return colGroupDefs.flatMap((column, idx) =>
    idx === 0
      ? column
      : [createPlaceholderColGroupDef(args ?? {}, groupArgs ?? {}), column],
  );
}

export type ColGroupActions = {
  onActiveCellClick?: (row: IGlRow, column: IColumn) => void;
};

export function useColGroupDefs({
  columns,
  periodsType,
  actions,
}: {
  columns: IColumnGroup[];
  periodsType: IReportTableConfig['periodsType'];
  actions: ColGroupActions;
}) {
  const columnDefs = useMemo<ColGroupDef[]>(() => {
    const createBaseColGroupDefs = (colGroup: IColumnGroup) =>
      createColGroupDef({
        colGroup,
        createChildDef: createColGroupChildDef,
        actions,
      });

    const colGroupDefWithPinnedTotals = (colGroup: IColumnGroup) =>
      createColumnDefWithPinnedTotals({
        colGroup,
        actions,
      });

    switch (periodsType) {
      case 'mtd_and_total': {
        return columns.map(colGroupDefWithPinnedTotals);
      }

      case 'year_mtd':
      case 'total': {
        const colGroupDefs = columns.map(createBaseColGroupDefs);
        return [
          ...colGroupDefs,
          createPlaceholderColGroupDef({
            width: NEXT_GEN_TABLE_CONFIG.column.default.width,
          }),
        ];
      }

      case 'variance_report':
      case 'single_mtd_and_ytd':
      case 'single_mtd_qtd_and_ytd':
      case 'single_mtd_qtd_and_itd':
      case 'single_mtd_qtd_and_uw_itd':
      case 'single_qtd_and_ytd':
      case 'single_qtd_ytd_and_itd':
      case 'single_t3_and_t12':
      case 'single_qtd_ytd_and_uw_itd': {
        const colGroupDefs = columns.map(createBaseColGroupDefs);

        return insertBetweenPlaceholderColumnGroupDef(colGroupDefs);
      }

      default: {
        return columns.map(createBaseColGroupDefs);
      }
    }
  }, []);

  return columnDefs;
}
