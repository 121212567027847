import { CellRenderers } from '@/shared/lib/formatting/table';

export const getPrecisionFormat = (precision?: number) => {
  if (!precision || precision === 0) {
    return '0';
  }
  return `0.${'0'.repeat(precision)}`;
};

export const getColumnExcelFormat = (format: {
  precision?: number;
  type?: CellRenderers;
  kilo_formatting?: boolean;
}): string => {
  if (format.type === 'currency') {
    return `$ #,##${getPrecisionFormat(format.precision)}`;
  }
  if (format.type === 'percentage') {
    return `${getPrecisionFormat(format.precision)} %;(${getPrecisionFormat(
      format.precision,
    )} %)`;
  }
  return getPrecisionFormat(format.precision);
};
