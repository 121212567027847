import { ERPSystem } from 'bundles/Shared/entities/erpsystem/model';
import { IPropertyManagementCompany } from 'bundles/Shared/entities/propertyManagementCompany/model';

export type ParserDocumentStatus =
  | 'error'
  | 'processed'
  | 'processing'
  | 'empty';

enum TConnectionStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  CORRUPTED = 'corrupted',
  DEACTIVATED = 'deactivated',
}

export enum TParserStatus {
  ACTIVE = 'active',
  ERROR = 'error',
  EMPTY = 'empty',
}

export enum TConnectionReportType {
  OPERATIONAL = 'operational',
  FINANCIAL = 'financial',
  COMBINED = 'combined',
  MARKET_DATA = 'market_data',
}

export enum TConnectionType {
  EMAIL = 'email',
  SFTP = 'sftp',
  API = 'api',
}

export type ConnectionEmailAttr = {
  emailPostfix: string;
};

export type ConnectionSFTPAttr = {
  username: string;
  password: string;
  host: string;
  folderPath: string;
};

export interface IConnection {
  id: number;
  name: string;
  companyName: string;
  connectionStatus: FlattenEnum<TConnectionStatus>;
  parserStatus: FlattenEnum<TParserStatus>;
  reportType: FlattenEnum<TConnectionReportType>;
  type: FlattenEnum<TConnectionType>;
  extractionCount: number;
  erpSystem: ERPSystem;
  createdAt: Date;
  lastReceivedAt: Date;
  company: IPropertyManagementCompany;
  emailAddress?: string;
  memo?: {
    id: string;
    message: string;
    authorName: string;
    writtenAt: number;
  };
}
