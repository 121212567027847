import { widgetFullscreenSlice } from '@/bundles/Shared/widgets/dashboard/widgets/common/model/widgetFullscreenSlice';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useEventListener } from '@/shared/lib/hooks/useEventListener';
import { IconButton } from '@/stories';
import React from 'react';

export const useWidgetFullScreen = (
  wrapperDivRef: React.RefObject<HTMLDivElement>,
) => {
  const isFullscreen = useAppSelector((s) => s.widgetFullscreen.value);
  const dispatch = useAppDispatch();

  // @ts-expect-error `fullscreenchange` is not part of Event type inside useEventListener
  useEventListener('fullscreenchange', () => {
    if (document.fullscreenElement === wrapperDivRef.current) {
      dispatch(widgetFullscreenSlice.actions.enableFullscreen());
    } else if (document.fullscreenElement == null) {
      dispatch(widgetFullscreenSlice.actions.disableFullscreen());
    }
  });

  const handleToggle = () => {
    if (isFullscreen) {
      document.exitFullscreen();
    } else {
      wrapperDivRef.current?.requestFullscreen();
    }
  };

  return {
    onToggle: handleToggle,
    isFullscreen,
  };
};

export const WidgetStateFullScreenIconButton = ({
  isFullscreen,
  onToggle,
}: ReturnType<typeof useWidgetFullScreen>) => (
  <IconButton
    iconName={isFullscreen ? 'collapse' : 'expand'}
    onClick={onToggle}
  />
);

export const WidgetFullScreen = ({
  wrapperDivRef,
}: {
  wrapperDivRef: React.RefObject<HTMLDivElement>;
}) => {
  const props = useWidgetFullScreen(wrapperDivRef);

  return <WidgetStateFullScreenIconButton {...props} />;
};
