import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { DelinquencyAverageWidget } from 'bundles/Shared/widgets/dashboard/widgets/delinquencyAverage/ui/DelinquencyAverageWidget';
import * as am5 from '@amcharts/amcharts5';
import { COLOR_SET, getDefaultLabelStyleInJson } from 'lib/amcharts/utils';
import {
  getAmchartNumberFormatForByDisplayOptions,
  getCurrencyTooltipFormat,
} from '@/shared/lib/formatting/charts';

export const DELINQUENCY_AVERAGE_WIDGET_CONFIG = {
  title: 'Delinquency Average',
  layout: {
    w: 1,
    h: 13,
    maxW: 2,
    maxH: 12,
    minH: 10,
    minW: 1,
  },
  Component: DelinquencyAverageWidget,
  icon: 'chartBar',
} as const satisfies WidgetConfiguration;

export const DELINQUENCY_AVERAGE_CHART_CONFIG = {
  refs: [
    {
      xAxis: {
        type: 'CategoryAxis',
        settings: {
          maxDeviation: 0,
          categoryField: 'label',
          renderer: {
            type: 'AxisRendererX',
            settings: {
              minGridDistance: 40,
              cellStartLocation: 0.1,
              cellEndLocation: 0.9,
            },
          },
          tooltip: {
            type: 'Tooltip',
          },
        },
        properties: {
          data: '#data',
        },
      },
      yAxis: {
        type: 'ValueAxis',
        settings: {
          numberFormat: `${getAmchartNumberFormatForByDisplayOptions({
            type: 'currency',
            precision: 2,
          })}`,
          renderer: {
            type: 'AxisRendererY',
            settings: {
              minGridDistance: 30,
            },
          },
        },
        children: [
          {
            index: 0,
            type: 'Label',
            settings: {
              text: 'Average Delinquent Balance ($)',
              y: am5.p50,
              centerX: am5.p50,
              rotation: -90,
              paddingTop: 16,
              ...getDefaultLabelStyleInJson(),
            },
          },
        ],
      },
    },
    {
      yAxisTenants: {
        type: 'ValueAxis',
        settings: {
          syncWithAxis: '#yAxis',
          renderer: {
            type: 'AxisRendererY',
            settings: {
              minGridDistance: 30,
              opposite: true,
            },
          },
        },
        children: [
          {
            type: 'Label',
            settings: {
              text: 'Delinquent Tenants',
              y: am5.p50,
              centerX: am5.p50,
              rotation: -90,
              paddingTop: 16,
              ...getDefaultLabelStyleInJson(),
            },
          },
        ],
      },
    },
    {
      series: [
        {
          type: 'ColumnSeries',
          settings: {
            name: 'Average Delinquent Balance',
            yAxis: '#yAxis',
            xAxis: '#xAxis',
            valueYField: 'averageDelinquentBalance',
            categoryXField: 'label',
            tooltip: {
              type: 'Tooltip',
              settings: {
                labelText: getCurrencyTooltipFormat(),
              },
            },
          },
          properties: {
            data: '#data',
          },
        },
        {
          type: 'LineSeries',
          settings: {
            name: 'Delinquent Tenants',
            yAxis: '#yAxisTenants',
            xAxis: '#xAxis',
            valueYField: 'delinquentTenantsCount',
            categoryXField: 'label',
            fill: COLOR_SET.at(-1),
            stroke: COLOR_SET.at(-1),
            tooltip: {
              type: 'Tooltip',
              settings: {
                labelText: '{valueY}',
              },
            },
          },
          properties: {
            data: '#data',
            bullets: [
              {
                type: 'Bullet',
                settings: {
                  sprite: {
                    type: 'Circle',
                    settings: {
                      radius: 4,
                      fill: "@series.get('fill')",
                    },
                  },
                },
              },
            ],
          },
        },
      ],
    },
  ],
  type: 'XYChart',
  properties: {
    xAxes: ['#xAxis'],
    yAxes: ['#yAxis', '#yAxisTenants'],
    series: '#series',
  },
  settings: {
    panY: false,
    layout: 'vertical',
    scrollbarX: {
      type: 'Scrollbar',
      settings: {
        orientation: 'horizontal',
        marginBottom: 20,
      },
    },
    cursor: {
      type: 'XYCursor',
      settings: {
        behavior: 'zoomX',
      },
      properties: {
        lineY: {
          settings: {
            visible: false,
          },
        },
      },
    },
  },
  children: [
    {
      type: 'Legend',
      settings: {
        centerX: {
          type: 'Percent',
          value: 50,
        },
        x: {
          type: 'Percent',
          value: 50,
        },
        layout: 'horizontal',
      },
      properties: {
        data: '#series',
      },
    },
  ],
} as const;
