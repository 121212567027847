import { WidgetFullScreen } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { useLoadingOverlayEffect } from 'lib/ag-grid/utils';
import { getDefaultAgGridNumberColDef } from '@/shared/lib/formatting/table';
import { useModal } from '@/shared/lib/hooks/useModal';
import { AgGridReact } from 'ag-grid-react';
import {
  GlobalLeaseTableWidgetDto,
  ReportObjectDashboardSection,
} from 'bundles/Shared/entities/dashboard';
import {
  DashboardWidgetCard,
  DashboardWidgetTableCard,
  DateRangeWidgetStateWithGranularity,
  getStateFromFilterChangedEvent,
  PaginationWidgetState,
  postProcessPopup,
  QueryWidgetState,
  useWidgetTableDefaultColDef,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { WidgetStateCalendarRangeSelector } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateCalendarRangeSelector';
import { WidgetTable } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetTable';
import {
  getGlobalLeaseColumnDefs,
  UNIT_COL_ID,
} from 'bundles/Shared/widgets/dashboard/widgets/globalLeaseTable/index';
import { buildWidgetTableColumnDefs } from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import {
  WidgetConfigProps,
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import { LeaseDetailsModal } from 'bundles/Shared/widgets/dashboard/widgets/unitTable/ui/LeaseDetailsModal';
import { useMemo, useRef } from 'react';
import { UnknownRecord } from 'type-fest/source/internal';
import { WidgetStateTablePagination } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateTablePagination';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { WidgetStateSearchInput } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateSearchInput';

export type UnitTableWidgetState = PaginationWidgetState &
  DateRangeWidgetStateWithGranularity &
  QueryWidgetState;

export function GlobalLeaseTableWidget({
  widgetSection,
  data,
  state,
  onStateChange,
  isLoading,
  isFetching,
  isError,
  mode,
}: WidgetProps<GlobalLeaseTableWidgetDto, ReportObjectDashboardSection> &
  WidgetConfigProps<UnknownRecord> &
  WidgetStateProps<UnitTableWidgetState>) {
  const { openModal } = useModal();
  const gridRef = useRef<AgGridReact>(null);
  const wrapperDivRef = useRef<HTMLDivElement>(null);

  useLoadingOverlayEffect({
    isLoading: isFetching,
    grid: gridRef.current,
  });
  const mappedData = useMemo(
    () =>
      data
        ? {
            ...data,
            rows: data.rows.map((row) => ({
              ...row,
              values: Object.fromEntries(
                row.values.map((v) => [v.key, v.value]),
              ),
            })),
          }
        : undefined,
    [data],
  );

  const columnDefs = useMemo(() => {
    return buildWidgetTableColumnDefs({
      columns: data?.columns ?? [],
      columnToColDefMapper: (c) => ({
        headerName: c.label,
        colId: c.key.toString(),
        field: `values.${c.key.toString()}`,
        ...(c.valueDisplayOptions &&
          getDefaultAgGridNumberColDef(c.valueDisplayOptions)),
      }),
      rows: data?.rows ?? [],
      agGridConfig: getGlobalLeaseColumnDefs(data?.filterOptions ?? []),
    });
  }, [JSON.stringify(data?.columns)]);

  const defaultColDef = useWidgetTableDefaultColDef({
    mode,
  });

  return (
    <DashboardWidgetTableCard
      mode={mode}
      ref={wrapperDivRef}
      isError={isError}
      isLoading={isLoading}
      className="h-full"
    >
      <DashboardWidgetCard.Header>
        <DashboardWidgetCard.Header.Title>
          {widgetSection.title}
        </DashboardWidgetCard.Header.Title>
        <div className="grow" />
        <WidgetStateCalendarRangeSelector
          state={state}
          onStateChange={onStateChange}
          disableFuture={false}
        />
        <WidgetFullScreen wrapperDivRef={wrapperDivRef} />
      </DashboardWidgetCard.Header>
      <DashboardWidgetCard.Panel>
        <WidgetStateTablePagination
          isLoading={isLoading ?? isFetching ?? false}
          state={state}
          onStateChange={onStateChange}
          totalSize={data?.totalSize ?? 0}
          includeTotalSizeInPerPage
        />
        <GrowDiv />
        <WidgetStateSearchInput
          state={state}
          onStateChange={onStateChange}
          suggestions={['unit label', 'asset name']}
        />
      </DashboardWidgetCard.Panel>
      {!isLoading && !isError && (
        <WidgetTable
          ref={gridRef}
          treeData={false}
          onCellClicked={(e) => {
            if (e.colDef.colId !== UNIT_COL_ID) return;
            openModal(LeaseDetailsModal, {
              unitId: e.data.unit_id,
              unitLabel: e.value,
            });
          }}
          mode={mode}
          autoGroupColumnDef={undefined}
          rowData={mappedData?.rows}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onFilterChanged={(e) => {
            onStateChange({
              ...state,
              ...getStateFromFilterChangedEvent(e, data?.columns ?? []),
            });
          }}
          postProcessPopup={postProcessPopup}
        />
      )}
    </DashboardWidgetTableCard>
  );
}
