import { Tooltip } from '@/stories';
import {
  ColDef,
  ICellRendererParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { CurrencyCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/CurrencyCellRenderer';
import { HeaderComponent } from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderComponent';
import { SourceTypeImageWrapper } from 'bundles/Shared/components/AgGrid/Table/cellComponents/SourceTypeImageWrapper';
import { NEXT_GEN_TABLE_CONFIG } from 'bundles/Shared/components/AgGrid/Table/consts';
import { useReportTableRowData } from 'bundles/Shared/components/ReportTable/helpers/hooks/useReportTableRowData';
import {
  autoGroupValueFormatter,
  scrollTableCenterViewport,
} from 'bundles/Shared/components/ReportTable/utils';
import { useGridDefaultParams } from 'lib/ag-grid/useGridDefaultParams';
import { useMemo, useRef } from 'react';
import { IReportTableConfig } from 'types/ReportTableConfig';
import { NextGenTable } from '../AgGrid/Table/NextGenTable';
import { AutoGroupCellRenderer } from '../AgGrid/Table/cellComponents/AutoGroupCellRenderer';
import { AutoGroupHeaderComponent } from '../AgGrid/Table/cellComponents/AutoGroupHeaderComponent';
import { getEmptyCellRendererParams } from '../AgGrid/Table/utils/getCurrencyCellRendererProps';
import { useColGroupDefs } from './helpers/hooks/useColGroupDefs';
import {
  IColumn,
  IColumnGroup,
  IGlRow,
  IRow,
  IRowBase,
  TRow,
  isRowGl,
} from './types';

interface Props {
  columns: IColumnGroup[];
  periodsType: IReportTableConfig['periodsType'];
  rows: TRow[];
  headerTitle?: string;
  onActiveCellClick?: (row: IGlRow, column: IColumn) => void;
}

const MIN_AUTO_GROUP_WIDTH = 280;

export function ReportFinancialsTable({
  rows,
  columns,
  periodsType,
  onActiveCellClick,
}: Props) {
  const gridRef = useRef<AgGridReact>(null);

  const { onGridReady } = useGridDefaultParams({
    enableExpandOnClick: true,
  });

  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      pinned: true,
      resizable: true,
      headerName: '',
      suppressMenu: true,
      minWidth: MIN_AUTO_GROUP_WIDTH,
      initialWidth: MIN_AUTO_GROUP_WIDTH,
      headerComponent: AutoGroupHeaderComponent,
      headerComponentParams: {
        subHeaderName: '',
      },
      cellRendererParams: {},
      cellRenderer: (
        params: OverrideField<ICellRendererParams, 'data', IRow>,
      ) => (
        <SourceTypeImageWrapper
          source={
            isRowGl(params.data)
              ? params.data.generalLedger.source ?? null
              : null
          }
          isGLRow={isRowGl(params.data)}
        >
          <AutoGroupCellRenderer {...params} />
        </SourceTypeImageWrapper>
      ),
      valueGetter: (params: ValueGetterParams) => {
        const { node } = params;
        const isRootTotal = node?.level === -1 && node?.footer;
        const nodeData = node?.data as IRowBase | undefined;

        if (isRootTotal) return 'Grand Total';

        if (!nodeData) return '';

        if (nodeData.label) {
          if (node?.footer) return `Total ${nodeData.label}`;

          return nodeData.label;
        }

        if (isRowGl(nodeData)) {
          return (
            <Tooltip
              placement="left"
              disabled={nodeData.generalLedger.legalEntity?.name == null}
              mainText={nodeData.generalLedger.legalEntity?.name}
            >
              {`${nodeData.generalLedger.accNumber} - ${nodeData.generalLedger.accTitle} | ${nodeData.generalLedger.legalEntity.name}`}
            </Tooltip>
          );
        }

        return autoGroupValueFormatter(params);
      },
    }),
    [columns],
  );

  const rowData = useReportTableRowData(rows);

  const colGroupDefs = useColGroupDefs({
    columns,
    periodsType,
    actions: {
      onActiveCellClick,
    },
  });

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      cellRenderer: CurrencyCellRenderer,
      cellRendererParams: getEmptyCellRendererParams,
      headerComponent: HeaderComponent,
      suppressMovable: true,
      suppressMenu: true,
      minWidth: NEXT_GEN_TABLE_CONFIG.column.default.width,
      width: NEXT_GEN_TABLE_CONFIG.column.default.width,
    }),
    [columns],
  );

  return (
    <NextGenTable
      hideAmountsOnExpandedRows
      onGridReady={onGridReady}
      groupIncludeTotalFooter={false}
      groupDefaultExpanded={0}
      ref={gridRef}
      defaultColDef={defaultColDef}
      rowData={rowData}
      columnDefs={colGroupDefs}
      autoGroupColumnDef={autoGroupColumnDef}
      onFirstDataRendered={() => scrollTableCenterViewport()}
    />
  );
}
