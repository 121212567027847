import { cn } from '@/shared/lib/css/cn';
import { IHeaderParams } from 'ag-grid-community';
import { HeaderSortButton } from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderComponent';
import React from 'react';
import { CELL_CLASS_NAMES } from '../classNames';

export function AutoGroupHeaderComponent({
  subHeaderName,
  displayName,
  classes,
  style,
  ...params
}: IHeaderParams & {
  style?: React.CSSProperties;
} & {
  subHeaderName: string;
  classes?: {
    wrapper?: string;
    inner?: string;
    subHeader?: string;
    header?: string;
  };
}) {
  return (
    <div
      className={cn(
        CELL_CLASS_NAMES.AutoGroupHeader.wrapper.default,
        classes?.wrapper,
      )}
      style={style}
    >
      <div
        className={cn(
          CELL_CLASS_NAMES.AutoGroupHeader.inner.default,
          classes?.inner,
          params.enableSorting && '!flex-row items-center !justify-between',
        )}
      >
        <div className="flex flex-col">
          <p className={cn('inline-regular', classes?.header)}>{displayName}</p>
          <p
            className={cn(
              'inline-semibold text-neutral-500',
              classes?.subHeader,
            )}
          >
            {subHeaderName}
          </p>
        </div>
        {params.enableSorting && (
          <HeaderSortButton displayName={displayName} {...params} />
        )}
      </div>
    </div>
  );
}

export function AutoGroupCustomHeaderComponent({
  customComponent,
}: IHeaderParams & {
  customComponent: string;
}) {
  return (
    <div className={cn(CELL_CLASS_NAMES.AutoGroupHeader.wrapper.default)}>
      <div className={cn(CELL_CLASS_NAMES.AutoGroupHeader.inner.default)}>
        {customComponent}
      </div>
    </div>
  );
}
