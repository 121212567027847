import { UNGROUPED_GROUP_KEY } from '@/bundles/Shared/entities/dashboard';
import { HeaderComponentWithSubHeader } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/HeaderComponentWithSubHeader';
import { TableVizConfigColumn } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { groupBy } from 'lodash-es';
import { ComponentProps } from 'react';

export const resolveHeaderWithSubheaderComponentProps = ({
  headerName,
  subHeaderName,
  hide_title,
  hide_subtitle,
}: TableVizConfigColumn['header'] &
  Pick<
    ComponentProps<typeof HeaderComponentWithSubHeader>,
    'headerName' | 'subHeaderName'
  >): Pick<
  ComponentProps<typeof HeaderComponentWithSubHeader>,
  'headerName' | 'subHeaderName'
> => {
  return {
    headerName: hide_title ? '' : headerName,
    subHeaderName: hide_subtitle ? '' : subHeaderName,
  };
};

type RecordWithKey = { key: number | string };
type ColumnSettingRecord = { key: number | string; group_id?: string | null };
type Merged<C extends RecordWithKey, CS extends ColumnSettingRecord> = (C & {
  viz_config: CS | undefined;
})[];

export const createMapByKey = <R extends RecordWithKey>(items: R[]) =>
  new Map(items.map((c) => [String(c.key), c]));

export const createColumnGroupSettingsMap = <
  CGS extends { group_id: number | string },
>(
  columnGroupSettings: CGS[],
) => new Map(columnGroupSettings.map((c) => [String(c.group_id), c]));

export const mergeColumnsAndColumnSettings = <
  C extends RecordWithKey,
  CS extends ColumnSettingRecord,
>(
  columns: C[],
  columnSettingsMap: Map<string, CS>,
): Merged<C, CS> =>
  columns.map((c) => {
    return {
      ...c,
      viz_config: columnSettingsMap.get(String(c.key)),
    };
  });

export const groupColumnsAndColumnsSettingsByGroupId = <
  C extends RecordWithKey,
  CS extends ColumnSettingRecord,
>(
  merged: Merged<C, CS>,
) => groupBy(merged, (c) => c.viz_config?.group_id ?? UNGROUPED_GROUP_KEY);
