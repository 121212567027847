import { NotFound } from '@/bundles/Errors';
import {
  REPORT_BUILDER_GRID_COLS_CLASSNAME,
  resolveWidgetSectionPositionClassName,
} from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { REPORT_BUILDER_WIDGETS_CONFIG_MAP } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import {
  isWidgetHasNoDataAndHiddenWidgetPlaceholders,
  widgetHasData,
} from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib';
import {
  REPORT_BUILDER_ORIENTATION_CLASSES,
  ReportPDFHeader,
  useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdQuery,
  usePostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotQuery,
} from '@/entities/report/reportBuilder';
import {
  AssetsReportBuilderReportGroupDto,
  GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse,
} from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { safelyParseJSON } from '@/lib/typeHelpers/safelyParseJSON';
import { cn } from '@/shared/lib/css/cn';
import {
  TRouteQueryParams,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import { AnimationLoader } from '@/stories';
import { RouteComponentProps, useParams } from '@reach/router';
import { WidgetProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { FC, useMemo } from 'react';
import { UnknownRecord } from 'type-fest/source/internal';

const Widget = ({
  reportData,
  pageOrientation,
  widgetSection,
  ...props
}: {
  reportData: GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse['report'];
  pageOrientation: AssetsReportBuilderReportGroupDto['pageOrientation'];
} & Omit<
  WidgetProps,
  | 'data'
  | 'isLoading'
  | 'isFetching'
  | 'isError'
  | 'mode'
  | 'state'
  | 'dashboardId'
  | 'widgetId'
  | 'context'
>) => {
  const params = useParams();
  const { assetId, hidePlaceholders } =
    useQueryParams<TRouteQueryParams['/reports/:id/view']>();

  const { data, isLoading, isFetching, isError } =
    usePostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotQuery(
      {
        assetId: Number(assetId),
        reportId: params.id,
        id: widgetSection.id,
        body: {
          context: {},
        },
      },
    );

  const { Component } =
    REPORT_BUILDER_WIDGETS_CONFIG_MAP[widgetSection.widgetType] ?? {};

  const isWidgetHidden = useMemo(() => {
    if (isError || !Component) return true;

    if (
      data !== null &&
      isWidgetHasNoDataAndHiddenWidgetPlaceholders(
        Boolean(safelyParseJSON(hidePlaceholders)),
        data,
      )
    ) {
      return true;
    }

    if (data !== null && !widgetHasData(data)) return true;

    return false;
  }, [isError, reportData, data, Component]);

  if (isWidgetHidden) return null;

  return (
    <Component
      mode="pdf"
      pageOrientation={pageOrientation}
      state={(widgetSection.snapshotContext ?? {}) as UnknownRecord}
      onStateChange={() => {}}
      data={data?.widget}
      isLoading={isLoading}
      isError={isError}
      isFetching={isFetching}
      widgetSection={widgetSection}
      dashboardId={''}
      widgetId={''}
      context={{}}
      className={resolveWidgetSectionPositionClassName(widgetSection.position)}
      {...props}
    />
  );
};

export const ReportViewPage: FC<RouteComponentProps> = () => {
  const params = useParams();
  const { assetId, hidePlaceholders } =
    useQueryParams<TRouteQueryParams['/reports/:id/view']>();
  const { data, isLoading } =
    useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdQuery({
      id: params.id,
      assetId: Number(assetId),
    });
  if (isLoading) return <AnimationLoader />;

  if (data?.report == null) {
    return <NotFound />;
  }

  return (
    <article className="ml-auto flex min-h-screen flex-col items-center justify-start bg-[#3D3D3D] px-tw-4 py-tw-6 print:m-0 print:min-h-[auto] print:w-full print:items-start print:bg-[initial] print:p-0 ">
      {data.report.groups.map((g, gIdx) => (
        <section
          id={`section-${g.id}`}
          key={g.id}
          className={cn(
            'grid gap-tw-6 bg-neutral-000 p-tw-4 px-tw-6 print:bg-[initial]',
            REPORT_BUILDER_GRID_COLS_CLASSNAME,
            REPORT_BUILDER_ORIENTATION_CLASSES[g.pageOrientation].className,
            {
              'print:empty:hidden': hidePlaceholders,
              'page-wide': g.pageOrientation === 'landscape',
            },
          )}
        >
          {gIdx === 0 && (
            <ReportPDFHeader
              objectAddress={data.meta.asset?.address ?? ''}
              objectName={data.meta.asset?.name ?? ''}
              reportTitle={data.report.name}
              reportSubtitle={data.report.subtitle}
            />
          )}
          {g.widgets.map((w, index) => (
            <Widget
              reportData={data.report}
              key={w.id}
              widgetSection={w}
              pageOrientation={g.pageOrientation}
              isLast={
                gIdx === data.report.groups.length - 1 &&
                index === g.widgets.length - 1
              }
            />
          ))}
        </section>
      ))}
    </article>
  );
};
