import React, { useEffect, useMemo, useRef } from 'react';
import { useGetProjectsQuery } from 'bundles/REconcile/components/development/api/projects';
import { useFavoriteItemIds } from '@/shared/lib/hooks/useFavoriteItemIds';
import ProjectCardList from 'stories/ProjectCard/ProjectCardList';
import { ProjectCardSkeleton } from 'stories/ProjectCard/ProjectCard';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { Link, useNavigate } from '@reach/router';
import ReconcileDevelopmentProjectCard from 'bundles/REconcile/components/development/home/dashboard/ReconcileDevelopmentProjectCard';
import { usePageParamsContext } from 'bundles/Shared/components/pageParams';
import {
  TRouteParams,
  useNavigation,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import { sortBy } from 'lodash-es';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import useElementSize, { ElementSizeContext } from '@/shared/lib/hooks/useElementSize';

function ReconcileDevelopmentDashboardCardList() {
  const listRef = useRef(null);
  const size = useElementSize(listRef);
  const { getUrl } = useNavigation();
  const navigate = useNavigate();
  const { projectId } = useQueryParams<TRouteParams['RECONCILE_DEVELOPMENT']>();
  const { pageParams } = usePageParamsContext();
  const { data, isLoading } = useGetProjectsQuery({
    ...pageParams,
  });
  const { isItemFavorite, toggleItemFavorite } = useFavoriteItemIds(
    'reconcileDevelopmentProjectFavoriteIds',
  );

  const projects = useMemo(
    () =>
      data?.items
        ? sortBy(data.items, (project) => isItemFavorite(project.id)).reverse()
        : [],
    [data?.items],
  );

  useEffect(() => {
    if (projectId === undefined && data != null && projects.length > 0) {
      navigate(getUrl('RECONCILE_DEVELOPMENT', { projectId: projects[0].id }));
    }
  }, [projects]);

  if (isLoading) {
    return (
      <ProjectCardList>
        {Array.from({ length: 5 }).map((_, i) => (
          <ProjectCardSkeleton key={i} />
        ))}
      </ProjectCardList>
    );
  }

  if (data == null || projects.length === 0) {
    return (
      <NoDataOverlay
        title="Nothing Found"
        subTitle="Reset filters to see all projects"
      />
    );
  }
  return (
    <OverlayScrollbarsComponent
      options={{
        className: 'os-theme-thin-dark os-host-flexbox',
      }}
    >
      <ProjectCardList ref={listRef}>
        <ElementSizeContext.Provider value={size}>
          {projects.map((project) => (
            <Link
              key={project.id}
              to={getUrl('RECONCILE_DEVELOPMENT', { projectId: project.id })}
            >
              <ReconcileDevelopmentProjectCard
                key={project.id}
                legalEntities={[project.legalEntity]}
                name={project.name}
                latestBudgetAmount={project.latestBudgetAmount}
                fundedToDate={project.fundedToDate}
                percentOfCompletion={project.percentOfCompletion}
                favorite={isItemFavorite(project.id)}
                onFavoriteClick={() => toggleItemFavorite(project.id)}
                selected={projectId === project.id}
                pictureUrl={project.pictureUrl}
              />
            </Link>
          ))}
        </ElementSizeContext.Provider>
      </ProjectCardList>
    </OverlayScrollbarsComponent>
  );
}

export default ReconcileDevelopmentDashboardCardList;
