/* eslint-disable react/jsx-wrap-multilines */
import { HUGE_MODAL_Z_INDEX } from '@/bundles/Shared/constants';
import { cn } from '@/shared/lib/css/cn';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import React, { PropsWithChildren } from 'react';
import { Button, Modal, ModalHeaderWithIcon } from 'stories';
import { ClassNameProps } from 'types/Props';
import { AmountComparison } from './AmountComparison';
import { LinkTransaction } from './LinkTransaction';
import { Total } from './Total';

function ReviewActionModal({
  onClose,
  onSubmit,
  children,
}: DialogProps<boolean> & PropsWithChildren) {
  return (
    <Modal
      size="600"
      classes={{
        body: 'bg-light',
      }}
      zIndex={HUGE_MODAL_Z_INDEX + 2}
      header={<ModalHeaderWithIcon title="Review Action" />}
      toggle={onClose}
      actions={
        <>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button onClick={() => onSubmit?.(true)} variant="success">
            Confirm
          </Button>
        </>
      }
    >
      {children}
    </Modal>
  );
}

function ReviewActionItem({
  title,
  children,
  variant = 'with-borders',
}: {
  title: string;
  variant?: 'with-shadows' | 'with-borders';
} & PropsWithChildren) {
  if (!children) return null;

  return (
    <div className="flex flex-col gap-tw-2">
      <p className="body-semibold text-light-90">{title}</p>
      <div
        className={cn('grid gap overflow-hidden rounded-lg bg-light-5', {
          'border border-solid border-light-5': variant === 'with-borders',
          'shadow-s': variant === 'with-shadows',
        })}
      >
        {children}
      </div>
    </div>
  );
}

function ReviewActionSubItem({
  name,
  children,
  className,
  subtitle,
}: {
  name: string;
  subtitle?: React.ReactNode;
} & PropsWithChildren &
  ClassNameProps) {
  if (!children) return null;

  return (
    <div
      className={cn(
        'flex items-center justify-between bg-white px-tw-6 py-tw-4',
        className,
      )}
    >
      <p className="inline-regular flex flex-col gap-tw-1 text-light-90">
        {name}
        {subtitle}
      </p>
      {children}
    </div>
  );
}

export default {
  Modal: ReviewActionModal,
  Item: ReviewActionItem,
  SubItem: ReviewActionSubItem,
  AmountComparison,
  LinkTransaction,
  Total,
};
