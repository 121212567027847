import { DialogProps } from '@/shared/lib/hooks/useModal';
import {
  CreateNewForm,
  EditPanelActions,
  FormulaField,
  listUpdater,
  useUpdateWidgetConfig,
  useWidgetConfig,
  ValueDisplayOptionsFields,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { OBJECT_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  hasSeriesRef,
  upsertSeriesInConfig,
  useRemoveKpiFromConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib';
import { produce } from 'immer';
import { pick } from 'lodash-es';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import { Field, FieldsContainer } from 'stories/Field/Field';
import { Input } from '@/stories';
import { SectionField } from 'stories/Field/FieldsWrappers';
import { ColorPicker } from 'bundles/Shared/features/dashboard/sectionActions';
import { COLOR_SET } from 'lib/amcharts/utils';
import React from 'react';
import {
  XYCHART_CONFIG_ROW_SCHEMA,
  XYChartWidgetConfigRowForm,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChart/config/row.form';
import { XYChartWidgetConfigKpi } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/model';

interface Props
  extends DialogProps<{
    createNew: boolean;
  }> {
  type: 'create' | 'edit';
  rowConfig?: XYChartWidgetConfigKpi;
}

export function XYChartWidgetConfigRow({
  type,
  rowConfig,
  onClose,
  onSubmit,
}: Props) {
  const { widget } =
    useWidgetConfig<typeof OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART>();
  const resolver = yupResolver(XYCHART_CONFIG_ROW_SCHEMA);
  const methods = useForm<XYChartWidgetConfigRowForm & CreateNewForm>({
    resolver,
    mode: 'all',
    defaultValues: {
      ...rowConfig,
    },
  });
  const { control, register, handleSubmit } = methods;
  const [_, __, debouncedUpdateConfig] = useUpdateWidgetConfig(
    OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART,
  );
  const removeKpi = useRemoveKpiFromConfig();

  const handleSubmitClick = handleSubmit((values) => {
    debouncedUpdateConfig({
      config: produce(widget.widgetConfig, (draft) => {
        const { upsertItem } = listUpdater(draft.kpis, {
          key: 'key',
        });
        const newKpis = upsertItem(
          pick(values, ['label', 'expression', 'key', 'value_display_options']),
        );
        draft.kpis = newKpis;
        upsertSeriesInConfig(draft.am_chart_config, {
          key: newKpis.at(-1)!.key,
          ...values,
        });
      }),
    });
    onSubmit?.({
      createNew: values.createNew,
    });
  });

  return (
    <FormProvider {...methods}>
      <SidePanel
        header={type === 'create' ? 'Create' : 'Edit Row'}
        toggle={onClose}
        actions={
          <EditPanelActions
            type={type}
            onClose={onClose}
            onSubmit={handleSubmitClick}
            onRemove={() => removeKpi(rowConfig!.key)}
          />
        }
      >
        <FieldsContainer>
          <Field note="Add text to your card (Optional)" labelText="Label">
            <Input
              placeholder="Enter Label"
              className="w-full"
              {...register('label')}
            />
          </Field>
          <FormulaField control={control} name="expression" required />
          <ValueDisplayOptionsFields
            name="value_display_options"
            control={control}
          />
          {hasSeriesRef(widget.widgetConfig.am_chart_config) && (
            <>
              <SectionField labelText="Color">
                <Controller
                  render={({ field }) => (
                    <ColorPicker
                      activeColor={field.value}
                      colors={COLOR_SET.map((c) => ({
                        value: c.toCSSHex(),
                      }))}
                      onChange={field.onChange}
                    />
                  )}
                  name="color"
                  control={control}
                />
              </SectionField>
            </>
          )}
        </FieldsContainer>
      </SidePanel>
    </FormProvider>
  );
}
