import { LegalEntity } from '@/entities/core/legalEntity';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { ROUTES_ROOT, generateUrl } from '@/shared/lib/hooks/useNavigation';
import { useNavigate } from '@reach/router';
import { fetchInvestmentObjectDetails } from 'bundles/REturn/actions/capitalLegalEntities';
import DetailsChart from 'bundles/REturn/components/Home/investmentObjectDetailsModal/DetailsChart';
import DetailsTable from 'bundles/REturn/components/Home/investmentObjectDetailsModal/DetailsTable';
import InvestmentObjectDetailsHeader from 'bundles/REturn/components/Home/investmentObjectDetailsModal/InvestmentObjectDetailsHeader';
import LegalEntitiesFilter from 'bundles/REturn/components/Home/investmentObjectDetailsModal/LegalEntitiesFilter';
import {
  DEFAULT_TAB,
  MAX_DISPLAYED_CHART_ITEMS,
  RETURN_HOME_PAGE_DETAILS_MODAL_TABS,
  ReturnInvestmentObjectTab,
  getDetailsItemsForTab,
  getTabIsDisabled,
} from 'bundles/REturn/components/Home/investmentObjectDetailsModal/utils';
import LegalEntitiesLinkWithPopover from 'bundles/REturn/components/LegalEntitiesLinkWithPopover';
import { IReturnInvestmentObjectDetails } from 'bundles/REturn/types';
import { capitalize, sortBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { AnimationLoader, ThinTabGroup } from 'stories';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import { IThinTabItem } from 'stories/Tabs/ThinTabGroup/ThinTabGroup';

type Props = DialogProps & {
  id: string;
  investmentObjectIds: string[];
  tab?: ReturnInvestmentObjectTab;
};

function InvestmentObjectDetailsModal({
  id,
  investmentObjectIds,
  tab,
  onClose,
}: Props) {
  const currentTab = tab ?? DEFAULT_TAB;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [investmentObject, setInvestmentObject] = useState<
    IReturnInvestmentObjectDetails | undefined
  >();
  const [selectedLegalEntities, setSelectedLegalEntities] = useState<
    LegalEntity[]
  >([]);

  const tabs = useMemo<IThinTabItem<ReturnInvestmentObjectTab>[]>(
    () =>
      Object.keys(RETURN_HOME_PAGE_DETAILS_MODAL_TABS).map(
        (key: ReturnInvestmentObjectTab) => {
          const disabled = investmentObject
            ? getTabIsDisabled(key, investmentObject)
            : true;

          return {
            label: capitalize(key),
            id: key,
            disabled,
            tooltipProps: {
              mainText: 'No Data Yet',
              disabled: !disabled,
            },
          };
        },
      ),
    [investmentObject],
  );

  const detailsItems = useMemo(
    () =>
      investmentObject
        ? getDetailsItemsForTab(currentTab, investmentObject, [])
        : [],
    [investmentObject, currentTab],
  );
  const filteredDetailsItems = useMemo(
    () =>
      investmentObject
        ? getDetailsItemsForTab(
            currentTab,
            investmentObject,
            selectedLegalEntities.map(({ id }) => id),
          )
        : [],
    [detailsItems, selectedLegalEntities],
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await fetchInvestmentObjectDetails(id);
      setInvestmentObject(data);
      setSelectedLegalEntities([]);
      setIsLoading(false);
    };
    fetchData();
  }, [id]);

  const handleTabChange = (newTab: ReturnInvestmentObjectTab) => {
    navigate(
      generateUrl(ROUTES_ROOT.return.fullPath, {
        queryParams: { detailsId: id, tab: newTab },
        pathParams: {},
      }),
    );
  };

  return (
    <SidePanel
      toggle={onClose}
      bodyHeight="100vh"
      maxHeight
      header={
        investmentObject && (
          <div className="flex flex-col gap-xxs">
            <h6 className="header6-bold">{investmentObject.object.name}</h6>
            <LegalEntitiesLinkWithPopover
              className="dark-60 secondary-regular"
              legalEntities={investmentObject.legalEntities}
            />
          </div>
        )
      }
      backdrop={false}
      contentClassName="rounded-0"
      showDivider={false}
      customClose={
        <InvestmentObjectDetailsHeader
          investmentObjectIds={investmentObjectIds}
          id={id}
          onClose={onClose}
          currentTab={currentTab}
        />
      }
    >
      {isLoading && <AnimationLoader />}
      {!isLoading && (
        <div className="flex h-full flex-col gap-l">
          <div className="flex flex-col gap-l">
            <ThinTabGroup<ReturnInvestmentObjectTab>
              className="self-start"
              selectedItem={tabs.find((t) => t.id === currentTab)}
              onSelectedItemChange={(newTab) => handleTabChange(newTab.id)}
              items={tabs}
            />
            <LegalEntitiesFilter
              legalEntities={investmentObject?.legalEntities ?? []}
              selectedLegalEntities={selectedLegalEntities}
              onSelectedChange={setSelectedLegalEntities}
            />
          </div>
          <div className="flex flex-col gap-l overflow-auto">
            <DetailsChart
              items={sortBy(filteredDetailsItems, (item) => item.amount)
                .toReversed()
                .slice(0, MAX_DISPLAYED_CHART_ITEMS)}
            />
            <DetailsTable tab={currentTab} items={filteredDetailsItems} />
          </div>
        </div>
      )}
    </SidePanel>
  );
}

export default InvestmentObjectDetailsModal;
