import { getNextStatusAction, OBJECT_STATUSES_LABELS } from '@/lib/asset';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import StepperStages from 'bundles/Assets/components/StepperStages/StepperStages';
import {
  ASSET_PORTAL_PRODUCT_NAME,
  currentUserAllowedTo,
} from 'lib/permissions';
import React, { useEffect, useState } from 'react';
import { Button, IconButton, Input, LinkButton, Modal, Tumbler } from 'stories';
import { IAsset } from 'types/Asset';
import DropzoneArea from '../../Shared/components/DropzoneArea';
import { fetchCustomerConfig } from '../actions';

interface Props extends DialogProps<FormData> {
  asset?: IAsset;
  isLoading?: boolean;
}

export const INPUT_NAMES: Record<
  keyof Pick<IAsset, 'id' | 'name' | 'stage' | 'aasmState' | 'offering'>,
  string
> & { picture: 'picture' } = {
  id: 'id',
  name: 'asset[name]',
  stage: 'asset[stage]',
  aasmState: 'asset[aasm_state]',
  offering: 'asset[offering]',
  picture: 'picture',
};

const AssetFormModal = ({ asset, onSubmit, onClose, isLoading }: Props) => {
  const customerConfig = useAppSelector((state) => state.customerConfig);
  const assetStages = customerConfig.assetStages.filter((a) => a.active);
  const [stage, setStage] = useState(asset?.stage?.name);
  const [status, setStatus] = useState(asset?.aasmState);
  const [offering, setOffering] = useState(asset?.offering || false);
  const [assetPhoto, setAssetPhoto] = useState(
    asset
      ? {
          smallPictureUrl: asset.smallPictureUrl,
        }
      : null,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCustomerConfig());
  }, []);

  const { confirm } = useModal();

  const currentStatusIndex = OBJECT_STATUSES_LABELS.findIndex(
    ({ key }) => key === status,
  );
  const nextStatus = getNextStatusAction(
    OBJECT_STATUSES_LABELS[currentStatusIndex]?.key,
  );

  const changeStatus = async (aasmState) => {
    const result = await confirm({
      subtitle: 'Do you want to change Asset’s status?',
      title: 'Changing Asset’s Status',
      actions: {
        primaryButton: {
          variant: 'success',
          text: `Yes, ${nextStatus.label}`,
        },
        secondaryButton: {
          variant: 'secondary',
          text: 'Cancel',
        },
      },
    });
    if (result) setStatus(aasmState);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    if (
      assetPhoto?.smallPictureUrl !== asset?.smallPictureUrl &&
      assetPhoto !== undefined
    ) {
      formData.append(INPUT_NAMES.picture, assetPhoto);
    }
    if (asset) {
      formData.append(INPUT_NAMES.id, asset.id);
      if (stage) formData.append(INPUT_NAMES.stage, stage);
      formData.append(INPUT_NAMES.aasmState, status);
      formData.append(INPUT_NAMES.offering, offering);
    }

    onSubmit?.(formData);
  };

  return (
    <Modal
      toggle={onClose}
      header={asset ? 'Edit Asset' : 'New Asset'}
      size="600"
      classes={{
        header: 'bg-light',
        body: 'bg-light',
        footer: 'bg-light',
      }}
      actions={
        <>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            loading={isLoading}
            form="assetFormModal"
            type="submit"
            variant="success"
          >
            {asset ? 'Save Changes' : 'Create Asset'}
          </Button>
        </>
      }
    >
      <form id="assetFormModal" onSubmit={handleSubmit}>
        <label className="mb-tw-4 w-full">
          <span>
            Asset Name <span className="red">*</span>
          </span>
          <Input
            size="l"
            name={INPUT_NAMES.name}
            placeholder="Fill Asset name"
            defaultValue={asset?.name}
            className="input-light"
          />
        </label>
        <section style={{ height: 200 }}>
          {assetPhoto?.smallPictureUrl && (
            <div className="asset-photo-preview">
              <IconButton
                onClick={() => setAssetPhoto(null)}
                iconName="trash"
                variant="primary"
              />
              <img src={assetPhoto.smallPictureUrl} alt="Asset cover" />
            </div>
          )}
          {!assetPhoto?.smallPictureUrl && (
            <DropzoneArea onChange={(files) => setAssetPhoto(files[0])} />
          )}
        </section>
        {currentUserAllowedTo('manage', ASSET_PORTAL_PRODUCT_NAME) && asset && (
          <div className="my-s">
            <span className="label-regular text-dark-60">Stage</span>
            <div className="inline-regular mb-m">
              Here you can select the current stage for this asset
            </div>
            <div className="mb-m">
              <StepperStages
                selectedStage={stage}
                assetStages={assetStages}
                onChange={setStage}
              />
            </div>

            <div className="inline-regular mb-tw-1 mt-tw-4">Status</div>
            {currentStatusIndex !== -1 && (
              <h6
                style={{
                  color: OBJECT_STATUSES_LABELS[currentStatusIndex].color,
                }}
              >
                {OBJECT_STATUSES_LABELS[currentStatusIndex].label}
                {nextStatus && (
                  <LinkButton
                    color="blue-link"
                    className="ml-tw-4"
                    onClick={() => changeStatus(nextStatus.status)}
                  >
                    {nextStatus.label}
                  </LinkButton>
                )}
              </h6>
            )}
            <Tumbler
              onChange={() => setOffering(!offering)}
              checked={offering}
              className="mt-m"
            >
              Offering
            </Tumbler>
          </div>
        )}
      </form>
    </Modal>
  );
};

export default AssetFormModal;
