import { OBJECT_STATUSES_OPTIONS } from '@/lib/asset';
import { cn } from '@/shared/lib/css/cn';
import { RouteComponentProps, useMatch } from '@reach/router';
import { LinkWrapper } from '@/shared/ui/LinkWrapper';
import {
  useGetAssetsQuery,
  useHeavyUpdateAssetWithInvalidationMutation,
} from 'bundles/Settings/components/Portal/AssetDashboard/api/coreAssetsApi';
import { ALL_STATUS_OPTIONS_MAP } from 'bundles/Settings/components/Portal/shared/consts';
import { useGenerateUrlForCard } from 'bundles/Settings/components/Portal/shared/hooks/useGenerateUrlForCard';
import { useGoToFirstObjectFromDataEffect } from 'bundles/Settings/components/Portal/shared/hooks/useGoToFirstObjectFromDataEffect';
import ExternalUsersIcon from 'bundles/Settings/components/Portal/shared/ui/ExternalUsersIcon';
import { LegalEntitiablesThinTabGroup } from 'bundles/Settings/components/Portal/shared/ui/LegalEntitiablesThinTabGroup';
import ObjectCardSkeletonBlock from 'bundles/Settings/components/Portal/shared/ui/ObjectCardSkeletonBlock';
import StatusDropdown from 'bundles/Settings/components/Portal/shared/ui/StatusDropdown';
import { useMenuItemsPermissions } from 'bundles/Shared/components/LeftSidebar/hooks/useMenuItemsPermissions';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import TablePagination from 'bundles/Shared/components/Table/pagination/TablePagination';
import ListNavLayout from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/ScreenWithListNavigationLayout';
import { filterByExternal } from 'bundles/Shared/entities/user/lib';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useDebouncedQuery } from '@/shared/lib/hooks/useDebouncedQuery';
import { useFavoriteItemIds } from '@/shared/lib/hooks/useFavoriteItemIds';
import {
  ROUTES_ROOT,
  allSubPathMatches,
  generateUrl,
} from '@/shared/lib/hooks/useNavigation';
import {
  getOptionsValues,
  mapItemsToListOption,
} from '@/shared/lib/listHelpers';
import {
  TProductNames,
  currentUserAllowedTo,
  currentUserIsExternal,
} from 'lib/permissions';
import { capitalize } from 'lodash-es';
import { ComponentProps, FC, PropsWithChildren, useState } from 'react';
import { CheckList, ListOption } from 'stories/Checkbox/CheckList';
import FavoriteCardIcon from 'stories/ProjectCard/FavoriteCardIcon';
import {
  Button,
  Icon,
  IconButton,
  Popover,
  ProjectCard,
  SearchInput,
} from 'stories/index';
import { IAsset } from 'types/Asset';

const prepareUrl = (assetSlug: IAsset['slug']) =>
  generateUrl(ROUTES_ROOT.assets.asset.fullPath, {
    pathParams: {
      assetSlug,
    },
  });

export const OBJECT_DASHBOARD_PAGE_TABS_MAP = [
  ['overview', { to: ROUTES_ROOT.assets.asset.fullPath }],
  ['media', { to: ROUTES_ROOT.assets.asset.media.albums.fullPath }],
  ['documents', { to: ROUTES_ROOT.assets.asset.documents.fullPath }],
] as const;

export const DropdownWithCheckboxes = ({
  label,
  items,
  value,
  onChange,
  disabled,
}: {
  label: string;
  items: ListOption<string>[];
  value: ListOption<string>[];
  onChange: (item: ListOption<string>[]) => void;
  disabled?: boolean;
}) => {
  const template = (
    <CheckList
      disabled={disabled}
      items={items}
      value={value}
      onChange={onChange}
    />
  );

  return (
    <Popover
      trigger="click"
      hiddenArrow
      placement="bottom-end"
      disabled={disabled}
      template={template}
    >
      <Button disabled={disabled} variant="secondary" size="s">
        {label} <Icon iconName="arrowBottom" />
      </Button>
    </Popover>
  );
};

const INITIAL_PER_PAGE = 50;

const AssetDashboardPage: FC<RouteComponentProps & PropsWithChildren> = ({
  children,
}) => {
  const allStatuses = OBJECT_STATUSES_OPTIONS.filter(
    (st) => !currentUserIsExternal() || st.label !== 'Draft',
  );
  const dashboardExpanded = useAppSelector(
    (state) => state.coreEntity.dashboardExpanded,
  );
  const { query, debouncedQuery, handleChange, resetQuery } =
    useDebouncedQuery('');
  const menuItemsPermissions = useMenuItemsPermissions();

  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(INITIAL_PER_PAGE);
  const [stages, setStages] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<string[]>([]);
  const { isItemFavorite, toggleItemFavorite, favouriteItems } =
    useFavoriteItemIds('coreAssetsFavoriteIds');

  const generateUrlForCard = useGenerateUrlForCard(
    ROUTES_ROOT.assets.asset.fullPath,
  );
  const [heavyUpdateWithInvalidate] =
    useHeavyUpdateAssetWithInvalidationMutation();

  const match = useMatch(allSubPathMatches(ROUTES_ROOT.assets.asset.fullPath));
  const slugFromQuery = match?.assetSlug;

  const { data: assetsData, isFetching } = useGetAssetsQuery({
    page,
    per_page: perPage,
    stages,
    statuses,
    search_by_query: debouncedQuery,
    favorite_ids: favouriteItems,
  });

  useGoToFirstObjectFromDataEffect(
    {
      objects: assetsData?.assets ?? [],
      slugFromQuery,
      prepareUrl,
    },
    [assetsData],
  );

  const navHeaderProps: ComponentProps<typeof ListNavLayout.NavigationHeader> =
    menuItemsPermissions.fundsVisible
      ? {
          title: 'coRE',
        }
      : {
          title: 'Assets',
          subtitle: 'coRE',
        };

  const isCurrentUserExternal = currentUserIsExternal();

  return (
    <ListNavLayout className={cn(dashboardExpanded && 'flex h-[initial]')}>
      <ListNavLayout.Navigation
        className={cn(
          'next-div-w-full relative',
          dashboardExpanded && 'hidden',
        )}
      >
        <ListNavLayout.NavigationHeaderGroup>
          <ListNavLayout.NavigationHeader {...navHeaderProps} />
        </ListNavLayout.NavigationHeaderGroup>
        {menuItemsPermissions.fundsVisible && (
          <LegalEntitiablesThinTabGroup selectedItemId="assets" />
        )}
        <div className="grid grid-cols-[1fr_1fr] items-center gap-tw-2">
          <div className="flex items-center gap-tw-2">
            <DropdownWithCheckboxes
              label="Stages"
              disabled={isFetching}
              items={mapItemsToListOption(
                (assetsData?.meta.stages ?? []).map((s) => s.name),
              )}
              value={mapItemsToListOption(stages)}
              onChange={(options) => {
                setStages(getOptionsValues(options));
              }}
            />
            {!isCurrentUserExternal && (
              <DropdownWithCheckboxes
                label="Statuses"
                disabled={isFetching}
                items={allStatuses}
                value={mapItemsToListOption(statuses)}
                onChange={(options) => {
                  setStatuses(getOptionsValues(options));
                }}
              />
            )}
          </div>
          <SearchInput
            size="s"
            placeholder="Search"
            suggestions={['Asset name']}
            value={query}
            onChange={handleChange}
            className="ml-auto w-full"
            resetValue={resetQuery}
          />
        </div>
        {!isFetching && (
          <TablePagination
            currentPage={page}
            totalSize={assetsData?.meta.totalSize ?? 0}
            sizePerPage={perPage}
            loading={isFetching}
            onSizePerPageChange={(s) => {
              setPerPage(s);
              setPage(1);
            }}
            setCurrentPage={(p, s) => {
              setPage(p);
              setPerPage(s ?? INITIAL_PER_PAGE);
            }}
          />
        )}

        {!isFetching &&
          assetsData?.assets &&
          assetsData?.assets.length === 0 && (
            <NoDataOverlay title="Nothing Found" />
          )}

        {isFetching && <ObjectCardSkeletonBlock />}

        {!isFetching && assetsData && (
          <ListNavLayout.NavigationCardList className="rounded-2xl">
            {assetsData?.assets?.map((asset) => {
              const externalUsers = filterByExternal(asset?.users ?? []);
              const statusOption = ALL_STATUS_OPTIONS_MAP[asset.aasmState];
              const isUserAllowedToManageAsset = currentUserAllowedTo(
                'manage',
                TProductNames.ASSET_PORTAL,
                'Asset',
                asset.id,
              );
              const isUserAllowedToViewAsset = currentUserAllowedTo(
                'view',
                TProductNames.ASSET_PORTAL,
                'Asset',
                asset.id,
              );

              const isUserAllowedToViewAssetStatus =
                !isCurrentUserExternal &&
                !isUserAllowedToManageAsset &&
                isUserAllowedToViewAsset;

              return (
                <LinkWrapper
                  to={generateUrlForCard(asset.slug, ':assetSlug')}
                  className="grid"
                  key={asset.id}
                >
                  <ProjectCard
                    imagePlaceholder=""
                    fallbackNode={
                      <Icon
                        iconName="asset"
                        className="header1-regular text-neutral-550"
                      />
                    }
                    selected={asset.slug === slugFromQuery}
                    pictureUrl={asset.smallPictureUrl}
                    header={
                      <div className="flex items-center justify-between gap-tw-2">
                        <p className="body-semibold text-neutral-800">
                          {asset.name}
                        </p>
                        <div className="flex items-center gap-tw-2">
                          {externalUsers.length > 0 && (
                            <ExternalUsersIcon
                              externalUsersLen={externalUsers.length}
                            />
                          )}
                          {isUserAllowedToManageAsset && (
                            <StatusDropdown
                              object={asset}
                              heavyUpdate={heavyUpdateWithInvalidate}
                              status={asset.aasmState}
                            >
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                }}
                                iconName={statusOption.iconName}
                                classes={{
                                  icon: statusOption.className,
                                }}
                                variant="secondary"
                              />
                            </StatusDropdown>
                          )}
                          {isUserAllowedToViewAssetStatus && (
                            <IconButton
                              tooltipProps={{
                                mainText: capitalize(statusOption.value),
                              }}
                              iconName={statusOption.iconName}
                              className="!cursor-default"
                              classes={{ icon: statusOption.className }}
                              variant="secondary"
                            />
                          )}
                          <FavoriteCardIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              toggleItemFavorite(asset.id);
                            }}
                            selected={isItemFavorite(asset.id)}
                          />
                        </div>
                      </div>
                    }
                  >
                    <ListNavLayout.NavigationCardList.Label
                      color={asset.stage?.color}
                      text={asset.stage?.name}
                    />
                  </ProjectCard>
                </LinkWrapper>
              );
            })}
          </ListNavLayout.NavigationCardList>
        )}
      </ListNavLayout.Navigation>

      {children}
    </ListNavLayout>
  );
};

export default AssetDashboardPage;
