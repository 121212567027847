import {
  allSubPathMatches,
  generateUrl,
} from '@/shared/lib/hooks/useNavigation';
import { useMatch, useNavigate } from '@reach/router';
import { useEffect } from 'react';

export function useNavigateToFirst<T extends { id: number | string }>({
  items,
  matchParamKey,
  path,
}: {
  items: T[] | undefined;
  matchParamKey: string;
  path: string;
}) {
  const navigate = useNavigate();
  const match = useMatch(allSubPathMatches(path));

  const idFromURL = match?.[matchParamKey];

  useEffect(() => {
    if (items == null || items.length <= 0) return;

    const manVarExistsInItems = Boolean(items.find((mv) => mv.id === idFromURL))

    if (manVarExistsInItems) return;

    const [firstVariable] = items;

    navigate(
      generateUrl(path, {
        pathParams: {
          manualVariableId: firstVariable.id,
        },
      }),
    );
  }, [items]);

  return {
    idFromURL,
  };
}
