import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { DateWidgetState } from 'bundles/Shared/widgets/dashboard/widgets/common';
import { WidgetStateProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import dayjs from 'dayjs';
import Calendar from 'stories/FlexibleFilterByPeriods/calendar/Calendar';

export function WidgetStateDate({
  state,
  onStateChange,
  ...props
}: WidgetStateProps<DateWidgetState> &
  Omit<React.ComponentProps<typeof Calendar>, 'value' | 'onChange'>) {
  const fullscreen = useAppSelector((s) => s.widgetFullscreen.value);
  return (
    <Calendar
      popoverProps={{
        appendTo: fullscreen ? 'parent' : () => document.body,
      }}
      selectionMode="daily"
      value={[dayjs(state.date)]}
      onChange={(dates) =>
        onStateChange({ date: formatToDateStringForRequest(dates[0]) })
      }
      {...props}
    />
  );
}
