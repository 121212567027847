import { SettingsOperational } from '@/bundles/Settings/components/REport/Operational';
import { SettingsReportConnectionsPage } from '@/pages/settings/report/connections/page';
import { currentUserAllowedToSeeConnectionsPages } from '@/pages/settings/report/connections/permissions';
import { currentUserAllowedToSeeReportOperationalPages } from '@/pages/settings/report/operational/permissions';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { ManualVariablesPage } from '@/widgets/report/manualVariable/settings';
import { navigate, RouteComponentProps } from '@reach/router';
import SettingsReportDashboards from 'bundles/Settings/components/REport/Dashboards/SettingsReportDashboards';
import ReportDataPeriodBulks from 'bundles/Settings/components/REport/DataPeriods/ReportDataPeriodBulks';
import GeneralLedgers from 'bundles/Settings/components/REport/GeneralLedgers/GeneralLedgers';
import ReportTableConfigs from 'bundles/Settings/components/REport/TableBuilder/ReportTableConfigs';
import ReportTableSegments from 'bundles/Settings/components/REport/TableSegment/ReportTableSegments';
import { FormulasAndVariablesWorkspace } from 'bundles/Shared/widgets/formula/panel';
import {
  currentUserAllowedTo,
  currentUserIsExternal,
  currentUserIsSreAdmin,
  TProductNames,
} from 'lib/permissions';
import React from 'react';
import { UnknownRecord } from 'type-fest/source/internal';

export const ROUTES = ROUTES_ROOT.settings.report;
export type ScreenSettings = {
  path: string;
  linkPath: string;
  name: string;
  Component: React.FC<RouteComponentProps>;
  isVisible: (() => boolean) | boolean;
  ignoreLayoutWrapper?: boolean;
  componentProps?: UnknownRecord;
};

export const MENU_GROUPS = {
  Onboarding: [
    {
      name: 'Financial',
      Component: GeneralLedgers,
      path: ROUTES.financial.path,
      linkPath: ROUTES.financial.path,
      isVisible: true,
    },
    {
      name: 'Operational',
      Component: SettingsOperational,
      path: ROUTES.operational.path,
      linkPath: ROUTES.operational.path.concat(
        '/',
        ROUTES.operational.unitTypes.path,
      ),
      isVisible: currentUserAllowedToSeeReportOperationalPages,
    },
    {
      name: 'Connections',
      Component: SettingsReportConnectionsPage,
      path: ROUTES.connections.path,
      linkPath: ROUTES.connections.path,
      isVisible: currentUserAllowedToSeeConnectionsPages,
    },
  ],
  Builders: [
    {
      name: 'Dashboards',
      Component: SettingsReportDashboards,
      path: ROUTES.dashboards.path,
      linkPath: ROUTES.dashboards.path,
      isVisible: true,
    },
    {
      name: 'Tables',
      Component: ReportTableConfigs,
      path: ROUTES.tableBuilder.path,
      linkPath: ROUTES.tableBuilder.path,
      isVisible: true,
    },
    {
      name: 'Formulas',
      Component: () => (
        <FormulasAndVariablesWorkspace
          initialOpen
          editMode
          onClose={() => {
            navigate(-1);
          }}
        />
      ),
      path: ROUTES.financialFormulas.path,
      linkPath: ROUTES.financialFormulas.path,
      isVisible: () => currentUserIsSreAdmin(),
    },
    {
      name: 'Manual Variables',
      Component: ManualVariablesPage,
      path: ROUTES.manualVariables.path,
      linkPath: ROUTES.manualVariables.path,
      isVisible: () =>
        currentUserIsSreAdmin() || currentUserAllowedTo('configure', 'report'),
      ignoreLayoutWrapper: true,
    },
  ],
  Data: [
    {
      name: 'Periods',
      Component: ReportDataPeriodBulks,
      path: ROUTES.dataPeriods.path,
      linkPath: ROUTES.dataPeriods.path,
      isVisible: true,
    },
    {
      name: 'Segments',
      Component: ReportTableSegments,
      path: ROUTES.segments.path,
      linkPath: ROUTES.segments.path,
      isVisible: () =>
        !currentUserIsExternal() &&
        currentUserAllowedTo('configure', TProductNames.REPORT),
    },
  ],
} as const satisfies Record<string, readonly ScreenSettings[]>;
