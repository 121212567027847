import { capitalize } from 'lodash-es';
import * as yup from 'yup';
import { InferType } from 'yup';

export const VALUE_TYPES = [
  'number',
  'percentage',
  'currency',
  'sqft',
  'metric',
  'bps',
] as const;
export const VALUE_TYPE_OPTIONS = VALUE_TYPES.map((v) => ({
  label: capitalize(v),
  value: v,
}));
export const PRECISION_OPTIONS = [0, 1, 2];
export type ValuePrecision = (typeof PRECISION_OPTIONS)[number];

export const VALUE_DISPLAY_OPTIONS_SCHEMA = yup.object().shape({
  type: yup
    .string()
    .oneOf([...VALUE_TYPES])
    .required(),
  precision: yup.number().oneOf(PRECISION_OPTIONS).required(),
  kilo_formatting: yup.boolean(),
  hide_comma_separator: yup.boolean(),
});
export type ValueDisplayOptionsForm = InferType<
  typeof VALUE_DISPLAY_OPTIONS_SCHEMA
>;
export const DEFAULT_VALUE_DISPLAY_OPTIONS = {
  type: 'currency',
  precision: 0,
  kilo_formatting: false,
} as const satisfies ValueDisplayOptions;
export type ValueDisplayType = (typeof VALUE_TYPES)[number];

export type ValueDisplayOptions = {
  type: ValueDisplayType;
  precision?: ValuePrecision | number;
  kilo_formatting?: boolean;
  hide_comma_separator?: boolean;
};
