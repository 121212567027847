import { WidgetSettingsListLayout } from '@/bundles/Shared/components/layouts/dashboard/WidgetLayout';
import {
  COLUM_GROUP_FOR_UNGROUPED_COLUMNS,
  ComparisonDashboardSectionTypes,
} from '@/bundles/Shared/entities/dashboard';
import {
  useDragEndResponder,
  useFinancialTableVizConfig,
  useRenderGroupCallback,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/ui/financialTableWidgetColumnGroups/lib';
import { FinancialTableWidgetActions } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/ui/financialTableWidgetColumnGroups/model';
import { useWidgetConfig } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib';
import { GROUPS_DROPPABLE_ID } from '@/bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { Button } from '@/stories';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import pluralize from 'pluralize';

export const FinancialTableWidgetColumnGroups = ({
  actions,
}: {
  actions: FinancialTableWidgetActions;
}) => {
  const { widget } = useWidgetConfig<ComparisonDashboardSectionTypes>();

  const { columns, viz_config } = widget.widgetConfig;

  const vizConfigGroups = useFinancialTableVizConfig({
    columns,
    vizConfig: viz_config,
  });
  const handleSortEnd = useDragEndResponder();
  const renderGroup = useRenderGroupCallback(actions);

  const allColumnsChecked = !viz_config?.columns?.some((c) => c.hidden);
  const allShownColumnsLen =
    viz_config?.columns?.filter((c) => !c.hidden).length ?? 0;
  const vizConfigColumnLen = viz_config?.columns?.length ?? 0;

  return (
    <WidgetSettingsListLayout>
      <WidgetSettingsListLayout.Header
        checked={allColumnsChecked}
        onChange={(e) => actions.column.onHideAll(e.target.checked)}
        label={
          vizConfigColumnLen === 0
            ? 'No Columns Yet'
            : `${allShownColumnsLen} of ${vizConfigColumnLen} ${pluralize(
                'COLUMN',
                vizConfigColumnLen,
              )}`
        }
      >
        <Button
          size="xs"
          variant="secondary"
          onClick={() => {
            actions.group.onAdd();
          }}
        >
          Add
        </Button>
      </WidgetSettingsListLayout.Header>

      <DragDropContext onDragEnd={handleSortEnd}>
        <Droppable type="groups" droppableId={GROUPS_DROPPABLE_ID}>
          {(provided) => (
            <div
              className="flex flex-col gap-tw-2"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {vizConfigGroups.columnGroups.map(renderGroup)}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </WidgetSettingsListLayout>
  );
};
