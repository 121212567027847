import { BasicDashboardModal } from '@/bundles/Shared/entities/dashboard/ui/DashboardModal';
import { prepareDashboardNameForDuplicate } from '@/bundles/Shared/widgets/dashboard/card/lib';
import {
  ReportDashboardType,
  ReportEagleEyeDashboard,
  usePostApiSettingsReportComparisonDashboardsDuplicateMutation,
  usePostApiSettingsReportEagleEyeDashboardsDuplicateMutation,
  usePostApiSettingsReportObjectDashboardsDuplicateMutation,
} from 'bundles/Shared/entities/dashboard';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useCallback, useMemo } from 'react';

export const useDuplicateDashboard = ({
  type,
  dashboardName,
}: {
  type: `${ReportDashboardType}`;
  dashboardName?: string;
}) => {
  const eagleEyeMutation =
    usePostApiSettingsReportEagleEyeDashboardsDuplicateMutation();

  const objectMutation =
    usePostApiSettingsReportObjectDashboardsDuplicateMutation();

  const comparisonMutation = usePostApiSettingsReportComparisonDashboardsDuplicateMutation()
  const { openModal } = useModal();


  const [duplicate, options] = useMemo(() => {
    switch (type) {
      case ReportDashboardType.OBJECT: {
        return objectMutation;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return eagleEyeMutation;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return comparisonMutation;
      }
      default: {
        return [() => {}, {}]
      }
    }
  }, [
    type,
    eagleEyeMutation,
    comparisonMutation,
    objectMutation,
  ])

  return [
    useCallback(async (id: ReportEagleEyeDashboard['id']) => {
      const res = await openModal(BasicDashboardModal, {
        type: 'create',
        submitText: 'Duplicate',
        dashboard: {
          name: dashboardName
            ? prepareDashboardNameForDuplicate(dashboardName)
            : '',
        },
      });
      if (!res) {
        return;
      }
      return duplicate({ body: { id, name: res.name } });
    }, []),
    options,
  ] as const;
};
