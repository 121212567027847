import {
  AutoGroupCellRenderer,
  AutoGroupCellRendererProps,
} from '@/bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupCellRenderer';
import { AutoGroupHeaderComponent } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupHeaderComponent';
import {
  BasicCellRenderer,
  CellRendererStylesProps,
} from '@/bundles/Shared/components/AgGrid/Table/cellComponents/BasicCellRenderer';
import {
  CurrencyCellRenderer,
  CurrencyCellRendererProps,
} from '@/bundles/Shared/components/AgGrid/Table/cellComponents/CurrencyCellRenderer';
import { HeaderComponent } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/HeaderComponent';
import {
  ComparisonObjectHeaderGroupComponent,
  HeaderGroupComponent,
  HeaderGroupComponentProps,
} from '@/bundles/Shared/components/AgGrid/Table/cellComponents/HeaderGroupComponent';
import { SourceTypeImageWrapper } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/SourceTypeImageWrapper';
import {
  DEFAULT_TABLE_ROW_PATH_DELIMITER,
  NEXT_GEN_TABLE_CONFIG,
} from '@/bundles/Shared/components/AgGrid/Table/consts';
import { useTableCellStyleApplier } from '@/bundles/Shared/components/AgGrid/Table/utils/useTableCellStyleApplier';
import { FinancialTableSingleDateWidgetConfigDto } from '@/bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import {
  AUTO_COLUM_DEF_MAX_WIDTH,
  AUTO_COLUM_DEF_MIN_WIDTH,
  formatDateRangeForWidgetColumnSubHeader,
  resolveDefaultColumnMinWidth,
} from '@/bundles/Shared/widgets/dashboard/widgets/common';
import {
  FinancialTableSingeDateWidgetContext,
  FinancialTableSingeDateWidgetNS,
} from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate';
import { insertBetweenPlaceholderColumnGroupDefFinancialTableWidget } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib';
import { isGLRowData } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib/typeGuards';
import { FinancialTableSingePeriodWidgetContext } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/widget';
import { WidgetProps } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { shiftDateBackward, yesterdayDate } from '@/shared/lib/date';
import { getNumberFormatterByDisplayType } from '@/shared/lib/formatting/table';
import { CurrencyFormatter, Tooltip } from '@/stories';
import {
  CellClassRules,
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { ReportDashboardType } from 'bundles/Shared/entities/dashboard';
import {
  AVAILABLE_PERIOD_TYPES,
  AvailablePeriodType,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import {
  createMapByKey,
  resolveHeaderWithSubheaderComponentProps,
} from 'bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import { buildExcelStyleId } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import dayjs from 'dayjs';
import { groupBy } from 'lodash-es';
import React, { ComponentProps, useCallback, useMemo } from 'react';
import { HeaderComponentWithSubHeader } from '../ui/table/HeaderComponentWithSubHeader';

const buildRowValueId = (
  groupKey: string | number,
  columnKey: string | number,
) => `${groupKey}_${columnKey}`;

export const resolveFinancialTableWidgetDefaultOptionsDate = (
  defaultOptionsDate?: string | null,
): DateString => {
  return formatToDateStringForRequest(
    defaultOptionsDate
      ? shiftDateBackward(new Date(defaultOptionsDate), 1, 'day')
      : yesterdayDate(),
  );
};

export const showLegalEntitiesDropdownByWidgetContext = (
  context: (
    | FinancialTableSingeDateWidgetContext
    | FinancialTableSingePeriodWidgetContext
  ) & {
    dashboardType: ReportDashboardType;
  },
): boolean =>
  Boolean(
    context.dashboardType === ReportDashboardType.COMPARISON_MODE &&
      (context.assets?.length ?? 0) > 0,
  );

export const COL_DEF_MAX_WIDTH = 240;

export const COL_DEF_ID = {
  Delimiter: '_',
  IdKeyIdValueDelimiter: ':',
  ObjectIdKey: 'objectId',
  GroupIdKey: 'groupId',
  ColumnIdKey: 'columnId',
  NoneIdValue: 'none',
} as const;

const joinIdKeyAndIdValue = (key: string, value: string) =>
  `${key}${COL_DEF_ID.IdKeyIdValueDelimiter}${value}`;

export const parseColDefId = (colDefId: string) => {
  const splitColDefId = colDefId.split(COL_DEF_ID.Delimiter);
  if (splitColDefId.length !== 3) {
    throw new Error('`colDefId` has wrong format');
  }
  const [objectId, groupId, colId] = splitColDefId.map(
    (id) => id.split(COL_DEF_ID.IdKeyIdValueDelimiter)[1],
  );

  return {
    [COL_DEF_ID.ObjectIdKey]: objectId,
    [COL_DEF_ID.GroupIdKey]: groupId,
    [COL_DEF_ID.ColumnIdKey]: colId,
  } as const;
};
export const stringifyColDefId = ({
  objectId = COL_DEF_ID.NoneIdValue,
  groupId = COL_DEF_ID.NoneIdValue,
  columnId,
}: {
  [COL_DEF_ID.ColumnIdKey]: string;
  [COL_DEF_ID.ObjectIdKey]?: string | undefined;
  [COL_DEF_ID.GroupIdKey]?: string | undefined;
}) => {
  return [
    joinIdKeyAndIdValue(COL_DEF_ID.ObjectIdKey, objectId),
    joinIdKeyAndIdValue(COL_DEF_ID.GroupIdKey, groupId),
    joinIdKeyAndIdValue(COL_DEF_ID.ColumnIdKey, columnId),
  ].join(COL_DEF_ID.Delimiter);
};

export const useFinancialTableWidgetColDef = ({
  data,
  cellClassRules,
  widgetSection,
  vizConfig,
  dashboardType,
  mode,
}: {
  dashboardType: ReportDashboardType;
  data: FinancialTableSingeDateWidgetNS.SnapshotData | undefined;
  cellClassRules: CellClassRules;
  vizConfig: FinancialTableSingleDateWidgetConfigDto['viz_config'];
} & Pick<
  WidgetProps<FinancialTableSingeDateWidgetNS.SnapshotData>,
  'widgetSection' | 'mode'
>) => {
  const columns = data?.columns ?? [];
  const columnsDataMap = useMemo(() => createMapByKey(columns), [columns]);
  const vizConfigColumns = vizConfig?.columns ?? [];

  const vizConfigColumnsMap = useMemo(
    () => createMapByKey(vizConfigColumns),
    [vizConfigColumns],
  );

  const groupedColumns = groupBy(vizConfigColumns, 'group_id');

  const headerBackground = vizConfig?.header_background;
  const vizConfigColumnGroups = vizConfig?.column_groups ?? [];

  const widgetSectionFontSize = widgetSection.fontSize ?? undefined;
  const tableCellStyleApplier = useTableCellStyleApplier();

  const style = tableCellStyleApplier({
    background: headerBackground,
    fontSize: widgetSectionFontSize,
  });

  const headerComponentParams = useMemo(
    () =>
      ({
        style,
      }) satisfies Partial<HeaderGroupComponentProps>,
    [headerBackground, widgetSectionFontSize],
  );

  const createColDef = useCallback(
    (
      column: FinancialTableSingeDateWidgetNS.Column,
      ctx?: {
        objectGroup: { key: string; name: string };
        group?: { key: string; name: string };
      },
    ): ColDef => {
      const col_vz = vizConfigColumnsMap.get(String(column.key));
      const subHeaderName = formatDateRangeForWidgetColumnSubHeader(
        column,
        column,
        col_vz,
      );

      const { hide_subtitle, hide_title } = col_vz?.header ?? {};

      return {
        colId: stringifyColDefId({
          objectId: ctx?.objectGroup.key,
          groupId: ctx?.group?.key,
          columnId: String(column.key),
        }),
        cellClass: ({ colDef }) =>
          colDef.colId && buildExcelStyleId(colDef.colId),
        cellClassRules,
        headerName: column.label,
        hide: col_vz?.hidden,
        maxWidth: col_vz?.max_width,
        headerComponent: HeaderComponentWithSubHeader,
        headerComponentParams: {
          ...headerComponentParams,
          classes: {
            header: mode === 'pdf' ? 'whitespace-normal' : '',
            subHeader: mode === 'pdf' ? 'whitespace-normal' : '',
          },
          ...resolveHeaderWithSubheaderComponentProps({
            headerName: column.label,
            subHeaderName: mode === 'pdf' ? column.subLabel : subHeaderName,
            hide_subtitle,
            hide_title,
          }),
        } satisfies Partial<
          ComponentProps<typeof HeaderComponentWithSubHeader>
        >,
        cellRenderer: (
          params: OverrideField<
            ICellRendererParams,
            'data',
            FinancialTableSingeDateWidgetNS.TableRowData
          > &
            CurrencyCellRendererProps,
        ) => {
          const { displayOptions } = params.data;
          const precision =
            col_vz?.value_display_options?.precision ??
            params.data?.valueDisplayOptions?.precision ??
            0;

          const formatterType =
            col_vz?.value_display_options?.type ??
            params.data.valueDisplayOptions?.type ??
            'currency';

          const hideCommaSeparator =
            col_vz?.value_display_options?.hide_comma_separator ??
            params.data.valueDisplayOptions?.hide_comma_separator ??
            false;

          const formatterProps = {
            ...params.formatterParams,
            styles: {
              allParts: {
                color: displayOptions.color as React.CSSProperties['color'],
              },
            },
          } satisfies Partial<ComponentProps<typeof CurrencyFormatter>>;

          const commonParams = {
            ...params,
            styles: {
              wrapper: tableCellStyleApplier({
                ...displayOptions,
                fontSize: widgetSectionFontSize,
              } as React.CSSProperties),
            },
          };

          const FormatterComponent = getNumberFormatterByDisplayType({
            precision,
            displayType: formatterType,
            hideCommaSeparator,
          });

          return (
            <BasicCellRenderer {...commonParams}>
              <FormatterComponent value={params.value} {...formatterProps} />
            </BasicCellRenderer>
          );
        },
        cellRendererParams: {
          formatterParams: {
            fallbackValue: '',
          },
        },
        valueGetter: (
          p: OverrideField<
            ValueGetterParams,
            'data',
            FinancialTableSingeDateWidgetNS.TableRowData
          >,
        ) => {
          if (dashboardType === ReportDashboardType.COMPARISON_MODE) {
            const objectGroupKeyOrNothing = ctx?.objectGroup?.key ?? '-1';
            const rowValueId = buildRowValueId(
              objectGroupKeyOrNothing,
              column.key,
            );

            return (
              p.data?.valuesGroupedByObjGroupAndColumnKey?.[rowValueId]
                ?.value ?? null
            );
          }
          return (
            p.data?.valuesGroupedByColumnKey?.[column.key]?.[0]?.value ?? null
          );
        },
      };
    },
    [
      mode,
      cellClassRules,
      headerComponentParams,
      headerBackground,
      widgetSectionFontSize,
      vizConfigColumnsMap,
    ],
  );

  const columnDefs = useMemo<(ColGroupDef | ColDef)[]>(() => {
    if (data == null) return [];

    const getGroupChildrenFromGroupedColumns = (group_id: string) => {
      const groupChildren = groupedColumns[group_id] ?? [];

      return groupChildren
        .map((col_vz) => {
          if (!columnsDataMap.has(String(col_vz.col_id))) return null;
          const columnData = columnsDataMap.get(String(col_vz.col_id));

          return {
            ...columnData,
            ...col_vz,
          };
        })
        .filter(Boolean)
        .toSorted((a, b) => a.order - b.order);
    };

    if (dashboardType === ReportDashboardType.COMPARISON_MODE) {
      return insertBetweenPlaceholderColumnGroupDefFinancialTableWidget(
        data.groups.map((objectGroup) => {
          return {
            marryChildren: true,
            headerName: objectGroup.asset.name,
            headerGroupComponent: ComparisonObjectHeaderGroupComponent,
            headerGroupComponentParams: {
              ...headerComponentParams,
              entityCounterProps: {
                selectedCount: objectGroup.legalEntities.length,
                totalCount: objectGroup.legalEntities.length,
              },
            } satisfies Partial<
              ComponentProps<typeof ComparisonObjectHeaderGroupComponent>
            >,
            children: vizConfigColumnGroups.map((group) => {
              const children = getGroupChildrenFromGroupedColumns(
                group.group_id,
              );

              return {
                headerName: group.header_name,
                headerGroupComponent: HeaderGroupComponent,
                headerGroupComponentParams: headerComponentParams,
                children: children.map((column) => {
                  return createColDef(column, {
                    objectGroup: {
                      key: String(objectGroup.key),
                      name: objectGroup.asset.name,
                    },
                    group: {
                      key: String(group.group_id),
                      name: group.header_name,
                    },
                  });
                }),
              };
            }),
          } satisfies ColGroupDef;
        }),
      );
    }
    if (vizConfigColumnGroups.length === 0) {
      return columns.map((c) => createColDef(c));
    }

    return insertBetweenPlaceholderColumnGroupDefFinancialTableWidget(
      vizConfigColumnGroups.map((group) => {
        const children = getGroupChildrenFromGroupedColumns(group.group_id);

        return {
          headerName: group.header_name,
          headerGroupComponent: HeaderGroupComponent,
          headerGroupComponentParams: headerComponentParams,
          children: children.map((column) => {
            return createColDef(column, {
              objectGroup: {
                key: group.group_id,
                name: group.header_name,
              },
            });
          }),
        };
      }),
    );
  }, [headerComponentParams, data?.columns, createColDef]);

  return columnDefs;
};

export const useFinancialTableWidgetAutoColumDef = (props: {
  headerBackground: React.CSSProperties['color'] | undefined;
  widgetSectionFontSize: number | undefined;
  data: FinancialTableSingeDateWidgetNS.SnapshotData | undefined;
  cellClassRules: CellClassRules;
}) => {
  const tableCellStyleApplier = useTableCellStyleApplier();
  const headerTitle = props.data?.vizConfig?.auto_column?.header?.title ?? '';

  const autoColumnDef = React.useMemo<ColDef>(() => {
    return {
      flex: 1,
      pinned: true,
      headerName: '',
      resizable: true,
      minWidth: AUTO_COLUM_DEF_MIN_WIDTH,
      maxWidth: AUTO_COLUM_DEF_MAX_WIDTH,
      cellClassRules: props.cellClassRules,
      suppressMenu: true,
      headerComponent: AutoGroupHeaderComponent,
      headerComponentParams: {
        subHeaderName: '',
        displayName: headerTitle,
        style: tableCellStyleApplier({
          background: props.headerBackground,
          fontSize: props.widgetSectionFontSize,
        }),
      } satisfies Partial<ComponentProps<typeof AutoGroupHeaderComponent>>,
      cellRendererParams: (
        params: OverrideField<
          ICellRendererParams,
          'data',
          FinancialTableSingeDateWidgetNS.TableRowData
        > &
          AutoGroupCellRendererProps,
      ) => {
        const { displayOptions } = params.data;

        return {
          styles: {
            wrapper: tableCellStyleApplier({
              ...displayOptions,
              fontSize: props.widgetSectionFontSize,
            } as React.CSSProperties),
          },
          ...params,
        } satisfies ICellRendererParams &
          AutoGroupCellRendererProps &
          CellRendererStylesProps;
      },
      cellRenderer: (
        params: OverrideField<
          ICellRendererParams,
          'data',
          FinancialTableSingeDateWidgetNS.TableRowData
        >,
      ) => (
        <SourceTypeImageWrapper
          source={isGLRowData(params.data) ? params.data.erpSystem : null}
          isGLRow={isGLRowData(params.data)}
        >
          <AutoGroupCellRenderer {...params} />
        </SourceTypeImageWrapper>
      ),
      valueGetter: (
        params: OverrideField<
          ValueGetterParams,
          'data',
          FinancialTableSingeDateWidgetNS.TableRowData
        >,
      ) => {
        if (isGLRowData(params.data)) {
          return (
            <Tooltip
              placement="left"
              disabled={params.data.legalEntityCode == null}
              mainText={params.data.legalEntityCode}
            >
              {`${params.data?.label} | ${params.data.legalEntityCode}`}
            </Tooltip>
          );
        }
        return params.data?.label ?? '';
      },
    };
  }, [...Object.values(props)]);

  return autoColumnDef;
};

export const useFinancialTableWidgetDefaultColDef = ({
  isCompactSelected = false,
  deps = [],
}: {
  isCompactSelected?: boolean;
  deps?: React.DependencyList;
}) => {
  return React.useMemo<ColDef>(
    () => ({
      suppressMenu: true,
      suppressMovable: true,
      resizable: true,
      headerComponent: HeaderComponent,
      cellRenderer: CurrencyCellRenderer,
      minWidth: resolveDefaultColumnMinWidth(isCompactSelected),
      width: NEXT_GEN_TABLE_CONFIG.column.default.width,
    }),
    [isCompactSelected, ...deps],
  );
};

export const useFinancialTableWidgetRowData = ({
  data,
}: {
  data: FinancialTableSingeDateWidgetNS.SnapshotData | undefined;
}) => {
  return React.useMemo<
    OverrideField<
      FinancialTableSingeDateWidgetNS.TableRowData,
      'path',
      string[]
    >[]
  >(() => {
    const rowsVizConfigMap = new Map(
      (data?.vizConfig?.rows ?? []).map((row) => [String(row.key), row]),
    );
    return (
      data?.rows?.map((r) => {
        const row_vz = rowsVizConfigMap.get(r.path);

        return {
          ...r,
          valueDisplayOptions: row_vz?.value_display_options,
          displayOptions:
            row_vz?.background?.color || row_vz?.font_color
              ? {
                  background: row_vz?.background?.color,
                  color: row_vz?.font_color,
                }
              : {},
          valuesGroupedByColumnKey: groupBy(r.values, 'columnKey'),
          valuesGroupedByObjGroupAndColumnKey: Object.fromEntries(
            r.values.map((v) => [buildRowValueId(v.groupKey, v.columnKey), v]),
          ),
          display: {},
          label: r.label,
          key: r.path,
          path: r.path.split(DEFAULT_TABLE_ROW_PATH_DELIMITER),
          children: [],
        };
      }) ?? []
    );
  }, [data]);
};

export const resolveActualStateDate = (
  date: string,
  periodType: AvailablePeriodType,
) => {
  if (
    periodType === AVAILABLE_PERIOD_TYPES.MTD ||
    periodType === AVAILABLE_PERIOD_TYPES.YTD
  ) {
    return formatToDateStringForRequest(dayjs(date).endOf('month'));
  }
  return date;
};
