import { ICapitalInvestment } from 'bundles/REturn/types';
import { camelKeysToSnake } from 'bundles/UserManagement/components/roles/utils';
import http, { apiClient, requireAuthorizationHeaders } from 'lib/http';
import saveFileOnUserDevice from '@/shared/lib/browser/saveFileOnUserDevice';
import { DEFAULT_STRINGIFY_OPTIONS } from 'lib/stringify';
import { stringify } from 'qs';
import type * as Type from './types';

export interface IUpdateCapitalInvestmentRequest {
  id: number;
  capital_investments: {
    commitment_amount: number;
  };
}

export const updateCapitalInvestment = async (
  data: IUpdateCapitalInvestmentRequest,
) => {
  const res = await http.put(`/capital/investments/${data.id}`, {
    ...data,
    investment: data.capital_investments,
  });

  if (res.status === 200) {
    toastr.success('Successfully updated');
  } else {
    toastr.error('Something went wrong');
    return undefined;
  }

  const json = await res.json();

  return json as ICapitalInvestment;
};

export const deleteCapitalInvestment = async (id: number) => {
  const res = await http.del(`/capital/investments/${id}`);

  if (res.status === 200) {
    toastr.success('Successfully deleted');
  } else {
    toastr.error('Something went wrong');
  }

  return res.json();
};

export interface IBulkUpdateCapitalInvestmentRequest {
  investment: {
    data: {
      id: number;
      commitment_amount: number | string;
    }[];
  };
}

export const bulkUpdateCapitalInvestment = async (
  data: IBulkUpdateCapitalInvestmentRequest,
) => {
  const res = await http.post('/capital/investments/bulk_update', data);

  if (res.status === 200) {
    toastr.success('Successfully updated');
  } else {
    toastr.error('Something went wrong');
    return undefined;
  }
  const json = await res.json();
  return json as { status: 'success' };
};

export interface ICreateCapitalInvestmentRequest {
  investment_entity_ids: number[];
  legal_entity_id: string;
  commitment_amount: number;
}

export const createCapitalInvestment = async (
  data: ICreateCapitalInvestmentRequest,
) => {
  // TODO: Remove investment_class when FE will be ready
  const res = await http.post('/capital/investments', {
    ...data,
    investment_class: 'b',
  });

  if (res.status === 200) {
    toastr.success('Successfully created');
  } else {
    toastr.error('Something went wrong');
    return undefined;
  }
  const json = await res.json();

  return json as { status: 'success' };
};

// consider removing. Ask Denis!
export const fetchCapitalInvestment = async (data) => {
  const res = await http.get(
    `/capital/investments/${data.id}?${stringify(data)}`,
  );

  return res.json();
};

export async function plainFetchCapitalInvestmentContributionTransactions(
  params: Type.FetchCapitalInvestmentParams,
) {
  const snakeParams = camelKeysToSnake(
    params,
  ) as Type.FetchCapitalInvestmentReqParams;
  const stringifiedParams = stringify(snakeParams, DEFAULT_STRINGIFY_OPTIONS);
  const path = '/capital/investment/contributions';
  const res = await http.get(`${path}${stringifiedParams}`);
  return res.json() as Promise<Type.EntryResponse<Type.CapitalContribution>>;
}

export async function plainFetchCapitalInvestmentDistributionTransactions(
  params: Type.FetchCapitalInvestmentParams,
) {
  const snakeParams = camelKeysToSnake(
    params,
  ) as Type.FetchCapitalInvestmentReqParams;
  const stringifiedParams = stringify(snakeParams, DEFAULT_STRINGIFY_OPTIONS);
  const path = '/capital/investment/distributions';
  const res = await http.get(`${path}${stringifiedParams}`);
  return res.json() as Promise<Type.EntryResponse<Type.CapitalDistribution>>;
}

export async function plainFetchCapitalInvestmentAccountingTransactions(
  params: Type.FetchReportLineItemsParams,
) {
  const snakeParams = camelKeysToSnake(
    params,
  ) as Type.FetchReportLineItemsReqParams;
  const stringifiedParams = stringify(snakeParams, DEFAULT_STRINGIFY_OPTIONS);
  const path = '/capital/accounting/transactions';
  const res = await http.get(`${path}${stringifiedParams}`);
  return res.json() as Promise<Type.ReportLineItemsResponse>;
}

interface RemoveParams {
  type: Type.CapitalInvestmentEntryType;
  rowId: string;
}

export async function removeCapitalManual({ rowId, type }: RemoveParams) {
  const res = await http.del(`/capital/manual_${type}/${rowId}`);
  return res.json();
}

export async function removeCapitalTransaction({ rowId, type }: RemoveParams) {
  const res = await http.del(`/capital/transaction_${type}/${rowId}`);
  return res.json();
}

export async function exportCapitalInvestmentEntities() {
  const res = await http.post(
    '/capital/investment_object_summaries/export.xlsx',
    {},
  );
  const file = await res.blob();
  saveFileOnUserDevice(
    file,
    `${window.symmetreConfig.customer.subdomain}-investment-entities.xlsx`,
  );
}

export async function importCapital(formData: FormData, importType: string) {
  const res = await apiClient.post(
    `/api/capital/import/${importType}.xlsx`,
    formData,
    {
      headers: requireAuthorizationHeaders({
        'Content-Type': 'multipart/form-data',
      }),
      responseType: 'blob',
    },
  );

  return res.data;
}
