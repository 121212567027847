import {
  useGetApiCapitalInvestmentObjectsByIdPreferredReturnQuery,
  useGetApiCapitalInvestmentObjectsByIdQuery,
  useGetApiCapitalTransactionSourcesQuery,
  useGetApiCapitalUnitPricesQuery,
} from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import { RouteComponentProps, Router } from '@reach/router';
import { NotFound } from 'bundles/Errors';
import { ExtractParams, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { AnimationLoader } from 'stories/index';
import Ownerships from '../components/Ownership/Ownerships';
import { CreateEntryWorkflow } from '../components/workflows/createEntry/CreateEntryWorkflow';

export function OwnershipContainer({
  objectId,
}: Required<
  RouteComponentProps<ExtractParams<typeof ROUTES_ROOT.return.object.fullPath>>
>) {
  const { isLoading } = useGetApiCapitalInvestmentObjectsByIdQuery({
    id: objectId,
  });
  const { isLoading: isPreferredReturnLoading } =
    useGetApiCapitalInvestmentObjectsByIdPreferredReturnQuery({
      id: objectId,
    });
  const { isLoading: isUnitPricesLoading } = useGetApiCapitalUnitPricesQuery({
    investmentObjectId: objectId,
  });

  const { isLoading: isSourcesLoading } =
    useGetApiCapitalTransactionSourcesQuery({
      investmentObjectId: objectId,
    });

  const allLoading =
    isLoading ||
    isUnitPricesLoading ||
    isPreferredReturnLoading ||
    isSourcesLoading;

  if (allLoading) return <AnimationLoader />;

  return (
    <Router basepath="/" primary={false}>
      <NotFound default className="mt-l" />
      <Ownerships path={ROUTES_ROOT.return.object.fullPath} />
      <CreateEntryWorkflow
        path={ROUTES_ROOT.return.object.createEntry.fullPath}
      />
    </Router>
  );
}
