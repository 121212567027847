import { Button } from '@/stories';
import React, { useEffect } from 'react';
import { AccruedAmountValuesEditor } from './AccruedAmountValuesEditor';
import {
  getMonthlyPreferredReturn,
  getQuarterlyPreferredReturn,
} from '../capitalInvestmentsEditor/utils';
import { useLoadedCapitalPreferredReturn } from '@/bundles/REturn/hooks/useLoadedCapitalPreferredReturn';
import { convertCentsToDollars } from '@/shared/lib/converters';

export const EnterAccruedAmountValuesStep = ({ form, editable = false }) => {
  const { data: preferredReturn } = useLoadedCapitalPreferredReturn();

  const calculateClassAContributionsAmountCents = (item: {
    period_type: string;
    totalClassAContributionsAmountCents: number;
  }) => {
    const getPreferredReturnAmount =
      item.period_type === 'monthly'
        ? getMonthlyPreferredReturn
        : getQuarterlyPreferredReturn;

    return getPreferredReturnAmount(
      item.totalClassAContributionsAmountCents,
      preferredReturn?.classAPercent ?? 0,
    );
  };

  const calculateClassBContributionsAmountCents = (item: {
    period_type: string;
    totalClassBContributionsAmountCents: number;
  }) => {
    const getPreferredReturnAmount =
      item.period_type === 'monthly'
        ? getMonthlyPreferredReturn
        : getQuarterlyPreferredReturn;

    return getPreferredReturnAmount(
      item.totalClassBContributionsAmountCents,
      preferredReturn?.classBPercent ?? 0,
    );
  };

  const calculateAccruedAmount = (item: {
    investmentClasses: string[];
    totalClassAContributionsAmountCents?: number;
    totalClassBContributionsAmountCents?: number;
  }) => {
    let result = 0;

    if (item.investmentClasses.length === 2) {
      result =
        calculateClassAContributionsAmountCents(item) +
        calculateClassBContributionsAmountCents(item);
    }

    if (item.investmentClasses.length === 1) {
      if (item.investmentClasses[0] === 'a') {
        result = calculateClassAContributionsAmountCents(item);
      } else {
        result = calculateClassBContributionsAmountCents(item);
      }
    }

    return convertCentsToDollars(result);
  };

  const onReset = () => {
    form.setValue(
      'accrued_entries',
      form.getValues('accrued_entries').map((entry) => ({
        ...entry,
        amount: calculateAccruedAmount(entry),
      })),
    );

    form.trigger();
  };

  useEffect(() => {
    onReset();
  }, [form]);

  const values = form.getValues();

  return (
    <>
      <div className="flex justify-between gap-l">
        <div>
          <span className="inline-semibold text-dark-80">
            {editable ? 'Enter your Accrued Preferred below:' : 'Review Action'}
          </span>
          <span className="text-danger-055"> *</span>
        </div>
        {editable && (
          <div>
            <Button variant="secondary" size="xs" onClick={onReset}>
              Reset
            </Button>
          </div>
        )}
      </div>
      {!editable && (
        <div className="inline-regular mt-xs text-sm text-neutral-650">
          Please note the Accrued Preferred entry will be generated
          automatically every month or quarter, depending on your selection
        </div>
      )}
      <div className="pt-l">
        {values && (
          <AccruedAmountValuesEditor
            form={form}
            editable={editable}
            values={values}
            calculateAccruedAmount={calculateAccruedAmount}
          />
        )}
      </div>
    </>
  );
};
