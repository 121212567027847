import React, { useEffect, useMemo } from 'react';
import Table from 'bundles/Shared/components/Table/Table';
import styles from '../capitalInvestmentsEditor/CapitaInvestmentEditor.module.scss';
import InvestmentEntityColumn from '../../ownershipTable/formatters/InvestmentEntityColumn';
import { CurrencyFormatter } from '@/stories';
import { Controller } from 'react-hook-form';
import { capitalize } from 'lodash-es';
import CurrencyInputNumber from '@/bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import { convertCentsToDollars } from '@/shared/lib/converters';
import { formatDate } from '@/shared/lib/formatting/dates';

export const AccruedAmountValuesEditor = ({ form, editable, values, calculateAccruedAmount }) => {
  const { setValue, control, trigger } = form;

  const getContributionsAmountCents = (item) => {
    if (item.investmentClasses.length === 2) {
      return (
        item.totalClassAContributionsAmountCents +
        item.totalClassBContributionsAmountCents
      );
    }

    if (item.investmentClasses.length === 1) {
      if (item.investmentClasses[0] === 'a') {
        return item.totalClassAContributionsAmountCents;
      }
      return item.totalClassBContributionsAmountCents;
    }

    return 0;
  };

  const getContributionsAmountDollars = (item) => {
    return convertCentsToDollars(getContributionsAmountCents(item));
  };

  useEffect(() => {
    setValue(
      'accrued_entries',
      values.accrued_entries.map((value) => ({
        ...value,
        amount: calculateAccruedAmount(value),
      })),
    );
    trigger();
  }, []);

  const columns = useMemo(
    () => [
      {
        text: 'Investment Entity',
        dataField: 'investmentEntity',
        formatter: ({ row: item }) => (
          <InvestmentEntityColumn
            capitalInvestment={item}
            withIcon={false}
            withUsers={false}
          />
        ),
      },
      {
        text: 'Legal Entity',
        dataField: 'legalEntity',
        formatter: ({ row: item }) => (
          <span className="dark-60 inline-regular">
            {item.legalEntity.name}
          </span>
        ),
      },
      {
        text: 'Contribution',
        dataField: 'contribution',
        formatter: ({ row }) => (
          <CurrencyFormatter
            classes={{ value: 'text-neutral-900' }}
            value={getContributionsAmountDollars(row)}
          />
        ),
      },
      {
        dataField: 'start_date',
        text: 'Start Date',
        hidden: editable,
        formatter: ({ row }) => formatDate(row.start_date, 'MMM DD, YYYY'),
      },
      {
        dataField: 'end_date',
        text: 'End Date',
        hidden: editable,
        formatter: ({ row }) => formatDate(row.end_date, 'MMM DD, YYYY'),
      },
      {
        dataField: 'period_type',
        text: 'period',
        hidden: editable,
        formatter: ({ row }) => capitalize(row.period_type),
      },
      {
        text: (
          <div>
            <div className="mt-s flex items-center gap-s">
              Accrued Pref.
              {/* {editable && (
                <IconButton
                  iconName="reset"
                  onClick={() => {
                    setValue(
                      'accrued_entries',
                      values.accrued_entries.map((entry) => ({
                        ...entry,
                        amount: null,
                      })),
                    );
                  }}
                />
              )} */}
            </div>
          </div>
        ),
        dataField: 'amount_cents',
        headerStyle: {
          width: 220,
        },
        formatter: ({ row }) => {
          const index = values.accrued_entries.findIndex(
            (entry) => entry.capital_investment_id === row.id,
          );

          if (editable) {
            return (
              <Controller
                name={`accrued_entries.${index}.amount`}
                control={control}
                render={({ field }) => (
                  <CurrencyInputNumber
                    value={field.amount}
                    onValueChange={(value) => {
                      field.onChange(value);
                      trigger();
                    }}
                    size="m"
                    prefix="("
                    suffix=")"
                    allowNegative={false}
                    placeholder="(0)"
                    defaultValue={values.accrued_entries[index].amount}
                  />
                )}
              />
            );
          }

          return (
            <div className="w-100 flex flex-col items-end">
              <CurrencyFormatter
                value={values.accrued_entries[index].amount * -1}
              />
            </div>
          );
        },
      },
    ],
    [editable, form, values.accrued_entries],
  );

  return (
    <>
      <Table
        borderLessOutside
        classes={{
          table: styles.table,
          container: styles.container,
          th: 'align-bottom',
        }}
        defaultColumn={{
          headerClasses: 'bg-white pt-tw-2 light-60',
        }}
        items={values.accrued_entries}
        columns={columns}
        bottomRowColumns={[
          {
            id: 'total',
            text: (
              <span className="inline-semibold text-neutral-500">Total</span>
            ),
          },
          {
            id: 'empty-accrued-total',
          },
          {
            id: 'empty-accrued-total-2',
            className: editable ? 'd-none' : '',
          },
          {
            id: 'empty-accrued-total-3',
            className: editable ? 'd-none' : '',
          },
          {
            id: 'empty-accrued-total-4',
            className: editable ? 'd-none' : '',
          },
          {
            id: 'empty-accrued-total-3',
          },
          {
            id: 'amount',
            text: (
              <div className="text-right">
                <CurrencyFormatter
                  value={
                    values.accrued_entries.reduce(
                      (acc, entry) => acc + Number(entry.amount ?? 0),
                      0,
                    ) * -1
                  }
                />
              </div>
            ),
          },
        ]}
      />
    </>
  );
};
