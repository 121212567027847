import * as React from 'react';
import { IntrinsicElements } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { ISharedDocument } from 'types/SharedDocument';
import { generateLinkBySharedFileId } from '@/entities/sharedFile/lib';

interface Props extends Omit<IntrinsicElements.a, 'href'> {
  row: ISharedDocument;
  className?: string;
}

function DocumentLink({
  row,
  className,
  children,
}: React.PropsWithChildren<Props>) {
  const Tag = row.confidential ? 'span' : 'a';
  return (
    <Tag
      className={cn(
        'inline-semibold',
        {
          blue: !row.confidential,
          'cursor-default text-dark-60': row.confidential,
        },
        className,
      )}
      href={!row.confidential ? generateLinkBySharedFileId(row.id) : undefined}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </Tag>
  );
}

export default DocumentLink;
