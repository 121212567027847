import SetFilter from '@/bundles/REconcile/components/operational/table/SetFilter';
import GroupByPopover from '@/bundles/Settings/components/REport/GeneralLedgers/GroupByPopover';
import GeneralLedgersTable from '@/bundles/Settings/components/REport/GeneralLedgers/Table/GeneralLedgersTable';
import GroupRowInnerRenderer from '@/bundles/Settings/components/REport/GeneralLedgers/Table/GroupRowInnerRenderer';
import { useTableRefCallback } from '@/bundles/Settings/components/REport/GeneralLedgers/hooks/useTableRefCallback';
import { SETTINGS_REPORT_OPERATIONAL_DICTIONARY } from '@/bundles/Settings/components/REport/Operational/config';
import {
  filterWithLEClass,
  useLEClassUpdateAndInvalidateEntity,
} from '@/bundles/Settings/components/REport/Operational/lib';
import { getErpColumn } from '@/bundles/Settings/components/REport/Operational/lib/getErpColumn';
import { getLegalEntityColumn } from '@/bundles/Settings/components/REport/Operational/lib/getLegalEntityColumn';
import { getPmcColumn } from '@/bundles/Settings/components/REport/Operational/lib/getPmcColumn';
import { useSettingReportOperationTabs } from '@/bundles/Settings/components/REport/Operational/lib/useSettingReportOperationTabs';
import { useSettingReportOperationalPageParams } from '@/bundles/Settings/components/REport/Operational/lib/useSettingReportOperationalPageParams';
import { useSettingsReportOperationalLEOptions } from '@/bundles/Settings/components/REport/Operational/lib/useSettingsReportOperationalLEOptions';
import { BulkSetUnitTypeModal } from '@/bundles/Settings/components/REport/Operational/ui/BulkSetUnitTypeModal';
import { getCheckboxAggridTableColumn } from '@/bundles/Settings/shared/lib/getCheckboxAggridTableColumn';
import { getLEClassAggridTableColumn } from '@/bundles/Settings/shared/lib/getLEClassAggridTableColumn';
import BulkActionsPanel from '@/bundles/Shared/components/BulkActionsPanel/BulkActionsPanel';
import {
  PageParamsPagination,
  PageParamsProvider,
  PageParamsSearch,
} from '@/bundles/Shared/components/pageParams';
import {
  OPERATIONAL_MAPPING_GROUP_BY_LIST,
  SourceUnitType,
  SreUnitType,
  SreUnitTypeFormatter,
  generateUrlSettingsUnitTypes,
} from '@/bundles/Shared/entities/sreUnitType';
import {
  useDeleteApiSettingsReportRentRollUnitTypesByIdMutation,
  useGetApiSettingsReportRentRollSourceUnitTypesQuery,
  useGetApiSettingsReportRentRollUnitTypesQuery,
  usePostApiSettingsReportRentRollUnitTypesMutation,
  usePutApiSettingsReportRentRollSourceUnitTypesSetUnitTypeMutation,
  usePutApiSettingsReportRentRollUnitTypesByIdMutation,
} from '@/entities/report/unitTypes';
import {
  SettingsReportUnitTypesExportButton,
  SettingsReportUnitTypesImportButton,
} from '@/features/report/unitTypes';
import useGridSelectedRows from '@/lib/ag-grid/useGridSelectedRows';
import { SOURCE_TYPE_SETTINGS } from '@/lib/sourceType';
import { currentUserAllowedToConfigureUnitTypes } from '@/pages/settings/report/operational/unitTypes/permissions';
import { useModal } from '@/shared/lib/hooks/useModal';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { GeneralSettingsNavigationSection, IconButton } from '@/stories';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useSreUnitTypeSettingsModal } from 'bundles/Shared/entities/sreUnitType/ui/UnitTypeSettingsModal';
import { omit } from 'lodash-es';
import pluralize from 'pluralize';
import { ComponentProps, FC, useCallback, useMemo, useRef } from 'react';

export const UnitTypesPage: FC<RouteComponentProps> = () => {
  const navigate = useNavigate();
  const isMappedUnitTypesTab = useSettingReportOperationTabs();

  const tabParams = {
    withUnitType: isMappedUnitTypesTab,
    withoutUnitType: !isMappedUnitTypesTab,
  };

  const {
    pageParams,
    setPageParams,
    setSortParams,
    setGroupByParam,
    setLegalEntityIds,
    setSreUnitTypeIds,
    setPmcIds,
    setErps
  } = useSettingReportOperationalPageParams();

  const {
    data: sourceUnitTypesData,
    isFetching: sourceUnitTypesFetching,
    isLoading: sourceUnitTypesLoading,
  } = useGetApiSettingsReportRentRollSourceUnitTypesQuery({
    ...tabParams,
    ...pageParams,
  });

  const exportParams = {
    ...tabParams,
    ...omit(pageParams, ['page', 'perPage']),
  };

  const [updateSourceUnitType] =
    usePutApiSettingsReportRentRollSourceUnitTypesSetUnitTypeMutation();
  const [editUnitType] = usePutApiSettingsReportRentRollUnitTypesByIdMutation();
  const [deleteUnitType] =
    useDeleteApiSettingsReportRentRollUnitTypesByIdMutation();

  const [createUnitType] = usePostApiSettingsReportRentRollUnitTypesMutation();

  const isFetching = sourceUnitTypesFetching;
  const isLoading = sourceUnitTypesLoading;
  const { openModal, confirm } = useModal();
  const { data: unitTypesData } =
    useGetApiSettingsReportRentRollUnitTypesQuery();

  const totalSize = sourceUnitTypesData?.meta.totalSize ?? 0;
  const mappedSourceUnitTypesCount =
    sourceUnitTypesData?.meta.withUnitTypeSize ?? 0;

  const unmappedSourceUnitTypesCount =
    sourceUnitTypesData?.meta.withoutUnitTypeSize ?? 0;

  const usedUnitTypesSize = sourceUnitTypesData?.meta.usedSreUnitTypesSize ?? 0;

  const { allLEOptions, selectedLEOptions } =
    useSettingsReportOperationalLEOptions({
      legalEntities: sourceUnitTypesData?.meta.legalEntities ?? [],
      selectedLEIds: pageParams.legalEntityIds,
      deps: [pageParams, sourceUnitTypesData],
    });

  const allSreUnitTypeOptions = useMemo(() => {
    return sourceUnitTypesData?.meta?.sreUnitTypes?.map((ut) => ({ ...ut, value: ut.id })) ?? [];
  }, [sourceUnitTypesData]);

  const allErpsOptions = useMemo(() => {
    return sourceUnitTypesData?.meta?.erps?.map((ut) => ({ id: ut, value: ut, label: SOURCE_TYPE_SETTINGS[ut].title })) ?? [];
  }, [sourceUnitTypesData]);

  const allPmcOptions = useMemo(() => {
    return sourceUnitTypesData?.meta?.pmcs?.map((ut) => ({ ...ut, value: ut.id, label: ut.name })) ?? [];
  }, [sourceUnitTypesData]);

  const unitTypes = unitTypesData?.items ?? [];
  const items = (sourceUnitTypesData?.items ?? []) as SourceUnitType[];
  const tableRef = useRef<AgGridReact>();

  const sourceUnitTypesMap = useMemo(() => {
    return new Map(items.map((i) => [i.id, i] as const));
  }, [items]);

  const openSreUnitTypeSettingsModal = useSreUnitTypeSettingsModal();

  const onSetClassification = useLEClassUpdateAndInvalidateEntity('unit-types');
  const {
    selectedRows: selectedUTs,
    deselectAll: deselectAllUTs,
    handleRowCheck,
    allShownRowsChecked,
    handleAllShownRowsCheck,
    handleGroupCheck,
    resolveGroupCheck,
  } = useGridSelectedRows<SourceUnitType>(tableRef, items);

  const refCallBack = useTableRefCallback({
    setSortParams,
    tableRef,
  });

  const handleRemoveUnitType = async (unitType: SreUnitType) => {
    const res = await confirm();

    if (!res) return;

    deleteUnitType({
      id: unitType.id,
    });
  };
  const handleResetUnitType = async (id: SourceUnitType['id']) => {
    const res = await confirm();

    if (!res) return;

    updateSourceUnitType({
      body: {
        source_unit_type_ids: [id],
        unit_type_id: null,
      },
    });
  };

  const handleEditUnitType = async (unitType: SreUnitType) => {
    const res = await openSreUnitTypeSettingsModal({
      name: unitType.label,
      reference: unitType.name,
    });

    if (res == null) return;

    editUnitType({
      id: unitType.id,
      body: {
        label: res.name,
        name: res.reference,
      },
    });
  };

  const handleCreateUnitType = async () => {
    const res = await openSreUnitTypeSettingsModal();

    if (res == null) return;

    createUnitType({
      body: {
        label: res.name,
        name: res.reference,
      },
    });
  };

  const selectedUTsWithLEClass = useMemo(() => {
    return filterWithLEClass(selectedUTs);
  }, [selectedUTs]);

  const handleSetBulkUnitType = async () => {
    const res = await openModal(BulkSetUnitTypeModal, {
      sourceUnitTypes: selectedUTsWithLEClass,
      unitTypes: unitTypesData?.items ?? [],
    });

    if (res == null) return;

    updateSourceUnitType({
      body: {
        unit_type_id: res.id,
        source_unit_type_ids: mapListToIds(selectedUTsWithLEClass),
      },
    });
    deselectAllUTs();
  };

  const handleResetBulk = async () => {
    const res = await confirm();

    if (!res) return;

    updateSourceUnitType({
      body: {
        unit_type_id: null,
        source_unit_type_ids: mapListToIds(selectedUTs),
      },
    });
    deselectAllUTs();
  };

  const getSreUnitTypeFormatter = useCallback(
    ({
      sourceUnitType,
      ...props
    }: Pick<
      ComponentProps<typeof SreUnitTypeFormatter>,
      'value' | 'showEditButton' | 'initialValue'
    > & {
      sourceUnitType: SourceUnitType;
    }) => (
      <SreUnitTypeFormatter
        searchRowActions={
          currentUserAllowedToConfigureUnitTypes() && (
            <IconButton
              onClick={handleCreateUnitType}
              variant="secondary"
              size="l"
              iconName="add"
            />
          )
        }
        onUnitTypeEdit={
          currentUserAllowedToConfigureUnitTypes()
            ? handleEditUnitType
            : undefined
        }
        onUnitTypeRemove={
          currentUserAllowedToConfigureUnitTypes()
            ? handleRemoveUnitType
            : undefined
        }
        onUnitTypeReset={() => handleResetUnitType(sourceUnitType.id)}
        disabled={sourceUnitType?.legalEntity?.classification == null}
        unitTypes={unitTypes}
        onSubmit={(unitType) => {
          updateSourceUnitType({
            body: {
              unit_type_id: unitType.id,
              source_unit_type_ids: [sourceUnitType.id],
            },
          });
        }}
        {...props}
      />
    ),
    [
      unitTypes,
      updateSourceUnitType,
      handleResetUnitType,
      handleCreateUnitType,
      handleEditUnitType,
      handleRemoveUnitType,
    ],
  );

  const columnDefsAggrid: ColDef[] = useMemo(() => {
    const columns = [
      getCheckboxAggridTableColumn({
        allChecked: allShownRowsChecked,
        checked: selectedUTs,
        onClickAll: handleAllShownRowsCheck,
        onClick: (item, options) => handleRowCheck(item, options?.withShift),
      }),
      {
        cellClass: 'first',
        colId: 'parseUnitType',
        headerName: `Parsed ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm}`,
        field: 'name',
        flex: 1,
        rowGroup: false,
      },
      {
        colId: 'sreUnitType',
        field: 'unitType.label',
        flex: 1,
        rowGroup: false,
        cellRenderer: ({ value, data }) => {
          const sourceUnitType = sourceUnitTypesMap.get(data.id)!;

          return getSreUnitTypeFormatter({ value, sourceUnitType });
        },
        headerComponent: allSreUnitTypeOptions.length && isMappedUnitTypesTab ? SetFilter : null,
        headerComponentParams: {
          filterName: `sRE ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm}`,
          items: allSreUnitTypeOptions,
          value: pageParams.sreUnitTypeIds,
          onChange: setSreUnitTypeIds,
          label: `sRE ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm}`,
          appendTo: () => document.body,
          headerComponent: allSreUnitTypeOptions.length && isMappedUnitTypesTab ? SetFilter : null,
          headerComponentParams: {
            filterName: `sRE ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm}`,
            items: allSreUnitTypeOptions,
            value: pageParams.sreUnitTypeIds,
            onChange: setSreUnitTypeIds,
            label: `sRE ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm}`,
            appendTo: () => document.body,
          },
        },
      },
      getErpColumn({
        group: pageParams.group,
        options: allErpsOptions,
        onChange: setErps,
        value: pageParams.erps,
      }),
      getPmcColumn({
        group: pageParams.group,
        options: allPmcOptions,
        onChange: setPmcIds,
        value: pageParams.pmcIds,
      }),
      getLegalEntityColumn({
        allLEOptions,
        selectedLEOptions,
        setLegalEntityIds,
      }),
      getLEClassAggridTableColumn({
        isLastColumn: !isMappedUnitTypesTab,
        actions: {
          onSetClassification,
        },
      }),
    ];

    if (isMappedUnitTypesTab) {
      columns.push({
        cellClass: 'last',
        colId: 'actions',
        headerName: '',
        field: 'unitType.name',
        cellRenderer: ({ value, data }) => {
          return getSreUnitTypeFormatter({
            value,
            sourceUnitType: data,
            showEditButton: true,
            initialValue: data.unitType,
          });
        },
        width: 40,
      });
    }

    return columns;
  }, [
    items,
    allLEOptions,
    selectedLEOptions,
    unitTypes,
    isMappedUnitTypesTab,
    selectedUTs,
    getSreUnitTypeFormatter,
    allShownRowsChecked,
  ]);
  return (
    <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
      <div className="flex flex-grow flex-col gap-tw-4">
        <div className="flex gap-tw-4">
          <GeneralSettingsNavigationSection
            isLoading={isLoading}
            disabled={isFetching}
            title={`${mappedSourceUnitTypesCount} ${pluralize(
              SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm,
              mappedSourceUnitTypesCount,
            )}`}
            subtitle={`Mapped with ${usedUnitTypesSize} sRE ${pluralize(
              SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm,
              usedUnitTypesSize,
            )}`}
            active={isMappedUnitTypesTab}
            onClick={() => {
              navigate(generateUrlSettingsUnitTypes());
            }}
          />
          <GeneralSettingsNavigationSection
            isLoading={isLoading}
            disabled={isFetching}
            title={`${unmappedSourceUnitTypesCount} Active ${pluralize(
              SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm,
              unmappedSourceUnitTypesCount,
            )}`}
            subtitle="Not Mapped"
            active={!isMappedUnitTypesTab}
            onClick={() => {
              navigate(generateUrlSettingsUnitTypes(false));
              setSreUnitTypeIds([]);
            }}
          />
        </div>
        <div className="flex flex-grow flex-col gap-tw-4">
          <div className="flex flex-col gap-tw-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <PageParamsPagination
                  loading={isFetching}
                  totalSize={totalSize}
                />
              </div>
              <div className="flex items-center gap-tw-4">
                <GroupByPopover
                  items={OPERATIONAL_MAPPING_GROUP_BY_LIST}
                  value={OPERATIONAL_MAPPING_GROUP_BY_LIST.filter((c) => {
                    return pageParams.group.includes(c.value);
                  })}
                  onChange={setGroupByParam}
                />
                <PageParamsSearch
                  suggestions={[
                    `Parsed ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm} name`,
                  ]}
                  size="s"
                  placeholder={
                    SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes
                      .singularForm
                  }
                />
                <SettingsReportUnitTypesImportButton />
                <SettingsReportUnitTypesExportButton params={exportParams} />
              </div>
            </div>
          </div>

          <GeneralLedgersTable // The table renders not general ledgers but unit types, should be renamed
            className="ag-theme-light_small-padding h-[50vh] flex-grow"
            ref={refCallBack}
            loading={isLoading}
            fetching={isFetching}
            columnDefs={columnDefsAggrid}
            rowData={items}
            onRowClicked={(e) => {
              const event = e.event as MouseEvent;
              if (!event.shiftKey) return;
              handleRowCheck(e.data, true);
            }}
            groupRowRendererParams={{
              suppressCount: true,
              innerRenderer: GroupRowInnerRenderer,
              handleGroupCheck,
              resolveGroupCheck,
            }}
          />
          {selectedUTs.length > 0 && (
            <BulkActionsPanel
              selectedRows={selectedUTs}
              setSelectedRows={() => deselectAllUTs()}
              actions={[
                {
                  title: `Set ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm}`,
                  icon: 'edit',
                  handleClick: handleSetBulkUnitType,
                  hidden: selectedUTsWithLEClass.length === 0,
                },
                {
                  title: `Reset ${SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm}`,
                  icon: 'reset',
                  handleClick: handleResetBulk,
                  hidden: selectedUTs.length === 0 || !isMappedUnitTypesTab,
                },
              ]}
            />
          )}
        </div>
      </div>
    </PageParamsProvider>
  );
};
