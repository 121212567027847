import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';

export const PAGES_WITHOUT_MAIN_MENU = [
  '/mobile',
  '/approve',
  ROUTES_ROOT.useDesktopVersion.fullPath,
  ROUTES_ROOT.settings.report.templateBuilder.fullPath,
] as const;

export const PAGES_EXCEPTION_WITH_MAIN_MENU = ['workflow/approvers'] as const;

export const PAGES_WITHOUT_FOOTER = [
  'change-event',
  'change-order',
  'reallocation',
  ROUTES_ROOT.reports.fullPath,
  ROUTES_ROOT.reconcile.operational.fullPath,
  ROUTES_ROOT.reconcile.development.fullPath,
  ROUTES_ROOT.reconcile.underwriting.fullPath,
  '/create-entry',
  ROUTES_ROOT.assets.fullPath,
  ROUTES_ROOT.funds.fullPath,
  ROUTES_ROOT.settings.report.fullPath,
  ROUTES_ROOT.settings.report.templateBuilder.fullPath,
  ROUTES_ROOT.useDesktopVersion.fullPath,
  'report/dashboards',
  'report/comparison-mode-dashboards',
  ROUTES_ROOT.scoreboards.fullPath,
  ROUTES_ROOT.documentation.fullPath,
] as const;

export const PAGES_WITH_SMALL_SCREEN_SUPPORT = [
  'mobile',
  ROUTES_ROOT.useDesktopVersion.fullPath,
] as const;
