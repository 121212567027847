import { IDetailsItem } from 'bundles/REturn/components/Home/investmentObjectDetailsModal/DetailsTable';
import { IReturnInvestmentObjectDetails } from 'bundles/REturn/types';
import { groupBy, max, sumBy } from 'lodash-es';

import { convertCentsToDollars } from '@/shared/lib/converters';

export const MAX_DISPLAYED_CHART_ITEMS = 5;
const keys = ['commitments', 'contributions', 'distributions'] as const;

export const RETURN_HOME_PAGE_DETAILS_MODAL_TABS = keys.reduce(
  (obj, key) => ({ ...obj, [key]: key }),
  {} as Record<(typeof keys)[number], (typeof keys)[number]>,
);

export type ReturnInvestmentObjectTab =
  keyof typeof RETURN_HOME_PAGE_DETAILS_MODAL_TABS;
export const DEFAULT_TAB: ReturnInvestmentObjectTab = 'commitments';

export const getDetailsItemsForTab = (
  tab: ReturnInvestmentObjectTab,
  data: IReturnInvestmentObjectDetails,
  legalEntitiesIds: string[],
): IDetailsItem[] => {
  const filtered = data[tab].filter(
    (item) =>
      legalEntitiesIds.length === 0 ||
      legalEntitiesIds.includes(item.legalEntity.id),
  );
  const groups = groupBy(filtered, (item) => item.investmentEntity.id);
  return Object.entries(groups).map(([_, items]) => ({
    investmentEntity: items[0].investmentEntity,
    amount: sumBy(items, (item) =>
      item.amount
        ? Number(item.amount)
        : convertCentsToDollars(item.amountCents),
    ),
    date: max(items.map((item) => item.date)),
  }));
};

export const getTabIsDisabled = (
  tab: ReturnInvestmentObjectTab,
  data: IReturnInvestmentObjectDetails,
) => {
  const key = RETURN_HOME_PAGE_DETAILS_MODAL_TABS[tab];

  if (data[key].length === 0) return true;
  if (key === 'distributions')
    return data[key].every((item) => Number(item.amount) === 0);
  if (key === 'contributions')
    return data[key].every((item) => Number(item.amountCents) === 0);

  return data[key].every((item) => Number(item.commitmentAmount) === 0);
};
