import { cn } from '@/shared/lib/css/cn';
import IconButton from '@/stories/IconButton/IconButton';
import React, { ReactNode } from 'react';
import './radioButton.css';

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  classes?: {
    label?: string;
    text?: string;
  };
  children?: ReactNode | ReactNode[];
  labelProps?: React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >;
  actionsSlot?: React.ReactNode;
}

export const RadioButton = ({
  children,
  classes,
  labelProps,
  actionsSlot,
  ...props
}: Props) => (
  <label className={cn('sre-radio', classes?.label)} {...labelProps}>
    <input className="sre-radio__input" type="radio" {...props} />
    <div className="sre-radio__content">
      {children && (
        <span className={cn('sre-radio__text', classes?.text)}>{children}</span>
      )}
      {actionsSlot}
    </div>
  </label>
);

export default RadioButton;
