import { UNSUPPORTED_DASHBOARD_INFO_TEXT } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/config';
import {
  addSectionsToLayout,
  AllWidgetTypes,
  mapLayoutToLayoutDto,
  ReportDashboardSectionPositionWithId,
  ReportDashboardType,
  useDashboardContext,
  usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
  usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
  usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
} from 'bundles/Shared/entities/dashboard';
import {
  DEFAULT_CONFIG_MAP,
  WIDGETS_CONFIG_MAP,
} from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { useMemo } from 'react';

export const useAddDashboardWidgetSection = () => {
  const { dashboardId, dashboardType } = useDashboardContext();
  const createEagleEyeWidgetMutation =
    usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsMutation();
  const createObjectWidgetMutation =
    usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsMutation();
  const createComparisonWidgetMutation =
    usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsMutation();

  const [createWidget, options] = useMemo(() => {
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return createObjectWidgetMutation;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return createEagleEyeWidgetMutation;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return createComparisonWidgetMutation;
      }
      default: {
        return [
          () => {
            toastr.warning(UNSUPPORTED_DASHBOARD_INFO_TEXT);
          },
          { isLoading: false },
        ];
      }
    }
  }, [dashboardType]);

  const handleCreateWidget = async <T extends AllWidgetTypes>({
    layout,
    layoutItem,
    boardId,
    type,
  }: {
    layout: ReportDashboardSectionPositionWithId[];
    layoutItem?: ReportDashboardSectionPositionWithId;
    boardId?: string;
    type: T | T[];
  }) => {
    if (boardId == null) return;

    const types = Array.isArray(type) ? type : [type];
    const newLayout = addSectionsToLayout(
      layout,
      types.map((t) => ({
        i: t,
        type: t,
        ...WIDGETS_CONFIG_MAP[t].layout,
        ...layoutItem,
      })),
      { toTheEnd: layoutItem == null },
    );

    const dashboard_layout = mapLayoutToLayoutDto(
      layout.filter((l) => !types.includes(l.i as unknown as T)),
    );

    return await createWidget({
      objectDashboardId: dashboardId,
      eagleEyeDashboardId: dashboardId,
      comparisonDashboardId: dashboardId,
      boardId,
      body: {
        // @ts-expect-error
        widget_sections: types.map((t) => ({
          title: `${WIDGETS_CONFIG_MAP[t].title}`,
          widget_type: t,
          position: newLayout.find((l) => l.i === t)!,
          // @ts-expect-error
          config: DEFAULT_CONFIG_MAP[t],
        })),
        dashboard_layout,
      },
    });
  };

  return [handleCreateWidget, options] as const;
};
