import { getAmchartRef } from 'lib/amcharts/utils';
import { DEFAULT_AMCHART_DATE_FORMATS } from '@/shared/lib/formatting/charts';

export const DEFAULT_SERIES_REF_KEY = 'series';
export const DEFAULT_X_AXIS_REF_KEY = 'xAxis';
export const DEFAULT_Y_AXIS_REF_KEY = 'yAxis';

export const DEFAULT_LINE_SERIES_TEMPLATE = {
  type: 'LineSeries',
  settings: {
    name: 'Line Series',
    xAxis: getAmchartRef(DEFAULT_X_AXIS_REF_KEY),
    yAxis: getAmchartRef(DEFAULT_Y_AXIS_REF_KEY),
    fill: {
      type: 'Color',
      value: '#0d1a3f',
    },
    stroke: {
      type: 'Color',
      value: '#0d1a3f',
    },
    tooltip: {
      type: 'Tooltip',
      settings: {
        labelText: '{valueY}',
      },
    },
    valueXField: 'dateFrom',
  },
  properties: {
    data: getAmchartRef('data'),
    bullets: [
      {
        type: 'Bullet',
        settings: {
          sprite: {
            type: 'Circle',
            settings: {
              fill: "@series.get('fill')",
              radius: 4,
            },
          },
        },
      },
    ],
  },
} as const;

export const DEFAULT_DATE_X_AXIS_TEMPLATE = {
  type: 'DateAxis',
  settings: {
    renderer: {
      type: 'AxisRendererX',
      settings: {
        cellEndLocation: 0.9,
        minGridDistance: 40,
        cellStartLocation: 0.1,
      },
    },
    dateFormats: DEFAULT_AMCHART_DATE_FORMATS,
    baseInterval: {
      count: 1,
      timeUnit: 'day',
    },
    maxDeviation: 0.2,
    categoryField: 'dateFrom',
  },
  properties: {
    data: '#data',
  },
} as const;

export const DEFAULT_VALUE_Y_AXIS_TEMPLATE = {
  type: 'ValueAxis',
  settings: {
    renderer: {
      type: 'AxisRendererY',
    },
  },
};

export const DEFAULT_Y_AXES_REFS = [getAmchartRef(DEFAULT_Y_AXIS_REF_KEY)];

export const DEFAULT_X_AXES_REFS = [getAmchartRef(DEFAULT_X_AXIS_REF_KEY)];

export const DEFAULT_SERIES_REF = getAmchartRef(DEFAULT_SERIES_REF_KEY);

export const DEFAULT_AMCHART_CONFIG_TEMPLATE = {
  type: 'XYChart',
  refs: [
    {
      [DEFAULT_X_AXIS_REF_KEY]: DEFAULT_DATE_X_AXIS_TEMPLATE,
      [DEFAULT_Y_AXIS_REF_KEY]: DEFAULT_VALUE_Y_AXIS_TEMPLATE,
    },
    {
      [DEFAULT_SERIES_REF_KEY]: [],
    },
  ],
  children: [
    {
      type: 'Legend',
      settings: {
        x: {
          type: 'Percent',
          value: 50,
        },
        layout: 'horizontal',
        centerX: {
          type: 'Percent',
          value: 50,
        },
      },
      properties: {
        data: getAmchartRef('series'),
      },
    },
  ],
  properties: {
    xAxes: DEFAULT_X_AXES_REFS,
    yAxes: DEFAULT_Y_AXES_REFS,
    series: DEFAULT_SERIES_REF,
  },
  settings: {
    panY: false,
    cursor: {
      type: 'XYCursor',
      settings: {
        behavior: 'zoomX',
      },
      properties: {
        lineY: {
          settings: {
            visible: false,
          },
        },
      },
    },
    layout: 'vertical',
    scrollbarX: {
      type: 'Scrollbar',
      settings: {
        orientation: 'horizontal',
        marginBottom: 20,
      },
    },
  },
} as const;
