import { ColumSizeWidgetState } from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { DEFAULT_AUTO_SIZE_ALL_COLUMNS_SKIP_HEADER } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/config/config';
import {
  handleOnRowDataChangedAutoSizeAllColumns,
  sleepUntilWidgetStateIsChanged,
} from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib/utils';
import { RowDataChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { RefObject } from 'react';

export type WidgetTableColumSize = null | 'default' | 'compact';
export type WidgetTableColumnSizeChangeHandler = (
  c: WidgetTableColumSize,
) => void;

export const useWidgetTableColumnSize = ({
  gridRef,
  state,
  onStateChange,
}: {
  gridRef: RefObject<AgGridReact>;
  state: ColumSizeWidgetState;
  onStateChange: (s: ColumSizeWidgetState) => void;
}) => {
  const handleColumnSizeChange = async (
    selectedColumnSize: WidgetTableColumSize,
  ) => {
    switch (selectedColumnSize) {
      case 'compact': {
        onStateChange({ ...state, selectedColumnSize: 'compact' });

        await sleepUntilWidgetStateIsChanged();

        gridRef.current?.columnApi.autoSizeAllColumns(
          DEFAULT_AUTO_SIZE_ALL_COLUMNS_SKIP_HEADER,
        );
        break;
      }

      case 'default':
      default: {
        onStateChange({ ...state, selectedColumnSize: 'default' });

        await sleepUntilWidgetStateIsChanged();

        gridRef.current?.api.sizeColumnsToFit();
        break;
      }
    }
  };

  const handleRowDataChanged = (event: RowDataChangedEvent) => {
    if (state.selectedColumnSize !== 'compact') {
      event.api.sizeColumnsToFit();
      return;
    }

    handleOnRowDataChangedAutoSizeAllColumns({
      event,
      skipHeader: DEFAULT_AUTO_SIZE_ALL_COLUMNS_SKIP_HEADER,
    });
  };

  return {
    handleColumnSizeChange,
    handleRowDataChanged,
  };
};
