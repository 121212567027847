import { useAppDispatch } from '@/shared/lib/hooks/redux';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import * as React from 'react';
import { Button } from 'stories';
import { createAsset } from '../actions';
import AssetFormModal from './AssetFormModal';

function CreateAssetButton({
  refetchAssets,
}: {
  refetchAssets: () => Promise<void>;
}) {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const {
    value: isLoading,
    setTrue: enableLoading,
    setFalse: disableLoading,
  } = useBoolean();
  return (
    <>
      <Button onClick={() => setShowModal(true)} variant="primary">
        Create New Asset
      </Button>
      {showModal && (
        <AssetFormModal
          isLoading={isLoading}
          onSubmit={async (res) => {
            try {
              enableLoading();
              await dispatch(createAsset(res));
            } catch (e) {
              console.error(e);
            } finally {
              disableLoading();
              setShowModal(false);
            }
            refetchAssets();
          }}
          />)}
    </>
  );
}

export default CreateAssetButton;
