import { plainPreviewReportPdf } from '@/entities/report/reportBuilder/lib/plainPreviewReportPdf';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { useHostedUrl } from '@/shared/lib/hooks/useHostedUrl';
import {
  TRouteQueryParams,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import { useParams } from '@reach/router';
import { useEffect, useMemo, useState } from 'react';

export const useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdfQuery =
  () => {
    const params = useParams();
    const { assetId } = useQueryParams<TRouteQueryParams['/reports/:id']>();
    const hostedUrl = useHostedUrl();
    const [pdfData, setPdfData] = useState<Blob | null>(null);
    const {
      value: isLoading,
      setTrue: enableLoading,
      setFalse: disableLoading,
    } = useBoolean(false);
    const pendingRequestIds = useAppSelector(
      (s) => s.reportBuilder.pendingRequestIds,
    );

    useEffect(() => {
      if (pendingRequestIds.length !== 0) return;

      const fetchData = async () => {
        enableLoading();

        try {
          const res = await plainPreviewReportPdf({
            assetId,
            renderPdfHost: hostedUrl,
            reportId: params.id,
          });

          setPdfData(res.data);
        } catch {
          setPdfData(null);
        } finally {
          disableLoading();
        }
      };
      fetchData();
    }, [pendingRequestIds]);

    const dataObjectURL = useMemo(
      () => pdfData && URL.createObjectURL(pdfData),
      [pdfData],
    );

    return {
      dataObjectURL,
      data: pdfData,
      isLoading,
    };
  };
