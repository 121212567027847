import {
  bulkSetObject,
  fetchLegalEntitiesByParams,
  fetchLegalEntitiesMeta,
  includeOrExcludeLE,
  setObjectForSingleLE,
  updateBulkLEClass,
  updateLegalEntity,
} from 'bundles/Settings/actions/legalEntity';
import LegalEntityModal from 'bundles/Settings/components/Portal/LegalEntities/LegalEntityModal';
import { ModalHeaderForUpdatingLE } from 'bundles/Settings/components/Portal/LegalEntities/ModalHeaderForSetObjectLE';
import {
  LEFieldOption,
  SetObjectForLEModal,
} from 'bundles/Settings/components/Portal/LegalEntities/UpdateLEModals';
import { settingsCoreLegalEntityActivityLogsApi } from 'bundles/Settings/components/Portal/LegalEntities/api/settingsCoreLegalEntityActivityLogsApi';
import {
  TFilterModel,
  createLegalEntityColumns,
} from 'bundles/Settings/components/Portal/LegalEntities/legalEntityColumns';
import BulkActionsPanel from 'bundles/Shared/components/BulkActionsPanel/BulkActionsPanel';
import { ViewPermissionedUsersModal } from 'bundles/Shared/components/Permissions/ViewPermissionedUsersModal';
import {
  FetchPermissionedUsersWithMetaResponse,
  LightUser,
} from 'bundles/Shared/components/Permissions/ViewPermissionedUsersModal/types';
import PermissionsModal from 'bundles/Shared/components/PermissionsModal';
import Table from 'bundles/Shared/components/Table/Table';
import TableSearch from 'bundles/Shared/components/Table/TableSearch';
import { resetFilter } from 'bundles/Shared/components/Table/filters/helpers';
import TablePagination from 'bundles/Shared/components/Table/pagination/TablePagination';
import {
  LeClassificationModal,
  LeClassification,
} from 'bundles/Shared/entities/leClasssification';
import { useGetApiSettingsCoreLegalEntitiesLegalEntitiablesQuery } from 'bundles/Shared/entities/legalEntity/api/settingsCoreLegalEntitiesApiEnhanced';
import { legalEntityPermissions } from 'bundles/Shared/legalEntityPermissions';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { useModal } from '@/shared/lib/hooks/useModal';
import http from 'lib/http';
import { ASSET_PORTAL_PRODUCT_NAME } from 'lib/permissions';
import { omit, partition, snakeCase } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { AnimationLoader, Button, ThinTabGroupWithAmount } from 'stories';
import {
  LEConfigsMeta,
  LEKindsAmountMeta,
  LegalEntity,
} from '@/entities/core/legalEntity';

interface IScopeItem {
  id: string;
  scopeName: string;
  label: string;
  varName: string;
  amount?: number;
}

const SIZE_PER_PAGE = 20;
const DEFAULT_SCOPE = 'active-and-not-mapped';

export const LegalEntities = () => {
  const [metaData, setMetaData] = useState<LEKindsAmountMeta>();
  const AVAILABLE_SCOPES = useMemo<IScopeItem[]>(
    () => [
      {
        id: 'active-and-mapped',
        scopeName: 'active-and-mapped',
        label: 'Active & Mapped',
        varName: 'mappedSize',
        amount: metaData?.mappedSize,
      },
      {
        id: 'active-and-not-mapped',
        scopeName: 'active-and-not-mapped',
        label: 'Active & Not Mapped',
        varName: 'notMappedSize',
        amount: metaData?.notMappedSize,
      },
      {
        id: 'excluded',
        scopeName: 'excluded',
        label: 'Excluded',
        varName: 'excludedSize',
        amount: metaData?.excludedSize,
      },
    ],
    [metaData],
  );

  const [legalEntitiesLoading, setLoadingLegalEntities] = useState(true);
  const dispatch = useAppDispatch();

  const [legalEntities, setLegalEntities] = useState<LegalEntity[]>([]);
  const [legalEntitiesMeta, setLegalEntitiesMeta] = useState<LEConfigsMeta>({
    perPage: '0',
    totalFireStationPermissions: 0,
    totalSize: 0,
  });
  const [currentLegalEntity, setCurrentLegalEntity] = useState<LegalEntity>();

  const [totalSize, setTotalSize] = useState<number>();
  const { openModal, confirm } = useModal();

  const defaultFilters = {
    investmentObjects: [],
  };

  const [pageParams, setPageParams] = useState({
    currentPage: 1,
    sizePerPage: SIZE_PER_PAGE,
    sortField: 'updated_at',
    sortOrder: 'desc',
    scope: DEFAULT_SCOPE,
    filters: defaultFilters,
    fire_investment_objects: [],
  });

  const { currentPage } = pageParams;
  const setCurrentPage = (page: number) =>
    setPageParams({ ...pageParams, currentPage: page });
  const setSearchQuery = (query: string) =>
    setPageParams({
      ...pageParams,
      currentPage: 1,
      searchQuery: query,
    });

  const { data: legalEntitiables, isLoading: isLegalEntitiablesLoading } =
    useGetApiSettingsCoreLegalEntitiesLegalEntitiablesQuery();
  const [allAssets, allFunds] = partition(
    legalEntitiables ?? [],
    (l) => l.objectType === 'Asset',
  );

  const [selectedRows, setSelectedRows] = useState<LegalEntity[]>([]);

  const [permissionsModalOpened, setPermissionsModalOpened] = useState(false);
  const [bulkPermissionsModalOpened, setBulkPermissionsModalOpened] =
    useState(false);
  const [legalEntityModalOpened, setLegalEntityModalOpened] = useState(false);

  const legalEntitiesScope = pageParams.scope;
  const setLegalEntitiesScope = (scope: string) => {
    setPageParams({
      ...pageParams,
      scope,
      currentPage: 1,
      filters: defaultFilters,
    });
  };

  const selectedFilters = pageParams.filters;

  const fetchLegalEntities = async () => {
    setLoadingLegalEntities(true);
    const fetchedLegalEntities = await fetchLegalEntitiesByParams({
      page: pageParams.currentPage,
      sort_field: snakeCase(pageParams.sortField),
      sort_order: snakeCase(pageParams.sortOrder),
      search_query: pageParams.searchQuery,
      size_per_page: SIZE_PER_PAGE,
      view: pageParams.view,
      scope: pageParams.scope,
      fire_permissions: pageParams.firePermissions,
      filters: {
        asset_ids: selectedFilters.investmentObjects
          .filter(({ klass }) => klass === 'Asset')
          .map(({ id }) => id),
        fund_ids: selectedFilters.investmentObjects
          .filter(({ klass }) => klass === 'Fund')
          .map(({ id }) => id),
      },
    });
    setLegalEntities(fetchedLegalEntities.legalEntities);
    setTotalSize(fetchedLegalEntities.meta.totalSize);
    setLegalEntitiesMeta(fetchedLegalEntities.meta);
    setLoadingLegalEntities(false);
  };

  const refreshData = () => {
    dispatch(
      settingsCoreLegalEntityActivityLogsApi.util.invalidateTags([
        'LegalEntityActivityLogsTag',
      ]),
    );
    fetchLegalEntitiesMeta(setMetaData);
    fetchLegalEntities();
  };

  const setSelectedFilters = (filters) =>
    setPageParams({
      ...pageParams,
      currentPage: 1,
      filters: {
        ...selectedFilters,
        ...filters,
      },
    });

  const localResetFilter = (key: string, id: number) =>
    resetFilter(setSelectedFilters, selectedFilters, key, id);

  const onSetObjectForSingleLE = async (
    selectedObjectOption: LEFieldOption,
    row: LegalEntity,
  ) => {
    const [type, id] = selectedObjectOption.value.split('_');
    const res = await setObjectForSingleLE(row.id, {
      legal_entitiable_type: type,
      legal_entitiable_id: id,
    });
    if (!res) {
      return;
    }
    refreshData();
  };

  const onBulkSetClass = async (classification: LeClassification) => {
    const res = await updateBulkLEClass({
      classification,
      legalEntityIds: selectedRows.map(({ id }) => id),
    });
    if (!res) {
      return;
    }
    refreshData();
    setSelectedRows([]);
  };

  const onBulkSetObject = async (selectedObjectOption: LEFieldOption) => {
    const [type, objectId] = selectedObjectOption.value.split('_');
    const res = await bulkSetObject({
      legal_entitiable_type: type,
      legal_entitiable_id: Number(objectId),
      legal_entity_ids: selectedRows.map(({ id }) => id),
    });
    if (!res) {
      return;
    }
    refreshData();
    setSelectedRows([]);
  };
  useEffect(() => {
    fetchLegalEntities();
  }, [pageParams]);
  const onCloseSetObjectModal = () => {
    setCurrentLegalEntity(undefined);
  };
  const openSetObjModalForSingleLE = async (row: LegalEntity) => {
    const res = await openModal(SetObjectForLEModal, {
      assets: allAssets,
      funds: allFunds,
      currentLegalEntities: [row],
    });
    if (res) {
      onSetObjectForSingleLE(res, row);
    } else {
      onCloseSetObjectModal();
    }
  };

  const openSetLEClassifaction = async (row: LegalEntity) => {
    const res = await openModal(LeClassificationModal<false>, {
      header: (
        <ModalHeaderForUpdatingLE
          title="Set class for"
          currentLegalEntities={[row]}
        />
      ),
    });

    if (!res) return;

    await updateLegalEntity({
      id: row.id,
      classification: res,
    });
    refreshData();
  };

  const onViewPermissions = async (le: LegalEntity) => {
    await openModal(ViewPermissionedUsersModal, {
      fetchPermissionedUsersWithMeta: async () => {
        const res = await http.get(`/legal_entities/${le.id}/permissions`);
        const data = await res.json();

        return data as FetchPermissionedUsersWithMetaResponse<LightUser>;
      },
    });
  };

  const tableColumns = useMemo(
    () =>
      createLegalEntityColumns({
        actions: {
          onViewPermissions,
          openSetObjModalForSingleLE,
          openSetLEClassifaction,
          setPermissionsModalOpened,
          setCurrentLegalEntity,
          setLegalEntitiesScope,
          legalEntitiesScope,
          refreshMeta: refreshData,
          setLegalEntityModalOpened,
          localResetFilter,
          setSelectedFilters,
          setSelectedRows,
        },
        allAssets,
        allFunds,
        selectedFilters,
        confirm,
        fireStationOptions: {
          totals: omit(legalEntitiesMeta, 'perPage'),
        },
      }),
    [legalEntitiesMeta],
  );

  const handleFilterModelChange = (filterModel: TFilterModel) =>
    setPageParams((prevParams) => ({
      ...prevParams,
      currentPage: 1,
      firePermissions: filterModel.permissions_fire,
    }));

  const handleIncludeAndExclude = async () => {
    const [excludedIds, includedIds] = [
      selectedRows
        .filter((selectedRow) => selectedRow.excluded)
        .map(({ id }) => id),
      selectedRows
        .filter((selectedRow) => !selectedRow.excluded)
        .map(({ id }) => id),
    ];
    await Promise.all([
      excludedIds.length > 0
        ? includeOrExcludeLE({ ids: excludedIds, excluded: false })
        : Promise.resolve(true),
      includedIds.length > 0
        ? includeOrExcludeLE({ ids: includedIds, excluded: true })
        : Promise.resolve(true),
    ]);
    setSelectedRows([]);
    refreshData();
  };

  if (isLegalEntitiablesLoading)
    return <AnimationLoader className="static min-h-[360px]" />;

  const selectedLETabChanged = (newTab: IScopeItem) => {
    setLegalEntitiesScope(newTab.scopeName);
    setSelectedRows([]);
  };

  return (
    <>
      {(bulkPermissionsModalOpened || permissionsModalOpened) && (
        <PermissionsModal
          title="Configure permissions"
          onClose={() => {
            setBulkPermissionsModalOpened(false);
            setPermissionsModalOpened(false);
          }}
          onSubmit={async (newPermissions) => {
            if (bulkPermissionsModalOpened) {
              await http
                .put('/legal_entities/bulk/update_permissions', {
                  ...legalEntityPermissions(newPermissions),
                  legal_entity_ids: selectedRows.map(({ id }) => id),
                })
                .then(() => setSelectedRows([]));
            }

            if (permissionsModalOpened) {
              await http.put(`/legal_entities/${currentLegalEntity.id}`, {
                ...legalEntityPermissions(newPermissions),
              });
            }

            setLegalEntitiesScope(legalEntitiesScope);
            setPermissionsModalOpened(false);
            toastr.success('Permissions have been successfully updated');
          }}
          initialState={
            permissionsModalOpened ? currentLegalEntity?.permitted : null
          }
          initialTab={
            permissionsModalOpened && currentLegalEntity?.permitted.public
              ? 'public'
              : 'restricted'
          }
          productName={ASSET_PORTAL_PRODUCT_NAME}
        />
      )}

      <div>
        <div className="mt-tw-4 flex gap-tw-4" role="tablist">
          <ThinTabGroupWithAmount
            selectedItem={AVAILABLE_SCOPES.find(
              (scope) => legalEntitiesScope === scope.scopeName,
            )}
            onSelectedItemChange={selectedLETabChanged}
            items={AVAILABLE_SCOPES}
          />
        </div>
      </div>

      <div className="mt-tw-4 flex items-center justify-between">
        <div>
          <div className="flex items-center">
            <TablePagination
              loading={legalEntitiesLoading}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalSize={totalSize}
              sizePerPage={pageParams.sizePerPage}
            />
          </div>
        </div>
        <div className="flex items-center">
          <div className="mr-tw-6">
            {/* DEPRECATED: FE-2167 use SearchInput */}
            <TableSearch
              debounceTimeout={500}
              onChange={setSearchQuery}
              leftIconOfInput=""
              rightIconOfInput="search"
              inputPlaceholder="Search"
            />
          </div>
          <Button
            variant="primary"
            onClick={() => setLegalEntityModalOpened({})}
          >
            Add Legal Entity
          </Button>
        </div>
      </div>
      <div className="mt-tw-5">
        <Table
          nothingFoundClasses="mt-tw-[8px]"
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          items={legalEntities}
          columns={tableColumns}
          loading={legalEntitiesLoading}
          settings={pageParams}
          setSettings={setPageParams}
          classes={{ container: 'table-container_legal-entities' }}
          onFilterModelChange={handleFilterModelChange}
        />
      </div>

      {selectedRows.length > 0 && (
        <BulkActionsPanel
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          actions={[
            {
              title: 'Set Object',
              icon: 'edit',
              handleClick: async () => {
                const res = await openModal(SetObjectForLEModal, {
                  assets: allAssets,
                  funds: allFunds,
                  currentLegalEntities: selectedRows,
                });
                if (res) {
                  onBulkSetObject(res);
                } else {
                  onCloseSetObjectModal();
                }
              },
            },
            {
              title: 'Set Class',
              icon: 'edit',
              handleClick: async () => {
                const res = await openModal(LeClassificationModal, {
                  header: (
                    <ModalHeaderForUpdatingLE
                      title="Set class for"
                      currentLegalEntities={selectedRows}
                    />
                  ),
                });
                if (!res) return;

                onBulkSetClass(res);
              },
            },
            {
              title: legalEntitiesScope === 'excluded' ? 'Include' : 'Exclude',
              handleClick: handleIncludeAndExclude,
              icon: legalEntitiesScope === 'excluded' ? 'eye' : 'eyeSlash',
            },
            {
              title: 'Set Permissions',
              icon: 'edit',
              handleClick: () => {
                setBulkPermissionsModalOpened(true);
              },
            },
          ]}
        />
      )}
      {legalEntityModalOpened && (
        <LegalEntityModal
          onSubmitCallback={() => {
            refreshData();
          }}
          setLegalEntityModalOpened={setLegalEntityModalOpened}
          currentLegalEntity={legalEntityModalOpened}
          assets={allAssets}
          funds={allFunds}
        />
      )}
    </>
  );
};
