import { cn } from '@/shared/lib/css/cn';
import { CssVar } from '@/shared/config/cssVar';
import React, { ComponentProps } from 'react';
import { Badge, Tooltip } from 'stories';
import ThinTabItem from 'stories/Tabs/ThinTabItem/ThinTabItem';
import styles from './ThinTabGroup.module.scss';

type BaseID = number | string;

export interface IThinTabItem<ID extends BaseID = BaseID> {
  id: ID;
  label: React.ReactNode;
  value?: React.ReactNode;
  disabled?: boolean;
  resolveHidden?: () => boolean;
  counter?: number;
  tooltipProps?: ComponentProps<typeof Tooltip>;
}

interface Props<ID extends BaseID = BaseID> {
  items: IThinTabItem<ID>[] | readonly IThinTabItem<ID>[];
  selectedItem?: IThinTabItem<ID> | ID;
  onSelectedItemChange?: (selected: IThinTabItem<ID>) => void;
  className?: string;
  classes?: {
    item?: string;
    // todo workaround for tooltip
    itemContainer?: string;
  };
  fullWidth?: boolean;
  disabled?: boolean;
}

const Container = React.forwardRef<
  HTMLDivElement,
  JSX.IntrinsicElements['div']
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn(styles.container, className)} {...props} />
));

export const ThinTabGroup = <ID extends BaseID = BaseID>({
  onSelectedItemChange,
  selectedItem,
  items,
  className,
  classes,
  fullWidth,
  disabled,
}: Props<ID>) => {
  const selectedId =
    typeof selectedItem === 'object' ? selectedItem?.id : selectedItem;
  return (
    <div className={cn(styles.container, className)}>
      {items.map((item) => ( item.resolveHidden?.() ? null :
        <Tooltip
          key={item.id}
          classes={{
            spanContainer: cn(
              'inline-flex',
              fullWidth && 'grow',
              classes?.itemContainer,
            ),
          }}
          disabled={item.tooltipProps ? item.tooltipProps.disabled : true}
          {...item.tooltipProps}
        >
          <ThinTabItem
            className={cn(fullWidth && 'w-full justify-center', classes?.item)}
            disabled={disabled ?? item.disabled}
            onClick={() => onSelectedItemChange?.(item)}
            id={item.id.toString()}
            selected={item.id === selectedId}
          >
            {item.label}
            {item?.counter !== undefined && (
              <Badge
                className={'rounded-4 ml-xs'}
                classes={{ value: 'py-0 px-xs square font-weight-400' }}
                textColor={CssVar.light90}
              >
                {item.counter}
              </Badge>
            )}
          </ThinTabItem>
        </Tooltip>
      ))}
    </div>
  );
};

ThinTabGroup.Container = Container;
ThinTabGroup.Item = ThinTabItem;

export default ThinTabGroup;
