import React from 'react';
import { Control, useController } from 'react-hook-form';
import {
  ExpressionEditor,
  useReportFormulasQuery,
} from 'bundles/Shared/entities/formula';
import { Field, Input } from 'stories';
import { EditFormulaForm } from '../config';
import { useGetApiSettingsReportVariablesQuery } from 'bundles/Shared/shared/api/settingsReportFormulasEnhanced';
import { REFERENCE_FIELD_NOTE } from '@/bundles/Shared/entities/formula/config';

type FormulaFieldProps = {
  control: Control<EditFormulaForm>;
  disabled?: boolean;
};

export const FormulaLabelField = ({ control, disabled }: FormulaFieldProps) => {
  const { field } = useController({
    name: 'label',
    control,
  });
  return (
    <Field labelText="Label">
      <Input size="s" disabled={disabled} {...field} />
    </Field>
  );
};

export const FormulaDescriptionField = ({
  control,
  disabled,
}: FormulaFieldProps) => {
  const { field } = useController({
    name: 'description',
    control,
  });
  return (
    <Field labelText="Description">
      <Input size="s" disabled={disabled} {...field} />
    </Field>
  );
};

export const FormulaReferenceField = ({
  control,
  disabled,
}: FormulaFieldProps) => {
  const { field } = useController({
    name: 'reference',
    control,
  });
  return (
    <Field labelText="Reference" note={REFERENCE_FIELD_NOTE}>
      <Input size="s" disabled={disabled} {...field} />
    </Field>
  );
};

export const FormulaExpressionField = ({
  control,
  errors,
  disabled,
}: FormulaFieldProps &
  Pick<React.ComponentProps<typeof ExpressionEditor>, 'errors'>) => {
  const { formulas } = useReportFormulasQuery();
  const { data: variablesData = [] } = useGetApiSettingsReportVariablesQuery();

  const { field } = useController({
    name: 'expression',
    control,
  });

  return (
    <ExpressionEditor
      options={{
        readOnly: disabled,
      }}
      errors={errors}
      suggestions={[...formulas, ...variablesData].map(
        ({ reference }) => reference,
      )}
      defaultValue=""
      value={field.value}
      onChange={field.onChange}
      height="200px"
    />
  );
};
