import { ComparisonDashboardDto } from '@/bundles/Shared/shared/api/dashboardsGeneratedApi';
import { resolveFinancialTableWidgetDefaultOptionsDate } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/lib';
import { FinancialTableSinglePeriodPeriodType } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/model';
import { FinancialTableSinglePeriodWidgetProps } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/ui';
import { LegalEntity } from '@/entities/core/legalEntity';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { shiftTodayBackward, yesterdayDate } from '@/shared/lib/date';
import { UnknownRecord } from 'type-fest/source/internal';

type BaseState<R extends UnknownRecord> = R & {
  groupingType: 'assets';
  excludedLegalEntityIds?: LegalEntity['id'];
};

export const getInitialComparisonDashboardState = <
  WT extends
    ComparisonDashboardDto['boards'][number]['sections'][number]['widgetType'],
>(
  widgetType: WT,
  widgetSection: FinancialTableSinglePeriodWidgetProps,
):
  | BaseState<{
      date?: DateString;
      periodType?: 'week';
      granularity?: 'day';
    }>
  | BaseState<{
      dateFrom?: DateString;
      dateTo?: DateString;
      periodType?: 'week';
      granularity?: 'day';
    }>
  | BaseState<{
      date?: DateString;
      periodType?: FinancialTableSinglePeriodPeriodType;
    }> => {
  switch (widgetType) {
    case 'kpi_table_single_date': {
      return {
        date: formatToDateStringForRequest(yesterdayDate()),
        groupingType: 'assets',
      };
    }
    case 'kpi_table': {
      return {
        dateFrom: formatToDateStringForRequest(shiftTodayBackward(2, 'day')),
        dateTo: formatToDateStringForRequest(yesterdayDate()),
        groupingType: 'assets',
        periodType: 'week',
      };
    }
    case 'xy_chart_single_kpi': {
      return {
        dateFrom: formatToDateStringForRequest(yesterdayDate()),
        dateTo: formatToDateStringForRequest(yesterdayDate()),
        granularity: 'day',
        groupingType: 'assets',
      };
    }
    case 'financial_table_single_date': {
      return {
        date: resolveFinancialTableWidgetDefaultOptionsDate(
          widgetSection.defaultOptions?.date,
        ),
        groupingType: 'assets',
      };
    }
    case 'financial_table_single_period': {
      return {
        date: resolveFinancialTableWidgetDefaultOptionsDate(
          widgetSection.defaultOptions?.date,
        ),
        periodType: widgetSection?.defaultOptions?.periodType ?? 't3',
        groupingType: 'assets',
      };
    }
    default: {
      return {
        groupingType: 'assets',
      };
    }
  }
};
