import { useState } from 'react';
import { DashboardWidgetEditPanel } from 'bundles/Shared/widgets/dashboard/widgetPanel/ui/DashboardWidgetEditPanel';
import { ScoreboardGridLayout } from 'bundles/Shared/entities/dashboard';

export const useDashboardSectionFocus = () => {
  const [focusedSection, setFocusedSection] = useState<string | null>(null);

  return {
    focusedSection,
    setFocusedSection,
    isSectionFocused: (sectionId: string) => focusedSection === sectionId,
    dashboardLayoutProps: {
      backdrop: focusedSection != null,
    } satisfies Pick<
      React.ComponentProps<typeof ScoreboardGridLayout>,
      'backdrop'
    >,
    widgetEditPanelProps: {
      onDestroy: () => setFocusedSection(null),
      onClickOutside: (e) => {
        setFocusedSection(null);
      },
      onUntrigger: (e) => {
        setFocusedSection(null);
      },
    } satisfies Pick<
      React.ComponentProps<typeof DashboardWidgetEditPanel>,
      'onDestroy' | 'onClickOutside' | 'onUntrigger'
    >,
  };
};
