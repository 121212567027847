import { CellRendererStylesProps } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/BasicCellRenderer';
import { cn } from '@/shared/lib/css/cn';
import { ICellRendererParams } from 'ag-grid-community';
import { CornerArrow } from 'bundles/Shared/components/AgGrid/Table/cellComponents/CornerArrow';
import {
  CellRendererClasses,
  LinkableCellRendererProps,
  NextGenTableContext,
} from 'bundles/Shared/components/AgGrid/Table/types';
import { useAutoGroupExpand } from 'lib/ag-grid/useAutoGroupExpand';
import { expandAllGroupChildren } from 'lib/ag-grid/utils';
import { PropsWithChildren, useCallback } from 'react';
import { IconButton } from 'stories';
import { ConnectorList, ConnectorProps } from '../Connector';
import { CELL_CLASS_NAMES } from '../classNames';
import { getColorClassName } from '../utils/getColorClassName';
import { getNodeFlags } from '../utils/getNodeFlags';
import stylesFromCssModule from './styles.module.scss';

const {
  AutoGroupCell: { wrapper: WRAPPER_CLASSNAMES, inner: INNER_CLASSNAMES },
} = CELL_CLASS_NAMES;

export type BasicAutoGroupCellRendererProps = CellRendererClasses &
  CellRendererStylesProps &
  LinkableCellRendererProps & {
    disableDeepExpandButton?: boolean;
  };

/**
 * @param withoutFooter - if true, connector list component will not handle footer cases for rendering connectors.
 * Enable this flag when "groupIncludeTotalFooter" & "groupIncludeFooter" props were disabled at "NextGenTable" component.
 */
export function BasicAutoGroupCellRenderer({
  children,
  classes,
  styles,
  linkable,
  disableDeepExpandButton = false,
  ...params
}: ICellRendererParams & PropsWithChildren & BasicAutoGroupCellRendererProps) {
  const { node } = params;
  const context = params.context as NextGenTableContext;
  const isExpandable = node.isExpandable();

  const [isExpanded] = useAutoGroupExpand(params);

  const handleDeepExpand = useCallback(() => {
    expandAllGroupChildren(node, !isExpanded);
  }, [node, isExpanded]);

  return (
    <div
      className={cn(
        CELL_CLASS_NAMES.AutoGroupCell.wrapper.default,
        (isExpandable || linkable) && 'cursor-pointer',
        classes?.wrapper ?? WRAPPER_CLASSNAMES.basic,
      )}
      style={styles?.wrapper}
    >
      {isExpandable && !disableDeepExpandButton && (
        <IconButton
          onClick={handleDeepExpand}
          iconName={isExpanded ? 'doubleArrowBottom' : 'doubleArrowTop'}
          className={cn(stylesFromCssModule.expandAllGroupChildrenBtn)}
        />
      )}
      <div
        className={cn(
          INNER_CLASSNAMES.default,
          classes?.inner ?? INNER_CLASSNAMES.basic,
        )}
        style={styles?.inner}
      >
        <ConnectorList
          node={node}
          expanded={isExpanded}
          withoutFooter={!context.options.groupIncludeFooter}
        />
        {children}
        <CornerArrow.Svg className={cn(!linkable && 'hidden')} />
      </div>
    </div>
  );
}

export type AutoGroupCellRendererProps = Pick<ConnectorProps, 'withoutFooter'>;

export function AutoGroupCellRenderer(
  params: ICellRendererParams &
    AutoGroupCellRendererProps &
    CellRendererStylesProps,
) {
  const { node, value } = params;

  const rowBooleans = getNodeFlags(node);
  const wrapperColorClassName = getColorClassName(
    WRAPPER_CLASSNAMES,
    rowBooleans,
  );
  const innerColorClassName = getColorClassName(INNER_CLASSNAMES, rowBooleans);

  return (
    <BasicAutoGroupCellRenderer
      classes={{
        wrapper: wrapperColorClassName,
        inner: innerColorClassName,
      }}
      {...params}
    >
      {value}
    </BasicAutoGroupCellRenderer>
  );
}
