import { WidgetSettingsListLayout } from '@/bundles/Shared/components/layouts/dashboard/WidgetLayout';
import { ComparisonDashboardSectionTypes } from '@/bundles/Shared/entities/dashboard';
import { financialTableUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/config/updaters';
import { FinancialTableWidgetActions } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/ui/financialTableWidgetColumnGroups/model';
import {
  useUpdateWidgetConfig,
  useWidgetConfig,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib';
import { createMapByKey } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import { DEFAULT_GROUP_TEXT_CLASS } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui';
import { FinancialTableSingleDateWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate';
import { FinancialTableSinglePeriodWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/model';
import {
  GROUPS_DROPPABLE_ID,
  buildDraggableId,
  getIdFromDraggableId,
} from '@/bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { cn } from '@/shared/lib/css/cn';
import { Dropdown, Icon, IconButton } from '@/stories';
import {
  Draggable,
  DraggableProvided,
  OnDragEndResponder,
} from '@hello-pangea/dnd';
import styles from 'bundles/Shared/components/layouts/dashboard/WidgetLayout.module.scss';
import { groupBy } from 'lodash-es';
import React, { useCallback } from 'react';

export const useFinancialTableVizConfig = <
  Section extends
    | FinancialTableSingleDateWidgetSection
    | FinancialTableSinglePeriodWidgetSection,
>({
  columns,
  vizConfig,
}: {
  columns: Section['widgetConfig']['columns'];
  vizConfig: Section['widgetConfig']['viz_config'];
}) => {
  type VizConfig = {
    columnGroups: (NonNullable<
      NonNullable<Section['widgetConfig']['viz_config']>['column_groups']
    >[number] & {
      children: NonNullable<
        NonNullable<Section['widgetConfig']['viz_config']>['columns']
      >;
    })[];
  };

  return React.useMemo<VizConfig>(() => {
    const vizConfigColumnsMap = createMapByKey(vizConfig?.columns ?? []);

    const dataAndVizConfigColumns = columns.map((col) => {
      if (!vizConfigColumnsMap.has(String(col.key))) return col;
      return {
        ...col,
        ...vizConfigColumnsMap.get(String(col.key))!,
      };
    });
    const groupedColumns = groupBy(dataAndVizConfigColumns ?? [], 'group_id');

    const columnGroups =
      vizConfig?.column_groups?.map((g) => {
        const children = groupedColumns[g.group_id] ?? [];
        return {
          ...g,
          children,
        };
      }) ?? [];

    return {
      columnGroups,
    };
  }, [vizConfig, columns]);
};

export const useDragEndResponder = () => {
  const { widget } = useWidgetConfig<ComparisonDashboardSectionTypes>();
  const [updateConfig] = useUpdateWidgetConfig(widget.widgetType);

  return React.useCallback<OnDragEndResponder>(
    ({ source, destination, draggableId }) => {
      if (!destination) return;
      const config =
        source.droppableId === GROUPS_DROPPABLE_ID
          ? financialTableUpdaters.moveGroup(
              {
                fromIndex: source.index,
                toIndex: destination.index,
              },
              widget.widgetConfig,
            )
          : financialTableUpdaters.moveColumn(
              {
                fromIndex: source.index,
                toIndex: destination.index,
                sourceGroupId: source.droppableId,
                targetGroupId: destination.droppableId,
                columnId: getIdFromDraggableId(draggableId),
              },
              widget.widgetConfig,
            );
      updateConfig({
        config,
      });
    },
    [widget],
  );
};

export const useRenderGroupCallback = <
  Section extends
    | FinancialTableSingleDateWidgetSection
    | FinancialTableSinglePeriodWidgetSection,
>(
  actions: FinancialTableWidgetActions,
) => {
  type VizConfig = ReturnType<typeof useFinancialTableVizConfig<Section>>;

  const getRenderGroupContent = useCallback(
    (group: VizConfig['columnGroups'][number]) => {
      const groupId = group.group_id.toString();

      return (provided: DraggableProvided) => (
        <WidgetSettingsListLayout.SortableList droppableId={groupId}>
          <WidgetSettingsListLayout.ListItem
            checked={!group.hidden}
            onChange={() => {
              actions.group.onHide({ groupId });
            }}
          >
            <Icon
              className={styles.sortableItem__dragHandle}
              iconName="move"
              {...provided.dragHandleProps}
            />
            <span className={cn('inline-semibold', DEFAULT_GROUP_TEXT_CLASS)}>
              {group.header_name}
            </span>
            <div className="grow" />
            <WidgetSettingsListLayout.ListItem.ActionsPanel>
              <Dropdown
                className="sre-dropdown-v2"
                items={
                  <>
                    <Dropdown.Item
                      iconName="edit"
                      onClick={() => {
                        actions.group.onEdit({
                          groupId,
                          headerName: group.header_name,
                        });
                      }}
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      iconName="trash"
                      onClick={() => {
                        actions.group.onRemove({
                          groupId,
                        });
                      }}
                    >
                      Remove
                    </Dropdown.Item>
                  </>
                }
              >
                <IconButton iconName="more" />
              </Dropdown>
              <IconButton
                onClick={() => {
                  actions.column.onAdd({
                    groupId,
                  });
                }}
                iconName="addSmall"
              />
            </WidgetSettingsListLayout.ListItem.ActionsPanel>
          </WidgetSettingsListLayout.ListItem>
          {group.children
            .filter((c) => c != null)
            .map((column, colIndex) => (
              <WidgetSettingsListLayout.SortableListItem
                draggableId={buildDraggableId(column.key.toString(), 'column')}
                index={colIndex}
                listIndex={colIndex}
                key={column.key}
                checked={!column.hidden}
                onChange={() => {
                  actions.column.onHide({
                    columnId: column.col_id,
                  });
                }}
              >
                <WidgetSettingsListLayout.ListItem.Text>
                  {column.label}
                </WidgetSettingsListLayout.ListItem.Text>
                <div className="grow" />
                <WidgetSettingsListLayout.ListItem.ActionsPanel>
                  <IconButton
                    iconName="edit"
                    onClick={() => {
                      actions.column.onEdit({
                        columnConfig: column,
                        groupId,
                      });
                    }}
                  />
                  <IconButton
                    iconName="copy"
                    onClick={() => {
                      actions.column.onClone({
                        columnId: column.key.toString(),
                        groupId,
                      });
                    }}
                  />
                  <IconButton
                    iconName="trash"
                    onClick={() => {
                      actions.column.onRemove({
                        columnId: column.key.toString(),
                        groupId,
                      });
                    }}
                  />
                </WidgetSettingsListLayout.ListItem.ActionsPanel>
              </WidgetSettingsListLayout.SortableListItem>
            ))}
        </WidgetSettingsListLayout.SortableList>
      );
    },
    [actions],
  );

  return useCallback(
    (group: VizConfig['columnGroups'][number], index: number) => {
      const renderGroupContent = getRenderGroupContent(group);
      const groupId = group.group_id.toString();

      return (
        <Draggable
          key={groupId}
          draggableId={buildDraggableId(groupId, 'group')}
          index={index}
        >
          {(provided) => (
            <div ref={provided.innerRef} {...provided.draggableProps}>
              {renderGroupContent(provided)}
            </div>
          )}
        </Draggable>
      );
    },
    [getRenderGroupContent],
  );
};
