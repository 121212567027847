import React, { useMemo } from 'react';
import {
  AnimationLoader,
  CurrencyFormatter,
  Modal,
  ModalHeaderWithSubtitle,
} from '@/stories';
import {
  distributionKindFormatter,
  InvestmentClassLabel,
  ReturnInfoCard,
} from '@/entities/return/ui';
import { formatDate } from '@/shared/lib/formatting/dates';
import FullWhiteTable from 'bundles/Shared/components/Table/variants/FullWhiteTable';
import { capitalize, sumBy } from 'lodash-es';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import {
  useGetApiPortfolioContributionsByIdQuery,
  useGetApiPortfolioDistributionsByIdQuery,
} from 'bundles/Shared/shared/api/portfolioEnhancedApi';
import { IColumn } from 'bundles/Shared/components/Table/types';
import {
  ContributionDto,
  DistributionDto,
} from 'bundles/Shared/shared/api/portfolioGeneratedApi';
import { formatDateByPeriodType } from '@/entities/return/capital/lib';
import { convertCentsToDollars } from '@/shared/lib/converters';

interface Props extends DialogProps {
  kind: 'distribution' | 'contribution';
  id: number;
}

export function PortfolioTransactionDetailsModal({ kind, id, onClose }: Props) {
  const query =
    kind === 'contribution'
      ? useGetApiPortfolioContributionsByIdQuery
      : useGetApiPortfolioDistributionsByIdQuery;

  const columns = useMemo<IColumn<DistributionDto | ContributionDto>[]>(() => {
    if (kind === 'distribution') {
      return [
        {
          dataField: 'kind',
          text: 'Kind',
          formatter: distributionKindFormatter,
        },
        {
          dataField: 'amountCents',
          text: 'Amount',
          formatter: ({ row }) => {
            const distribution = row as DistributionDto;
            return (
              <CurrencyFormatter
                value={
                  distribution.splitItems.length > 0
                    ? sumBy(distribution.splitItems, 'amount')
                    : convertCentsToDollars(row.amountCents)
                }
              />
            );
          },
        },
      ];
    }
    return [
      {
        dataField: 'investmentClass',
        text: 'Class',
        formatter: ({ row }) => (
          <InvestmentClassLabel
            withClassPrefix
            investmentClass={(row as ContributionDto).investmentClass}
          />
        ),
      },
      {
        dataField: 'amountCents',
        text: 'Amount',
        formatter: ({ row }) => (
          <CurrencyFormatter value={convertCentsToDollars(row.amountCents)} />
        ),
      },
    ];
  }, [kind]);

  const { data, isLoading } = query({
    id,
  });

  return (
    <Modal
      toggle={onClose}
      size="700"
      classes={{
        body: 'flex flex-col gap-tw-2 min-h-[200px]',
      }}
      header={
        <ModalHeaderWithSubtitle
          title={capitalize(kind)}
          subtitle={data?.investmentEntityName ?? ''}
          order="title-subtitle"
        />
      }
    >
      {isLoading && <AnimationLoader />}
      {data && (
        <>
          <ReturnInfoCard>
            <ReturnInfoCard.Part label={data.investmentObjectType}>
              {data.investmentObjectName}
            </ReturnInfoCard.Part>
            <ReturnInfoCard.Part label="Date">
              {formatDate(data.date as DateString, 'MMM DD, YYYY')}
            </ReturnInfoCard.Part>
            {kind === 'distribution' && (
              <ReturnInfoCard.Part label="Period">
                {formatDateByPeriodType(
                  (data as DistributionDto).periodType,
                  data.date as DateString,
                )}
              </ReturnInfoCard.Part>
            )}
          </ReturnInfoCard>

          <FullWhiteTable<ContributionDto | DistributionDto>
            columns={columns}
            items={data ? [data] : []}
          />
        </>
      )}
    </Modal>
  );
}
