import { ICellEditorParams } from 'ag-grid-community';
import {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
} from 'react';
import styles from './styles.module.scss';
import { cn } from '@/shared/lib/css/cn';
import NumberFormat from 'react-number-format';

interface Props extends ICellEditorParams {
  height: number;
  value: string | number | null | undefined;
}

const MIN_DOLLAR_DECIMALS = 2;
const MAX_POSSIBLE_VALUE = 999_999_999_999;

// Docs link https://www.ag-grid.com/react-data-grid/component-cell-editor/
export const CurrencyCellEditor = forwardRef<unknown, Props>((props, ref) => {
  const [value, setValue] = useState<string | null>(() => {
    const notUndefined = props.value ?? null;
    if (typeof notUndefined === 'number') {
      return String(notUndefined);
    }
    return notUndefined;
  });
  const refInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    refInput?.current?.focus();
    refInput?.current?.select();
  }, []);

  useImperativeHandle(ref, () => ({
    getValue() {
      return value;
    },
  }));

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    const { value: newValue } = target;

    setValue(newValue === '' ? null : newValue);
  };

  return (
    <NumberFormat
      getInputRef={refInput}
      className={cn(
        styles.input,
        'inline-regular h-full w-full px-tw-2 text-right',
      )}
      decimalSeparator="."
      maxLength={18}
      max={MAX_POSSIBLE_VALUE}
      min={MAX_POSSIBLE_VALUE}
      thousandSeparator=","
      decimalScale={MIN_DOLLAR_DECIMALS}
      value={value}
      onChange={handleChange}
    />
  );
});
