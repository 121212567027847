import FireIcon from '@/bundles/FireStation/components/FireIcon/FireIcon';
import NavigationCardList from '@/bundles/Shared/components/layouts/screenWithListNavigationLayout/NavigationCardList';
import {
  PageParamsProvider,
  PageParamsSearch,
} from '@/bundles/Shared/components/pageParams';
import {
  FormulaCard,
  FormulaInUse,
  filterFormulaOrVariableByTags,
} from '@/bundles/Shared/entities/formula';
import { LegalEntitiesCounter } from '@/bundles/Shared/entities/legalEntity/ui/LegalEntityCounter';
import {
  GetApiReportManualVariablesParams,
  addManualVariableReferencePrefix,
  useReportManualVariableQuery,
} from '@/entities/report/manualVariable';
import {
  CreateManualVariable,
  FilterByFire,
} from '@/features/report/manualVariable';
import { currentUserIsSreAdmin } from '@/lib/permissions';
import { cn } from '@/shared/lib/css/cn';
import { useNavigateToFirst } from '@/shared/lib/hooks/useNavigateToFirst';
import { ROUTES_ROOT, generateUrl } from '@/shared/lib/hooks/useNavigation';
import { ItemTableCounter } from '@/shared/ui/ItemTableCounter';
import { ResizablePanel } from '@/shared/ui/Resizable';
import { ManageTagsButton } from '@/widgets/report/manageTags';
import {
  ManualVariablesList,
  SettingsReportManualVariables,
} from '@/widgets/report/manualVariable/settings';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { UntaggedFormulaTag } from 'bundles/Shared/entities/formula/config';
import { EntityTag, TAG_ENTITIES } from 'bundles/Shared/entities/tag';
import { EntityTagFilterDropdown } from 'bundles/Shared/features/tag/filter';
import { FormulaPopover } from 'bundles/Shared/widgets/formula/panel/ui/FormulaAndVariablesPanel';
import { FC, useMemo, useState } from 'react';

export const ManualVariablesPage: FC<RouteComponentProps> = () => {
  const [pageParams, setPageParams] = useState<
    Required<GetApiReportManualVariablesParams>
  >({
    query: '',
    filterByNotConfigured: false,
  });
  const [selectedTags, setSelectedTags] = useState<
    (EntityTag | UntaggedFormulaTag)[]
  >([]);
  const { manualVariables, meta, isLoading, isFetching } =
    useReportManualVariableQuery(pageParams);
  const navigate = useNavigate();

  const { notFullyConfiguredVariables } = meta;

  const handleFilterByNotConfiguredChange = (val: boolean) => {
    setPageParams((prev) => ({
      ...prev,
      filterByNotConfigured: val,
    }));
  };

  const toggleFilterByNotConfigured = () => {
    handleFilterByNotConfiguredChange(!pageParams.filterByNotConfigured);
  };

  const filteredManualVariables = useMemo(() => {
    return manualVariables.filter((manVar) => {
      return (
        selectedTags.length === 0 ||
        filterFormulaOrVariableByTags(manVar, selectedTags)
      );
    });
  }, [manualVariables, selectedTags]);

  const items = filteredManualVariables;

  const { idFromURL: manualVariableIdFromURL } = useNavigateToFirst({
    items,
    matchParamKey: 'manualVariableId',
    path: ROUTES_ROOT.settings.report.manualVariables.manualVariable.fullPath,
  });

  const selectedManVar = useMemo(() => {
    return manualVariables.find(({ id }) => id === manualVariableIdFromURL);
  }, [items, manualVariableIdFromURL]);

  return (
    <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
      <div className="flex">
        <ResizablePanel.Group
          autoSaveId="manual-variables-panel"
          direction="horizontal"
        >
          <ResizablePanel className="min-w-[320px] max-w-[800px]">
            <div
              className={cn(
                'flex max-h-screen min-h-screen flex-col bg-neutral-000',
                'border-r border-solid border-neutral-200',
              )}
            >
              <div className="flex flex-col gap-tw-4 px-tw-4 pb-tw-3 pt-tw-5">
                <div className="flex items-center justify-between">
                  <p className="header5-bold text-neutral-900">
                    Manual Variables
                  </p>

                  {currentUserIsSreAdmin() && <CreateManualVariable />}
                </div>
                <PageParamsSearch
                  suggestions={['Variable Name', 'Reference']}
                  inputClassName="!bg-transparent"
                />
                <EntityTagFilterDropdown
                  onChange={setSelectedTags}
                  value={selectedTags}
                  entity={TAG_ENTITIES.MANUAL_VARIABLE}
                  panel={
                    currentUserIsSreAdmin() && (
                      <ManageTagsButton entity={TAG_ENTITIES.MANUAL_VARIABLE} />
                    )
                  }
                />
                <div className="flex items-center justify-between">
                  <ItemTableCounter count={items.length} word="Variable" />
                  {notFullyConfiguredVariables > 0 && (
                    <FilterByFire
                      onClick={() => toggleFilterByNotConfigured()}
                      onClose={() => handleFilterByNotConfiguredChange(false)}
                      count={notFullyConfiguredVariables}
                      filtered={pageParams.filterByNotConfigured}
                    />
                  )}
                </div>
              </div>
              <NavigationCardList>
                <ManualVariablesList
                  isFetching={isFetching}
                  isLoading={isLoading}
                >
                  {items.map((v) => (
                    <FormulaCard
                      className="!cursor-pointer"
                      reference={addManualVariableReferencePrefix(v.name)}
                      label={v.label}
                      key={v.id}
                      description={v.description}
                      tags={v.tags}
                      labelInfo={
                        <div className="flex items-center gap-tw-2">
                          {v.notConfiguredLegalEntitiesCount > 0 && (
                            <FireIcon priority="high" />
                          )}
                          {v.referencedInEntities.length > 0 && (
                            <FormulaPopover
                              formula={{
                                referencedInEntities: v.referencedInEntities,
                                label: v.label,
                                invalidReferences: [],
                              }}
                            >
                              <div className="flex items-center gap-tw-1">
                                <FormulaInUse.UsedSvg
                                  viewBox="4 4 16 16"
                                  className="h-[12px] w-[12px]"
                                  used
                                />
                                {v.referencedInEntities.length > 1 && (
                                  <span
                                    className={
                                      'secondary-regular text-neutral-500'
                                    }
                                  >
                                    {v.referencedInEntities.length}
                                  </span>
                                )}
                              </div>
                            </FormulaPopover>
                          )}
                          <LegalEntitiesCounter
                            legalEntityCount={v.configuredLegalEntitiesCount}
                          />
                        </div>
                      }
                      selected={v.id === manualVariableIdFromURL}
                      buttonProps={{
                        onClick: () => {
                          navigate(
                            generateUrl(
                              ROUTES_ROOT.settings.report.manualVariables
                                .manualVariable.fullPath,
                              {
                                pathParams: {
                                  manualVariableId: v.id,
                                },
                              },
                            ),
                          );
                        },
                      }}
                    />
                  ))}
                </ManualVariablesList>
              </NavigationCardList>
            </div>
          </ResizablePanel>
          <ResizablePanel.Handle withHandle />
          <ResizablePanel>
            {selectedManVar && (
              <SettingsReportManualVariables
                path={
                  ROUTES_ROOT.settings.report.manualVariables.manualVariable
                    .fullPath
                }
                manualVariable={selectedManVar}
              />
            )}
          </ResizablePanel>
        </ResizablePanel.Group>
      </div>
    </PageParamsProvider>
  );
};
