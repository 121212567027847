import {
  maxIdGenerator,
  transformPeriodShiftFormToDto,
  transformPeriodTypeFormToDto,
} from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { transformFinancialTableWidgetAdjustmentFormToConfig } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/config/column.form';
import { financialTableUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/config/updaters';
import { FinancialTableSingleDateWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/model';
import { FinancialTableSinglePeriodWidgetConfigColumnForm } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/config/column.form';
import { produce } from 'immer';

const upsertColumn = (
  {
    columnId,
    columnForm: column,
    groupId,
  }: {
    groupId: string;
    columnForm: FinancialTableSinglePeriodWidgetConfigColumnForm;
    columnId?: string;
  },
  config: FinancialTableSingleDateWidgetSection['widgetConfig'],
): FinancialTableSingleDateWidgetSection['widgetConfig'] => {
  return produce(config, (draft) => {
    let columnIndex = draft.columns.findIndex(
      (c) => c.key.toString() === String(columnId),
    );
    if (columnIndex === -1) {
      columnIndex = draft.columns.length;
    }
    const columnKey = String(
      column.key ?? maxIdGenerator(draft.columns, 'key'),
    );

    draft.columns[columnIndex] = {
      key: Number(columnKey),
      label: column.label,
      period_shift: transformPeriodShiftFormToDto(
        column.period_shift ?? undefined,
      ),
      period_type: transformPeriodTypeFormToDto(column.period_type),
      ...transformFinancialTableWidgetAdjustmentFormToConfig(column),
    };

    let vizConfigColumnIndex = draft.viz_config!.columns!.findIndex(
      (c) => c.key.toString() === columnId,
    );
    if (vizConfigColumnIndex === -1) {
      vizConfigColumnIndex = draft.viz_config!.columns!.length;
    }

    draft.viz_config!.columns![vizConfigColumnIndex] = {
      key: columnKey,
      col_id: columnKey,
      group_id: groupId,
      header: column.header,
      order: vizConfigColumnIndex,
      hidden: column.hidden,
      // @ts-expect-error "yup" is not smart enough to infer "precision" type from schema
      value_display_options: column.value_display_options ?? undefined,
    };
  });
};

export const financialTableSingleDateUpdaters = {
  ...financialTableUpdaters,
  upsertColumn,
};
