import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSections:
      build.query<
        GetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiResponse,
        GetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/comparison_dashboards/${queryArg.comparisonDashboardId}/boards/${queryArg.boardId}/copyable_widget_sections`,
        }),
      }),
    postApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoard:
      build.mutation<
        PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardApiResponse,
        PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/comparison_dashboards/${queryArg.comparisonDashboardId}/boards/${queryArg.boardId}/copyable_widget_sections/copy_to_board`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    postApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSections:
      build.mutation<
        PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsApiResponse,
        PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/comparison_dashboards/${queryArg.comparisonDashboardId}/boards/${queryArg.boardId}/widget_sections`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    getApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsId:
      build.query<
        GetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse,
        GetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/comparison_dashboards/${queryArg.comparisonDashboardId}/boards/${queryArg.boardId}/widget_sections/${queryArg.id}`,
        }),
      }),
    putApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsId:
      build.mutation<
        PutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse,
        PutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/comparison_dashboards/${queryArg.comparisonDashboardId}/boards/${queryArg.boardId}/widget_sections/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    deleteApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsId:
      build.mutation<
        DeleteApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse,
        DeleteApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/comparison_dashboards/${queryArg.comparisonDashboardId}/boards/${queryArg.boardId}/widget_sections/${queryArg.id}`,
          method: 'DELETE',
          body: queryArg.body,
        }),
      }),
    postApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsPreview:
      build.query<
        PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiResponse,
        PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/comparison_dashboards/${queryArg.comparisonDashboardId}/boards/${queryArg.boardId}/widget_sections/preview`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    putApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsMove:
      build.mutation<
        PutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsMoveApiResponse,
        PutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsMoveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/comparison_dashboards/${queryArg.comparisonDashboardId}/boards/${queryArg.boardId}/widget_sections/move`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    putApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoard:
      build.mutation<
        PutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardApiResponse,
        PutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/comparison_dashboards/${queryArg.comparisonDashboardId}/boards/${queryArg.boardId}/widget_sections/${queryArg.id}/move_to_board`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    postApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoards:
      build.mutation<
        PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsApiResponse,
        PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/comparison_dashboards/${queryArg.comparisonDashboardId}/boards`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    getApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardId:
      build.query<
        GetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdApiResponse,
        GetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/comparison_dashboards/${queryArg.comparisonDashboardId}/boards/${queryArg.boardId}`,
        }),
      }),
    putApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardId:
      build.mutation<
        PutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdApiResponse,
        PutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/comparison_dashboards/${queryArg.comparisonDashboardId}/boards/${queryArg.boardId}`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    deleteApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardId:
      build.mutation<
        DeleteApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdApiResponse,
        DeleteApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/comparison_dashboards/${queryArg.comparisonDashboardId}/boards/${queryArg.boardId}`,
          method: 'DELETE',
        }),
      }),
    postApiSettingsReportComparisonDashboards: build.mutation<
      PostApiSettingsReportComparisonDashboardsApiResponse,
      PostApiSettingsReportComparisonDashboardsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/comparison_dashboards`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiSettingsReportComparisonDashboardsById: build.mutation<
      PutApiSettingsReportComparisonDashboardsByIdApiResponse,
      PutApiSettingsReportComparisonDashboardsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/comparison_dashboards/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getApiSettingsReportComparisonDashboardsById: build.query<
      GetApiSettingsReportComparisonDashboardsByIdApiResponse,
      GetApiSettingsReportComparisonDashboardsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/comparison_dashboards/${queryArg.id}`,
      }),
    }),
    deleteApiSettingsReportComparisonDashboardsById: build.mutation<
      DeleteApiSettingsReportComparisonDashboardsByIdApiResponse,
      DeleteApiSettingsReportComparisonDashboardsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/comparison_dashboards/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiSettingsReportComparisonDashboardsMeta: build.query<
      GetApiSettingsReportComparisonDashboardsMetaApiResponse,
      GetApiSettingsReportComparisonDashboardsMetaApiArg
    >({
      query: () => ({ url: `/api/settings/report/comparison_dashboards/meta` }),
    }),
    getApiSettingsReportComparisonDashboardsByIdSubjectables: build.query<
      GetApiSettingsReportComparisonDashboardsByIdSubjectablesApiResponse,
      GetApiSettingsReportComparisonDashboardsByIdSubjectablesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/comparison_dashboards/${queryArg.id}/subjectables`,
      }),
    }),
    postApiSettingsReportComparisonDashboardsDuplicate: build.mutation<
      PostApiSettingsReportComparisonDashboardsDuplicateApiResponse,
      PostApiSettingsReportComparisonDashboardsDuplicateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/comparison_dashboards/duplicate`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiSettingsReportDashboards: build.query<
      GetApiSettingsReportDashboardsApiResponse,
      GetApiSettingsReportDashboardsApiArg
    >({
      query: () => ({ url: `/api/settings/report/dashboards` }),
    }),
    getApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSections:
      build.query<
        GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiResponse,
        GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/boards/${queryArg.boardId}/copyable_widget_sections`,
        }),
      }),
    postApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoard:
      build.mutation<
        PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardApiResponse,
        PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/boards/${queryArg.boardId}/copyable_widget_sections/copy_to_board`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    postApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSections:
      build.mutation<
        PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsApiResponse,
        PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/boards/${queryArg.boardId}/scorecard_sections`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    getApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsId:
      build.query<
        GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsIdApiResponse,
        GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/boards/${queryArg.boardId}/scorecard_sections/${queryArg.id}`,
        }),
      }),
    putApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsId:
      build.mutation<
        PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsIdApiResponse,
        PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/boards/${queryArg.boardId}/scorecard_sections/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    deleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsId:
      build.mutation<
        DeleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsIdApiResponse,
        DeleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/boards/${queryArg.boardId}/scorecard_sections/${queryArg.id}`,
          method: 'DELETE',
          body: queryArg.body,
        }),
      }),
    putApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsMove:
      build.mutation<
        PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsMoveApiResponse,
        PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsMoveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/boards/${queryArg.boardId}/scorecard_sections/move`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    putApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsIdMoveToBoard:
      build.mutation<
        PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsIdMoveToBoardApiResponse,
        PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsIdMoveToBoardApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/boards/${queryArg.boardId}/scorecard_sections/${queryArg.id}/move_to_board`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    postApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSections:
      build.mutation<
        PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsApiResponse,
        PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/boards/${queryArg.boardId}/widget_sections`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    getApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsId:
      build.query<
        GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse,
        GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/boards/${queryArg.boardId}/widget_sections/${queryArg.id}`,
        }),
      }),
    putApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsId:
      build.mutation<
        PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse,
        PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/boards/${queryArg.boardId}/widget_sections/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    deleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsId:
      build.mutation<
        DeleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse,
        DeleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/boards/${queryArg.boardId}/widget_sections/${queryArg.id}`,
          method: 'DELETE',
          body: queryArg.body,
        }),
      }),
    postApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsPreview:
      build.query<
        PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiResponse,
        PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/boards/${queryArg.boardId}/widget_sections/preview`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    putApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsMove:
      build.mutation<
        PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsMoveApiResponse,
        PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsMoveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/boards/${queryArg.boardId}/widget_sections/move`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    putApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoard:
      build.mutation<
        PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardApiResponse,
        PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/boards/${queryArg.boardId}/widget_sections/${queryArg.id}/move_to_board`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    postApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoards:
      build.mutation<
        PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsApiResponse,
        PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/boards`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    getApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardId:
      build.query<
        GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdApiResponse,
        GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/boards/${queryArg.boardId}`,
        }),
      }),
    putApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardId:
      build.mutation<
        PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdApiResponse,
        PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/boards/${queryArg.boardId}`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    deleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardId:
      build.mutation<
        DeleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdApiResponse,
        DeleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/eagle_eye_dashboards/${queryArg.eagleEyeDashboardId}/boards/${queryArg.boardId}`,
          method: 'DELETE',
        }),
      }),
    getApiSettingsReportEagleEyeDashboardsFilterSets: build.query<
      GetApiSettingsReportEagleEyeDashboardsFilterSetsApiResponse,
      GetApiSettingsReportEagleEyeDashboardsFilterSetsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/eagle_eye_dashboards/filter_sets`,
        params: { eagle_eye_scoreboard_id: queryArg.eagleEyeScoreboardId },
      }),
    }),
    postApiSettingsReportEagleEyeDashboardsFilterSets: build.mutation<
      PostApiSettingsReportEagleEyeDashboardsFilterSetsApiResponse,
      PostApiSettingsReportEagleEyeDashboardsFilterSetsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/eagle_eye_dashboards/filter_sets`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiSettingsReportEagleEyeDashboardsFilterSetsById: build.mutation<
      PutApiSettingsReportEagleEyeDashboardsFilterSetsByIdApiResponse,
      PutApiSettingsReportEagleEyeDashboardsFilterSetsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/eagle_eye_dashboards/filter_sets/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiSettingsReportEagleEyeDashboardsFilterSetsById: build.mutation<
      DeleteApiSettingsReportEagleEyeDashboardsFilterSetsByIdApiResponse,
      DeleteApiSettingsReportEagleEyeDashboardsFilterSetsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/eagle_eye_dashboards/filter_sets/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiSettingsReportEagleEyeDashboardsFilterSetsMeta: build.query<
      GetApiSettingsReportEagleEyeDashboardsFilterSetsMetaApiResponse,
      GetApiSettingsReportEagleEyeDashboardsFilterSetsMetaApiArg
    >({
      query: () => ({
        url: `/api/settings/report/eagle_eye_dashboards/filter_sets/meta`,
      }),
    }),
    getApiSettingsReportEagleEyeDashboardsMeta: build.query<
      GetApiSettingsReportEagleEyeDashboardsMetaApiResponse,
      GetApiSettingsReportEagleEyeDashboardsMetaApiArg
    >({
      query: () => ({ url: `/api/settings/report/eagle_eye_dashboards/meta` }),
    }),
    getApiSettingsReportEagleEyeDashboards: build.query<
      GetApiSettingsReportEagleEyeDashboardsApiResponse,
      GetApiSettingsReportEagleEyeDashboardsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/eagle_eye_dashboards`,
        params: { query: queryArg.query },
      }),
    }),
    postApiSettingsReportEagleEyeDashboards: build.mutation<
      PostApiSettingsReportEagleEyeDashboardsApiResponse,
      PostApiSettingsReportEagleEyeDashboardsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/eagle_eye_dashboards`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiSettingsReportEagleEyeDashboardsById: build.query<
      GetApiSettingsReportEagleEyeDashboardsByIdApiResponse,
      GetApiSettingsReportEagleEyeDashboardsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/eagle_eye_dashboards/${queryArg.id}`,
      }),
    }),
    putApiSettingsReportEagleEyeDashboardsById: build.mutation<
      PutApiSettingsReportEagleEyeDashboardsByIdApiResponse,
      PutApiSettingsReportEagleEyeDashboardsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/eagle_eye_dashboards/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiSettingsReportEagleEyeDashboardsById: build.mutation<
      DeleteApiSettingsReportEagleEyeDashboardsByIdApiResponse,
      DeleteApiSettingsReportEagleEyeDashboardsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/eagle_eye_dashboards/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiSettingsReportEagleEyeDashboardsByIdSubjectables: build.query<
      GetApiSettingsReportEagleEyeDashboardsByIdSubjectablesApiResponse,
      GetApiSettingsReportEagleEyeDashboardsByIdSubjectablesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/eagle_eye_dashboards/${queryArg.id}/subjectables`,
      }),
    }),
    postApiSettingsReportEagleEyeDashboardsDuplicate: build.mutation<
      PostApiSettingsReportEagleEyeDashboardsDuplicateApiResponse,
      PostApiSettingsReportEagleEyeDashboardsDuplicateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/eagle_eye_dashboards/duplicate`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSections:
      build.query<
        GetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiResponse,
        GetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/object_dashboards/${queryArg.objectDashboardId}/boards/${queryArg.boardId}/copyable_widget_sections`,
        }),
      }),
    postApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoard:
      build.mutation<
        PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardApiResponse,
        PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/object_dashboards/${queryArg.objectDashboardId}/boards/${queryArg.boardId}/copyable_widget_sections/copy_to_board`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    postApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSections:
      build.mutation<
        PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsApiResponse,
        PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/object_dashboards/${queryArg.objectDashboardId}/boards/${queryArg.boardId}/widget_sections`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    getApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsId:
      build.query<
        GetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse,
        GetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/object_dashboards/${queryArg.objectDashboardId}/boards/${queryArg.boardId}/widget_sections/${queryArg.id}`,
        }),
      }),
    putApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsId:
      build.mutation<
        PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse,
        PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/object_dashboards/${queryArg.objectDashboardId}/boards/${queryArg.boardId}/widget_sections/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    deleteApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsId:
      build.mutation<
        DeleteApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse,
        DeleteApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/object_dashboards/${queryArg.objectDashboardId}/boards/${queryArg.boardId}/widget_sections/${queryArg.id}`,
          method: 'DELETE',
          body: queryArg.body,
        }),
      }),
    postApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsPreview:
      build.query<
        PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiResponse,
        PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/object_dashboards/${queryArg.objectDashboardId}/boards/${queryArg.boardId}/widget_sections/preview`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    putApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsMove:
      build.mutation<
        PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsMoveApiResponse,
        PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsMoveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/object_dashboards/${queryArg.objectDashboardId}/boards/${queryArg.boardId}/widget_sections/move`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    putApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoard:
      build.mutation<
        PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardApiResponse,
        PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/object_dashboards/${queryArg.objectDashboardId}/boards/${queryArg.boardId}/widget_sections/${queryArg.id}/move_to_board`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    postApiSettingsReportObjectDashboardsByObjectDashboardIdBoards:
      build.mutation<
        PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsApiResponse,
        PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/object_dashboards/${queryArg.objectDashboardId}/boards`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    getApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardId:
      build.query<
        GetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdApiResponse,
        GetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/object_dashboards/${queryArg.objectDashboardId}/boards/${queryArg.boardId}`,
        }),
      }),
    putApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardId:
      build.mutation<
        PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdApiResponse,
        PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/object_dashboards/${queryArg.objectDashboardId}/boards/${queryArg.boardId}`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    deleteApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardId:
      build.mutation<
        DeleteApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdApiResponse,
        DeleteApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report/object_dashboards/${queryArg.objectDashboardId}/boards/${queryArg.boardId}`,
          method: 'DELETE',
        }),
      }),
    postApiSettingsReportObjectDashboards: build.mutation<
      PostApiSettingsReportObjectDashboardsApiResponse,
      PostApiSettingsReportObjectDashboardsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/object_dashboards`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiSettingsReportObjectDashboardsById: build.mutation<
      PutApiSettingsReportObjectDashboardsByIdApiResponse,
      PutApiSettingsReportObjectDashboardsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/object_dashboards/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getApiSettingsReportObjectDashboardsById: build.query<
      GetApiSettingsReportObjectDashboardsByIdApiResponse,
      GetApiSettingsReportObjectDashboardsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/object_dashboards/${queryArg.id}`,
      }),
    }),
    deleteApiSettingsReportObjectDashboardsById: build.mutation<
      DeleteApiSettingsReportObjectDashboardsByIdApiResponse,
      DeleteApiSettingsReportObjectDashboardsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/object_dashboards/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiSettingsReportObjectDashboardsMeta: build.query<
      GetApiSettingsReportObjectDashboardsMetaApiResponse,
      GetApiSettingsReportObjectDashboardsMetaApiArg
    >({
      query: () => ({ url: `/api/settings/report/object_dashboards/meta` }),
    }),
    getApiSettingsReportObjectDashboardsByIdSubjectables: build.query<
      GetApiSettingsReportObjectDashboardsByIdSubjectablesApiResponse,
      GetApiSettingsReportObjectDashboardsByIdSubjectablesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/object_dashboards/${queryArg.id}/subjectables`,
      }),
    }),
    postApiSettingsReportObjectDashboardsDuplicate: build.mutation<
      PostApiSettingsReportObjectDashboardsDuplicateApiResponse,
      PostApiSettingsReportObjectDashboardsDuplicateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report/object_dashboards/duplicate`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as dashboardSettingsApi };
export type GetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiResponse =
  /** status 200 List of copyable widget sections */ DashboardCopyableWidgetSectionDto[];
export type GetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiArg =
  {
    comparisonDashboardId: string;
    boardId: string;
  };
export type PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardApiResponse =
  unknown;
export type PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardApiArg =
  {
    comparisonDashboardId: string;
    boardId: string;
    body: {
      sections_to_copy_ids: string[];
      board_layout: DashboardLayoutDto;
    };
  };
export type PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsApiResponse =
  /** status 200 Widget Section created */ ReportScoreboardWidgetSectionDto[];
export type PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsApiArg =
  {
    comparisonDashboardId: string;
    boardId: string;
    body: {
      widget_sections: {
        title: string;
        widget_type:
          | 'kpi_table'
          | 'kpi_table_single_date'
          | 'xy_chart_single_kpi'
          | 'financial_table_single_date'
          | 'financial_table_single_period';
        position: {
          x: number;
          y: number;
          w: number;
          h: number;
          maxW: number;
          maxH: number;
          minW: number;
          minH: number;
        };
        config?:
          | KpiTableWidgetConfigDto
          | KpiTableSingleDateWidgetConfigDto
          | XyChartSingleKpiWidgetConfigDto
          | FinancialTableSingleDateWidgetConfigDto
          | FinancialTableSinglePeriodWidgetConfigDto;
      }[];
      dashboard_layout?: DashboardLayoutDto;
    };
  };
export type GetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse =
  /** status 200 Widget Section found */ ReportScoreboardWidgetSectionDto;
export type GetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdApiArg =
  {
    comparisonDashboardId: string;
    boardId: string;
    id: string;
  };
export type PutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse =
  /** status 200 Widget Section updated */ ReportScoreboardWidgetSectionDto;
export type PutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdApiArg =
  {
    comparisonDashboardId: string;
    boardId: string;
    id: string;
    body: {
      title?: string;
      widget_type?:
        | 'kpi_table'
        | 'kpi_table_single_date'
        | 'xy_chart_single_kpi'
        | 'financial_table_single_date'
        | 'financial_table_single_period';
      config?:
        | KpiTableWidgetConfigDto
        | KpiTableSingleDateWidgetConfigDto
        | XyChartSingleKpiWidgetConfigDto
        | FinancialTableSingleDateWidgetConfigDto
        | FinancialTableSinglePeriodWidgetConfigDto;
    };
  };
export type DeleteApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse =
  unknown;
export type DeleteApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdApiArg =
  {
    comparisonDashboardId: string;
    boardId: string;
    id: string;
    body: {
      dashboard_layout?: DashboardLayoutDto;
    };
  };
export type PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiResponse =
  /** status 200 Widget section preview generated */
  | ReportScoreboardKpiTableSingleDateWidgetDto
  | ReportScoreboardKpiTableWidgetDto
  | ReportScoreboardXyChartSingleKpiWidgetDto
  | ReportScoreboardFinancialTableSingleDateWidgetDto
  | ReportScoreboardFinancialTableSinglePeriodWidgetDto;
export type PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg =
  {
    comparisonDashboardId: string;
    boardId: string;
    body: {
      widget_type:
        | 'kpi_table'
        | 'kpi_table_single_date'
        | 'xy_chart_single_kpi'
        | 'financial_table_single_date'
        | 'financial_table_single_period';
      config:
        | KpiTableWidgetConfigDto
        | KpiTableSingleDateWidgetConfigDto
        | XyChartSingleKpiWidgetConfigDto
        | FinancialTableSingleDateWidgetConfigDto
        | FinancialTableSinglePeriodWidgetConfigDto;
      context:
        | {
            date: string;
            legal_entity_ids: string[];
            grouping_type: 'assets';
            asset_ids?: number[];
            date_from: string;
            date_to: string;
            period_type:
              | 'week'
              | 'day_to_day'
              | 'month'
              | 'month_to_date'
              | 'month_to_month'
              | 'year_to_date';
          }
        | {
            date: string;
            legal_entity_ids: string[];
            grouping_type: 'assets';
            asset_ids?: number[];
          }
        | {
            date: string;
            legal_entity_ids: string[];
            grouping_type: 'assets';
            asset_ids?: number[];
            date_from: string;
            date_to: string;
            granularity: 'day' | 'week' | 'month';
          }
        | {
            date: string;
            legal_entity_ids: string[];
            grouping_type: 'assets';
            asset_ids?: number[];
          }
        | {
            date: string;
            legal_entity_ids: string[];
            grouping_type: 'assets';
            asset_ids?: number[];
            period_type:
              | 't3'
              | 't6'
              | 't12'
              | 'month'
              | 'quarter'
              | 'year'
              | 'itd';
          };
    };
  };
export type PutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsMoveApiResponse =
  unknown;
export type PutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsMoveApiArg =
  {
    comparisonDashboardId: string;
    boardId: string;
    body: {
      id: string;
      section_type: 'widget' | 'scorecard' | 'divider';
      position: {
        x: number;
        y: number;
        w: number;
        h: number;
        maxW: number;
        maxH: number;
        minW: number;
        minH: number;
      };
    }[];
  };
export type PutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardApiResponse =
  unknown;
export type PutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardApiArg =
  {
    comparisonDashboardId: string;
    boardId: string;
    id: string;
    body: {
      destination_board_id: string;
      destination_board_layout: DashboardLayoutDto;
      origin_board_layout: DashboardLayoutDto;
    };
  };
export type PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsApiResponse =
  /** status 200 Board created */ DashboardBoardDto;
export type PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsApiArg =
  {
    comparisonDashboardId: string;
    body: {
      name: string;
      order?: number;
    };
  };
export type GetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdApiResponse =
  /** status 200 Success */ DashboardBoardDto;
export type GetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdApiArg =
  {
    comparisonDashboardId: string;
    boardId: string;
  };
export type PutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdApiResponse =
  /** status 200 Board updated */ DashboardBoardDto;
export type PutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdApiArg =
  {
    comparisonDashboardId: string;
    boardId: string;
    body: {
      name?: string;
      order?: number;
    };
  };
export type DeleteApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdApiResponse =
  unknown;
export type DeleteApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdApiArg =
  {
    comparisonDashboardId: string;
    boardId: string;
  };
export type PostApiSettingsReportComparisonDashboardsApiResponse =
  /** status 200 Dashboard created */ ReportComparisonDashboardDto;
export type PostApiSettingsReportComparisonDashboardsApiArg = {
  body: {
    name: string;
    asset_ids: number[];
    excluded_legal_entity_ids?: string[];
    public_permission_attributes: {
      _destroy?: boolean;
    };
    directly_permitted_user_role_ids: string[];
    directly_permitted_user_ids: string[];
    directly_permitted_investment_entity_ids: string[];
    directly_permitted_user_tag_ids: string[];
  };
};
export type PutApiSettingsReportComparisonDashboardsByIdApiResponse =
  /** status 200 Dashboard updated */ ReportComparisonDashboardDto;
export type PutApiSettingsReportComparisonDashboardsByIdApiArg = {
  id: string;
  body: {
    name?: string;
    status?: 'draft' | 'published';
    asset_ids?: number[];
    excluded_legal_entity_ids?: string[];
  };
};
export type GetApiSettingsReportComparisonDashboardsByIdApiResponse =
  /** status 200 Dashboard */ ReportComparisonDashboardDto;
export type GetApiSettingsReportComparisonDashboardsByIdApiArg = {
  id: string;
};
export type DeleteApiSettingsReportComparisonDashboardsByIdApiResponse =
  unknown;
export type DeleteApiSettingsReportComparisonDashboardsByIdApiArg = {
  id: string;
};
export type GetApiSettingsReportComparisonDashboardsMetaApiResponse =
  /** status 200 Dashboard meta */ ReportComparisonDashboardMetaDto;
export type GetApiSettingsReportComparisonDashboardsMetaApiArg = void;
export type GetApiSettingsReportComparisonDashboardsByIdSubjectablesApiResponse =
  /** status 200 Dashboard subjectables */ ReportScoreboardDto;
export type GetApiSettingsReportComparisonDashboardsByIdSubjectablesApiArg = {
  id: string;
};
export type PostApiSettingsReportComparisonDashboardsDuplicateApiResponse =
  unknown;
export type PostApiSettingsReportComparisonDashboardsDuplicateApiArg = {
  body: {
    id: string;
    name: string;
  };
};
export type GetApiSettingsReportDashboardsApiResponse =
  /** status 200 List of dashboards */ ReportDashboardDto[];
export type GetApiSettingsReportDashboardsApiArg = void;
export type GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiResponse =
  /** status 200 List of copyable widget sections */ DashboardCopyableWidgetSectionDto[];
export type GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiArg =
  {
    eagleEyeDashboardId: string;
    boardId: string;
  };
export type PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardApiResponse =
  unknown;
export type PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardApiArg =
  {
    eagleEyeDashboardId: string;
    boardId: string;
    body: {
      sections_to_copy_ids: string[];
      board_layout: DashboardLayoutDto;
    };
  };
export type PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsApiResponse =
  /** status 200 Scorecard Section created */ ReportScoreboardScorecardSectionDto[];
export type PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsApiArg =
  {
    eagleEyeDashboardId: string;
    boardId: string;
    body: {
      scorecard_sections: {
        default_presentation: 'table' | 'chart';
        default_view: 'asset' | 'segment';
        scorecard_id: number;
        kpi_goals_attributes?: {
          kpi_key: string;
          value: number;
        }[];
        table_presentation_attributes?: {
          period_type?: 'mtd' | 'ytd';
        };
        chart_presentation_attributes?: {
          kpi_key?: string;
          default_assets_attributes?: {
            id?: number;
            asset_id?: number;
            _destroy?: boolean;
          }[];
          default_report_table_segments_attributes?: {
            id?: number;
            table_segment_id?: number;
            _destroy?: boolean;
          }[];
        };
        position: {
          x: number;
          y: number;
          w: number;
          h: number;
          maxW: number;
          maxH: number;
          minW: number;
          minH: number;
        };
      }[];
      dashboard_layout?: DashboardLayoutDto;
    };
  };
export type GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsIdApiResponse =
  /** status 200 Scorecard Section found */ ReportScoreboardScorecardSectionDto;
export type GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsIdApiArg =
  {
    eagleEyeDashboardId: string;
    boardId: string;
    id: string;
  };
export type PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsIdApiResponse =
  /** status 200 Scorecard Section updated */ ReportScoreboardScorecardSectionDto;
export type PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsIdApiArg =
  {
    eagleEyeDashboardId: string;
    boardId: string;
    id: string;
    body: {
      default_presentation?: 'table' | 'chart';
      default_view?: 'asset' | 'segment';
      scorecard_id?: number;
      kpi_goals_attributes?: {
        kpi_key: string;
        value: number;
      }[];
      table_presentation_attributes?: {
        period_type?: 'mtd' | 'ytd';
      };
      chart_presentation_attributes?: {
        kpi_key?: string;
        default_assets_attributes?: {
          id?: number;
          asset_id?: number;
          _destroy?: boolean;
        }[];
        default_report_table_segments_attributes?: {
          id?: number;
          table_segment_id?: number;
          _destroy?: boolean;
        }[];
      };
    };
  };
export type DeleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsIdApiResponse =
  unknown;
export type DeleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsIdApiArg =
  {
    eagleEyeDashboardId: string;
    boardId: string;
    id: string;
    body: {
      dashboard_layout?: DashboardLayoutDto;
    };
  };
export type PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsMoveApiResponse =
  unknown;
export type PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsMoveApiArg =
  {
    eagleEyeDashboardId: string;
    boardId: string;
    body: {
      id: string;
      section_type?: 'widget' | 'scorecard' | 'divider';
      position: {
        x: number;
        y: number;
        w: number;
        h: number;
        maxW: number;
        maxH: number;
        minW: number;
        minH: number;
      };
    }[];
  };
export type PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsIdMoveToBoardApiResponse =
  unknown;
export type PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdScorecardSectionsIdMoveToBoardApiArg =
  {
    eagleEyeDashboardId: string;
    boardId: string;
    id: string;
    body: {
      destination_board_id: string;
      destination_board_layout: DashboardLayoutDto;
      origin_board_layout: DashboardLayoutDto;
    };
  };
export type PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsApiResponse =
  /** status 200 Widget Section created */ ReportScoreboardWidgetSectionDto[];
export type PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsApiArg =
  {
    eagleEyeDashboardId: string;
    boardId: string;
    body: {
      widget_sections: {
        title: string;
        widget_type:
          | 'historical_review_table'
          | 'delinquency_tracker_table'
          | 'recent_rent_roll_table'
          | 'master_unit_table'
          | 'global_lease_table'
          | 'unit_mix_table'
          | 'unit_mix_summary_table'
          | 'kpi_table'
          | 'kpi_table_single_date'
          | 'average_actual_rent_chart'
          | 'delinquency_snapshot_column_chart'
          | 'delinquency_average_column_chart'
          | 'kpi_card'
          | 'xy_chart'
          | 'xy_chart_single_kpi'
          | 'financial_table_single_date'
          | 'financial_table_single_period';
        position: {
          x: number;
          y: number;
          w: number;
          h: number;
          maxW: number;
          maxH: number;
          minW: number;
          minH: number;
        };
        config?:
          | KpiCardWidgetConfigDto
          | KpiTableWidgetConfigDto
          | KpiTableSingleDateWidgetConfigDto
          | XyChartSingleKpiWidgetConfigDto;
      }[];
      dashboard_layout?: DashboardLayoutDto;
    };
  };
export type GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse =
  /** status 200 Widget Section found */ ReportScoreboardWidgetSectionDto;
export type GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdApiArg =
  {
    eagleEyeDashboardId: string;
    boardId: string;
    id: string;
  };
export type PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse =
  /** status 200 Widget Section updated */ ReportScoreboardWidgetSectionDto;
export type PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdApiArg =
  {
    eagleEyeDashboardId: string;
    boardId: string;
    id: string;
    body: {
      title?: string;
      widget_type?:
        | 'historical_review_table'
        | 'delinquency_tracker_table'
        | 'recent_rent_roll_table'
        | 'master_unit_table'
        | 'global_lease_table'
        | 'unit_mix_table'
        | 'unit_mix_summary_table'
        | 'kpi_table'
        | 'kpi_table_single_date'
        | 'average_actual_rent_chart'
        | 'delinquency_snapshot_column_chart'
        | 'delinquency_average_column_chart'
        | 'kpi_card'
        | 'xy_chart'
        | 'xy_chart_single_kpi'
        | 'financial_table_single_date'
        | 'financial_table_single_period';
      config?:
        | KpiCardWidgetConfigDto
        | KpiTableWidgetConfigDto
        | KpiTableSingleDateWidgetConfigDto
        | XyChartSingleKpiWidgetConfigDto;
    };
  };
export type DeleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse =
  unknown;
export type DeleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdApiArg =
  {
    eagleEyeDashboardId: string;
    boardId: string;
    id: string;
    body: {
      dashboard_layout?: DashboardLayoutDto;
    };
  };
export type PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiResponse =
  /** status 200 Widget section preview generated */
  | ReportScoreboardKpiCardWidgetDto
  | ReportScoreboardKpiTableWidgetDto
  | ReportScoreboardKpiTableSingleDateWidgetDto
  | ReportScoreboardXyChartSingleKpiWidgetDto
  | ReportScoreboardGlobalLeaseTableWidgetDto;
export type PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg =
  {
    eagleEyeDashboardId: string;
    boardId: string;
    body: {
      widget_type:
        | 'historical_review_table'
        | 'delinquency_tracker_table'
        | 'recent_rent_roll_table'
        | 'master_unit_table'
        | 'global_lease_table'
        | 'unit_mix_table'
        | 'unit_mix_summary_table'
        | 'kpi_table'
        | 'kpi_table_single_date'
        | 'average_actual_rent_chart'
        | 'delinquency_snapshot_column_chart'
        | 'delinquency_average_column_chart'
        | 'kpi_card'
        | 'xy_chart'
        | 'xy_chart_single_kpi'
        | 'financial_table_single_date'
        | 'financial_table_single_period';
      config:
        | KpiTableWidgetConfigDto
        | KpiTableSingleDateWidgetConfigDto
        | KpiCardWidgetConfigDto
        | XyChartSingleKpiWidgetConfigDto;
      context: {
        date?: string;
        date_from?: string;
        date_to?: string;
        granularity?: 'day' | 'week' | 'month';
        grouping_type?: 'segments' | 'assets';
        period_type?:
          | 't3'
          | 't6'
          | 't12'
          | 'month'
          | 'quarter'
          | 'year'
          | 'week';
        assets?: number[];
        segments?: number[];
        min_lease_term?: number;
        max_term_variance?: number;
        min_lease_start_date?: string;
        max_lease_start_date?: string;
        asset_names?: string[];
        lease_types?: string[];
        unit_type_labels?: string[];
        query?: string;
        page?: number;
        per_page?: number;
      };
    };
  };
export type PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsMoveApiResponse =
  unknown;
export type PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsMoveApiArg =
  {
    eagleEyeDashboardId: string;
    boardId: string;
    body: {
      id: string;
      section_type: 'widget' | 'scorecard' | 'divider';
      position: {
        x: number;
        y: number;
        w: number;
        h: number;
        maxW: number;
        maxH: number;
        minW: number;
        minH: number;
      };
    }[];
  };
export type PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardApiResponse =
  unknown;
export type PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardApiArg =
  {
    eagleEyeDashboardId: string;
    boardId: string;
    id: string;
    body: {
      destination_board_id: string;
      destination_board_layout: DashboardLayoutDto;
      origin_board_layout: DashboardLayoutDto;
    };
  };
export type PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsApiResponse =
  /** status 200 Board created */ DashboardBoardDto;
export type PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsApiArg =
  {
    eagleEyeDashboardId: string;
    body: {
      name: string;
      order?: number;
    };
  };
export type GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdApiResponse =
  /** status 200 Success */ DashboardBoardDto;
export type GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdApiArg =
  {
    eagleEyeDashboardId: string;
    boardId: string;
  };
export type PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdApiResponse =
  /** status 200 Board updated */ DashboardBoardDto;
export type PutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdApiArg =
  {
    eagleEyeDashboardId: string;
    boardId: string;
    body: {
      name?: string;
      order?: number;
    };
  };
export type DeleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdApiResponse =
  unknown;
export type DeleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdApiArg =
  {
    eagleEyeDashboardId: string;
    boardId: string;
  };
export type GetApiSettingsReportEagleEyeDashboardsFilterSetsApiResponse =
  /** status 200 Filters returned */ FilterSetDto[];
export type GetApiSettingsReportEagleEyeDashboardsFilterSetsApiArg = {
  eagleEyeScoreboardId?: string;
};
export type PostApiSettingsReportEagleEyeDashboardsFilterSetsApiResponse =
  /** status 200 Filter set created */ FilterSetDto;
export type PostApiSettingsReportEagleEyeDashboardsFilterSetsApiArg = {
  body: {
    name: string;
    asset_ids?: number[];
    segment_ids?: number[];
    excluded_legal_entity_ids?: string[];
  };
};
export type PutApiSettingsReportEagleEyeDashboardsFilterSetsByIdApiResponse =
  /** status 200 Filter set created */ FilterSetDto;
export type PutApiSettingsReportEagleEyeDashboardsFilterSetsByIdApiArg = {
  id: string;
  body: {
    name?: string;
    asset_ids?: number[];
    segment_ids?: number[];
    excluded_legal_entity_ids?: string[];
  };
};
export type DeleteApiSettingsReportEagleEyeDashboardsFilterSetsByIdApiResponse =
  unknown;
export type DeleteApiSettingsReportEagleEyeDashboardsFilterSetsByIdApiArg = {
  id: string;
};
export type GetApiSettingsReportEagleEyeDashboardsFilterSetsMetaApiResponse =
  /** status 200 Filter meta returned */ {
    assets: {
      id: number;
      name: string;
      legalEntities: {
        id: string;
        name: string;
        classification?: string;
      }[];
    }[];
    segments: {
      id: number;
      title: string;
      legalEntities: {
        id: string;
        name: string;
        classification?: string;
      }[];
    }[];
    legalEntities: {
      id: string;
      name: string;
    }[];
  };
export type GetApiSettingsReportEagleEyeDashboardsFilterSetsMetaApiArg = void;
export type GetApiSettingsReportEagleEyeDashboardsMetaApiResponse =
  /** status 200 Dashboard meta */ ReportEagleEyeDashboardMetaDto;
export type GetApiSettingsReportEagleEyeDashboardsMetaApiArg = void;
export type GetApiSettingsReportEagleEyeDashboardsApiResponse =
  /** status 200 list of eagle eye dashboards */ ReportScoreboardDto2[];
export type GetApiSettingsReportEagleEyeDashboardsApiArg = {
  /** Search by name */
  query?: string;
};
export type PostApiSettingsReportEagleEyeDashboardsApiResponse =
  /** status 200 Eagle Eye Dashboard */ ReportScoreboardDto3;
export type PostApiSettingsReportEagleEyeDashboardsApiArg = {
  body: {
    asset_ids?: number[];
    segment_ids?: number[];
    excluded_legal_entity_ids?: string[];
    name: string;
    status: 'draft' | 'published';
    public_permission_attributes: {
      _destroy?: boolean;
    };
    directly_permitted_user_role_ids: string[];
    directly_permitted_user_ids: string[];
    directly_permitted_investment_entity_ids?: string[];
    directly_permitted_user_tag_ids?: string[];
  };
};
export type GetApiSettingsReportEagleEyeDashboardsByIdApiResponse =
  /** status 200 Eagle eye dashboard */ ReportScoreboardDto3;
export type GetApiSettingsReportEagleEyeDashboardsByIdApiArg = {
  id: string;
};
export type PutApiSettingsReportEagleEyeDashboardsByIdApiResponse =
  /** status 200 Eagle eye dashboard updated */ ReportScoreboardDto3;
export type PutApiSettingsReportEagleEyeDashboardsByIdApiArg = {
  id: string;
  body: {
    asset_ids?: number[];
    segment_ids?: number[];
    excluded_legal_entity_ids?: string[];
    name?: string;
    status?: 'draft' | 'published';
    public_permission_attributes?: {
      _destroy?: boolean;
    };
    directly_permitted_user_role_ids?: string[];
    directly_permitted_user_ids?: string[];
    directly_permitted_investment_entity_ids?: string[];
    directly_permitted_user_tag_ids?: string[];
  };
};
export type DeleteApiSettingsReportEagleEyeDashboardsByIdApiResponse = unknown;
export type DeleteApiSettingsReportEagleEyeDashboardsByIdApiArg = {
  id: string;
};
export type GetApiSettingsReportEagleEyeDashboardsByIdSubjectablesApiResponse =
  /** status 200 List of Eagle eye dashboard subjectables */ ReportScoreboardDto;
export type GetApiSettingsReportEagleEyeDashboardsByIdSubjectablesApiArg = {
  id: string;
};
export type PostApiSettingsReportEagleEyeDashboardsDuplicateApiResponse =
  unknown;
export type PostApiSettingsReportEagleEyeDashboardsDuplicateApiArg = {
  body: {
    id: string;
    name: string;
  };
};
export type GetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiResponse =
  /** status 200 List of copyable widget sections */ DashboardCopyableWidgetSectionDto[];
export type GetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiArg =
  {
    objectDashboardId: string;
    boardId: string;
  };
export type PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardApiResponse =
  unknown;
export type PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsCopyToBoardApiArg =
  {
    objectDashboardId: string;
    boardId: string;
    body: {
      sections_to_copy_ids: string[];
      board_layout: DashboardLayoutDto;
    };
  };
export type PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsApiResponse =
  /** status 200 Widget Section created */ ReportScoreboardWidgetSectionDto[];
export type PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsApiArg =
  {
    objectDashboardId: string;
    boardId: string;
    body: {
      widget_sections?: {
        title: string;
        widget_type:
          | 'historical_review_table'
          | 'delinquency_tracker_table'
          | 'recent_rent_roll_table'
          | 'master_unit_table'
          | 'global_lease_table'
          | 'unit_mix_table'
          | 'unit_mix_summary_table'
          | 'kpi_table'
          | 'kpi_table_single_date'
          | 'average_actual_rent_chart'
          | 'delinquency_snapshot_column_chart'
          | 'delinquency_average_column_chart'
          | 'kpi_card'
          | 'xy_chart'
          | 'xy_chart_single_kpi'
          | 'financial_table_single_date'
          | 'financial_table_single_period';
        position: {
          x: number;
          y: number;
          w: number;
          h: number;
          maxW: number;
          maxH: number;
          minW: number;
          minH: number;
        };
        config?:
          | HistoricalReviewTableWidgetConfigDto
          | KpiCardWidgetConfigDto
          | FinancialTableSingleDateWidgetConfigDto;
      }[];
      dashboard_layout?: DashboardLayoutDto;
    };
  };
export type GetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse =
  /** status 200 Widget Section found */ ReportScoreboardWidgetSectionDto;
export type GetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdApiArg =
  {
    objectDashboardId: string;
    boardId: string;
    id: string;
  };
export type PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse =
  /** status 200 Widget Section updated */ ReportScoreboardWidgetSectionDto;
export type PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdApiArg =
  {
    objectDashboardId: string;
    boardId: string;
    id: string;
    body: {
      title?: string;
      widget_type?:
        | 'historical_review_table'
        | 'delinquency_tracker_table'
        | 'recent_rent_roll_table'
        | 'master_unit_table'
        | 'global_lease_table'
        | 'unit_mix_table'
        | 'unit_mix_summary_table'
        | 'kpi_table'
        | 'kpi_table_single_date'
        | 'average_actual_rent_chart'
        | 'delinquency_snapshot_column_chart'
        | 'delinquency_average_column_chart'
        | 'kpi_card'
        | 'xy_chart'
        | 'xy_chart_single_kpi'
        | 'financial_table_single_date'
        | 'financial_table_single_period';
      config?:
        | HistoricalReviewTableWidgetConfigDto
        | KpiCardWidgetConfigDto
        | FinancialTableSingleDateWidgetConfigDto;
    };
  };
export type DeleteApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdApiResponse =
  unknown;
export type DeleteApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdApiArg =
  {
    objectDashboardId: string;
    id: string;
    boardId: string;
    body: {
      dashboard_layout?: DashboardLayoutDto;
    };
  };
export type PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiResponse =
  /** status 200 Widget section preview generated */
  | ReportScoreboardHistoricalReviewTableWidgetDto
  | ReportScoreboardRecentRentRollTableWidgetDto
  | ReportScoreboardMasterUnitTableDto
  | ReportScoreboardUnitMixTableWidgetDto
  | ReportScoreboardUnitMixSummaryTableWidgetDto
  | ReportScoreboardAverageActualRentChartWidgetDto
  | ReportScoreboardXyChartWidgetDto
  | ReportScoreboardFinancialTableSingleDateWidgetDto;
export type PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg =
  {
    objectDashboardId: string;
    boardId: string;
    body: {
      widget_type:
        | 'historical_review_table'
        | 'delinquency_tracker_table'
        | 'recent_rent_roll_table'
        | 'master_unit_table'
        | 'global_lease_table'
        | 'unit_mix_table'
        | 'unit_mix_summary_table'
        | 'kpi_table'
        | 'kpi_table_single_date'
        | 'average_actual_rent_chart'
        | 'delinquency_snapshot_column_chart'
        | 'delinquency_average_column_chart'
        | 'kpi_card'
        | 'xy_chart'
        | 'xy_chart_single_kpi'
        | 'financial_table_single_date'
        | 'financial_table_single_period';
      config:
        | HistoricalReviewTableWidgetConfigDto
        | KpiCardWidgetConfigDto
        | AverageActualRentChartWidgetConfigDto
        | XyChartWidgetConfigDto
        | FinancialTableSingleDateWidgetConfigDto;
      context: {
        date?: string;
        date_from?: string;
        date_to?: string;
        granularity?: 'day' | 'week' | 'month';
        min_lease_term?: number;
        max_term_variance?: number;
        query?: string;
        filters?: string;
        page?: number;
        per_page?: number;
        legal_entities: string[];
      };
    };
  };
export type PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsMoveApiResponse =
  unknown;
export type PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsMoveApiArg =
  {
    objectDashboardId: string;
    boardId: string;
    body: {
      id: string;
      section_type: 'widget';
      position: {
        x: number;
        y: number;
        w: number;
        h: number;
        maxW: number;
        maxH: number;
        minW: number;
        minH: number;
      };
    }[];
  };
export type PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardApiResponse =
  unknown;
export type PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardApiArg =
  {
    objectDashboardId: string;
    boardId: string;
    id: string;
    body: {
      destination_board_id: string;
      destination_board_layout: DashboardLayoutDto;
      origin_board_layout: DashboardLayoutDto;
    };
  };
export type PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsApiResponse =
  /** status 200 Board created */ DashboardBoardDto;
export type PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsApiArg =
  {
    objectDashboardId: string;
    body: {
      name: string;
      order?: number;
    };
  };
export type GetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdApiResponse =
  /** status 200 Success */ DashboardBoardDto;
export type GetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdApiArg =
  {
    objectDashboardId: string;
    boardId: string;
  };
export type PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdApiResponse =
  /** status 200 Board updated */ DashboardBoardDto;
export type PutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdApiArg =
  {
    objectDashboardId: string;
    boardId: string;
    body: {
      name?: string;
      order?: number;
    };
  };
export type DeleteApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdApiResponse =
  unknown;
export type DeleteApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdApiArg =
  {
    objectDashboardId: string;
    boardId: string;
  };
export type PostApiSettingsReportObjectDashboardsApiResponse =
  /** status 200 Dashboard created */ ReportObjectDashboardDto;
export type PostApiSettingsReportObjectDashboardsApiArg = {
  body: {
    name: string;
    asset_ids: number[];
    excluded_legal_entity_ids?: string[];
    public_permission_attributes: {
      _destroy?: boolean;
    };
    directly_permitted_user_role_ids: string[];
    directly_permitted_user_ids: string[];
    directly_permitted_investment_entity_ids: string[];
    directly_permitted_user_tag_ids: string[];
  };
};
export type PutApiSettingsReportObjectDashboardsByIdApiResponse =
  /** status 200 Dashboard updated */ ReportObjectDashboardDto;
export type PutApiSettingsReportObjectDashboardsByIdApiArg = {
  id: string;
  body: {
    name?: string;
    status?: 'draft' | 'published';
    asset_ids?: number[];
    excluded_legal_entity_ids?: string[];
  };
};
export type GetApiSettingsReportObjectDashboardsByIdApiResponse =
  /** status 200 Dashboard */ ReportObjectDashboardDto;
export type GetApiSettingsReportObjectDashboardsByIdApiArg = {
  id: string;
};
export type DeleteApiSettingsReportObjectDashboardsByIdApiResponse = unknown;
export type DeleteApiSettingsReportObjectDashboardsByIdApiArg = {
  id: string;
};
export type GetApiSettingsReportObjectDashboardsMetaApiResponse =
  /** status 200 Dashboard meta */ ReportObjectDashboardMetaDto;
export type GetApiSettingsReportObjectDashboardsMetaApiArg = void;
export type GetApiSettingsReportObjectDashboardsByIdSubjectablesApiResponse =
  /** status 200 Dashboard subjectables */ ReportScoreboardDto;
export type GetApiSettingsReportObjectDashboardsByIdSubjectablesApiArg = {
  id: string;
};
export type PostApiSettingsReportObjectDashboardsDuplicateApiResponse = unknown;
export type PostApiSettingsReportObjectDashboardsDuplicateApiArg = {
  body: {
    id: string;
    name: string;
  };
};
export type DashboardCopyableWidgetSectionDto = {
  id: string;
  widgetType:
    | 'historical_review_table'
    | 'unit_mix_table'
    | 'delinquency_tracker_table'
    | 'recent_rent_roll_table'
    | 'average_actual_rent_chart'
    | 'delinquency_snapshot_column_chart'
    | 'delinquency_average_column_chart'
    | 'kpi_card'
    | 'xy_chart'
    | 'xy_chart_single_kpi'
    | 'kpi_table'
    | 'kpi_table_single_date';
  board: {
    id: string;
    name: string;
    order: number;
    dashboard: {
      id: string;
      name: string;
      type: 'eagle_eye' | 'object_level' | 'comparison_mode';
    };
  };
  position: {
    x: number;
    y: number;
    w: number;
    h: number;
    maxW: number;
    maxH: number;
    minW: number;
    minH: number;
  };
};
export type DashboardLayoutDto = {
  id: string;
  section_type: 'widget' | 'section' | 'divider';
  position: {
    x: number;
    y: number;
    w: number;
    h: number;
    maxW: number;
    maxH: number;
    minW: number;
    minH: number;
  };
}[];
export type VizConfigBackgroundDto = {
  background?:
    | {
        type: 'gradient';
        ignore_zeros?: boolean;
        thresholds: {
          type: 'min' | 'max' | 'number' | 'percentage' | 'percentile';
          value?: number;
        }[];
      }
    | {
        type: 'color';
        color: string;
      };
};
export type ValueDisplayOptionsConfigDto = {
  type: 'number' | 'percentage' | 'currency' | 'metric' | 'sqft' | 'bps';
  precision: 0 | 1 | 2;
  kilo_formatting: boolean;
  hide_comma_separator?: boolean;
};
export type TrailingPeriodTypeDto = {
  type: 'day' | 'month' | 'quarter';
  amount: number;
};
export type AdjustmentExpressionDto = {
  name:
    | 'financial_t1a'
    | 'financial_t3a'
    | 'financial_beginning_balance'
    | 'financial_ending_balance'
    | 'financial_debit'
    | 'financial_credit'
    | 'op_variance'
    | 'op_variance_percent'
    | 'op_variance_flipped'
    | 'op_variance_flipped_percent'
    | 'op_budget'
    | 'uw_variance'
    | 'uw_variance_percent'
    | 'uw_variance_flipped'
    | 'uw_variance_flipped_percent'
    | 'uw_budget';
  source_overrides?: {
    source:
      | 'financial_t1a'
      | 'financial_t3a'
      | 'actual'
      | 'beginning_balance'
      | 'ending_balance'
      | 'debit'
      | 'credit'
      | 'budget'
      | 'underwriting_budget';
    override_to: string;
  }[];
};
export type HistoricalReviewTableWidgetConfigDto = {
  viz_type?: string;
  viz_config?: {
    rows: {
      key: string;
      font_weight?: string;
      font_color?: string;
      background?: VizConfigBackgroundDto;
      comparison?: {
        rule?:
          | 'negative_red_positive_green'
          | 'negative_red'
          | 'negative_green_positive_red';
        hide_negative_values?: boolean;
      };
    }[];
    columns: {
      col_id: string;
      key: string;
      order: number;
      group_id?: string | null;
      column_group_show?: string;
      initial_sort?: string;
      hidden?: boolean;
      hide_dates?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      value_display_options?: ValueDisplayOptionsConfigDto;
      font_weight?: string;
    }[];
    column_groups: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
      hidden?: boolean;
    }[];
  };
  columns: {
    key: number;
    label: string;
    period_type:
      | ('day' | 'week' | 'month' | 'quarter' | 'qtd' | 'year' | 'ytd')
      | TrailingPeriodTypeDto;
    period_shift?: {
      days?: number;
      weeks?: number;
      months?: number;
      quarters?: number;
      years?: number;
    };
  }[];
  rows: {
    key: number;
    label: string;
    expression: string;
    value_display_options?: ValueDisplayOptionsConfigDto;
    adjustment_expression?: AdjustmentExpressionDto;
  }[];
  default_options?: {
    date?: {
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
  };
};
export type WidgetColumnComparisonDto = {
  to_col_id?: string;
  rule?:
    | 'negative_red_positive_green'
    | 'negative_green_positive_red'
    | 'negative_red';
  hide_negative_values?: boolean;
};
export type KpiTableWidgetConfigDto = {
  viz_type: string;
  viz_config: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
      hidden?: boolean;
      child_can_override_period?: boolean;
    }[];
    columns?: {
      col_id: string;
      key: string;
      group_id: string | null;
      column_group_show: string;
      hidden?: boolean;
      value_display_options: {
        type?: 'number' | 'percentage' | 'currency' | 'metric' | 'sqft' | 'bps';
        precision?: 0 | 1 | 2;
        kilo_formatting?: boolean;
        hide_comma_separator?: boolean;
      };
      order: number;
      comparison?: WidgetColumnComparisonDto;
      border_color?: string | null;
      font_weight?: string | null;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      background?: VizConfigBackgroundDto;
    }[];
  };
  hide_total_row?: boolean;
  columns: {
    key: number;
    label: string;
    expression: string;
    adjustment_expression?: AdjustmentExpressionDto;
    period_shift?: {
      days?: number;
      weeks?: number;
      months?: number;
      quarters?: number;
      years?: number;
    };
    total_calculation_strategy?: 'evaluate' | 'sum' | 'avg' | 'none';
  }[];
  period_types: ('t3' | 't6' | 't12' | 'week' | 'mtd' | 'ytd')[];
  default_options: {
    grouping_type: 'segments' | 'assets';
    date: {
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
    period_type: 't3' | 't6' | 't12' | 'month' | 'quarter' | 'year' | 'week';
  };
  ag_grid_config?: object;
};
export type KpiTableSingleDateWidgetConfigDto = {
  viz_type?: string;
  viz_config?: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
      child_can_override_period?: boolean;
    }[];
    columns?: {
      col_id: string;
      key: string;
      group_id: string | null;
      column_group_show: string;
      hide_dates?: boolean;
      hidden?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      value_display_options: {
        type?: 'number' | 'percentage' | 'currency' | 'metric' | 'sqft' | 'bps';
        precision?: 0 | 1 | 2;
        kilo_formatting?: boolean;
        hide_comma_separator?: boolean;
      };
      order: number;
      comparison?: WidgetColumnComparisonDto;
      border_color?: string | null;
      font_weight?: string | null;
    }[];
  };
  hide_total_row?: boolean;
  columns: {
    key: number;
    label: string;
    expression: string;
    period_type:
      | ('day' | 'week' | 'month' | 'quarter' | 'qtd' | 'year' | 'ytd')
      | TrailingPeriodTypeDto;
    period_shift?: {
      days?: number;
      weeks?: number;
      months?: number;
      quarters?: number;
      years?: number;
    };
    value_display_options: {
      type?: 'number' | 'percentage' | 'currency' | 'metric' | 'sqft' | 'bps';
      precision?: 0 | 1 | 2;
      kilo_formatting?: boolean;
      hide_comma_separator?: boolean;
    };
    adjustment_expression?: AdjustmentExpressionDto;
    total_calculation_strategy?: 'evaluate' | 'sum' | 'avg' | 'none';
  }[];
  default_options: {
    grouping_type: 'segments' | 'assets';
    date?: {
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
  };
  ag_grid_config?: object;
};
export type KpiCardWidgetConfigDto = {
  kpis: {
    key: number;
    label: string;
    expression: string;
    value_display_options: {
      type?: 'number' | 'percentage' | 'currency' | 'metric' | 'sqft' | 'bps';
      precision?: 0 | 1 | 2;
      kilo_formatting?: boolean;
      hide_comma_separator?: boolean;
    };
    period_type:
      | (
          | 'day'
          | 'week'
          | 'month'
          | 'quarter'
          | 'qtd'
          | 'year'
          | 'ytd'
          | 't3'
          | 't6'
          | 't12'
        )
      | TrailingPeriodTypeDto;
    period_shift?: {
      days?: number;
      weeks?: number;
      months?: number;
      quarters?: number;
      years?: number;
    };
  }[];
};
export type XyChartWidgetConfigDto = {
  kpis: {
    key: number;
    expression: string;
  }[];
  granularities: ('day' | 'week' | 'month')[];
  default_options: {
    granularity: 'day' | 'week' | 'month';
    date_from: {
      period_type: 'day' | 'week' | 'month';
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
      };
    };
    date_to?: {
      period_type: 'day' | 'week' | 'month';
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
      };
    };
  };
  am_chart_config: object;
};
export type XyChartSingleKpiWidgetConfigDto = {
  kpis: {
    key: number;
    expression: string;
  }[];
  granularities: ('day' | 'week' | 'month')[];
  default_options: {
    granularity: 'day' | 'week' | 'month';
    grouping_type: 'assets' | 'segments';
    date_from: {
      period_type: 'day' | 'week' | 'month';
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
      };
    };
    date_to?: {
      period_type: 'day' | 'week' | 'month';
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
      };
    };
  };
  am_chart_config: object;
};
export type AverageActualRentChartWidgetConfigDto = {
  granularities: 'month'[];
  default_options: {
    granularity: 'month';
    date_from: {
      period_type: 'month';
      period_shift?: {
        months?: number;
      };
    };
    date_to?: {
      period_type: 'month';
      period_shift?: {
        months?: number;
      };
    };
  };
};
export type VizConfigAutoColumnDto = {
  header?: {
    title?: string;
  };
};
export type BaseFinancialTableWidgetConfigBaseColumnDto = {
  key: number;
  label: string;
  sub_label?: string;
  header?: {
    hide_title?: boolean;
    hide_subtitle?: boolean;
  };
  adjustment_expression_name?:
    | 'financial_beginning_balance'
    | 'financial_ending_balance'
    | 'financial_debit'
    | 'financial_credit'
    | 'op_variance'
    | 'op_variance_percent'
    | 'op_budget'
    | 'uw_variance'
    | 'uw_variance_percent'
    | 'uw_budget'
    | 'financial_t1a'
    | 'financial_t3a';
  adjustment_average_per?:
    | 'unit'
    | 'unit_occupied'
    | 'unit_vacant'
    | 'unit_available'
    | 'unit_preleased'
    | 'sqft';
  value_display_options_override?: ValueDisplayOptionsConfigDto;
  period_shift?: {
    months?: number;
    quarters?: number;
    years?: number;
  };
};
export type BaseFinancialTableWidgetConfigRowBaseRowDto = {
  path: string;
  label?: string;
  children?: BaseFinancialTableWidgetConfigRowDto[];
  display_options?: {
    font_weight?: string;
    color?: string;
    background?: string;
  };
  value_display_options?: ValueDisplayOptionsConfigDto;
};
export type BaseFinancialTableWidgetConfigRowDto =
  | (BaseFinancialTableWidgetConfigRowBaseRowDto & {
      type: 'blank';
    })
  | (BaseFinancialTableWidgetConfigRowBaseRowDto & {
      type: 'formula';
      expression: string;
      negate_variance?: boolean;
      adjustment_expression_source_overrides?: {
        source:
          | 'financial_beginning_balance'
          | 'financial_ending_balance'
          | 'financial_debit'
          | 'financial_credit'
          | 'op_variance'
          | 'op_variance_percent'
          | 'op_variance_flipped'
          | 'op_variance_flipped_percent'
          | 'op_budget'
          | 'uw_variance'
          | 'uw_variance_percent'
          | 'uw_variance_flipped'
          | 'uw_variance_flipped_percent'
          | 'uw_budget'
          | 'financial_t1a'
          | 'financial_t3a';
        override_to: string;
      }[];
    })
  | (BaseFinancialTableWidgetConfigRowBaseRowDto & {
      type: 'category';
      category_path: string;
      hide_general_ledgers?: boolean;
    });
export type FinancialTableSingleDateWidgetConfigDto = {
  hide_header?: boolean;
  header_background?: string;
  ag_grid_config: object;
  viz_type?: string;
  viz_config: {
    column_groups: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      hidden?: boolean;
      order: number;
    }[];
    columns: {
      col_id: string;
      key: string;
      order: number;
      group_id?: string | null;
      column_group_show?: string;
      hidden?: boolean;
      initial_sort?: string;
      hide_dates?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      value_display_options?: ValueDisplayOptionsConfigDto;
      font_weight?: string | null;
      border_color?: string | null;

      max_width?: number;
    }[];
    rows: {
      key: string;
      value_display_options: ValueDisplayOptionsConfigDto;
      font_weight?: string;
      font_color?: string;
      background?: VizConfigBackgroundDto;
      comparison?: {
        rule?:
          | 'negative_red_positive_green'
          | 'negative_red'
          | 'negative_green_positive_red';
        hide_negative_values?: boolean;
      };
    }[];
    auto_column: VizConfigAutoColumnDto;
    header_background?: string;
  };
  columns: (BaseFinancialTableWidgetConfigBaseColumnDto & {
    period_type:
      | ('month' | 'quarter' | 'year' | 'mtd' | 'qtd' | 'ytd' | 'itd')
      | TrailingPeriodTypeDto;
  })[];
  rows: BaseFinancialTableWidgetConfigRowDto[];
  default_options: {
    date: {
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
  };
};
export type FinancialTableSinglePeriodWidgetConfigDto = {
  period_types: ('t3' | 't6' | 't12' | 'month' | 'quarter' | 'year' | 'itd')[];
  hide_header?: boolean;
  header_background?: string;
  ag_grid_config: object;
  viz_type?: string;
  viz_config?: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
      hidden?: boolean;
    }[];
    columns?: {
      col_id: string;
      key: string;
      order: number;
      group_id?: string | null;
      column_group_show?: string;
      hidden?: boolean;
      initial_sort?: string;
      hide_dates?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      value_display_options?: ValueDisplayOptionsConfigDto;
      font_weight?: string | null;
      border_color?: string | null;
    }[];
    rows?: {
      key: string;
      value_display_options: ValueDisplayOptionsConfigDto;
      font_weight?: string;
      font_color?: string;
      background?: VizConfigBackgroundDto;
      comparison?: {
        rule?:
          | 'negative_red_positive_green'
          | 'negative_red'
          | 'negative_green_positive_red';
        hide_negative_values?: boolean;
      };
    }[];
    auto_column?: VizConfigAutoColumnDto;
    header_background?: string;
  };
  columns: BaseFinancialTableWidgetConfigBaseColumnDto[];
  rows: BaseFinancialTableWidgetConfigRowDto[];
  default_options: {
    date: {
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
    period_type: 't3' | 't6' | 't12' | 'month' | 'quarter' | 'year' | 'itd';
  };
};
export type ReportScoreboardWidgetSectionDto = {
  id: string;
  widgetType:
    | 'historical_review_table'
    | 'delinquency_tracker_table'
    | 'recent_rent_roll_table'
    | 'master_unit_table'
    | 'global_lease_table'
    | 'unit_mix_table'
    | 'unit_mix_summary_table'
    | 'kpi_table'
    | 'kpi_table_single_date'
    | 'average_actual_rent_chart'
    | 'delinquency_snapshot_column_chart'
    | 'delinquency_average_column_chart'
    | 'kpi_card'
    | 'xy_chart'
    | 'xy_chart_single_kpi'
    | 'financial_table_single_date'
    | 'financial_table_single_period';
  widgetConfig:
    | HistoricalReviewTableWidgetConfigDto
    | KpiTableWidgetConfigDto
    | KpiTableSingleDateWidgetConfigDto
    | KpiCardWidgetConfigDto
    | XyChartWidgetConfigDto
    | XyChartSingleKpiWidgetConfigDto
    | AverageActualRentChartWidgetConfigDto
    | FinancialTableSingleDateWidgetConfigDto
    | FinancialTableSinglePeriodWidgetConfigDto
    | object;
  defaultOptions: {
    [key: string]: any;
  } | null;
  boardName: string;
  dashboardName: string;
  position: {
    x: number;
    y: number;
    w: number;
    h: number;
    maxW: number;
    maxH: number;
    minW: number;
    minH: number;
  };
};
export type ValueDisplayOptionsDto = {
  type: 'number' | 'percentage' | 'currency' | 'metric' | 'sqft' | 'bps';
  precision: 0 | 1 | 2;
  kiloFormatting: boolean;
  hideCommaSeparator: boolean;
};
export type ReportScoreboardKpiTableSingleDateWidgetDto = {
  type: 'kpi_table_single_date';
  columns: {
    key: number;
    label: string;
    dateFrom: string;
    dateTo: string;
    valueDisplayOptions: ValueDisplayOptionsDto;
  }[];
  rows: {
    type?: 'segment' | 'asset' | 'legal_entity';
    label?: string;
    path?: string;
    values?: {
      [key: string]: number | null;
    };
  }[];
  data?: {
    type?: 'segment' | 'asset' | 'legal_entity' | 'total';
    label?: string;
    path?: string;
  }[];
};
export type ReportScoreboardKpiTableWidgetDto = {
  type: 'kpi_table';
  columns: {
    key: number;
    label: string;
  }[];
  columnGroups: {
    groupId: string;
    headerName: string;
  }[];
  rows: {
    type?: 'segment' | 'asset' | 'legal_entity';
    label?: string;
    path?: string;
    values?: {
      [key: string]: number | null;
    };
  }[];
  data?: {
    type?: 'segment' | 'asset' | 'legal_entity' | 'total';
    label?: string;
    path?: string;
  }[];
};
export type ReportScoreboardXyChartSingleKpiWidgetDto = {
  type: 'xy_chart_single_kpi';
  items: {
    values: {
      [key: string]: number;
    }[];
    dateFrom: string;
    dateTo: string;
  }[];
};
export type ReportScoreboardBaseFinancialTableWidgetBaseGroupDto = {
  key: number;
  legalEntities: {
    id: string;
    name: string;
    code: string;
  }[];
};
export type ReportScoreboardBaseFinancialTableWidgetBaseRowDto = {
  path: string;
  label: string;
  displayOptions: {
    fontWeight: string | null;
    color: string | null;
    background: string | null;
  };
  valueDisplayOptions: ValueDisplayOptionsDto;
  values: {
    columnKey: number;
    groupKey: number;
    expression: string | null;
    value: number | null;
  }[];
};
export type ReportScoreboardBaseFinancialTableWidgetDto = {
  hideHeader: boolean;
  headerBackground: string | null;
  agGridConfig: object;
  vizType?: string;
  vizConfig?: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
    }[];
    columns?: {
      col_id: string;
      key: string;
      order: number;
      group_id?: string | null;
      column_group_show?: string;
      hidden?: boolean;
      initial_sort?: string;
      hide_dates?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      value_display_options?: ValueDisplayOptionsConfigDto;
      font_weight?: string | null;
      border_color?: string | null;
    }[];
    rows?: {
      key: string;
      value_display_options: ValueDisplayOptionsConfigDto;
      font_weight?: string;
      background?: VizConfigBackgroundDto;
      comparison?: {
        rule?:
          | 'negative_red_positive_green'
          | 'negative_red'
          | 'negative_green_positive_red';
        hide_negative_values?: boolean;
      };
    }[];
    auto_column?: VizConfigAutoColumnDto;
    header_background?: string;
  };
  groups: (
    | (ReportScoreboardBaseFinancialTableWidgetBaseGroupDto & {
        type: 'total';
      })
    | (ReportScoreboardBaseFinancialTableWidgetBaseGroupDto & {
        type: 'asset';
        asset: {
          id: number;
          name: string;
        };
      })
    | (ReportScoreboardBaseFinancialTableWidgetBaseGroupDto & {
        type: 'segment';
        segment: {
          id: number;
          name: string;
        };
      })
  )[];
  columns: {
    key: number;
    label: string;
    subLabel: string | null;
    hideSubLabel?: boolean;
    dateFrom: string | null;
    dateTo: string | null;
    valueDisplayOptionsOverride?: ValueDisplayOptionsDto;
  }[];
  rows: (
    | (ReportScoreboardBaseFinancialTableWidgetBaseRowDto & {
        type: 'blank';
      })
    | (ReportScoreboardBaseFinancialTableWidgetBaseRowDto & {
        type: 'formula';
      })
    | (ReportScoreboardBaseFinancialTableWidgetBaseRowDto & {
        type: 'category';
      })
    | (ReportScoreboardBaseFinancialTableWidgetBaseRowDto & {
        type: 'general_ledger';
        legalEntityCode: string;
        erpSystem: string;
      })
  )[];
};
export type ReportScoreboardFinancialTableSingleDateWidgetDto =
  ReportScoreboardBaseFinancialTableWidgetDto & {
    type: 'financial_table_single_date';
  };
export type ReportScoreboardFinancialTableSinglePeriodWidgetDto =
  ReportScoreboardBaseFinancialTableWidgetDto & {
    type: 'financial_table_single_period';
  };
export type ReportScorecardPreviewGraphedColumnDto = {
  key: string;
  label: string;
  valueDisplayOptions: {
    type: 'number' | 'percentage' | 'currency' | 'sqft' | 'metric';
    precision: 0 | 1 | 2;
  } | null;
};
export type ReportScorecardPreviewDto = {
  id: string;
  title: string;
  withMtdYtd: boolean;
  defaultPeriodShift: number;
  graphedColumns: ReportScorecardPreviewGraphedColumnDto[];
  segments?: {
    id: number;
    title: string;
  }[];
  assets: {
    id: number;
    name: string;
  }[];
};
export type ReportScoreboardScorecardSectionDto = {
  id: number;
  sectionId: string;
  scorecard: ReportScorecardPreviewDto;
  defaultPresentation: 'chart' | 'table';
  defaultView: 'asset' | 'segment';
  chartPresentation: {
    id: number;
    assets: {
      id: number;
      assetId: number;
    }[];
    reportTableSegments?: {
      id: number;
      tableSegmentId?: number;
    }[];
    kpiKey: string;
  } | null;
  tablePresentation: {
    id: number;
    periodType: 'mtd' | 'ytd';
  } | null;
  kpiGoals?: {
    id: number;
    kpiKey: string;
    scorecardSectionId: number;
    value: number;
  }[];
  sectionType: 'scorecard';
  position: {
    x: number;
    y: number;
    w: number;
    h: number;
    maxW: number;
    maxH: number;
    minW: number;
    minH: number;
  };
  color: string | null;
};
export type ReportScoreboardDividerSectionDto = {
  id: number;
  sectionId: string;
  title: string;
  description: string | null;
  sectionType: 'divider';
  position: {
    x: number;
    y: number;
    w: number;
    h: number;
    maxW: number;
    maxH: number;
    minW: number;
    minH: number;
  };
  color: string | null;
};
export type DashboardWidgetSectionDto = {
  id: string;
  widgetType:
    | 'historical_review_table'
    | 'delinquency_tracker_table'
    | 'recent_rent_roll_table'
    | 'master_unit_table'
    | 'global_lease_table'
    | 'unit_mix_table'
    | 'unit_mix_summary_table'
    | 'kpi_table'
    | 'kpi_table_single_date'
    | 'average_actual_rent_chart'
    | 'delinquency_snapshot_column_chart'
    | 'delinquency_average_column_chart'
    | 'kpi_card'
    | 'xy_chart'
    | 'xy_chart_single_kpi'
    | 'financial_table_single_date'
    | 'financial_table_single_period';
  widgetConfig:
    | (
        | XyChartWidgetConfigDto
        | HistoricalReviewTableWidgetConfigDto
        | KpiTableWidgetConfigDto
        | KpiTableSingleDateWidgetConfigDto
        | FinancialTableSingleDateWidgetConfigDto
        | FinancialTableSinglePeriodWidgetConfigDto
        | object
      )
    | null;
  defaultOptions: {
    [key: string]: any;
  } | null;
  position: {
    x: number;
    y: number;
    w: number;
    h: number;
    maxW: number;
    maxH: number;
    minW: number;
    minH: number;
  };
};
export type DashboardBoardDto = {
  id: string;
  name: string;
  slug: string;
  sections: (
    | ReportScoreboardScorecardSectionDto
    | ReportScoreboardDividerSectionDto
    | DashboardWidgetSectionDto
  )[];
};
export type ReportComparisonDashboardAssetDto = {
  id: number;
  name: string;
  pictureUrl: string | null;
  slug: string;
  legalEntities?: {
    id: string;
    name: string;
    code: string;
  }[];
};
export type ReportComparisonDashboardDto = {
  id: string;
  slug: string;
  status: 'draft' | 'published';
  name: string;
  assets: ReportComparisonDashboardAssetDto[];
  boards: DashboardBoardDto[];
  permitted: {
    isPublic: boolean;
    directUsers: {
      id: string;
      fullName: string;
      email: string;
      role: {
        id: string;
        name: string;
      };
      tags: {
        id: string;
        name: string;
      }[];
    }[];
    indirectUsers: {
      id: string;
      fullName: string;
      email: string;
      role: {
        id: string;
        name: string;
      };
      tags: {
        id: string;
        name: string;
      }[];
    }[];
    directRoles: {
      id: string;
      name: string;
    }[];
    directTags: {
      id: number;
      name: string;
    }[];
    directInvestmentEntities: {
      id: number;
      name: string;
    }[];
  };
};
export type ReportComparisonDashboardMetaDto = {
  assets: {
    id: number;
    name: string;
    legalEntities: {
      id: string;
      classification:
        | (
            | 'multifamily'
            | 'retail'
            | 'hospitality'
            | 'office'
            | 'senior_living'
            | 'student'
            | 'mixed_use'
            | 'spv'
            | 'op_co'
            | 'commercial'
          )
        | null;
      name: string;
    }[];
  }[];
};
export type ReportScoreboardDto = {
  allUsers: {
    id: string;
    fullName: string;
    email: string;
    role: {
      id: string;
      name: string;
    };
    tags: {
      id: number;
      name: string;
    }[];
  }[];
  allRoles: {
    id: string;
    name: string;
  }[];
  allTags: {
    id: number;
    name: string;
  }[];
  allInvestmentEntities: {
    id: number;
    name: string;
  }[];
  isPublic: boolean;
  directUsers: {
    id: string;
    fullName: string;
    email: string;
    role: {
      id: string;
      name: string;
    };
    tags: {
      id: number;
      name: string;
    }[];
  }[];
  indirectUsers: {
    id: string;
    fullName: string;
    email: string;
    role: {
      id: string;
      name: string;
    };
    tags: {
      id: number;
      name: string;
    }[];
  }[];
  directRoles: {
    id: string;
    name: string;
  }[];
  directTags: {
    id: number;
    name: string;
  }[];
  directInvestmentEntities: {
    id: number;
    name: string;
  }[];
};
export type ReportDashboardDto = {
  id: string;
  name: string;
  type: 'eagle_eye' | 'object_level' | 'comparison_mode';
  status: 'draft' | 'published';
  permissions: {
    isPublic: boolean;
    directUsers: {
      id: string;
      fullName: string;
      email: string;
    }[];
    indirectUsers: {
      id: string;
      fullName: string;
      email: string;
    }[];
    directRoles: {
      id: string;
      name: string;
    }[];
    directTags: {
      id: number;
      name: string;
    }[];
    directInvestmentEntities: {
      id: number;
      name: string;
    }[];
  };
};
export type ReportScoreboardKpiCardWidgetDto = {
  type: 'kpi_card';
  items?: {
    key?: number;
    label?: string;
    valueDisplayOptions?: {
      type: 'number' | 'percent';
      precision: number;
      kiloFormatting: boolean;
      suffix: string;
      value: number;
      hideCommaSeparator: boolean;
    };
  }[];
};
export type ReportScoreboardGlobalLeaseTableWidgetDto = {
  type: 'global_lease_table';
  columns: {
    key: number;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    unit_id?: string;
    values: {
      key: number;
      value: (string | number | boolean) | null;
    }[];
  }[];
  filterOptions: {
    assetNames: string[];
    leaseTypes: string[];
    unitTypeLabels: string[];
  };
  totalSize: number;
};
export type FilterSetDto = {
  id: string;
  name: string;
  assets: {
    id: number;
    name: string;
    legalEntities: {
      id: string;
      name: string;
      classification: string;
    }[];
  }[];
  segments: {
    id: number;
    title: string;
    legalEntities: {
      id: string;
      name: string;
      classification: string;
    }[];
  }[];
  excludedLegalEntities: {
    id: string;
    name: string;
  }[];
  legalEntities: {
    id: string;
    name: string;
  }[];
};
export type ReportEagleEyeDashboardMetaDto = {
  assets: {
    id: number;
    name: string;
    legalEntities: {
      id: string;
      name: string;
      classification:
        | (
            | 'multifamily'
            | 'retail'
            | 'hospitality'
            | 'office'
            | 'senior_living'
            | 'student'
            | 'mixed_use'
            | 'spv'
            | 'op_co'
            | 'commercial'
          )
        | null;
    }[];
  }[];
  segments: {
    id: number;
    title: string;
    legalEntities: {
      id: string;
      name: string;
      classification:
        | (
            | 'multifamily'
            | 'retail'
            | 'hospitality'
            | 'office'
            | 'senior_living'
            | 'student'
            | 'mixed_use'
            | 'spv'
            | 'op_co'
            | 'commercial'
          )
        | null;
    }[];
  }[];
  periods: string[];
  actualAt: number;
  assetStages: {
    id?: number;
    name?: string;
  }[];
};
export type ReportScoreboardDto2 = {
  id: string;
  name: string;
  status: 'draft' | 'published';
  permitted: {
    isPublic: boolean;
    directUsers: {
      id: string;
      fullName: string;
      email: string;
    }[];
    indirectUsers: {
      id: string;
      fullName: string;
      email: string;
      role?: {
        id: string;
        name: string;
      };
      tags?: {
        id: string;
        name: string;
      }[];
    }[];
    directRoles: {
      id: string;
      name: string;
    }[];
    directTags: {
      id: string;
      name: string;
    }[];
    directInvestmentEntities: {
      id: string;
      name: string;
    }[];
  };
};
export type ReportScoreboardDto3 = {
  id: string;
  assets: {
    id: number;
    name: string;
    legalEntities: {
      id: string;
      name: string;
    }[];
  }[];
  segments: {
    id: number;
    title: string;
    legalEntities: {
      id: string;
      name: string;
    }[];
  }[];
  excludedLegalEntities: {
    id: string;
    name: string;
  }[];
  name: string;
  status: 'draft' | 'published';
  permitted: {
    isPublic: boolean;
    directUsers: {
      id: string;
      fullName: string;
      email: string;
      role: {
        id: string;
        name: string;
      };
      tags: {
        id: string;
        name: string;
      }[];
    }[];
    indirectUsers: {
      id: string;
      fullName: string;
      email: string;
      role: {
        id: string;
        name: string;
      };
      tags: {
        id: string;
        name: string;
      }[];
    }[];
    directRoles: {
      id: string;
      name: string;
    }[];
    directTags: {
      id: number;
      name: string;
    }[];
    directInvestmentEntities: {
      id: number;
      name: string;
    }[];
  };
  boards: {
    id: string;
    name: string;
    slug: string;
    order: number;
    sections: (
      | ReportScoreboardScorecardSectionDto
      | ReportScoreboardDividerSectionDto
      | DashboardWidgetSectionDto
    )[];
  }[];
  meta: {
    periods: string[];
    actualAt: number;
    assetStages: {
      id?: number;
      name?: string;
    }[];
  };
};
export type ReportScoreboardHistoricalReviewTableWidgetDto = {
  type: 'historical_review_table';
  columns: {
    key: number;
    label: string;
    dateFrom: string;
    dateTo: string;
  }[];
  data: object[];
};
export type ReportScoreboardRecentRentRollTableWidgetDto = {
  type: 'recent_rent_roll_table';
  columns: {
    key: number;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    values: {
      key: number;
      value: (string | number) | null;
    }[];
    label: string;
    path: string;
  }[];
  total: {
    values: {
      key: number;
      value: (string | number) | null;
    }[];
    path: string;
  };
  psf: {
    values: {
      key: number;
      value: (string | number) | null;
    }[];
    path: string;
  };
  latestParsings?: {
    legalEntityId: string;
    date: string;
  }[];
};
export type ReportScoreboardMasterUnitTableDto = {
  type: 'master_unit_table';
  columns: {
    key: number;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    label: string;
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  }[];
  filterOptions?: {
    column?: string;
    values?: string[];
  }[];
};
export type ReportScoreboardUnitMixTableWidgetDto = {
  type: 'unit_mix_table';
  columns: {
    key: number;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    path: string;
    label: string;
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  }[];
};
export type ReportScoreboardUnitMixSummaryTableWidgetDto = {
  type: 'unit_mix_summary_table';
  headerBackground: string | null;
  columns: {
    key: number;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    path: string;
    label: string;
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  }[];
  total: {
    path: string;
    label: string;
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  };
};
export type ReportScoreboardAverageActualRentChartWidgetDto = {
  type: 'average_actual_rent_chart';
  items: {
    key: string;
    label: string;
    value: number;
    dateFrom: string;
    dateTo: string;
    type: 'unit_type' | 'source_unit_type' | 'average';
  }[];
};
export type ReportScoreboardXyChartWidgetDto = {
  type: 'xy_chart';
  items: {
    values: {
      [key: string]: number;
    }[];
    dateFrom: string;
    dateTo: string;
  }[];
};
export type ReportObjectDashboardAssetDto = {
  id: number;
  name: string;
  pictureUrl: string | null;
  slug: string;
  legalEntities?: {
    id: string;
    name: string;
    code: string;
  }[];
};
export type ReportObjectDashboardDto = {
  id: string;
  slug: string;
  status: 'draft' | 'published';
  name: string;
  assets: ReportObjectDashboardAssetDto[];
  excludedLegalEntities: {
    id: string;
    name: string;
  }[];
  widgetSections: ReportScoreboardWidgetSectionDto[];
  boards: DashboardBoardDto[];
  permitted: {
    isPublic: boolean;
    directUsers: {
      id: string;
      fullName: string;
      email: string;
      role: {
        id: string;
        name: string;
      };
      tags: {
        id: string;
        name: string;
      }[];
    }[];
    indirectUsers: {
      id: string;
      fullName: string;
      email: string;
      role: {
        id: string;
        name: string;
      };
      tags: {
        id: string;
        name: string;
      }[];
    }[];
    directRoles: {
      id: string;
      name: string;
    }[];
    directTags: {
      id: number;
      name: string;
    }[];
    directInvestmentEntities: {
      id: number;
      name: string;
    }[];
  };
};
export type ReportObjectDashboardMetaDto = {
  assets: {
    id: number;
    name: string;
    legalEntities: {
      id: string;
      classification:
        | (
            | 'multifamily'
            | 'retail'
            | 'hospitality'
            | 'office'
            | 'senior_living'
            | 'student'
            | 'mixed_use'
            | 'spv'
            | 'op_co'
            | 'commercial'
          )
        | null;
      name: string;
    }[];
  }[];
};
