import React from 'react';
import { WidgetProps } from '../../model';
import { ReportDashboardDividerSection } from 'bundles/Shared/entities/dashboard';
import { isEmpty } from 'lodash-es';
import { cn } from '@/shared/lib/css/cn';
import { UnknownRecord } from 'type-fest/source/internal';

export function DashboardDividerSection({
  widgetSection,
}: WidgetProps<UnknownRecord, ReportDashboardDividerSection>) {
  const withoutDescription = isEmpty(widgetSection.description);
  return (
    <div className="flex h-full flex-col gap-tw-2 rounded-2xl p-tw-4 pt-tw-2">
      <div
        className={cn(
          'flex gap-tw-2',
          withoutDescription && 'h-full items-center',
        )}
      >
        <span className="header4-bold text-neutral-800">
          {widgetSection.title}
        </span>
      </div>
      {!withoutDescription && (
        <span className="body-semibold text-neutral-500">
          {widgetSection.description}
        </span>
      )}
    </div>
  );
}

export default DashboardDividerSection;
