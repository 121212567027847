import {
  AVAILABLE_PERIOD_TYPES,
  AVAILABLE_PERIOD_TYPES_OPTIONS,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';

export const SUPPORTED_FINANCIAL_TABLE_SINGLE_PERIOD_PERIOD_TYPES = [
  AVAILABLE_PERIOD_TYPES.T3,
  AVAILABLE_PERIOD_TYPES.T6,
  AVAILABLE_PERIOD_TYPES.T12,
  AVAILABLE_PERIOD_TYPES.MONTH,
] as const;

export const FINANCIAL_TABLE_SINGLE_PERIOD_PERIOD_TYPES = [
  ...SUPPORTED_FINANCIAL_TABLE_SINGLE_PERIOD_PERIOD_TYPES,
  AVAILABLE_PERIOD_TYPES.YEAR,
  AVAILABLE_PERIOD_TYPES.QUARTER,
  AVAILABLE_PERIOD_TYPES.ITD,
] as const;

export const FINANCIAL_TABLE_SINGLE_PERIOD_PERIOD_TYPES_OPTIONS =
  AVAILABLE_PERIOD_TYPES_OPTIONS.filter((type) =>
    SUPPORTED_FINANCIAL_TABLE_SINGLE_PERIOD_PERIOD_TYPES.includes(type.value),
  );
