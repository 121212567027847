import React, { useEffect, useMemo } from 'react';
import Table from 'bundles/Shared/components/Table/Table';
import styles from '../capitalInvestmentsEditor/CapitaInvestmentEditor.module.scss';
import InvestmentEntityColumn from '../../ownershipTable/formatters/InvestmentEntityColumn';
import ReactDatePicker from 'react-datepicker';
import { STATIC_DATE_PICKER_PROPS } from '../consts';
import { IconButton, Select, Tumbler } from '@/stories';
import { Controller } from 'react-hook-form';

const options = [
  {
    id: 'monthly',
    label: 'Monthly',
  },
  {
    id: 'quarterly',
    label: 'Quarterly',
  },
] as const;

const SameValueForAllToggle = ({ value, onChange, text }) => (
  <Tumbler onChange={onChange} value={value}>
    {text}
  </Tumbler>
);

export const AccruedValuesEditor = ({ form }) => {
  const [sameStartDate, setSameStartDate] = React.useState(false);
  const [sameEndDate, setSameEndDate] = React.useState(false);
  const [samePeriod, setSamePeriod] = React.useState(false);

  const { getValues, setValue, control, trigger } = form;

  useEffect(() => {
    trigger();
  }, []);

  const dateForm = (rowId, fieldForUpdate, updateAll) => {
    const index = getValues('accrued_entries').findIndex(
      (entry) => entry.capital_investment_id === rowId,
    );

    return (
      <Controller
        control={control}
        name={`accrued_entries.${index}.${fieldForUpdate}`}
        render={({ field }) => (
          <ReactDatePicker
            {...STATIC_DATE_PICKER_PROPS}
            selected={field.value}
            onChange={(e) => {
              if (updateAll) {
                setValue(
                  'accrued_entries',
                  getValues('accrued_entries').map((entry) => ({
                    ...entry,
                    [fieldForUpdate]: e,
                  })),
                );
              } else {
                field.onChange(e);
              }
              trigger();
            }}
          />
        )}
      />
    );
  };

  const updateAllFields = (field, value) => {
    const entries = getValues('accrued_entries');

    const updatedEntries = entries.map((entry) => ({
      ...entry,
      [field]: value,
    }));

    setValue('accrued_entries', updatedEntries);
    trigger();
  };

  const updatePeriod = (field, option) => {
    if (samePeriod) {
      updateAllFields('period_type', option.id);
    } else {
      field.onChange(option?.id);
    }
    trigger();
  };

  const columns = useMemo(
    () => [
      {
        text: 'Investment Entity',
        dataField: 'investmentEntity',
        formatter: ({ row: item }) => (
          <InvestmentEntityColumn
            capitalInvestment={item}
            withIcon={false}
            withUsers={false}
          />
        ),
      },
      {
        text: 'Legal Entity',
        dataField: 'legalEntity',
        formatter: ({ row: item }) => (
          <span className="dark-60 inline-regular">
            {item.legalEntity.name}
          </span>
        ),
      },
      {
        text: (
          <div>
            <SameValueForAllToggle
              value={sameStartDate}
              onChange={(e) => {
                if (e.target.checked) {
                  updateAllFields('start_date', null);
                }
                setSameStartDate(e.target.checked);
              }}
              text="SAME DATE FOR ALL"
            />
            <div className="mt-s flex items-center gap-s">
              Start Date
              <IconButton
                iconName="reset"
                onClick={() => updateAllFields('start_date', null)}
              />
            </div>
          </div>
        ),
        dataField: 'start_date',
        headerStyle: {
          width: 220,
        },
        formatter: ({ row }) => dateForm(row.id, 'start_date', sameStartDate),
      },
      {
        text: (
          <div>
            <SameValueForAllToggle
              value={sameEndDate}
              onChange={(e) => {
                if (e.target.checked) {
                  updateAllFields('end_date', null);
                }
                setSameEndDate(e.target.checked);
              }}
              text="SAME DATE FOR ALL"
            />
            <div className="mt-s flex items-center gap-s">
              End Date (Optional)
              <IconButton
                iconName="reset"
                onClick={() => updateAllFields('end_date', null)}
              />
            </div>
          </div>
        ),
        dataField: 'end_date',
        headerStyle: {
          width: 220,
        },
        formatter: ({ row }) => dateForm(row.id, 'end_date', sameEndDate),
      },
      {
        text: (
          <div>
            <SameValueForAllToggle
              value={samePeriod}
              onChange={(e) => {
                if (e.target.checked) {
                  updateAllFields('period_type', null);
                }
                setSamePeriod(e.target.checked);
                trigger();
              }}
              text="SAME PERIOD FOR ALL"
            />
            <div className="mt-s flex items-center gap-s">
              Period
              <IconButton
                iconName="reset"
                onClick={() => updateAllFields('period_type', null)}
              />
            </div>
          </div>
        ),
        dataField: 'Period',
        headerStyle: {
          width: 220,
        },
        formatter: ({ row }) => {
          const index = getValues('accrued_entries').findIndex(
            (entry) => entry.capital_investment_id === row.id,
          );

          return (
            <Controller
              control={control}
              name={`accrued_entries.${index}.period_type`}
              render={({ field }) => (
                <Select
                  placeholder="Select Period"
                  size="m"
                  options={options}
                  selected={options.find((option) => option.id === field.value)}
                  onSelectedChange={(option) => updatePeriod(field, option)}
                />
              )}
            />
          );
        },
      },
    ],
    [sameStartDate, sameEndDate, samePeriod],
  );

  return (
    <>
      <Table
        borderLessOutside
        classes={{
          table: styles.table,
          container: styles.container,
          th: 'align-bottom',
        }}
        defaultColumn={{
          headerClasses: 'bg-white pt-tw-2 light-60',
        }}
        items={getValues('accrued_entries')}
        columns={columns}
      />
    </>
  );
};
