import DetailsCard from 'bundles/REturn/components/Home/detailsCard/DetailsCard';
import { CssVar } from '@/shared/config/cssVar';

export type TReturnDetailsColumns =
  | 'contributions'
  | 'distributions'
  | 'commitments'
  | 'yields';
type TDetailCardProps = Pick<
  React.ComponentProps<typeof DetailsCard>,
  'iconColor' | 'iconName' | 'title' | 'tab'
>;
export const DETAIL_CARD_PROPS_BY_RETURN_COLUMN: Record<
  TReturnDetailsColumns,
  TDetailCardProps
> = {
  commitments: {
    title: 'Commitments',
    iconName: 'handshake',
    iconColor: CssVar.pumpkin,
    tab: 'commitments',
  },
  contributions: {
    title: 'Contributions',
    iconName: 'contributions',
    iconColor: CssVar.violet,
    tab: 'contributions',
  },
  distributions: {
    title: 'Distributions',
    iconName: 'distribution',
    iconColor: CssVar.green,
    tab: 'distributions',
  },
  yields: {
    title: 'Yield',
    iconName: 'profitAlt',
    iconColor: CssVar.greenDark2,
  },
};
