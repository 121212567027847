import { useLoadedCapitalPreferredReturn } from '@/bundles/REturn/hooks/useLoadedCapitalPreferredReturn';
import { useLoadedCapitalUnitPrices } from '@/bundles/REturn/hooks/useLoadedUnitPrices';
import { CapitalInvestment } from '@/entities/return';
import {
  usePostApiCapitalInvestmentAccruedPreferredReturnsBulkMutation,
  usePostApiCapitalInvestmentCommitmentsBulkMutation,
  usePostApiCapitalInvestmentContributionsBulkMutation,
  usePostApiCapitalInvestmentDistributionsBulkMutation,
  usePostApiCapitalInvestmentsMutation,
} from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import { useNavigate, useParams } from '@reach/router';
import { CapitalInvestmentEntryType } from 'bundles/REturn/actions/types';
import AddContributionsModal from 'bundles/REturn/components/Ownership/modals/addContributionsModal/AddContributionsModal';
import AddDistributionsModal from 'bundles/REturn/components/Ownership/modals/addDistributionsModal/AddDistributionsModal';
import BulkCommitmentModal from 'bundles/REturn/components/Ownership/modals/bulkCommitmentModal/BulkCommitmentModal';
import CreateOwnerModal from 'bundles/REturn/components/Ownership/modals/createOwnerModal/CreateOwnerModal';
import { useLoadedInvestmentObject } from 'bundles/REturn/hooks/useLoadedInvestmentObject';
import { ICapitalInvestment } from 'bundles/REturn/types';
import useEvent from '@/shared/lib/hooks/useEvent';
import { useModal } from '@/shared/lib/hooks/useModal';
import {
  generateUrl,
  ROUTES_ROOT,
  TRouteParams,
} from '@/shared/lib/hooks/useNavigation';
import { convertDollarsToCents } from '@/shared/lib/converters';
import AddAccruedModal from '../modals/addAccruedModal/AddAccruedModal';

export interface NavigateToCreateEntryWorkflowParams {
  entryType: CapitalInvestmentEntryType;
  capitalInvestmentId?: ICapitalInvestment['id'];
}

export const useActions = (capitalInvestments: CapitalInvestment[]) => {
  const { openModal } = useModal();
  const navigate = useNavigate();
  const { objectId } = useParams<TRouteParams['RETURN_OWNERSHIPS_SCREEN']>();
  const { data: capitalInvestmentObject } = useLoadedInvestmentObject();
  const { data: preferredReturn } = useLoadedCapitalPreferredReturn();
  const { data: unitPrices } = useLoadedCapitalUnitPrices();

  const [createCapitalInvestments] = usePostApiCapitalInvestmentsMutation();
  const [createBulkCommitments] =
    usePostApiCapitalInvestmentCommitmentsBulkMutation();
  const [createBulkContributions] =
    usePostApiCapitalInvestmentContributionsBulkMutation();
  const [createBulkDistributions] =
    usePostApiCapitalInvestmentDistributionsBulkMutation();
  const [createBulkAccrued] =
    usePostApiCapitalInvestmentAccruedPreferredReturnsBulkMutation();

  const openBulkCommitmentModal = useEvent(async () => {
    const res = await openModal(
      BulkCommitmentModal,
      {
        capitalInvestments,
      },
      {
        topLevelRender: false,
      },
    );
    if (res == null) return;

    await createBulkCommitments({
      body: {
        commitment: res.map((ci) => ({
          investment_id: ci.id,
          amount_cents: ci.amount
            ? convertDollarsToCents(ci.amount)
            : undefined,
          signed_date: ci.date,
        })),
      },
    });
  });

  const openAddContributionsModal = useEvent(async () => {
    const res = await openModal(
      AddContributionsModal,
      {
        capitalInvestments,
        unitPrices,
      },
      {
        topLevelRender: false,
      },
    );

    if (res == null) return;

    await createBulkContributions({
      body: res,
    });
  });

  const openAddDistributionsModal = useEvent(async () => {
    const res = await openModal(
      AddDistributionsModal,
      {
        capitalInvestments,
        preferredReturn,
      },
      {
        topLevelRender: false,
      },
    );

    if (res == null) return;

    await createBulkDistributions({
      body: res,
    });
  });

  const openAddAccruedModal = useEvent(async () => {
    const res = await openModal(
      AddAccruedModal,
      {
        capitalInvestments,
      },
      {
        topLevelRender: false,
      },
    );

    if (res == null) return;

    await createBulkAccrued({
      body: res,
    });
  });

  const handleCreateOwner = useEvent(async () => {
    const res = await openModal(CreateOwnerModal, {
      entityId: capitalInvestmentObject.object.id,
      entityType: capitalInvestmentObject.object.objectType,
      disabledInvestmentEntitiesIds: capitalInvestments.map(
        ({ investmentEntity, legalEntity }) => ({
          investmentEntityId: investmentEntity.id,
          legalEntityId: legalEntity.id,
        }),
      ),
      legalEntities: capitalInvestmentObject.legalEntities,
    });
    if (res === undefined) return;

    await createCapitalInvestments({
      body: res,
    });
  });

  const navigateToCreateEntryWorkflow = ({
    entryType,
    capitalInvestmentId,
  }: NavigateToCreateEntryWorkflowParams) => {
    const url = generateUrl(ROUTES_ROOT.return.object.createEntry.fullPath, {
      pathParams: {
        objectId,
      },
      queryParams: {
        entryType,
        capitalInvestmentId,
      },
    });
    navigate(url);
  };

  return {
    openBulkCommitmentModal,
    openAddContributionsModal,
    openAddDistributionsModal,
    handleCreateOwner,
    navigateToCreateEntryWorkflow,
    openAddAccruedModal,
  };
};
