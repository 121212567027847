import {
  ReportScorecardDto,
  useGetApiSettingsReportDashboardsQuery,
} from 'bundles/Shared/entities/dashboard';

export const useGetApiSettingsReportDashboardsQueryById = (
  id: ReportScorecardDto['id'],
) => {
  const { data } = useGetApiSettingsReportDashboardsQuery();
  return data?.find((sb) => sb.id === id);
};

export const prepareDashboardNameForDuplicate = (name: string) =>
  `${name} Copy`;
