import {
  PeriodShiftConfig,
  ReportComparisonDashboardSection,
} from '@/bundles/Shared/entities/dashboard';
import { FinancialTableSingleDateWidgetConfigDto } from '@/bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { ExternalSourceType, LegalEntity } from '@/entities/core/legalEntity';
import { IAsset } from '@/types/Asset';

export const isFinancialTableSingleDateWidgetSection = (
  section: ReportComparisonDashboardSection,
): section is FinancialTableSingleDateWidgetSection =>
  section.widgetType === 'financial_table_single_date';

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace FinancialTableSingeDateWidgetNS {
  interface SnapshotData {
    agGridConfig: {
      width: number;
      type: string;
      columnDefs?: {
        headerName: string;
        groupId: number;
        children: { colId: string }[];
      }[];
    };
    groups: {
      asset: Pick<IAsset, 'id' | 'name'>;
      key: number;
      legalEntities: Pick<LegalEntity, 'id' | 'name' | 'code'>[];
      type: 'asset' | 'segment' | 'fund';
    }[];
    columns: Column[];
    rows: Row[];
    headerBackground: React.CSSProperties['color'];
    hideHeader: boolean;
    widgetType: string;
    vizConfig?: FinancialTableSingleDateWidgetConfigDto['viz_config'];
    vizType?: FinancialTableSingleDateWidgetConfigDto['viz_type'];
  }

  interface Column {
    key: number | string;
    subLabel: string;
    label: string;
    dateFrom: Date | null;
    dateTo: Date;
    /**
     * @deprecated
     */
    valueDisplayOptionsOverride?: ValueDisplayOptions;
  }

  type BaseRow = {
    path: string;
    label: string;
    displayOptions: DisplayOptions;
    values: Value[];
    valueDisplayOptions?: ValueDisplayOptions;
  };

  type GLRow = BaseRow & {
    type: 'general_ledger';
    legalEntityCode: string;
    erpSystem: ExternalSourceType;
  };

  type BlankRow = BaseRow & {
    type: 'blank';
  };

  type FormulaRow = BaseRow & {
    type: 'formula';
  };

  type CategoryRow = BaseRow & {
    type: 'category';
  };

  type Row = BlankRow | FormulaRow | CategoryRow | GLRow;

  interface DisplayOptions {
    fontWeight: null | string;
    color: null | string;
    background: null | string;
  }

  enum RowType {
    Blank = 'blank',
    Formula = 'formula',
  }

  interface ValueDisplayOptions {
    type: 'number' | 'percentage' | 'currency' | 'metric' | 'sqft';
    precision: number;
    kiloFormatting: boolean;
    hideCommaSeparator: boolean;
  }

  interface Value {
    expression: string;
    value: number;
    columnKey: number;
    groupKey: number;
  }

  type ExtraRowData = {
    valuesGroupedByColumnKey: Record<
      number,
      FinancialTableSingeDateWidgetNS.Value[]
    >;
    valuesGroupedByObjGroupAndColumnKey: Record<
      string,
      FinancialTableSingeDateWidgetNS.Value
    >;
  };

  type TableRowData = Row & ExtraRowData;
}

export type FinancialTableSingleDateWidgetSection = {
  id: string;
  title: string;
  widgetType: 'financial_table_single_date';
  widgetConfig: {
    columns: FinancialTableSingleDateWidgetConfigDto['columns'];
    ag_grid_config: {
      columnDefs: {
        children: {
          colId: number;
        }[];
        groupId: number;
        headerName: string;
      }[];
    };
    default_options: {
      date: {
        period_shift: PeriodShiftConfig;
      };
    };
    viz_config: FinancialTableSingleDateWidgetConfigDto['viz_config'];
    viz_type?: FinancialTableSingleDateWidgetConfigDto['viz_type'];
  };
  defaultOptions: {
    groupingType: 'assets' | 'segments';
    date: {
      period_shift: PeriodShiftConfig;
    };
  };
};
