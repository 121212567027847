import React, { useState } from 'react';
import { IconButton } from 'stories';
import { IAsset } from 'types/Asset';
import AssetFormModal from '../../../AssetFormModal';
import { heavyUpdateAsset } from '../../../../actions';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { ActionColumn } from 'bundles/Assets/components/Assets';

type ActionColumnFormatterParams = ActionColumn['formatterParams'];

interface Props extends ActionColumnFormatterParams {
  row: IAsset;
}

function ActionsCell(props: Props) {
  const { row: asset, loadAssets } = props;
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSubmit = async (newAsset: IAsset) => {
    await dispatch(heavyUpdateAsset(newAsset));
    await loadAssets();
    setIsEditing(false);
  };

  return (
    <>
      <IconButton iconName="edit" onClick={handleEdit} className="bg-white" />
      {isEditing && (
        <AssetFormModal
          asset={asset}
          onClose={() => setIsEditing(false)}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
}

export default ActionsCell;
