import React, { useState } from 'react';
import { useItemsFilterByText } from '@/shared/lib/hooks/useItemsFilterByText';
import { Button, Popover, SearchInput } from '@/stories';
import { DEFAULT_DROPDOWN_OFFSET } from 'stories/Popover/Popover';
import {
  getOptionsValues,
  mapItemsToListOption,
} from '@/shared/lib/listHelpers';
import pluralize from 'pluralize';
import { ReportDashboardAsset } from 'bundles/Shared/entities/dashboard';
import CheckList from 'stories/Checkbox/CheckList';

interface Props {
  assets: ReportDashboardAsset[];
  onChange: (id: number[]) => void;
  defaultValue?: ReportDashboardAsset['id'][];
  value?: ReportDashboardAsset['id'][];
}

export function AssetDropdown({
  defaultValue,
  assets,
  onChange,
  value: externalValue,
}: Props) {
  const [internalValue, setInternalValue] = useState(defaultValue);
  const value = externalValue ?? internalValue!;
  const { inputProps, filteredItems } = useItemsFilterByText(assets, 'name');

  const handleChange = (newAssetIds: number[]) => {
    onChange(newAssetIds);
    if (externalValue !== undefined) return;
    setInternalValue(newAssetIds);
  };

  return (
    <Popover
      className="flex max-h-[280px] flex-col gap-tw-2 px-0 py-tw-2"
      offset={DEFAULT_DROPDOWN_OFFSET}
      hiddenArrow
      trigger="click"
      placement={'bottom-start'}
      maxWidth={300}
      template={
        <>
          <SearchInput
            {...inputProps}
            className="mx-tw-2"
            placeholder="Search asset"
          />
          <CheckList
            className="overflow-y-auto px-tw-4 pt-tw-2"
            selectAll
            items={mapItemsToListOption(filteredItems, 'name')}
            onChange={(items) => handleChange(getOptionsValues(items))}
            value={mapItemsToListOption(
              filteredItems.filter((asset) => value.includes(asset.id)),
              'name',
            )}
          />
        </>
      }
    >
      <Button size="s" iconName="asset" variant="secondary">
        {pluralize('Asset', value.length, true)}
      </Button>
    </Popover>
  );
}
