import ItemFilterList from '@/bundles/Shared/components/Filters/items/ItemFilterList';
import MirrorModeCard from '@/bundles/Shared/components/LeftSidebar/cards/MirrorModeCard';
import {
  incrementPage,
  queryChange,
  roleChange,
  useGetRolesQuery,
  useGetUsersQuery,
} from '@/entities/impersonation/api';
import { cn } from '@/shared/lib/css/cn';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { typedLocalStorage } from '@/shared/lib/localStorage';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { login } from 'lib/currentUser';
import http from 'lib/http';
import { useRef, useState } from 'react';
import Linkify from 'react-linkify';
import { Icon, IconButton, LinkButton, Popover } from 'stories';
import { PopoverRef } from 'stories/Popover/Popover';
import { IUser, IUserRole } from 'types/User';
import styles from './Impersonation.module.scss';
import ImpersonationSwitchButton from './impersonationSwitchButton';

interface Props {
  currentUser: IUser;
}

export function UserInfo({ user }: { user: IUser }) {
  return (
    <>
      <div className="flex items-center gap-tw-2">
        <Icon
          iconName="email"
          className="rounded-[8px] bg-neutral-100 p-tw-1 text-neutral-500"
        />
        <a
          className="inline-regular text-info-055"
          href={`mailto:${user.email}`}
        >
          {user.email}
        </a>
      </div>
      {user.phone && (
        <div className="flex items-center gap-tw-2">
          <Icon
            iconName="phone"
            className="rounded-[8px] bg-neutral-100 p-tw-1 text-neutral-500"
          />
          <a
            className="inline-regular text-info-055"
            href={`tel:${user.phone}`}
          >
            {user.phone}
          </a>
        </div>
      )}
      {user.info && (
        <div className="flex max-w-[320px] items-start gap-tw-2 break-all">
          <Icon
            iconName="infoSmall"
            className="rounded-[8px] bg-neutral-100 p-tw-1 text-neutral-500"
          />
          <p className={cn('text-neutral-900', styles.textWithHyperlinks)}>
            <Linkify
              componentDecorator={(href, text, key) => (
                <a target="_blank" href={href} key={key}>
                  {text}
                </a>
              )}
            >
              {user.info}
            </Linkify>
          </p>
        </div>
      )}
    </>
  );
}

const PER_PAGE = 50;

function Impersonation({ currentUser }: Props) {
  const closeRef = useRef();
  const textRef = useRef();
  const dispatch = useAppDispatch();
  const userRolePopoverRef = useRef<PopoverRef>();
  const userPopoverRef = useRef<PopoverRef>();
  const currentRole = useAppSelector(
    (state) => state.impersonation.selectedRole,
  );
  const query = useAppSelector((state) => state.impersonation.query);
  const page = useAppSelector((state) => state.impersonation.page);

  const { data: usersData, isLoading: loadingUsersData } = useGetUsersQuery(
    {
      page,
      per_page: PER_PAGE,
      search_query: query,
      user_role_id: currentRole?.id,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const users = usersData?.items ?? [];
  const isLoadable = users.length < (usersData?.meta.totalSize ?? 0);
  const { data, isFetching: isFetchingUserRoles } = useGetRolesQuery(skipToken);

  const onQuery = (q: string) => {
    dispatch(queryChange(q));
  };

  const userRoles = [...(data?.items ?? [])];

  const STEPS = {
    0: {
      ref: textRef,
      text: 'You are now viewing a reflection of the portal as the user listed above',
      arrowPosition: 'left',
    },
    1: {
      ref: closeRef,
      text: 'To exit Mirror Mode: Simply click on the X icon above',
      arrowPosition: 'right',
    },
  };
  const [currentStep, setCurrentStep] = useState<number | null>(
    typedLocalStorage.getItem('impersonationIntro') !== null ? null : 0,
  );

  const handleNextStep = () => {
    if (currentStep !== null) {
      setCurrentStep(currentStep < 1 ? currentStep + 1 : null);
    }
    if (currentStep === 1) {
      typedLocalStorage.setItem('impersonationIntro', true);
    }
  };

  const personate = async () => {
    const res = await http.del('/user_impersonation');
    const json = await res.json();
    if (!json.token) return;

    login(json.token);
    window.location.href =
      typedLocalStorage.getItem('trueUserRedirectUrl') ?? '/';
    typedLocalStorage.removeItem('currentMirrorModeRole');
  };

  return (
    <div className="grid">
      <div className={styles.border_top} />
      <div className={styles.border_left} />
      <div className={styles.border_bottom} />
      <div className={styles.border_right} />

      <div className={styles.hint}>
        <div className={styles.rightNegativeBorder} />
        <div className={styles.leftNegativeBorder} />
        <span ref={textRef} className={styles.item}>
          You are now browsing the page as:
          <Popover
            template={
              <ItemFilterList
                className="max-h-[21.875rem]"
                items={[
                  { id: undefined, name: 'All Roles' },
                  ...userRoles.sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                  }),
                ]}
                getMainFieldTitle={(item) => item.name}
                getSecondaryFieldTitle={() => ''}
                selectedItems={[]}
                onSelectedItemChange={(role: IUserRole) => {
                  userRolePopoverRef.current?.hide();
                  userPopoverRef.current?.show();
                  dispatch(roleChange(role));
                }}
                searchInputProps={{
                  placeholder: 'Search by Role Name',
                }}
              />
            }
            maxWidth="none"
            style={{ width: '21.25rem' }}
            appendTo={document.body}
            ref={userRolePopoverRef}
            placement="bottom-end"
            hiddenArrow
            trigger="click"
            offset={[0, 12]}
            className="shadow-m-light overflow-hidden rounded-[1rem] border border-light-30 bg-light p-0"
          >
            <ImpersonationSwitchButton
              label={currentRole?.name ?? 'All Roles'}
              active={userRolePopoverRef?.current?.visible}
            />
          </Popover>
          <Popover
            template={
              <MirrorModeCard
                onAction={() => userPopoverRef.current?.hide()}
                users={users}
                withInfiniteScroll
                loading={isFetchingUserRoles || loadingUsersData}
                loadingMoreActive={isLoadable}
                onIntersectionLastItem={() => {
                  dispatch(incrementPage());
                }}
                onQueryChange={onQuery}
                query={query}
              />
            }
            maxWidth="none"
            ref={userPopoverRef}
            style={{ width: '21.25rem' }}
            appendTo={document.body}
            placement="bottom-end"
            hiddenArrow
            trigger="click"
            offset={[0, 12]}
            className="shadow-m-light overflow-hidden rounded-[1rem] border border-light-30 bg-light p-0"
          >
            <ImpersonationSwitchButton
              label={currentUser.fullName}
              active={userPopoverRef?.current?.visible}
            />
          </Popover>
          <Popover
            template={
              <div className="flex flex-col gap-tw-2 p-tw-1">
                <p className="header6-bold text-neutral-850">
                  {currentUser.fullName}
                </p>
                <UserInfo user={currentUser} />
              </div>
            }
            maxWidth="initial"
            hiddenArrow
            placement="bottom-end"
            appendToBody
            trigger="click"
          >
            <IconButton iconName="infoSmall" size="s" />
          </Popover>
          <LinkButton
            ref={closeRef}
            rel="nofollow"
            data-method="delete"
            onClick={personate}
          >
            <IconButton iconName="closeSmall" size="s" />
          </LinkButton>
        </span>

        {currentStep !== null && (
          <Popover
            className="px-m py-s"
            maxWidth="200px"
            arrowPosition={STEPS[currentStep].arrowPosition}
            template={
              <div className="white-text">
                <span className="inline-semibold mb-xs">Mirror Mode</span>
                <p className="secondary-regular mb-s">
                  {STEPS[currentStep].text}{' '}
                </p>

                <LinkButton
                  className="inline-semibold"
                  onClick={handleNextStep}
                >
                  Got it
                </LinkButton>
              </div>
            }
            placement="top-start"
            theme="dark"
            visible
            reference={STEPS[currentStep].ref}
          />
        )}
      </div>
    </div>
  );
}

export default Impersonation;
