import { useLoadedInvestmentObject } from '@/bundles/REturn/hooks/useLoadedInvestmentObject';
import { useDeleteApiCapitalInvestmentsByIdMutation } from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import { InvestmentIndexDto } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { useModal } from '@/shared/lib/hooks/useModal';
import ColumnActionsDropdown from 'bundles/REturn/components/Ownership/ownershipTable/ColumnActionsDropdown';
import {
  ActionsColumnDropdownText,
  ActionsColumnText,
} from 'bundles/REturn/components/Ownership/ownershipTable/Wrappers';
import {
  NavigateToCreateEntryWorkflowParams,
  useActions,
} from 'bundles/REturn/components/Ownership/ownershipTable/actions';
import InvestmentEntityColumn from 'bundles/REturn/components/Ownership/ownershipTable/formatters/InvestmentEntityColumn';
import NumberValueColumn from 'bundles/REturn/components/Ownership/ownershipTable/formatters/NumberValueColumn';
import {
  IReturnInvestmentObjectScreenData,
  TReturnInvestmentTransactionDirection,
} from 'bundles/REturn/types';
import QuickFilterCheckList, {
  getDefaultMappingToOption,
  TQuickFilterCheckListProps,
} from 'bundles/Shared/components/Table/filters/QuickFilterCheckList';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { map, round, uniq } from 'lodash-es';
import { useMemo } from 'react';
import {
  Button,
  CurrencyFormatter,
  IconButton,
  PercentFormatter,
  Popover,
  PseudoLink,
} from 'stories';
import { IUser } from 'types/User';
import UnitQuantity from '../../Home/formatters/UnitQuantity';
import { convertCentsToDollars } from '@/shared/lib/converters';

type TLegalEntity = IReturnInvestmentObjectScreenData['legalEntities'][number];

export const useColumns = (
  actions: Omit<ReturnType<typeof useActions>, 'handleCreateOwner'> & {
    openManageCapitalInvestmentModal: (data: {
      row: InvestmentIndexDto;
      kind: TReturnInvestmentTransactionDirection | 'commitment';
    }) => void;
    navigateToCreateEntryWorkflow: (
      params: NavigateToCreateEntryWorkflowParams,
    ) => void;
  },
  currentCustomerHasReportProduct: boolean,
) => {
  const { confirm } = useModal();
  const { data: capitalInvestmentObject } = useLoadedInvestmentObject();
  const { legalEntities, fromSourceEnabled } = capitalInvestmentObject;

  const [deleteCapitalInvestment] =
    useDeleteApiCapitalInvestmentsByIdMutation();

  const removeCapitalInvestment = async (id: number) => {
    const result = await confirm({
      title: 'Removing ownership',
      subtitle: <p>Are you sure you want to remove this?</p>,
    });

    if (!result) return;

    await deleteCapitalInvestment({ id });
  };

  return useMemo<IColumn<InvestmentIndexDto>[]>(
    () => [
      {
        dataField: 'investment_entity',
        text: 'Investment Entity',
        formatter: ({ row: item }) => (
          <InvestmentEntityColumn capitalInvestment={item} />
        ),
        headerClasses: 'min-w-[250px]',
        sortable: true,
      },
      {
        dataField: 'interest',
        text: 'Interest',
        headerClasses: 'min-w-[140px]',
        formatter: ({ row }) => (
          <div className="flex flex-col gap-xs">
            <PercentFormatter
              toLocalStringOptions={{
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }}
              value={row.interest}
              classes={{ wrapper: 'text-sm' }}
            />
            <div className="flex items-center">
              <span className="text-xs text-neutral-550">Class B:&nbsp;</span>
              <PercentFormatter
                toLocalStringOptions={{
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }}
                value={row.interestClassB}
                classes={{ wrapper: 'text-sm' }}
              />
            </div>
          </div>
        ),
      },
      {
        dataField: 'legal_entity_name',
        text: 'Legal Entity',
        headerClasses: 'min-w-[250px]',
        formatter: ({ row }) => (
          <span className="dark-60 secondary-regular">
            {row.legalEntity.name}
          </span>
        ),
        filterComponent:
          legalEntities.length > 1 ? QuickFilterCheckList : undefined,
        filterComponentParams: {
          items: legalEntities,
          ...getDefaultMappingToOption<TLegalEntity>((le) => le.name),
        } as TQuickFilterCheckListProps<TLegalEntity>,
        sortable: legalEntities.length > 1,
      },
      {
        headerAlign: 'between',
        textWrapperClasses: 'justify-between w-full',
        dataField: 'capital_investments.commitment_amount',
        text: (
          <ActionsColumnText title="Commitment | Sign">
            <Button
              variant="secondary"
              size="xs"
              className="border-none"
              onClick={actions.openBulkCommitmentModal}
            >
              Add
            </Button>
          </ActionsColumnText>
        ),
        headerStyle: {
          minWidth: 220,
        },
        formatter: ({ row }) => (
          <NumberValueColumn
            value={convertCentsToDollars(row.totalCommitmentsCents)}
            onEdit={() =>
              actions.openManageCapitalInvestmentModal({
                row,
                kind: 'commitment',
              })
            }
            units={row.totalCommitmentUnits}
            date={row.lastCommitmentSignedDate}
          />
        ),
      },
      {
        textWrapperClasses: 'justify-between w-full',
        dataField: 'total_contributions',
        quickFilter: (
          <ActionsColumnText title="Contribution">
            {fromSourceEnabled ? (
              <ColumnActionsDropdown
                actions={[
                  {
                    label: 'Manually',
                    handler: actions.openAddContributionsModal,
                  },
                  {
                    label: 'From Source',
                    hidden: !currentCustomerHasReportProduct,
                    handler: () =>
                      actions.navigateToCreateEntryWorkflow({
                        entryType: 'contributions',
                      }),
                  },
                ]}
              >
                <ActionsColumnDropdownText title="Contribution" />
              </ColumnActionsDropdown>
            ) : (
              <Button
                variant="secondary"
                size="xs"
                className="border-none"
                onClick={actions.openAddContributionsModal}
              >
                Add
              </Button>
            )}
          </ActionsColumnText>
        ),
        mapClasses: {
          headerFilterDiv: 'order-first',
        },
        headerStyle: {
          minWidth: 200,
        },
        formatter: ({ row }) => (
          <NumberValueColumn
            value={convertCentsToDollars(row.totalContributionsCents)}
            onEdit={() =>
              actions.openManageCapitalInvestmentModal({
                row,
                kind: TReturnInvestmentTransactionDirection.CONTRIBUTION,
              })
            }
            kind="contribution"
            totalCount={row.totalContributionsCount}
            units={row.totalContributionUnits}
            date={row.lastContributionDate}
          />
        ),
        sortable: true,
      },
      {
        textWrapperClasses: 'justify-between w-full',
        dataField: 'total_distributions',
        headerStyle: {
          minWidth: 200,
        },
        quickFilter: (
          <ActionsColumnText title="Distribution">
            {fromSourceEnabled ? (
              <ColumnActionsDropdown
                actions={[
                  {
                    label: 'Manually',
                    handler: actions.openAddDistributionsModal,
                  },
                  {
                    label: 'From Source',
                    hidden: !currentCustomerHasReportProduct,
                    handler: () =>
                      actions.navigateToCreateEntryWorkflow({
                        entryType: 'distributions',
                      }),
                  },
                ]}
              >
                <ActionsColumnDropdownText title="Distribution" />
              </ColumnActionsDropdown>
            ) : (
              <Button
                variant="secondary"
                size="xs"
                className="border-none"
                onClick={actions.openAddDistributionsModal}
              >
                Add
              </Button>
            )}
          </ActionsColumnText>
        ),
        mapClasses: {
          headerFilterDiv: 'order-first',
        },
        formatter: ({ row }) => (
          <NumberValueColumn
            value={row.totalDistributions}
            onEdit={() =>
              actions.openManageCapitalInvestmentModal({
                row,
                kind: TReturnInvestmentTransactionDirection.DISTRIBUTION,
              })
            }
            kind={TReturnInvestmentTransactionDirection.DISTRIBUTION}
            totalCount={row.totalDistributionsCount}
            date={row.lastDistributionDate}
          />
        ),
        sortable: true,
      },
      {
        dataField: 'total_accrued_ending_balance',
        headerStyle: {
          minWidth: 210,
        },
        quickFilter: (
          // TODO: FE-3021
          <ActionsColumnText title="Accrued Pref.">
            <Button
              variant="secondary"
              size="xs"
              className="border-none"
              onClick={actions.openAddAccruedModal}
            >
              Add
            </Button>
          </ActionsColumnText>
        ),
        mapClasses: {
          headerFilterDiv: 'order-first',
        },
        formatter: ({ row }) => (
          <div className="w-full">
            <NumberValueColumn
              value={convertCentsToDollars(row.accruedEndingBalanceCents)}
              // TODO: FE-3019
              onEdit={() =>
                actions.openManageCapitalInvestmentModal({
                  row,
                  kind: TReturnInvestmentTransactionDirection.ACCRUED,
                })
              }
              kind={TReturnInvestmentTransactionDirection.ACCRUED}
            />
          </div>
        ),
        sortable: true,
      },
      {
        dataField: 'actions',
        formatter: ({ row }) => (
          <div className="flex w-full justify-center">
            <IconButton
              tooltipProps={{
                disabled: row.removable,
                mainText:
                  'It is not possible to remove Investment Entities with active contributions or distributions.',
                placement: 'top-start',
                classes: { spanContainer: '' },
              }}
              variant="secondary"
              iconName="trash"
              disabled={!row.removable}
              onClick={() => removeCapitalInvestment(row.id)}
            />
          </div>
        ),
      },
    ],
    [currentCustomerHasReportProduct, legalEntities, fromSourceEnabled],
  );
};

export const getTotals = (capitalInvestments: InvestmentIndexDto[]) => {
  const totals = {
    users: [] as IUser[],

    commitmentsCents: 0,
    commitmentsUnits: 0,

    contributionsCents: 0,
    contributionsUnits: 0,

    classAContributionsCents: 0,
    classAContributionsUnits: 0,

    classBContributionsCents: 0,
    classBContributionsUnits: 0,

    distributions: 0,

    accruedEndingBalanceCents: 0,
  };

  capitalInvestments.forEach((investment) => {
    totals.users = [...totals.users, ...investment.investmentEntity.users];
    totals.commitmentsCents += investment.totalCommitmentsCents;
    totals.commitmentsUnits += investment.totalCommitmentUnits;
    totals.contributionsCents += investment.totalContributionsCents;
    totals.classAContributionsCents +=
      investment.totalClassAContributionsAmountCents;
    totals.classAContributionsUnits += Number(
      investment.totalClassAContributionsUnits,
    );

    totals.classBContributionsCents +=
      investment.totalClassBContributionsAmountCents;
    totals.classBContributionsUnits += investment.totalClassBContributionsUnits;

    totals.contributionsUnits += Number(investment.totalContributionUnits) ?? 0;
    totals.distributions += investment.totalDistributions;

    totals.accruedEndingBalanceCents += investment.accruedEndingBalanceCents;
  });

  const classAContributionsPercentage = round(
    totals.classAContributionsUnits
      ? (totals.classAContributionsUnits / totals.contributionsUnits) * 100
      : (totals.classAContributionsCents / totals.contributionsCents) * 100,
    2,
  );

  const classBContributionsPercentage = round(
    totals.classAContributionsUnits
      ? (totals.classBContributionsUnits / totals.contributionsUnits) * 100
      : (totals.classBContributionsCents / totals.contributionsCents) * 100,
    2,
  );

  return {
    users: uniq(map(totals.users, 'id')).length,
    commitments: convertCentsToDollars(totals.commitmentsCents),
    commitmentsUnits: totals.commitmentsUnits,
    contributions: convertCentsToDollars(totals.contributionsCents),
    contributionsUnits: totals.contributionsUnits,
    classAContributions: convertCentsToDollars(totals.classAContributionsCents),
    classAContributionsUnits: totals.classAContributionsUnits,
    classAContributionsPercentage,
    classBContributions: convertCentsToDollars(totals.classBContributionsCents),
    classBContributionsUnits: totals.classBContributionsUnits,
    classBContributionsPercentage,
    distributions: totals.distributions,
    accruedEndingBalanceTotals: convertCentsToDollars(
      totals.accruedEndingBalanceCents,
    ),
  };
};

export function ContributionsByClassTotalColumn({
  ...totals
}: {
  classAContributions: number;
  classAContributionsUnits: number;

  classBContributions: number;
  classBContributionsUnits: number;

  contributions: number;
  contributionsUnits: number;
}) {
  return (
    <Popover
      maxWidth={500}
      appendToBody
      placement="bottom"
      className="min-w-[120px]"
      template={
        <div className="flex flex-col gap-tw-2">
          <div className="flex flex-col gap-tw-1 p-tw-2">
            <p className="secondary-semibold text-neutral-900">
              {totals.classAContributionsPercentage}% Class A
            </p>
            <p className="flex items-center justify-between gap-tw-2">
              <CurrencyFormatter
                classes={{ value: 'text-neutral-900' }}
                value={totals.classAContributions}
              />
              <UnitQuantity units={totals.classAContributionsUnits} />
            </p>
          </div>

          <div className="h-1 w-full bg-neutral-100" />
          <div className="flex flex-col gap-tw-1 p-tw-2">
            <p className="secondary-semibold text-neutral-900">
              {totals.classBContributionsPercentage}% Class B
            </p>
            <p className="flex items-center justify-between gap-tw-2">
              <CurrencyFormatter
                classes={{ value: 'text-neutral-900' }}
                value={totals.classBContributions}
              />
              <UnitQuantity units={totals.classBContributionsUnits} />
            </p>
          </div>
        </div>
      }
    >
      <PseudoLink>
        <CurrencyFormatter
          classes={{ value: 'text-neutral-900' }}
          value={totals.contributions}
        />
        <UnitQuantity
          units={
            totals.classAContributionsUnits + totals.classBContributionsUnits
          }
        />
      </PseudoLink>
    </Popover>
  );
}

export const useTotalColumns = (capitalInvestments: InvestmentIndexDto[]) =>
  useMemo(() => {
    const totals = getTotals(capitalInvestments);
    return [
      {
        id: 'totals',
        text: 'TOTALS',
        className: 'inline-semibold',
      },
      {
        id: 'Empty-0',
      },
      {
        id: 'Empty-1',
      },
      {
        id: 'commitmentsTotals',
        text: (
          <>
            <CurrencyFormatter value={totals.commitments} />
            <UnitQuantity units={totals.commitmentsUnits} />
          </>
        ),
        className: 'inline-regular',
      },
      {
        id: 'contributionTotals',
        text: <ContributionsByClassTotalColumn {...totals} />,
        className: 'inline-regular',
      },
      {
        id: 'distributionTotals',
        text: <CurrencyFormatter value={totals.distributions} />,
        className: 'inline-regular',
      },
      {
        id: 'accruedEndingBalanceTotals',
        text: <CurrencyFormatter value={totals.accruedEndingBalanceTotals} />,
        className: 'inline-regular',
      },
      { id: 'Empty-3' },
    ];
  }, [capitalInvestments]);
